<!-- eslint-disable vue/require-default-prop -->
<script lang="ts" setup>
import AppState from '@/states/app'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FlipBackwardIcon,
  Share04Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIColorPicker,
  UIDivider,
  UIForm,
  UIFormItem,
  UIInput,
  UISwitch,
  UITag,
} from '@gohighlevel/ghl-ui'
import { isEqual } from 'lodash'
import { computed, h, reactive, ref, watch } from 'vue'
import customWidgetImage from '../../assets/custom_widget.svg'
import {
  defaultPrimarySettings,
  setDefaultPrimarySettings,
} from '../../utils/widgetConfiguration'

const isExpanded = ref(true)
const state = reactive({
  primarySettings: {
    primaryColor: '#178af6ff',
    backgroundColor: '#ffffffff',
    buttonText: 'Schedule Meeting',
    showCalendarTitle: true,
    showCalendarDescription: true,
    showCalendarDetails: true,
  },
  default: true,
})

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})
const colorPickerRenderer = (id: string, color: string) =>
  h('div', {
    id: id,
    class: 'h-[24px] w-[24px] pl-4 ml-5 rounded border border-gray-400',
    style: { backgroundColor: color },
  })

const onClickPreviewWidget = () => {
  const {
    backgroundColor,
    primaryColor,
    buttonText,
    showCalendarTitle,
    showCalendarDescription,
    showCalendarDetails,
  } = state.primarySettings

  const queryParams = new URLSearchParams({
    backgroundColor,
    primaryColor,
    buttonText,
    showCalendarTitle: String(showCalendarTitle),
    showCalendarDescription: String(showCalendarDescription),
    showCalendarDetails: String(showCalendarDetails),
    default: String(state.default),
  })

  window.open(
    `${AppState.baseUrl}/widget/booking/${editingCalendar.value?.id}?${queryParams}`,
    '_blank'
  )
}

const enableResetToDefault = computed(() => {
  return !isEqual(defaultPrimarySettings, state.primarySettings)
})

const onClickReset = () => {
  state.primarySettings = setDefaultPrimarySettings()
  state.default = true
}

const methods = {
  loadData() {
    state.primarySettings = editingCalendar.value?.widgetConfig?.primarySettings
      ? editingCalendar.value?.widgetConfig?.primarySettings
      : setDefaultPrimarySettings()
    state.default = editingCalendar.value?.widgetConfig?.default ?? true
  },
  getWidgetConfiguration() {
    return state
  },
}

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

defineExpose({
  methods,
})

const onClickLearnMore = () => {
  window.open(
    'https://help.leadconnectorhq.com/en/support/solutions/articles/155000001509-calendar-widget-customization',
    '_blank'
  )
}

const onPrimaryColorChange = (value: string) => {
  state.primarySettings.primaryColor = value
  state.default = false
}

const onBackgroundColorChange = (value: string) => {
  state.primarySettings.backgroundColor = value
  state.default = false
}
</script>

<template>
  <div class="mt-5 w-full rounded-lg border-[1px] border-gray-200">
    <div
      id="expand-customization-accordion"
      class="flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-lg bg-gray-50 px-5 pb-5 pt-6 transition-all"
      :class="{ 'border-b-[1px]': isExpanded }"
      role="button"
      @click="isExpanded = !isExpanded"
      @keydown="isExpanded = !isExpanded"
    >
      <img
        :src="customWidgetImage"
        alt="Custom Widget Image"
        class="h-28 w-44"
      />
      <div class="ml-5 flex w-2/3 flex-col gap-2">
        <div
          class="flex flex-col items-baseline gap-3 text-xl font-medium text-gray-700"
        >
          {{ $t('widget_configuration.customise_calendar_widget') }}
          <UITag type="primary" :closable="false" size="medium" :round="true">
            {{ $t('widget_configuration.works_with_neo_widget') }}
          </UITag>
        </div>
        <div class="mt-1 font-light leading-6 text-gray-600">
          {{ $t('widget_configuration.customise_widget_desc') }}
          <span
            class="cursor-pointer font-light text-primary-600"
            role="button"
            @click="onClickLearnMore"
            @keydown="onClickLearnMore"
          >
            {{ $t('widget_configuration.learn_more') }}</span
          >
        </div>
      </div>
      <ChevronDownIcon v-if="!isExpanded" class="h-5 w-5" />
      <ChevronUpIcon v-else class="h-5 w-5" />
    </div>
    <UIForm
      v-if="isExpanded"
      id="widget-configuration-form"
      ref="formRef"
      class="mt-5 px-6 pb-4"
    >
      <div class="mb-6">
        <div class="font-medium text-gray-700">
          {{ $t('widget_configuration.primary_settings') }}
        </div>
        <div class="text-xs font-light leading-6 text-gray-600">
          {{ $t('widget_configuration.primary_settings_desc') }}
        </div>
      </div>

      <!-- ================ Primary Color ================ -->
      <div class="font-normal text-gray-700">
        {{ $t('widget_configuration.primary_color') }}
        <div class="text-xs font-light leading-6 text-gray-600">
          {{ $t('widget_configuration.primary_color_desc') }}
        </div>
      </div>
      <UIFormItem
        class="mb-5 mt-2 flex w-1/2 flex-row items-center gap-3 rounded-lg border-[1px] border-gray-300 px-[6px] py-[3px]"
      >
        <UIColorPicker
          v-model:value="state.primarySettings.primaryColor"
          :custom-trigger="
            () =>
              colorPickerRenderer(
                'primary-color-picker',
                state.primarySettings.primaryColor
              )
          "
          @on-complete="(value: string) => onPrimaryColorChange(value)"
        />
        <div class="pl-10 text-gray-500">
          {{ state.primarySettings.primaryColor }}
        </div>
      </UIFormItem>

      <!-- ================ Background Color ================ -->
      <div class="font-normal text-gray-700">
        {{ $t('widget_configuration.background_color') }}
        <div class="text-xs font-light leading-6 text-gray-600">
          {{ $t('widget_configuration.background_color_desc') }}
        </div>
      </div>
      <UIFormItem
        class="mb-5 mt-2 flex w-1/2 flex-row items-center gap-3 rounded-lg border-[1px] border-gray-300 px-[6px] py-[3px]"
      >
        <UIColorPicker
          v-model:value="state.primarySettings.backgroundColor"
          :custom-trigger="
            () =>
              colorPickerRenderer(
                'background-color-picker',
                state.primarySettings.backgroundColor
              )
          "
          @on-complete="(value: string) => onBackgroundColorChange(value)"
        />
        <div class="pl-10 text-gray-500">
          {{ state.primarySettings.backgroundColor }}
        </div>
      </UIFormItem>

      <!-- ================ Button Text ================ -->
      <div class="font-normal text-gray-700">
        {{ $t('widget_configuration.button_text') }}
        <div class="text-xs font-light leading-6 text-gray-600">
          {{ $t('widget_configuration.button_text_desc') }}
        </div>
      </div>
      <div class="mb-2 mt-2 w-1/2">
        <UIInput
          id="button-text-input"
          v-model="state.primarySettings.buttonText"
          placeholder="Schedule Meeting"
          type="text"
          :maxlength="50"
        />
      </div>

      <!-- ================ Calendar Toggle ================ -->
      <UIFormItem>
        <div class="flex flex-col gap-5">
          <div class="flex flex-row gap-5">
            <div id="show-calendar-title-toggle">
              <UISwitch
                v-model:value="state.primarySettings.showCalendarTitle"
                aria-label="Show Calendar Title"
                class="mt-1"
                :on-update:value="
                  value => (state.primarySettings.showCalendarTitle = value)
                "
              />
            </div>
            <div class="flex flex-col gap-[3px]">
              <div class="font-normal text-gray-700">
                {{ $t('widget_configuration.calendar_title') }}
              </div>
              <div class="text-xs font-light text-gray-600">
                {{ $t('widget_configuration.calendar_title_desc') }}
              </div>
            </div>
          </div>

          <div class="flex flex-row gap-5">
            <div id="show-calendar-description-toggle">
              <UISwitch
                v-model:value="state.primarySettings.showCalendarDescription"
                :title="$t('widget_configuration.show_calendar_description')"
                aria-label="Show Calendar Description"
                class="mt-1"
                :on-update:value="
                  value =>
                    (state.primarySettings.showCalendarDescription = value)
                "
              />
            </div>
            <div class="flex flex-col gap-[3px]">
              <div class="font-normal text-gray-700">
                {{ $t('widget_configuration.calendar_description') }}
              </div>
              <div class="text-xs font-light text-gray-600">
                {{ $t('widget_configuration.calendar_description_desc') }}
              </div>
            </div>
          </div>

          <div id="show-calendar-details-toggle" class="flex flex-row gap-5">
            <UISwitch
              v-model:value="state.primarySettings.showCalendarDetails"
              aria-label="Show Calendar Details"
              class="mt-1"
              :on-update:value="
                value => (state.primarySettings.showCalendarDetails = value)
              "
            />
            <div class="flex flex-col gap-[3px]">
              <div class="font-normal text-gray-700">
                {{ $t('widget_configuration.calendar_details') }}
              </div>
              <div class="text-xs font-light text-gray-600">
                {{ $t('widget_configuration.calendar_details_desc') }}
              </div>
            </div>
          </div>
        </div>
      </UIFormItem>

      <UIDivider />

      <div class="mb-2 flex w-full items-center justify-end gap-6">
        <div
          id="reset-default-button"
          class="flex cursor-pointer flex-row"
          :class="{ 'pointer-events-none opacity-50': !enableResetToDefault }"
          role="button"
          @click="onClickReset"
          @keydown="onClickReset"
        >
          <FlipBackwardIcon class="mr-2 h-5 w-5 text-gray-600" />
          <div class="font-medium text-gray-600">
            {{ $t('widget_configuration.reset_to_default') }}
          </div>
        </div>

        <UIButton
          id="preview-widget-button"
          type="tertiary"
          size="small"
          :text="false"
          :style="{ backgroundColor: '#EFF4FF' }"
          @click="onClickPreviewWidget"
        >
          <Share04Icon class="mr-2 h-5 w-5 text-primary-600" />
          {{ $t('widget_configuration.preview_widget') }}
        </UIButton>
      </div>
    </UIForm>
  </div>
</template>
