import AppState from '@/states/app'

export function toggleAdvancedView() {
  AppState.showAdvancedCalendarSettings = !AppState.showAdvancedCalendarSettings
  AppState.parentApp?.then(parent => {
    parent.emit('toggle-advanced-view', {})
  })
}

export function toggleAdvancedServiceMenuView() {
  AppState.showAdvancedServiceMenuSettings =
    !AppState.showAdvancedServiceMenuSettings
  AppState.parentApp?.then(parent => {
    parent.emit('toggle-advanced-view', {})
  })
}
