<script setup lang="ts">
import { MyAvailability } from '@/class/pages/MyAvailability'
import AppState, { fetchUserDetails } from '@/states/app'
import { timezones } from '@/utils/timezones'
import { computedTimezones } from '@/utils/timezonesOptions'
import {
  Copy01Icon,
  PlusIcon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UICheckbox,
  UIFormItem,
  UISelect,
  UISpin,
  UITimePicker,
} from '@gohighlevel/ghl-ui'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const myAvailability = ref(new MyAvailability(timezones[0].value))

const loadData = async () => {
  if (!AppState.user?.id || !AppState.locationId) {
    return
  }

  await fetchUserDetails(AppState.user?.id)

  if (
    AppState.loggedInUserDetail?.locationWiseTimezone &&
    AppState.loggedInUserDetail?.locationWiseTimezone[AppState.locationId]
  ) {
    myAvailability.value.selectedUserTimezone =
      AppState.loggedInUserDetail?.locationWiseTimezone[AppState.locationId]
  }

  myAvailability.value.setOpenHoursDataFromDatabase(
    (AppState.loggedInUserDetail?.locationWiseOpenHours &&
      AppState.loggedInUserDetail?.locationWiseOpenHours[
        AppState.locationId
      ]) ||
      [],
    false
  )
}

onMounted(async () => {
  await loadData()
})

watch(
  () => AppState.locationId,
  () => {
    loadData()
  }
)

const dayTranslation = ref({
  Sunday: t('settings.availability.sunday'),
  Monday: t('settings.availability.monday'),
  Tuesday: t('settings.availability.tuesday'),
  Wednesday: t('settings.availability.wednesday'),
  Thursday: t('settings.availability.thursday'),
  Friday: t('settings.availability.friday'),
  Saturday: t('settings.availability.saturday'),
})

watch(
  () => AppState.user?.id,
  () => {
    loadData()
  }
)
</script>

<template>
  <div>
    <div class="w-96">
      <UIFormItem :label="$t('settings.availability.timezone')">
        <UISelect
          id="select-timezone-dropdown-availability"
          v-model:value="myAvailability.selectedUserTimezone"
          :loading="!myAvailability.isReady"
          :options="computedTimezones"
          :filterable="true"
          placeholder="Select one"
        />
      </UIFormItem>
    </div>

    <div class="w-full py-2">
      <h3 class="text-lg">
        {{ $t('settings.availability.working_hours') }}
      </h3>
      <p class="text-gray-400">
        {{ $t('settings.availability.set_recurring_hours') }}
      </p>
    </div>

    <div v-if="myAvailability.isReady">
      <div class="my-3 flex border-b border-t py-4">
        <UICheckbox
          v-for="day in MyAvailability.OFFICE_DAYS"
          :key="day"
          v-model:checked="myAvailability.dayWiseOpenMap[day]"
          :name="day"
          class="w-38 mr-2 flex content-center items-center rounded-md border bg-white px-4 py-3"
          :class="{
            'border-primary-500 bg-primary-50':
              myAvailability.availableDays.find(x => x.day === day),
          }"
          @change="
            x =>
              x
                ? myAvailability.addAvailabilityDay(day)
                : myAvailability.removeAvailabilityDay(day)
          "
        >
          <span class="ml-2">{{ dayTranslation[day] }}</span>
        </UICheckbox>
      </div>

      <div>
        <div
          v-for="(availableDay, index) in myAvailability.availableDays"
          :key="index"
          class="my-4 flex"
        >
          <div class="font-md my-2 mr-32 w-12 font-semibold text-gray-700">
            {{ dayTranslation[availableDay.day] }}
          </div>

          <div>
            <div
              v-for="(availableDayHour, hourIndex) in availableDay.hours"
              :key="hourIndex"
              class="my-2 flex items-center"
            >
              <UITimePicker
                v-model:formatted-value="availableDayHour.startTime"
                :placeholder="$t('settings.availability.select_time')"
                value-format="H:m"
                :minutes="5"
                format="hh:mm a"
                use-12-hours
                :actions="['confirm']"
                :status="
                  availableDay.hoursErrorMapping[hourIndex] ||
                  !availableDayHour.startTime
                    ? 'error'
                    : 'success'
                "
              />

              <span class="mx-2 font-semibold">To</span>
              <UITimePicker
                v-model:formatted-value="availableDayHour.endTime"
                :placeholder="$t('settings.availability.select_time')"
                value-format="H:m"
                :minutes="5"
                format="hh:mm a"
                use-12-hours
                :actions="['confirm']"
                :status="
                  availableDay.hoursErrorMapping[hourIndex] ||
                  !availableDayHour.endTime
                    ? 'error'
                    : 'success'
                "
              />

              <UIButton
                v-if="hourIndex > 0"
                id=""
                class="ml-4"
                @click="availableDay.removeAvailabilityHour(hourIndex)"
              >
                <Trash01Icon class="w-4" />
              </UIButton>

              <UIButton
                v-if="index === 0 && hourIndex === 0"
                id=""
                class="ml-4"
                @click="
                  x => myAvailability.copyAvailabilieToAllDays(availableDay)
                "
              >
                <Copy01Icon class="mr-2 w-4" />
                {{ $t('settings.availability.copy_to_all') }}
              </UIButton>
            </div>

            <UIButton
              id=""
              :text="true"
              class="mt-2"
              aria-label="Add Time"
              @click="x => availableDay.addNewAvailabilityHour()"
            >
              <PlusIcon class="h-4 w-4" />
              {{ $t('settings.availability.add_time') }}
            </UIButton>
          </div>
        </div>

        <div
          :class="{
            'ml-40 mt-2 pl-3': myAvailability.availableDays.length > 0,
          }"
        >
          <UIButton
            id=""
            :loading="myAvailability.isProcessing"
            :disabled="myAvailability.hasAnyInvalidHours"
            type="primary"
            @click="myAvailability.saveMyAvailability()"
          >
            {{ $t('settings.availability.save_availability') }}
          </UIButton>
        </div>
      </div>
    </div>

    <div v-else class="mt-5">
      <UISpin :show="true" />
    </div>
  </div>
</template>
