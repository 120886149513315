{
  "settings" : {
    "calendar" : {
      "calendar_settings" : "Configuración del calendario",
      "select_calendar_type" : "Selecciona el tipo de calendario",
      "add_new_calendar_group" : "Añadir un nuevo grupo de calendarios",
      "add_new_equipment" : "Añadir un nuevo equipamiento",
      "new_calendar" : "Nuevo calendario",
      "calendar_group_description" : "Usa los grupos de calendarios para organizar y agrupar varios calendarios de forma eficaz.",
      "group" : "Grupo",
      "manage_your_calendars_and_groups" : "Administra tus calendarios y grupos",
      "groups" : "Grupos",
      "create_group" : "Crear grupo",
      "create_calendar" : "Crear calendario",
      "calendar_name" : "Nombre del calendario",
      "group_duration" : "Duración del grupo",
      "calendar_type" : "Tipo de calendario",
      "action" : "Acción",
      "round_robin" : "Rotación",
      "event" : "Evento",
      "group_name" : "Nombre del grupo",
      "room_name" : "Nombre de la sala",
      "group_description" : "Descripción del grupo",
      "calendar_url" : "URL del calendario",
      "calendars" : "Calendarios",
      "date_updated" : "Fecha actualizada",
      "status" : "Estado",
      "action_dropdown" : "Menú desplegable de acciones",
      "calendar_list" : "Servicios asociados a",
      "edit" : "Editar",
      "duplicate" : "Duplicar",
      "copy_embed_code" : "Copiar el código de inserción",
      "copy_permanent_link" : "Copiar el enlace permanente",
      "copy_new_slug_link" : "Copiar el enlace de programación",
      "copy_scheduling_link" : "Copiar el enlace de programación (obsoleto)",
      "deactivate_calendar" : "Desactivar el calendario",
      "move_to_group" : "Mover al grupo",
      "delete_calendar" : "Eliminar calendario",
      "delete_group" : "Borrar",
      "delete_this_group" : "Elimina este grupo",
      "activate_calendar" : "Activar calendario",
      "duration" : "Duración (minutos)",
      "all" : "Todo",
      "draft" : "Borrador",
      "active" : "Activo",
      "rearrange_calendars" : "Reorganizar calendarios",
      "deactivate_all_calendars_in_group" : "Desactivar todos los calendarios del grupo",
      "activate_all_calendars_in_group" : "Activar todos los calendarios del grupo",
      "no_groups_found" : "Tu búsqueda no coincide con ningún grupo de calendarios.",
      "no_groups_in_location" : "Empieza a usar grupos creando un nuevo grupo de calendario.",
      "no_calendars_found" : "Empieza a usar el calendario creando uno nuevo.",
      "no_calendars_found_search" : "Tu búsqueda no coincide con ningún calendario.",
      "delete_calendar_message" : "Estás a punto de eliminar el calendario. Cuando pulses borrar, también se borrarán todas las citas.",
      "delete_calendar_message_title" : "¿Eliminar este calendario?",
      "duplicate_calendar_message" : "Estás a punto de duplicar el calendario. Esto creará un nuevo calendario con la misma configuración que el calendario seleccionado.",
      "duplicate_calendar_message_title" : "¿Duplicar este calendario?",
      "no_calendars_found_title" : "No se han encontrado calendarios",
      "no_groups_in_location_title" : "No se han encontrado grupos",
      "delete_group_confirmation_title" : "¿Eliminar este grupo?",
      "delete_group_confirmation" : "¿Estás seguro de que deseas eliminar el grupo seleccionado?",
      "delete_group_confirmation_checkbox_title" : "Eliminar los calendarios y citas asociados",
      "delete_group_confirmation_checkbox_description" : "Al seleccionar esta opción, también se eliminarán los calendarios de este grupo, junto con todas las citas de esos calendarios.",
      "group_status_message_title" : "¿{status} este grupo?",
      "calendar_status_message_title" : "¿{status} este calendario?",
      "calendar_status_message" : "Estás a punto de {status} el calendario",
      "group_status_message" : "Estás a punto de {status} el grupo",
      "coming_soon" : "Próximamente",
      "select" : "Seleccionar",
      "15mins" : "15 minutos",
      "cancel" : "Cancelar",
      "save" : "Guardar",
      "create" : "Crear",
      "select_calendar_group" : "Seleccionar grupo de calendarios",
      "move_calendar_confirmation" : "Ten en cuenta que el enlace antiguo dejará de funcionar una vez que hagas el cambio. Confirma seleccionando la casilla de verificación.",
      "previously_called_unassigned" : " (Anteriormente conocido como no asignado)",
      "scheduling_type" : "Tipo de programación",
      "scheduling_type_description" : "Elige un tipo de programación para tu nuevo calendario",
      "service_menu_name" : "Nombre del menú de servicios",
      "template" : "Plantilla",
      "group_url" : "URL del grupo",
      "template_description" : "Ahora tienes la opción de seleccionar las plantillas clásicas o Neo para la vista de grupo",
      "rooms" : "Salas",
      "rooms_name" : "Nombre",
      "rooms_description" : "Descripción",
      "rooms_modal_description" : "Añadir o editar una sala",
      "rooms_capacity" : "Capacidad total",
      "no_rooms_in_location_title" : "No se han encontrado salas",
      "no_rooms_in_location" : "No se han encontrado salas en esta ubicación",
      "no_equipments_in_location" : "No se ha encontrado ningún equipamiento en esta ubicación",
      "equipments" : "Equipamiento",
      "equipment_name" : "Nombre",
      "equipment_name_search" : "Nombre del equipamiento",
      "equipment_description" : "Descripción",
      "equipment_modal_description" : "Añadir o editar un equipamiento",
      "add_equipment" : "Añadir/editar equipamiento",
      "add_room" : "Añadir/editar sala",
      "equipment_quantity" : "Cantidad total",
      "equipment_outOfService" : "Cantidad fuera de servicio",
      "no_equipments_in_location_title" : "No se ha encontrado ningún equipamiento",
      "delete_this_equipment" : "Eliminar este equipamiento",
      "delete_this_rooms" : "Eliminar esta sala",
      "delete_equipment_confirmation" : "¿Estás seguro de que deseas eliminar este equipamiento? También se eliminará de todos los calendarios vinculados.",
      "delete_rooms_confirmation" : "¿Estás seguro de que deseas borrar esta sala? También se eliminará de todos los calendarios vinculados.",
      "add_new_calendar_equipments" : "Añadir nuevo equipamiento",
      "no_equipments_found" : "Tu búsqueda no coincide con ningún equipamiento.",
      "no_rooms_found" : "Tu búsqueda no coincide con ninguna sala.",
      "add_new_calendar_rooms" : "Añadir nueva sala",
      "rooms_disable" : "Desactivar la sala",
      "equipments_disable" : "Desactivar equipamiento",
      "equipments_activate" : "Activar equipamiento",
      "rooms_activate" : "Activar sala",
      "create_equipments" : "Crear equipamiento",
      "create_rooms" : "Crear sala",
      "select_calendar_rooms_title" : "Selecciona calendario",
      "select_calendar_rooms_description" : "Puedes seleccionar varios calendarios para asociarlos con las salas",
      "select_calendar_equipments_title" : "Selecciona calendario",
      "select_calendar_equipments_description" : "Puedes seleccionar un calendario para asociarlo con el equipamiento",
      "disable_equipment_title" : "{status} equipamiento",
      "disable_room_title" : "{status} sala",
      "equipments_status_message" : "¿Estás seguro de que deseas {status} este equipamiento?",
      "rooms_status_message" : "¿Estás seguro de que deseas {status} esta sala?",
      "calendar_resource_disclaimer" : "Esto podría afectar la disponibilidad en los calendarios de servicio asociados.",
      "equipment_disclaimer" : "Nota: Al habilitar el equipamiento posteriormente, se volverá a conectar automáticamente a los calendarios con los que está asociado",
      "room_disclaimer" : "Nota: Al habilitar la sala posteriormente, se volverá a conectar automáticamente a los calendarios a los que está asociada",
      "equipment_tab_name" : "Equipamiento",
      "rooms_tab_name" : "Salas",
      "manage_your_rooms" : "Gestiona aquí las salas de tu negocio",
      "manage_your_equipments" : "Gestiona aquí el equipamiento de tu empresa",
      "select_service_calendar_description_equipments" : "Puedes seleccionar varios calendarios de servicio para asociarlos con el equipamiento",
      "select_service_calendar_description_rooms" : "Puedes seleccionar varios calendarios de servicio para asociarlos a las salas",
      "service_menus" : "Menú de servicios",
      "manage_your_calendars_groups_and_service_menus" : "Gestiona tus calendarios, grupos y menú de servicios",
      "new_service_menu" : "Crear menú de servicios",
      "deactivate_service_menu" : "Desactivar el menú de servicios",
      "delete_service_menu" : "Elimina este menú de servicio",
      "delete_service_menu_confirmation" : "¿Estás seguro de que deseas eliminar este menú de servicios?",
      "service_menu_status_message_title" : "¿{status} este menú de servicios?",
      "service_menu_status_message" : "Estás a punto de {status} el menú de servicios",
      "no_service_menus_in_location_title" : "Crea tu menú de servicio personalizado",
      "add_new_service_menu" : "Añadir nuevo menú de servicios",
      "no_service_menus_in_location" : "Empieza por crear un nuevo menú de servicios y añádele grupos",
      "no_service_menu_found" : "No se ha encontrado ningún menú de servicios",
      "no_service_menu_found_desc" : "Tu búsqueda no coincide con ningún menú de servicios.",
      "apply" : "Aplicar",
      "troubleshoot_calendar" : "Solucionar problemas del calendario",
      "round_robin_booking" : "Rotación",
      "round_robin_description" : "Distribuye las citas entre los miembros del equipo en orden rotativo.",
      "round_robin_example" : "Por ejemplo: llamadas de ventas, sesiones de incorporación.",
      "unassigned_booking" : "Calendario de eventos",
      "unassigned_description" : "Para programar eventos presenciales sin estar asociados a un anfitrión.",
      "unassigned_example" : "Por ejemplo: conferencias, seminarios públicos, exposiciones.",
      "class_booking" : "Reserva de clases",
      "class_booking_description" : "Un anfitrión se reúne con varios participantes.",
      "class_booking_example" : "Por ejemplo: webinarios, formación en grupo, clases en línea.",
      "collective_booking" : "Reserva colectiva",
      "collective_booking_description" : "Varios anfitriones se reúnen con un participante.",
      "collective_booking_example" : "Por ejemplo: entrevistas grupales, revisiones de comité.",
      "service_booking" : "Reserva de servicio",
      "service_booking_description" : "Programación fluida para empresas de servicios.",
      "service_booking_example" : "Por ejemplo: reservas en balnearios, servicios de reparación, consultas.",
      "personal_booking" : "Reserva personal",
      "personal_booking_description" : "Programa reuniones individuales con un miembro determinado del equipo.",
      "personal_booking_example" : "Por ejemplo: reuniones con clientes, consultas privadas.",
      "personal" : "Personal",
      "explore_more_types" : "Explora más tipos"
    },
    "preferences" : {
      "preferences" : "Preferencias",
      "my_preferences" : "Mi preferencia",
      "account_preferences" : "Preferencia de cuenta",
      "user_preferences" : "Preferencias de usuario",
      "set_preferences" : "Configura tus preferencias para tu cuenta.",
      "scheduling_options" : "Opciones de programación",
      "meeting_location" : "Lugar de la reunión",
      "zoom" : "Zoom",
      "save_preferences" : "Guardar preferencias",
      "in_app_preferences" : "En las preferencias de la aplicación",
      "set_start_day" : "Configurar preferencias como el día de inicio para la aplicación",
      "view_options" : "Opciones de visualización",
      "week_starts_on" : "La semana empieza el",
      "sunday" : "Domingo",
      "widget_preferences" : "Preferencias de widgets",
      "set_widget_language" : "Configurar preferencias como el idioma para el widget",
      "language_and_region" : "Idioma y región",
      "language" : "Idioma",
      "english" : "Inglés",
      "time_format" : "Formato de tiempo",
      "time_format_value" : "13:30",
      "monday" : "Lunes",
      "custom_meeting_location" : "Ubicación de reunión personalizada"
    },
    "availability" : {
      "availability" : "Disponibilidad",
      "my_availability" : "Mi disponibilidad",
      "account_availability" : "Disponibilidad de la cuenta",
      "timezone" : "Zona horaria",
      "working_hours" : "Horario laboral",
      "set_recurring_hours" : "Establece tus horas de trabajo recurrentes para la reunión",
      "sunday" : "Domingo",
      "monday" : "Lunes",
      "tuesday" : "Martes",
      "wednesday" : "Miércoles",
      "thursday" : "Jueves",
      "friday" : "Viernes",
      "saturday" : "Sábado",
      "widget_preferences" : "Preferencias de widgets",
      "set_widget_language" : "Configurar preferencias como el idioma para el widget",
      "language_and_region" : "Idioma y región",
      "language" : "Idioma",
      "english" : "Inglés",
      "copy_to_all" : "Copiar a todos",
      "save_availability" : "Guardar disponibilidad",
      "add_time" : "Añadir hora",
      "select_time" : "Seleccionar la hora"
    },
    "connections" : {
      "connections" : "Conexiones",
      "connect" : "conectar",
      "conferencing" : "Conferencias",
      "my_connections" : "Mis conexiones",
      "account_connections" : "Conexiones de cuenta",
      "connection_preferences" : "Preferencias de conexión",
      "manage_calendars_conferencing_channels" : "Gestionar calendarios y canales de conferencias",
      "main_integration_calendar" : "Calendario de integración principal",
      "event_integration_calendar" : "Los eventos creados en un calendario del sistema del que formes parte también se crearán en este calendario integrado",
      "2way_sync" : "Sincronización de bidireccional: crea eventos del sistema a partir de eventos reservados en el calendario integrado principal",
      "calendars" : "Calendarios",
      "google" : "Google",
      "outlook" : "Outlook",
      "check_for_conflicts" : "Comprueba si hay conflictos",
      "choose_accounts_to_connect" : "Selecciona las cuentas que desees conectar",
      "payments" : "Pagos",
      "stripe" : "Stripe",
      "ads" : "Anuncios",
      "local_service_ads" : "Local Service Ads",
      "fire_workflow" : "Desencadenar disparadores de flujo de trabajo para eventos reservados mediante la sincronización bidireccional",
      "connect_google_outlook" : "Conecta tu cuenta de Google/Outlook/iCloud para poder seleccionar el calendario principal.",
      "execute_automation" : "Ejecutar automatización",
      "save" : "Guardar",
      "lsa" : "Local Service Ads",
      "no_write_access" : "No tienes acceso de escritura a este calendario",
      "connection_remove_confirmation" : "Estás a punto de eliminar esta integración",
      "active" : "Activo",
      "icloud" : {
        "title" : "Integración de iCloud",
        "description" : "Se requiere una autenticación de dos factores y una contraseña específica de la aplicación para conectar la cuenta.",
        "email" : "Dirección de correo electrónico de iCloud",
        "password" : "Contraseña de iCloud",
        "connect" : "Conectar",
        "cancel" : "Cancelar"
      }
    }
  },
  "appointment_modal" : {
    "select_a_contact" : "Seleccionar un contacto",
    "start_time" : "Hora de inicio",
    "unassigned" : "Sin asignar",
    "end_time" : "Hora de finalización",
    "search_by_name_email_phone_or_company" : "Buscar por nombre, correo electrónico, teléfono o empresa",
    "add_new" : "Añadir nuevo",
    "pick_from_available_contacts" : "O seleccionar de entre los contactos disponibles",
    "book_appointment" : "Reservar cita",
    "add_blocked_off_time" : "Añadir tiempo de descanso bloqueado",
    "recommended_timezones" : "Zonas horarias recomendadas",
    "all_timezones" : "Todas las zonas horarias",
    "calendar" : "Calendario",
    "repeat" : "Repetir",
    "date_and_time" : "Fecha y hora",
    "close" : "Cerrar",
    "team_member" : "Miembro del equipo",
    "recurring_booking" : "Reserva recurrente",
    "one_slot_booking" : "Reserva de una sola franja horaria",
    "event_type" : "Tipo de evento",
    "occurrences_booked" : "sesiones se reservarán",
    "repeats" : "Repite",
    "booking_details" : "Detalles de la reserva",
    "showing_slots_in_this_timezone" : "Mostrando franjas horarias en esta zona horaria:",
    "update_contact_tz" : "Actualiza también la zona horaria del contacto a",
    "date" : "Fecha",
    "slot" : "Franja horaria",
    "calendar_default" : "Calendario predeterminado",
    "custom" : "Personalizado",
    "meeting_notes" : "(p. ej.) Notas de reunión para nuestra próxima cita",
    "out_of_office" : "(p. ej.) ¡Fuera de la oficina durante toda la semana!",
    "standard" : "Por defecto",
    "appointment_title" : "Título de la cita",
    "event_title" : "Título del evento",
    "appt_with_bob" : "(p. ej.) Cita con Bob",
    "vacation" : "(p. ej.) Vacaciones",
    "show_notes" : "Mostrar notas",
    "hide_notes" : "Ocultar notas",
    "additional_preferences" : "Preferencias adicionales",
    "meeting_location" : "Lugar de la reunión",
    "appointment_status" : "Estado de la cita",
    "appt_description" : "Descripción de la cita",
    "recurring_details" : "Detalles recurrentes",
    "event_description" : "Descripción del evento",
    "in_contact_timezone" : "En la zona horaria del contacto",
    "vacation_message" : "¿Te vas de vacaciones? ¿Estás tomándote un descanso? Bloquea los horarios en tu calendario para evitar que los clientes reserven citas. Las citas existentes seguirán en el calendario.",
    "contact" : "Contacto",
    "delete" : "Borrar",
    "cancel" : "Cancelar",
    "back" : "Atrás",
    "pick_available_contacts" : "O seleccionar de entre los contactos disponibles",
    "no_contacts_found" : "No se han encontrado contactos",
    "found" : "encontrado",
    "select_one" : "Seleccionar Uno",
    "tz_tooltip" : "Esto solo es relevante si utilizas valores personalizados, como appointment_start_time appointment_end_time appointment_timezone, etc., en la comunicación saliente con tus clientes. Y lo que hace simplemente es convertir los detalles antes mencionados en su hora local.",
    "meeting_location_tooltip" : "La ubicación de la reunión se establecerá según lo configurado en el calendario.",
    "appt_meeting_location_tooltip" : "El usuario puede establecer la ubicación de la reunión específica para la cita.",
    "gmeet_tooltip" : "Asegúrate de que el miembro del equipo respectivo tenga un calendario de Google configurado como su calendario principal",
    "zoom_tooltip" : "Asegúrate de que el miembro del equipo respectivo tenga Zoom integrado",
    "enter_meeting_location" : "Introducir la ubicación de la reunión",
    "save_appointment" : "Guardar cita",
    "save_event" : "Guardar evento",
    "search_contacts" : "Buscar por nombre, correo electrónico, teléfono o empresa",
    "recurring_slots" : "{recurringSlotsLength}/{count}",
    "custom_timezone" : "Zona horaria personalizada",
    "system_timezone" : "Zona horaria del sistema",
    "contact_timezone" : "Zona horaria del contacto",
    "account_timezone" : "Zona horaria de la cuenta",
    "an_error_occurred" : "Se ha producido un error"
  },
  "popup" : {
    "calendar" : "Calendario",
    "appointment_owner" : "Propietario de la cita",
    "source" : "Fuente",
    "booked_by" : "Reservada por",
    "view_activity_log" : "Ver registro de actividad",
    "status" : "Estado",
    "cancel_appointment" : "Cancelar cita",
    "cancel_all" : "Cancelar todo",
    "google_event" : "Evento de Google",
    "outlook_event" : "Evento de Outlook",
    "blocked_slot" : "Franja horaria bloqueada",
    "appointment" : "Cita",
    "confirmed" : "Confirmada",
    "unconfirmed" : "Sin confirmar",
    "cancelled" : "Cancelada",
    "showed" : "Asistido",
    "no_show" : "No asistido",
    "invalid" : "No válida",
    "more" : "Más",
    "less" : "Menos",
    "edit" : "Editar",
    "reschedule_all" : "Reprogramar todo",
    "guests" : "Invitados",
    "guest_count" : "Recuento de invitados"
  },
  "create_calendar" : {
    "calendar_name" : "Nombre del calendario",
    "calendar_logo" : "Logotipo del calendario",
    "select_team_member" : "Seleccionar a los miembros del equipo",
    "eg_outbound_reach" : "(p. ej.) Alcance saliente",
    "description" : "Descripción",
    "amount" : "Cantidad",
    "description_placeholder" : "Escribir una descripción",
    "meeting_duration" : "Duración de la reunión",
    "seats_per_class" : "Plazas por clase",
    "group" : "Grupo",
    "custom_url" : "URL personalizada",
    "meeting_invite_title" : "Título de la invitación a la reunión",
    "event_color" : "Color del evento",
    "availability_type" : "Tipo de disponibilidad",
    "new_calendar" : "Nuevo calendario",
    "add_description" : "Añadir descripción",
    "remove_description" : "Eliminar descripción",
    "booking_availability" : "Disponibilidad de reservas",
    "slug_tooltip" : "Personalizar el enlace directo a este calendario",
    "meeting_location_tooltip" : "Los enlaces de reuniones se muestran en «Eventos del calendario» como la «Ubicación de la reunión» y te permiten añadir cosas como los enlaces de zoom para el evento.",
    "advanced_settings" : "Ajustes avanzados",
    "reschedule_preference" : "Reprogramar preferencia",
    "reschedule_preference_subtext" : "Si un contacto reprograma una cita:",
    "reassign_through_round_robin" : "Reasignar mediante rotación",
    "keep_same_assinged_user" : "Mantener el mismo propietario de la cita",
    "new_appointment_preference" : "Preferencia de nuevas citas",
    "new_appointment_preference_text" : "Reservar siempre con el usuario asignado al contacto",
    "new_appointment_preference_subtext" : "Reservar nuevas citas con el usuario asignado al contacto en lugar de utilizar la programación rotativa.",
    "cancel" : "Cancelar",
    "confirm" : "Confirmar",
    "service_calendar_name" : "Nombre del servicio",
    "service_logo" : "Logotipo del servicio",
    "service_select_team_member" : "Seleccionar miembro del personal",
    "service_duration" : "Duración del servicio",
    "appointment_invite_title" : "Título de la invitación a la cita",
    "payment_placeholder" : "Introduce una descripción que se mostrará al usuario, informándole sobre el pago",
    "deposit_amount" : "Importe del depósito",
    "collect_deposit_amount" : "Aceptar pago parcial",
    "percentage" : "Porcentaje",
    "total_amount" : "Importe total"
  },
  "calendar_advanced" : {
    "general" : {
      "quick_tip" : "Consejo rápido",
      "timezone_change_tip" : "Las zonas horarias se pueden cambiar en cualquier momento en las preferencias de zona horaria",
      "save" : "Guardar",
      "close" : "Cerrar",
      "meeting_details" : "Detalles de la reunión",
      "availability" : "Disponibilidad",
      "forms_and_payment" : "Formularios y pago",
      "notifications_and_additional_options" : "Notificaciones y opciones adicionales",
      "connections" : "Conexiones",
      "customizations" : "Personalizaciones",
      "create" : "Crear",
      "edit" : "Editar",
      "equipments" : "Salas y equipamiento",
      "service_details" : "Detalles del servicio"
    },
    "meeting_details" : {
      "meeting_location" : "Lugar de la reunión",
      "enter_meeting_location" : "Introducir la ubicación de la reunión",
      "click_to_upload" : "Haz clic para cargar",
      "square" : "Cuadrado",
      "circle" : "Círculo",
      "remove_logo" : "Quitar el logotipo",
      "meeting_invite_title_tooltip" : "Este es el título del evento del calendario que se muestra en Google Calendar, Outlook, Apple Calendar, etc.",
      "event_color_tooltip" : "Elegir un color para este evento de reserva. Los colores se sincronizan con los eventos en Google Calendar.",
      "enter_a_name" : "Introduce un nombre",
      "enter_an_event_title" : "Introduce un título para el evento",
      "high_priority" : "Prioridad alta",
      "medium_priority" : "Prioridad media",
      "low_priority" : "Prioridad baja",
      "custom" : "Personalizado",
      "zoom_not_added" : "El usuario aún no ha conectado su cuenta de Zoom",
      "opt_avail" : "Optimizar para la disponibilidad",
      "opt_equal" : "Optimizar para una distribución equitativa",
      "team_members" : "Seleccionar a los miembros del equipo",
      "team_member" : "Seleccionar un miembro del equipo",
      "meeting_location_required" : "Se requiere el lugar de la reunión",
      "meeting_details" : "Detalles de la reunión",
      "meeting_details_description" : "Estos son los detalles que se mostrarán en la página de la lista del calendario.",
      "or_drag_and_drop" : "o arrastrar y soltar",
      "service_details" : "Detalles del servicio",
      "team_member_disabled_recurring_tooltip" : "Las reuniones periódicas solo permiten un miembro del equipo. Para añadir varios miembros del equipo, desactiva las reuniones recurrentes.",
      "team_member_disabled_class_booking_tooltip" : "Solo se puede añadir un miembro del equipo a un calendario de reservas de clases",
      "advanced_settings" : "Ajustes avanzados",
      "enable_contact_assigned_to_setting" : "Para habilitar esta configuración, el formulario debe ser el primero en el orden de los widgets. Reorganiza el orden de los widgets desde la pestaña «Formularios y pago».",
      "reschedule_preference_setting_tooltip" : "Elige a quién se debe asignar la cita cuando un contacto cambie la fecha desde el widget de reservas.",
      "appointment_preference_tooltip" : "Selecciona si las nuevas citas deben reservarse siempre con el usuario asignado al contacto. Si un contacto tiene un usuario asignado, se le dará preferencia a ese usuario; de lo contrario, será una asignación rotativa. Consejo: Desactiva la opción «Permitir selección del personal» para evitar que los clientes cambien de miembro del personal en el widget de reservas.",
      "add_location" : "Añadir ubicación",
      "multiple_locations_neo" : "El widget Neo solo admite múltiples ubicaciones de reunión con el formulario predeterminado.",
      "make_owner" : "Hacer propietario",
      "only_one_team_member" : "Solo se permite un miembro del equipo cuando se añaden varias ubicaciones.",
      "ask_the_booker_error_message" : "La ubicación «Preguntar al cliente» ya existe",
      "enter_location" : "Introducir la ubicación",
      "enter_display_label" : "Introducir la etiqueta de visualización",
      "add_display_label" : "Añadir etiqueta de visualización",
      "multiple_location_support_message" : "El widget Neo solo admite múltiples ubicaciones de reunión con el formulario predeterminado.",
      "only_one_team_member_personal_calendar" : "Solo se permite la reserva personal de un miembro del equipo ",
      "ms_teams_not_added" : "El usuario aún no ha conectado su cuenta de Microsoft Teams"
    },
    "availability" : {
      "my_availability" : "Mi disponibilidad",
      "set_availability" : "Establece aquí tu disponibilidad para el calendario.",
      "standard" : "Estándar",
      "custom" : "Personalizado",
      "to" : "A",
      "copy_to_all" : "Copiar a todos",
      "add_time" : "Añadir hora",
      "recurring_meeting" : "Reunión recurrente",
      "recurring_info" : "Solo un usuario por calendario para reservas recurrentes",
      "repeat" : "Repetir",
      "times_to_repeat" : "Veces a repetir",
      "slots_unavailable" : "¿Si no hay franjas horarias disponibles?",
      "overlap_status" : "Estado de la cita para franjas horarias superpuestas ",
      "meeting_interval" : "Intervalo de reunión",
      "meeting_duration" : "Duración de la reunión",
      "minimum_scheduling" : "Aviso mínimo de programación",
      "date_range" : "Intervalo de fechas",
      "maximum_bookings_per_day" : "Reservas máximas por día",
      "maximum_bookings_per_slot" : "Máximo de reservas por franja horaria",
      "maximum_bookings_per_slot_per_user" : "Máximo de reservas por franja horaria (por usuario)",
      "seats_per_class" : "Plazas por clase",
      "pre_buffer_time" : "Margen de preparación",
      "post_buffer_time" : "Margen posterior",
      "pre_buffer_tooltip" : "El margen de preparación es un tiempo que puede añadirse antes de una cita a fin de disponer de más tiempo para prepararse",
      "post_buffer_tooltip" : "Margen posterior es un tiempo adicional que puede añadirse después de una cita, permitiendo un tiempo extra para concluirla",
      "buffer_time" : "Margen",
      "daily" : "Diario",
      "weekly" : "Semanal",
      "monthly" : "Mensual",
      "skip_booking" : "Omitir la reserva de franjas horarias no disponibles",
      "continue_booking" : "Continuar con la reserva",
      "book_next_available" : "Reservar la próxima franja horaria disponible",
      "confirmed" : "Confirmada",
      "unconfirmed" : "Sin confirmar",
      "enter_valid_hours" : "Introduce un horario de apertura válido",
      "enter_valid_avail" : "Introduce una disponibilidad personalizada válida",
      "availability_type_tooltip" : "Selecciona la disponibilidad estándar para las reservas regulares que se ofrecen semanalmente. Selecciona «Personalizado» para las reservas que solo están disponibles en fechas específicas.",
      "meeting_interval_tooltip" : "El intervalo de reunión refleja la cantidad de tiempo entre las franjas horarias de reserva que se mostrarán en el calendario. Para un evento de 30 minutos que debería estar disponible al final de cada hora, el intervalo sería de una hora. Para un evento de 30 minutos que debería estar disponible cada 30 minutos, el intervalo sería de 30 minutos.",
      "date_range_tooltip" : "Los eventos se pueden programar durante los próximos n días.",
      "maximum_bookings_per_day_tooltip" : " Número máximo de citas permitidas por día en este calendario.",
      "maximum_bookings_per_slot_tooltip" : "Número máximo de citas permitidas por franja horaria para el usuario (si se supera el número de citas para la franja horaria asignada al usuario, se pasa al siguiente usuario de la cola de rotación o se marca la franja horaria como no disponible para su reserva).",
      "add_days" : "Añadir días",
      "month_on_day" : "Mensualmente en el día",
      "month_on_last_day" : "Mensualmente el último día",
      "day" : "Día",
      "week" : "Semana",
      "month" : "Mes",
      "mins" : "Minutos",
      "hours" : "Horas",
      "days" : "Días",
      "weeks" : "Semanas",
      "months" : "Meses",
      "meeting_interval_error" : "El intervalo de reunión debe ser de entre 5 minutos y 12 horas",
      "meeting_duration_error" : "La duración de la reunión debe ser de entre 1 minuto y 12 horas",
      "buffer_time_error" : "El margen debe ser inferior a 12 horas",
      "appointment_interval" : "Intervalo de la cita",
      "appointment_duration" : "Duración de la cita",
      "maximum_appointments_per_day" : "Máximo de citas por día",
      "subTitle" : "Elegir la fecha para establecer un horario específico",
      "heading_1" : "Horas disponibles semanales",
      "tooltip_1" : "Establece tu calendario de disponibilidad semanal. Estas son las horas estándar que se aplicarán semanalmente de forma sistemática",
      "heading_2" : "¿Cuándo estás disponible?",
      "unavailable_label" : "No disponible",
      "tooltip_2" : "Ajusta tu horario para fechas específicas. Anula las horas semanales regulares marcando tu disponibilidad, o la falta de ella, para las fechas seleccionadas.",
      "date_specific_level" : "Añadir horas específicas para una fecha",
      "specific_hours_button" : "Horas específicas de la fecha",
      "overlap_error" : "Las franjas horarias no pueden solaparse",
      "apply" : "Aplicar",
      "copy_times_to" : "Copiar horas a",
      "no_date_specific_heading" : "No se ha añadido una hora específica",
      "no_date_specific_description" : "Puedes añadir o eliminar fechas y horas específicas a tu disponibilidad",
      "recurring_disabled_conditions_tooltip" : "Las reuniones recurrentes están desactivadas. Para activarlas, asegúrate de que el calendario tenga solo un miembro del equipo y que no se añadan horas concretas para una fecha específica.",
      "date_specific_hours_disabled_conditions_tooltip" : "Para incluir una hora específica en una fecha, asegúrate de que las reuniones recurrentes estén desactivadas.",
      "recurring_disable_event_calendar_conditions" : "Las reuniones recurrentes están desactivadas. Para activarlas, asegúrate de que no se añadan horas específicas para una fecha.",
      "service_duration" : "Duración del servicio",
      "service_interval" : "Intervalo del servicio",
      "service_interval_tooltip" : "El intervalo de reunión establece la frecuencia con la que aparecen las franjas horarias de reserva en el calendario. Por ejemplo, para un evento de 30 minutos, un intervalo de una hora significa que las franjas aparecen cada hora. Un intervalo de 30 minutos significa que las franjas aparecen cada 30 minutos.",
      "service_duration_tooltip" : "Esto determina la extensión de la cita, es decir, el tiempo que durará.",
      "minimum_scheduling_tooltip" : "Esto establece el tiempo mínimo requerido para reservar una cita a través del widget de calendario. Por ejemplo, si se establece en 4 horas, las citas no se pueden reservar dentro de las 4 horas anteriores a su hora de inicio, es decir, las reservas deben hacerse con al menos 4 horas de antelación.",
      "look_busy_title" : "Muéstrate ocupado",
      "look_busy_helper_text" : "Oculta un porcentaje de tus franjas de citas disponibles en el widget de reservas para parecer más ocupado y con mayor demanda.",
      "look_busy_percentage_description" : "Ocultar un x % de las franjas horarias disponibles",
      "maximum_bookings_per_slot_tooltip_personal" : "Número máximo de citas permitidas por franja horaria para el usuario"
    },
    "forms_payment" : {
      "forms" : "Formularios",
      "forms_sub" : "Establece las preferencias del formulario posterior a la programación.",
      "select_form" : "Seleccionar formulario",
      "enable_same_user_assignment" : "Asignar contactos a los respectivos miembros del equipo de calendario cada vez que se reserve una cita",
      "sticky_contacts" : "Prerrellenar los campos (contactos fijos)",
      "confirmation_page" : "Página de confirmación",
      "default" : "Por defecto",
      "redirect_url" : "Redirigir la URL",
      "thank_you" : "Mensaje de agradecimiento",
      "enter_url" : "Introducir la URL",
      "pixel_id" : "ID de píxel de Facebook (opcional)",
      "auto_confirm" : "Confirmar automáticamente nuevas reuniones del calendario",
      "configure_payment" : "Configurar las opciones de pago",
      "accept_payments" : "Aceptar pagos",
      "test" : "Prueba",
      "live" : "En tiempo real",
      "enable_guests" : "Añadir invitados",
      "add_guest_tooltip" : "La opción de añadir invitados permitirá a tu asistente agregar varios asistentes a la misma cita",
      "add_guest_custom_form_tooltip" : "Al habilitar esta opción, se enviará un correo electrónico generado por el sistema a los invitados con un enlace al formulario personalizado una vez que se confirme su cita. Para evitar la duplicación de correos electrónicos, comprueba que no esté ya configurado un correo electrónico de confirmación independiente para los invitados (puedes verificarlo en la pestaña Notificaciones > Notificaciones de confirmación).",
      "add_guest_same_form_check" : "Enviar el mismo formulario para recopilar información por correo a todos los invitados",
      "payment_tooltip" : "El pago solo se consideraría para el asistente principal y no para los invitados.",
      "default_form" : "Por defecto (nombre, apellidos, correo electrónico, teléfono, notas)",
      "select_form_tooltip" : "Si quieres recopilar otra información más allá de la que se recoge por defecto (nombre, apellidos, correo electrónico y teléfono) de quienes reservan en este calendario, puedes crear un formulario personalizado en Sitios > Formularios > Creador y, a continuación, selecciona el formulario en el menú desplegable que aparece a continuación.",
      "confirmation_page_tooltip" : "Cuando alguien haga una reserva, puedes elegir entre mostrarle un mensaje personalizado en la misma página (predeterminado) o redirigirlo a la página que elijas (redirigir).",
      "add_guest_collect_email" : "Nombre y correo electrónico",
      "add_guest_count_only" : "Cuenta únicamente",
      "custom_email_notification" : "Enviar un formulario personalizado a los invitados",
      "consent_label" : "Casilla de verificación de consentimiento",
      "consent_input_placeholder" : "Añadir texto de consentimiento",
      "same_user_assignment_tooltip" : "Para habilitar esta configuración, el formulario debe ser el primero en el widget. Reorganiza el widget para que el formulario aparezca el primero y poder activar esta configuración.",
      "consent_tooltip" : "Activa esta opción para permitir obtener el consentimiento de los contactos cuando proporcionen su número de teléfono durante el proceso de reserva de la cita.",
      "stripe_error" : "Hasta dos decimales (ejemplo: 25,99)",
      "auto_confirm_service" : "Confirmar automáticamente nuevas citas",
      "service_form_message" : "Si un contacto está reservando varios servicios, solo verá el formulario predeterminado en el menú de servicios",
      "deposit_amount_error" : "El importe del depósito debe ser menor que el importe total.",
      "deposit_percentage_error" : "El porcentaje del depósito debe ser inferior al 100 %.",
      "partial_payment_tooltip" : "Habilita los pagos parciales para cobrar el importe del depósito de los clientes durante el proceso de reserva de la cita.",
      "partial_payment_enable_error" : "El importe total introducido debe ser superior a 0 para permitir los pagos parciales.",
      "payment_disable_text_integration" : "Integra un proveedor de pagos en Pagos > Integraciones para empezar a aceptar pagos",
      "payment_disable_text_recurring" : "La opción de aceptar pagos no está disponible para las reuniones periódicas. Desactiva la periodicidad para habilitar los pagos.",
      "date_and_time_selector" : "Selector de fecha y hora",
      "form_selector" : "Formulario",
      "order_step" : "Paso",
      "widget_order_label" : "Orden de los widgets",
      "widget_order_tool_tip" : "Reorganiza el orden de «Selector de fecha y hora» y de «Formulario» en el widget de calendario. Basta con arrastrar y reordenar según tus preferencias.",
      "widget_re_order_tooltip" : "El reordenamiento solo está disponible con el estilo de widget Neo. Cambiar a Neo para activar la reordenación.",
      "widget_re_order_disabled_tooltip" : "Para reorganizar el orden de los widgets, desactiva la opción «Nueva preferencia de cita (reserva siempre con el usuario asignado al contacto)» en la sección Detalles de la reunión > Miembros del equipo > Configuración avanzada.",
      "payment_description_error" : "Se ha alcanzado el límite máximo de 1000 caracteres",
      "widget_multiple_location_disabled_tooltip" : "El widget clásico no admite varias ubicaciones de reunión. Elimina cualquier ubicación adicional para usar el widget clásico",
      "recurring_payment_label" : "Pago de citas recurrentes",
      "first_appointment_only" : "Solo primera cita",
      "all_appointments" : "Todas las citas"
    },
    "notifications" : {
      "notifications" : "Notificaciones y opciones adicionales",
      "notifications_sub" : "Configurar notificaciones y opciones adicionales",
      "select_notification_type" : "Selecciona el tipo de notificación",
      "notification_person" : "¿Quién debería recibir esta notificación?",
      "contact" : "Contacto",
      "guest" : "Invitado",
      "assigned_user" : "Usuario asignado",
      "emails" : "Correos electrónicos",
      "alert_email_address" : "Dirección de correo electrónico de alerta",
      "ack_email" : "Correo electrónico de acuse de recibo",
      "send_google_invites" : "Permitir que Google Calendar envíe correos electrónicos de invitación o actualización a los asistentes.",
      "send_google_outlook_invites" : "Permitir que Google/Outlook Calendar envíe correos electrónicos de invitación y actualización a los asistentes.",
      "assign_user_to_contact" : "Asignar contactos a los respectivos miembros del equipo de calendario cada vez que se reserve una cita",
      "skip_assigning_user_to_contact" : "Omitir la asignación de contacto si el contacto ya tiene un usuario asignado",
      "assigned_staff" : "Personal asignado",
      "additional_emails" : "Correos electrónicos adicionales",
      "assign_user_to_staff" : "Asignar contactos a los miembros respectivos del personal de servicio cada vez que se reserve una cita",
      "allow_cancellation" : " Permitir la cancelación de la reunión",
      "allow_reschedule" : "Permitir la reprogramación de la reunión",
      "allow_cancellation_tooltip" : "Cuando esta opción esté habilitada, se añadirá un enlace de cancelación a la sección de notas adicionales y se incluirá en la invitación del calendario. Este enlace caducará según lo configurado antes de la hora de inicio de la reunión, lo que evitará que quien haya hecho la reserva pueda cancelar la reunión.",
      "allow_reschedule_tooltip" : " Cuando esté habilitado, se añadirá un enlace de reprogramación a la sección de notas adicionales y se incluirá en la invitación del calendario. Este enlace caducará tal como se configuró antes de la hora de inicio de la reunión, lo que impedirá que la persona que hizo la reserva la reprograme.",
      "cancellation_link_expiry" : "El enlace de cancelación caducará",
      "reschedule_link_expiry" : "El enlace de reprogramación caducará",
      "before_meeting" : "antes de la reunión",
      "cancellation_and_reschedule_policy" : "Política de cancelación y reprogramación:"
    },
    "connections" : {
      "connections_heading" : "Sincronización de calendario externo",
      "connections_sub" : "Sincronizar los eventos con un calendario externo",
      "link_to_calendar" : "Enlace al calendario",
      "sync_option" : "Opción de sincronización",
      "execute_automation" : "Ejecutar la automatización",
      "fire_workflow" : "Desencadenar disparadores de flujo de trabajo para eventos reservados mediante la sincronización bidireccional",
      "reserve_with_google" : "Reservar con Google",
      "connect_lsa" : "Conéctate con tu cuenta de Local Service Ads",
      "reserve_error" : "Actualmente, la opción de reservar a través de Google solo está disponible para los nichos de empresa \"Servicios a domicilio», «Servicios inmobiliarios» y «Servicios jurídicos» (proveedores de servicios locales) en Estados Unidos.",
      "one_way" : "Unidireccional",
      "two_way" : "Bidireccional",
      "smart" : "Inteligente",
      "confirm_remove" : "¿Estás seguro de que deseas eliminar el calendario de Google?",
      "read_only_access" : "No tienes acceso de escritura a este calendario. Selecciona otro calendario.",
      "reserve_with_google_desc" : "Este servicio de calendario aparecerá en la reserva de Google únicamente cuando la integración sea satisfactoria.",
      "check_integration" : "Comprueba el estado de tu integración",
      "setup_external" : "Configura tu calendario externo",
      "reserve_price_decimal_error" : "No se admiten los decimales en el precio",
      "reserve_price_max_error" : "El precio admitido ha de ser inferior a 1000"
    },
    "extras" : {
      "extras" : "Personalizaciones",
      "extras_sub" : "Establece el estilo del widget y otras preferencias.",
      "calendar_widget_style" : "Estilo del widget de calendario",
      "neo" : "Neo",
      "classic" : "Clásico",
      "insert_custom_code" : "Insertar código personalizado",
      "show_seats_per_slot" : "Mostrar plazas por franja horaria",
      "allow_reschedule_meeting" : "Permitir reprogramar la reunión",
      "allow_cancelling_meeting" : "Permitir cancelar la reunión",
      "additional_notes" : "Notas adicionales",
      "cancellation_and_reschedule" : "Cancelación y reprogramación:",
      "allow_staff" : "Permitir la selección de personal durante la reserva",
      "code_block_tooltip" : "Las secuencias de código de terceros dentro del bloque HTML pueden proporcionar una funcionalidad potente, pero también conllevan riesgos para la privacidad, la seguridad, el rendimiento y el comportamiento de la página. Así pues, te recomendamos que revises el contenido de las secuencias antes de guardarlas en el calendario.",
      "calendar_image" : "Imagen de portada del calendario",
      "remove_image" : "Eliminar imagen",
      "calendar_image_info" : "La imagen cargada será visible dentro de la plantilla «Vista de grupo para Neo» y no aparecerá en el enlace del calendario individual",
      "get_image_from_unsplash" : "Mejora tus visuales con",
      "unsplash" : "Unsplash",
      "service_additional_preferences" : "Configurar las preferencias adicionales",
      "service_image" : "Imagen de portada del servicio",
      "service_image_description" : "Selecciona una imagen que se mostrará en el menú de servicios para este servicio",
      "allow_reschedule_appointment" : "Permitir reprogramar la cita",
      "allow_cancelling_appointment" : "Permitir cancelar la cita",
      "custom_code_placeholder" : "Introduce aquí el código personalizado",
      "calendar_widget_style_desc" : "Elige entre nuestro widget clásico o el elegante widget Neo",
      "seats_per_slot" : "Plazas por franja horaria",
      "show_staff_selection" : "Selección del personal",
      "seats_per_slot_desc" : " Permitir a los usuarios ver el número de plazas disponibles para cada franja horaria directamente en el widget de calendario.",
      "show_staff_selection_desc" : "Permitir que los clientes elijan a un miembro del personal directamente desde el widget de calendario durante la reserva de citas. Solo funciona con el widget Neo",
      "widget_re_order_tooltip" : "El estilo de widget clásico no permite cambios en el orden de los widgets. Asegúrate de que el selector de fecha y hora se muestre primero y que el formulario esté en segundo lugar antes de seleccionar el estilo clásico.",
      "show_staff_selection_desc_service" : "Permitir que los clientes elijan a un miembro del personal directamente desde el widget de calendario durante la reserva de citas."
    },
    "roomsAndEquipments" : {
      "title" : "Selecciona las salas y el equipamiento",
      "description" : "Selecciona de una lista de salas y equipamiento lo que se reservará junto con este calendario",
      "rooms" : "Seleccionar salas",
      "equipments" : "Seleccionar equipamiento"
    }
  },
  "activity_log" : {
    "activity_log" : "Registro de actividad",
    "date" : "Fecha",
    "filter_by" : "Filtrar por",
    "calendar_update" : "Actualización del calendario",
    "start_time_update" : "Actualización de la hora de inicio",
    "end_time_update" : "Actualización de la hora de finalización",
    "title_update" : "Actualización del título",
    "notes_update" : "Actualización de las notas",
    "appointment_status_update" : "Actualización del estado de la cita",
    "meeting_location_update" : "Actualización de la ubicación de la reunión",
    "google_sync_update" : "Actualización de Google Sync",
    "outlook_sync_update" : "Actualización de Outlook Sync",
    "assigned_user_update" : "Actualización del usuario asignado",
    "appointment_status" : "Estado de la cita",
    "assigned_user" : "Usuario asignado",
    "uploaded_to" : "Subido a",
    "cancelled" : "Cancelada",
    "edited" : "editado",
    "other_edits" : "Otras ediciones",
    "view_details" : "Ver detalles",
    "all" : "Todo"
  },
  "appointment_list" : {
    "group_all" : "TODO",
    "group_primary" : "PRINCIPAL",
    "group_user" : "USUARIOS",
    "group_team" : "GRUPOS",
    "group_calendar" : "CALENDARIOS",
    "all_appointments" : "Todas las citas",
    "my_calendar" : "Mi calendario",
    "status_all" : "Todo",
    "status_confirmed" : "Confirmada",
    "status_new" : "Nueva (acción requerida)",
    "status_show" : "Asistido",
    "status_no_show" : "No asistido",
    "status_cancelled" : "Cancelada",
    "status_invalid" : "No válida",
    "filter_date_added_desc" : "Fecha de adición (desc.)",
    "filter_start_added_desc" : "Fecha de inicio (desc.)",
    "filter_start_added_asc" : "Fecha de inicio ascendente a partir de hoy",
    "button_new_appointment" : "Nueva cita",
    "name" : "Nombre",
    "title" : "Título",
    "requested_time" : "Tiempo solicitado",
    "date_added" : "Fecha añadida",
    "calendar" : "Calendario",
    "appointment_owner" : "Propietario de la cita",
    "notes" : "Notas",
    "status" : "Estado",
    "action_view_detail" : "Ver detalles",
    "action_view_consent" : "Ver consentimiento",
    "action_view_notes" : "Ver notas",
    "action_edit" : "Editar",
    "action_delete" : "Borrar",
    "action_reschedule" : "Reprogramar",
    "page_previous" : "Anterior",
    "page_next" : "Siguiente",
    "appointment_notes" : "Notas de la cita",
    "close" : "Cerrar",
    "confirm_delete_header" : "Eliminar cita",
    "confirm_delete_message" : "¿Estás seguro de que deseas eliminar esta cita? Esta acción no se puede deshacer.",
    "confirm_delete_button" : "Borrar",
    "confirm_update_header" : "Actualizar el estado de la cita",
    "confirm_update_confirmed_message" : "¿Estás seguro de que deseas marcar esta cita como confirmada?",
    "confirm_update_invalid_message" : "¿Estás seguro de que deseas marcar esta cita como no válida?",
    "confirm_update_showed_message" : "¿Estás seguro de que deseas marcar esta cita como mostrada?",
    "confirm_update_noshow_message" : "¿Estás seguro de que deseas marcar esta cita como no presentado?",
    "confirm_update_cancelled_message" : "¿Estás seguro de que deseas marcar esta cita como cancelada?",
    "confirm_update_button" : "Actualizar",
    "empty_state_title" : "No se han encontrado citas",
    "empty_state_description" : "No se han encontrado citas",
    "disable_menu_warning" : "Actualmente, no tienes permiso para ver o editar los detalles de la cita, ya que el contacto asociado no se te ha asignado. Ponte en contacto con el administrador para obtener más ayuda.",
    "no_contact_warning" : "No puedes acceder a la información de contacto en este momento. Esto puede deberse a que el contacto no se te ha asignado. Ponte en contacto con el administrador para obtener ayuda.",
    "consent_label" : "Consentir",
    "ip_address" : "IP",
    "youre_viewing_in" : "Actualmente, se está viendo en",
    "appointment_delete_msg" : "<b>Nota:</b> La cita borrada desde aquí no se borrará de calendly.",
    "rescheduled" : "Reprogramado"
  },
  "add_guest" : {
    "guest_label" : "Invitados",
    "search_placeholder" : "Buscar en los contactos",
    "add_new" : "Añadir nuevo",
    "save" : "Guardar",
    "close" : "Cerrar",
    "name" : "Nombre",
    "phone" : "Teléfono",
    "email" : "Correo electrónico",
    "guest_label_count" : "Recuento de invitados",
    "guest_count_error" : "El número de invitados debe estar entre 1 y 100"
  },
  "languages" : {
    "english" : "Inglés",
    "dutch" : "Neerlandés",
    "french" : "Francés",
    "german" : "Alemán",
    "hungarian" : "Húngaro",
    "italian" : "Italiano",
    "polish" : "Polaco",
    "portuguese_brazil" : "Portugués (Brasil)",
    "portuguese_portugal" : "Portugués (Portugal)",
    "spanish" : "Español",
    "danish" : "Danés"
  },
  "service_menu_advanced" : {
    "new_service_menu_details_header" : "Detalles del menú de servicios",
    "select_groups_header" : "Seleccionar servicios",
    "arrange_groups_header" : "Organiza tus servicios",
    "new_service_menu" : "Nuevo menú de servicios",
    "service_menu_sub_title" : "Crea un menú de servicios personalizado para tus clientes en unos pocos clics",
    "quick_tip" : "Puedes añadir grupos al menú de servicios",
    "service_menu_name" : "Nombre del menú de servicios",
    "service_menu_description" : "Descripción",
    "service_menu_slug" : "URL del servicio",
    "select_groups_calendars" : "Seleccionar grupos y calendarios",
    "select_groups_calendars_desc" : "Añade servicios a tu menú de servicios",
    "select_all" : "Seleccionar todo",
    "arrange_groups" : "Organizar el orden de los servicios",
    "arrange_groups_desc" : "Determina la secuencia de servicios en el menú de servicios",
    "arrange_services_quicktip" : "Para reorganizar los calendarios dentro del grupo, ve a Grupos > Opciones > Reorganizar calendarios.",
    "enable_add_guest_desc" : "Permite a los clientes incluir invitados adicionales al hacer una reserva.",
    "enable_multiple_service_desc" : "Permite a los clientes reservar múltiples servicios en una sola cita.",
    "enable_staff_selection_desc" : "Permite a los clientes seleccionar a los miembros del personal preferidos al hacer una reserva.",
    "enable_add_guest" : "Habilitar «Añadir invitados»",
    "enable_multiple_service" : "Habilitar la selección de múltiples servicios",
    "enable_staff_selection" : "Habilitar la selección del personal",
    "additional_options" : "Opciones adicionales",
    "code_block_tooltip" : "Las secuencias de código de terceros dentro del bloque HTML pueden proporcionar una funcionalidad potente, pero también conllevan riesgos para la privacidad, la seguridad, el rendimiento y el comportamiento de la página. Así pues, te recomendamos que revises el contenido de las secuencias antes de guardarlas en el menú de servicios.",
    "custom_code_placeholder" : "Introduce aquí el código personalizado",
    "insert_custom_code" : "Insertar código personalizado",
    "no_service" : "No se ha encontrado ningún servicio",
    "no_service_desc" : " No se han encontrado servicios. Crea servicios y añádelos a grupos para crear un menú de servicios.",
    "learn_more" : "Más información",
    "no_service_selected" : "No se ha seleccionado ningún servicio",
    "create_service_calendars" : "Crear calendarios de servicios",
    "alert_description" : " Para crear un menú de servicios, necesitas crear calendarios de servicios y añadirlos a los grupos",
    "dont_show_again" : "No volver a mostrar",
    "service_name_required" : "El nombre del menú de servicios es obligatorio",
    "slug_already_taken" : "Este slug ya está tomado",
    "slug_required" : "Se requiere un slug",
    "please_enter_name" : "Introduce un nombre",
    "service_name_placeholder" : "Menú principal",
    "consent_label" : "Casilla de verificación de consentimiento",
    "consent_input_placeholder" : "Añadir texto de consentimiento",
    "consent_tooltip" : "Activa esta opción para permitir obtener el consentimiento de los contactos cuando proporcionen su número de teléfono durante el proceso de reserva de la cita."
  },
  "widget_configuration" : {
    "customise_calendar_widget" : "Personalizar el widget de calendario",
    "works_with_neo_widget" : "Solo funciona con el widget Neo",
    "customise_widget_desc" : "Personaliza la apariencia del widget: color primario, color de fondo y texto del botón.",
    "learn_more" : "Más información",
    "primary_settings" : "Configuración principal",
    "primary_settings_desc" : "Configura el color principal, el color de fondo y otras preferencias del widget para personalizar el widget del calendario",
    "primary_color" : "Color principal",
    "primary_color_desc" : "Definir el color de los botones, las fechas, las franjas horarias y las acciones",
    "background_color" : "Color de fondo",
    "background_color_desc" : "Establece el color de fondo que sirve como fondo para el selector de fecha y hora y el formulario.",
    "button_text" : "Texto del botón",
    "button_text_desc" : "Personaliza el texto que se muestra en el botón al reservar una cita",
    "calendar_title" : "Título del calendario",
    "calendar_title_desc" : "El nombre de tu calendario",
    "calendar_description" : "Descripción del calendario",
    "calendar_description_desc" : "La descripción introducida",
    "calendar_details" : "Detalles del calendario",
    "calendar_details_desc" : "Incluye información como la duración, la fecha y la hora, los detalles recurrentes y la zona horaria",
    "reset_to_default" : "Restablecer los valores predeterminados",
    "preview_widget" : "Vista previa del widget"
  },
  "eventNotification" : {
    "customFields" : "Custom Fields",
    "customValues" : "Custom Values",
    "list" : {
      "booked" : {
        "title" : "Appointment Booked (Status: Unconfirmed)",
        "note" : "This notification is sent when a new appointment is created with Unconfirmed status.",
        "subTitle" : "Notifica cuando se reserva una cita con un estado no confirmado"
      },
      "confirmation" : {
        "title" : "Appointment Booked (Status: Confirmed)",
        "note" : "This notification is sent when an appointment is created with or updated to the Confirmed status.",
        "subTitle" : "Notifica cuando una cita se confirma correctamente"
      },
      "cancellation" : {
        "title" : "Cancellation",
        "note" : "This notification is sent when an appointment is cancelled, marked as no-show, or marked as invalid.",
        "subTitle" : "Alertas cuando se cancela una cita"
      },
      "reschedule" : {
        "title" : "Reschedule",
        "note" : "This notification is sent when an appointment is rescheduled. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Notifica cuando se reprograma una cita"
      },
      "reminder" : {
        "title" : "Reminder",
        "note" : "This notification is sent as a reminder before the appointment. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Envía un recordatorio antes de la cita"
      },
      "followup" : {
        "title" : "Follow-Up",
        "note" : "This notification is sent after the appointment as a follow-up. It will only be triggered if the appointment is in Unconfirmed, Confirmed, or Showed status.",
        "subTitle" : "Envía un mensaje de seguimiento una vez completada la cita"
      }
    },
    "saveToastMsg" : "La configuración se ha guardado.",
    "saveToastMsgDesc" : "Los cambios se han guardado correctamente",
    "enableSuccess" : "¡Notificaciones activadas!",
    "disableSuccess" : "¡Notificaciones desactivadas!",
    "tooltip" : "This notification has been disabled. Please enable it to make any changes.",
    "enabled" : "Enabled",
    "disabled" : "Disabled",
    "statusLabel" : "Status Labels",
    "email" : {
      "channelName" : "Email",
      "noneTemplate" : "Ninguno",
      "notificationLabel" : "¿Quién debería recibir esta notificación?",
      "sendButtonLabel" : "Enviar correo electrónico de prueba",
      "testEmailLabel" : "Correo electrónico de prueba",
      "testEmailPlaceholder" : "Introduce la dirección de correo electrónico del destinatario para hacer la comprobación",
      "body" : "Cuerpo del correo electrónico",
      "bodyPlaceholder" : "Escribe tu mensaje aquí",
      "subject" : "Asunto",
      "subjectPlaceholder" : "Introduce el asunto del correo electrónico",
      "templateLabel" : "Plantilla de correo electrónico",
      "templatePlaceholder" : "Selecciona una plantilla de correo electrónico o empieza desde cero",
      "alertEmailAddress" : "Dirección de correo electrónico de alerta",
      "addMore" : "Añadir más",
      "afterAppointmentEnds" : "una vez termine la cita",
      "beforeAppointmentStarts" : "antes de que comience la cita",
      "durationLabel" : "¿Cuándo quieres activar la notificación?",
      "disableEmailNotification" : "Deshabilitar las notificaciones por correo electrónico",
      "enableEmailNotification" : "Habilitar las notificaciones por correo electrónico",
      "enableSuccess" : "Las notificaciones por correo electrónico están habilitadas.",
      "enableSuccessDesc" : "A partir de ahora, empezarás a recibir esta notificación",
      "disableSuccess" : "Las notificaciones por correo electrónico están deshabilitadas.",
      "disableSuccessDesc" : "Ya no recibirás esta notificación"
    },
    "inApp" : {
      "channelName" : "In-app",
      "title" : "Configuración de notificaciones",
      "enableInAppNotification" : "Enable In-App Notification",
      "disableInAppNotification" : "Disable In-App Notification",
      "selectedUser" : {
        "label" : "Enviar a",
        "helperText" : "Esta notificación interna se envía a través de la web y las aplicaciones para móviles",
        "placeholder" : "Seleccionar usuarios"
      },
      "enableSuccess" : "Las notificaciones dentro de la aplicación están habilitadas.",
      "enableSuccessDesc" : "A partir de ahora, empezarás a recibir esta notificación",
      "disableSuccess" : "Las notificaciones dentro de la aplicación están deshabilitadas.",
      "disableSuccessDesc" : "Ya no recibirás esta notificación"
    }
  }
}