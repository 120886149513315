import {
  cleanHTMLForEmail,
  formatHTMLForTiptap,
  isFileTypeSupported,
} from '@/utils/generalFunctions'
import EmailService from '@/class/services/EmailService'
import AppState, { ISelectOption } from '@/states/app'
import {
  isValidEmail,
  isValidHandleBar,
  isWithinLimits,
} from '@/utils/validation'
import { FileService } from '@/class/services/FileService'
import { eventNotificationState } from '@/states/EventNotificationState'

const maxAttachmentSize = 25 * 1024 * 1024 // 25 MB
export type UserType = 'all' | 'assign' | 'user'
export type ToTypeEmail = UserType | 'custom_email'
export interface IStoredFile {
  name: string
  url: string
  size?: number
}
export interface IEmail {
  from_name?: string
  from_email?: string
  subject: string
  template_id?: string
  templatesource?: 'other' | 'email-builder'
  to?: string | string[]
  userType?: ToTypeEmail
  selectedUser?: string[]
  html?: string
  attachments?: IStoredFile[]
  previewUrl?: string
  createdAt?: string
  updatedAt?: string
}

export interface IEmailStats {
  count: string
  accepted: string
  stored: string
  rejected: string
  failed: string
  delivered: string
  replied: string
  clicked: string
  opened: string
  complained: string
  permanent_fail: string
  temporary_fail: string
  unsubscribed: string
}
export default class Email {
  name = 'Email' as string
  attributes = {} as IEmail
  attachments = [] as (File | IStoredFile)[]

  /**
   * to store the number of attachemnts, if user selects a template. Since attachements from template wont have name on them,
   * and its immutable, we dont need anything else. We can just show the number of attachments to user. In backend, we will anyway add those.
   */
  templateAttachments = 0

  emailStats: IEmailStats | undefined = undefined
  showErrors = false // only show error msgs on saving.
  isInternalEmail = false // in case of email inside internal notification

  testEmails = ''

  constructor(name?: string, attributes?: IEmail) {
    if (name) {
      this.name = name
    }
    if (attributes) {
      this.attributes = { ...attributes }
      this.attachments = this.attributes.attachments
        ? [...this.attributes.attachments]
        : []

      if (
        this.attributes.selectedUser &&
        !Array.isArray(this.attributes.selectedUser)
      ) {
        this.attributes.selectedUser = [this.attributes.selectedUser]
      }

      if (this.attributes.html) {
        this.attributes.html = formatHTMLForTiptap(this.attributes.html)
      }
    }
  }

  getUserTypeOptions(): { label: string; value: ToTypeEmail }[] {
    let options: { label: string; value: ToTypeEmail }[] = []
    options = [
      {
        label: 'All Users',
        value: 'all',
      },
      {
        label: 'Assigned User',
        value: 'assign',
      },
      {
        label: 'Particular User',
        value: 'user',
      },
      {
        label: 'Custom Email',
        value: 'custom_email',
      },
    ]
    return options
  }

  updateToTypeEmail(value: string) {
    this.attributes.selectedUser = undefined
    this.attributes.userType = value as ToTypeEmail
  }

  updateSelectedUser(users: string[]) {
    this.attributes.selectedUser = users
  }

  async sendTestEmail() {
    const { attributes } = await this.save()
    if (this.hasErrorsForTest) {
      return
    }
    try {
      const testAttributes = { ...attributes }
      testAttributes.to = this.testEmails
      const emailService = new EmailService(testAttributes)
      await emailService.sendTestEmail()
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  /*
  async getStats(filters?: {
    startAt?: string
    endAt?: string
    reload: boolean
    actionId?: string
  }): Promise<IEmailStats> {
    if (this.emailStats && (!filters || !filters.reload)) {
      return this.emailStats
    }
    let startAt, endAt
    if (filters?.startAt && filters?.endAt) {
      startAt = moment
        .tz(filters.startAt, AppState.locationTimeZone)
        .startOf('day')
        .format()
      endAt = moment
        .tz(filters.endAt, AppState.locationTimeZone)
        .endOf('day')
        .format()
    }

    const emailStats = await new EmailStatsReport(
      AppState.locationId,
      'workflow',
      WorkflowState.workflow?.id || '',
      filters?.actionId || AppState.actionId
    ).getAggregateStats(startAt, endAt)
    this.emailStats = emailStats
    return this.emailStats
  }
    */

  isToFieldValid(): boolean {
    if (this.isInternalEmail === false) {
      return true
    }

    if (this.attributes.userType === undefined) {
      return false
    }
    if (
      this.attributes.userType === 'custom_email' &&
      this.attributes.to === undefined
    ) {
      return false
    }
    if (
      this.attributes.userType === 'user' &&
      (this.attributes.selectedUser === undefined ||
        !this.attributes.selectedUser?.length)
    ) {
      return false
    }

    return true
  }

  changeAttachments(event: Event) {
    const target = event.target as HTMLInputElement
    const fileList = target.files as FileList
    const filteredFiles = [...fileList].filter(file =>
      isFileTypeSupported(file.type)
    )
    this.attachments = [...this.attachments, ...filteredFiles]
  }

  removeAttachment(index: number) {
    this.attachments.splice(index, 1)
  }

  selectTemplate(payload: { [key: string]: any }) {
    if (payload.value === 'none') {
      delete this.attributes.template_id
      delete this.attributes.templatesource
      this.attributes.subject = ''
      this.templateAttachments = 0
      return
    }
    this.attributes.template_id = payload.value
    this.attributes.templatesource = payload.source
    this.attributes.subject =
      payload.template?.subject ||
      payload.subject ||
      this.attributes.subject ||
      ''
    this.attributes.from_email =
      payload.fromAddress || this.attributes.from_email || ''
    this.attributes.from_name =
      payload.fromName || this.attributes.from_name || ''

    this.attachments = []
    this.templateAttachments = payload?.template?.attachments?.length || 0
    this.attributes.previewUrl = payload?.previewUrl
    this.attributes.createdAt = payload?.createdAt
    this.attributes.updatedAt = payload?.updatedAt
    delete this.attributes.attachments
    delete this.attributes.html
  }

  async updateSelectedTemplateData(payload: { [key: string]: any }) {
    this.attributes.previewUrl = payload.previewUrl
    this.attributes.createdAt = payload.createdAt
    this.attributes.updatedAt = payload.updatedAt
  }

  isAttachmentWithinLimits() {
    if (this.totalAttachmentSize > maxAttachmentSize) {
      return false
    }
    return true
  }

  async handleImageUploadInEditor(blobInfo: any, success: any, failure: any) {
    const attachment = blobInfo.blob()
    const formData = new FormData()
    formData.append('file', attachment)
    try {
      const resp = await FileService.uploadFiles(
        formData,
        eventNotificationState.calendarId,
        AppState?.locationId
      )
      console.log({ resp })
      const url = resp[0].url
      success(url)
    } catch (err) {
      console.error('Error in uploading', err)
      failure()
    }
  }

  trimInputs() {
    this.name = this.name.trim()
    this.attributes.from_email = this.attributes.from_email?.trim()
    this.attributes.from_name = this.attributes.from_name?.trim()
    this.attributes.subject = this.attributes.subject?.trim()
    this.attributes.to = this.attributes.to?.trim()
  }

  async save() {
    this.showErrors = true

    if (!this.hasErrors && this.attachments) {
      this.attributes.attachments = this.attachments.filter(
        file => file.url
      ) as IStoredFile[]
      this.attachments = this.attachments.filter(a => a.url === undefined)
      if (this.attachments.length > 0) {
        await this.upload()
      }

      // re-populate the attachment variable to display, fix : in case of test mail, the save funtion runs, and attachment dissapears.
      this.attachments = [...this.attributes.attachments]
    }

    this.trimInputs()

    if (this.attributes.html) {
      this.attributes.html = cleanHTMLForEmail(this.attributes.html)
      console.log('html', this.attributes.html)
    }

    return {
      name: this.name,
      attributes: this.attributes,
      hasErrors: this.hasErrors,
      errorMessage: this.validateHandleBar
        ? 'There are issues in some of your fields, please fix them before saving.'
        : 'Issues in your custom variables, please fix them before saving.',
    }
  }

  async upload() {
    try {
      const formData = new FormData()
      this.attachments.forEach(file => formData.append('file', file))
      const resp = await FileService.uploadFiles(
        formData,
        eventNotificationState.calendarId,
        AppState?.locationId
      )
      const attachmentUrls = [...resp]
      this.attributes.attachments = [
        ...this.attributes?.attachments,
        ...attachmentUrls,
      ]
    } catch (err) {
      console.error('error', err)
    }
  }

  get emailTemplates(): ISelectOption[] {
    if (AppState.emailTemplates) {
      return AppState.emailTemplates
    }
    return []
  }

  get state() {
    return {}
  }

  get hasErrorsForTest() {
    if (this.validateAll && isValidEmail(this.testEmails || '', false)) {
      return false
    }
    return true
  }

  get validateHandleBar(): boolean {
    return isValidHandleBar(this.attributes.html || '')
  }

  get validateBody(): boolean {
    if (this.attributes.template_id) {
      return true
    } // if user picks template, no need of body

    if (!this.attributes.html) {
      return false
    }

    return this.validateHandleBar // if it has body, check for handlebar validation
  }

  get validateFrom(): boolean {
    // if from name is present, email should also be present and valid
    if (this.attributes.from_name && !this.attributes.from_email) {
      return false
    }
    if (
      this.attributes.from_email &&
      !isValidEmail(this.attributes.from_email)
    ) {
      return false
    }
    return true
  }

  get validateSubject(): boolean {
    if (!this.attributes.template_id && !this.attributes.subject) {
      return false
    }
    return true
  }

  get validateAttachments(): boolean {
    if (!this.isAttachmentWithinLimits()) {
      return false
    }
    return true
  }

  get validateAll(): boolean {
    return (
      this.validateFrom &&
      this.validateSubject &&
      this.validateBody &&
      isWithinLimits(this.name) &&
      this.isToFieldValid() &&
      this.validateAttachments
    )
  }

  get hasErrors(): boolean {
    if (!this.validateAll) {
      return true
    }
    return false
  }

  get templateHtml(): string {
    if (!this.attributes.template_id) {
      return ''
    }
    const index = this.emailTemplates.findIndex(
      (template: { [key: string]: any }) =>
        template.id === this.attributes.template_id
    )
    if (index === -1) {
      return ''
    }
    const template = this.emailTemplates[index] as { [key: string]: any }
    this.templateAttachments = template?.template?.attachments?.length || 0 // to show number of attachment
    return template?.template?.body || template?.template?.html || ''
  }

  get totalAttachmentSize(): number {
    let totalSize = 0
    this.attachments.forEach(file => {
      if (file.size) {
        totalSize += file.size
      }
    })
    return totalSize
  }

  get customFields() {
    return [] // AppState.customFields || []
  }
  get customValues() {
    return AppState.customValues || []
  }
}
