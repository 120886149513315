export interface Permissions {
  //required permission can be added here
  campaignsEnabled?: boolean
  assignedDataOnly?: boolean
  contactsEnabled?: boolean
  appointmentsEnabled?: boolean
}
export default class User {
  private data: any = {}

  constructor(data: any) {
    this.data = data
  }

  get id(): string {
    return this.data.id
  }

  get name(): string {
    return this.data.name
  }

  get locationWiseTimezone(): { [key: string]: string } {
    return this.data.locationWiseTimezone
  }

  set locationWiseTimezone(value: { [key: string]: string }) {
    this.data.locationWiseTimezone = value
  }

  get locationWiseOpenHours(): { [key: string]: { [key: string]: any }[] } {
    return this.data.locationWiseOpenHours
  }

  set locationWiseOpenHours(value: {
    [key: string]: { [key: string]: any }[]
  }) {
    this.data.locationWiseOpenHours = value
  }

  get locationWiseZoomAdded(): { [key: string]: string } {
    return this.data.locationWiseZoomAdded
  }

  set locationWiseZoomAdded(value: { [key: string]: string }) {
    this.data.locationWiseZoomAdded = value
  }

  get locationWiseMeetingLocation(): { [key: string]: string } {
    return this.data.locationWiseMeetingLocation
  }

  set locationWiseMeetingLocation(value: { [key: string]: string }) {
    this.data.locationWiseMeetingLocation = value
  }

  get userCalendar(): { [key: string]: string } {
    if (this.data.userCalendar === undefined) {
      this.data.userCalendar = {}
    }
    return this.data.userCalendar
  }

  set userCalendar(values: { [key: string]: string }) {
    this.data.userCalendar = values
  }
  get permissions(): Permissions {
    return this.data.permissions
  }
  set permissions(value) {
    this.data.permissions = value
  }

  get role(): string {
    return this.data.roles?.role
  }

  get type(): string {
    return this.data.roles?.type
  }
}
