<script setup lang="ts">
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIDivider,
  UIForm,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UIInputGroupLabel,
  UIRadio,
  UISelect,
  UISwitch,
} from '@gohighlevel/ghl-ui'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarServiceMenuService } from '../../class/services/CalendarServiceMenuService'
import { formService } from '../../class/services/FormService'
import ServiceMenu from '../../models/service_menu'
import AppState, { setIsAdvancedCalendarPageLoading } from '../../states/app'
import { getTooltip } from '../../utils/generalFunctions'
import Tooltip from '../common/Tooltip.vue'
const { t } = useI18n()

// Define a reactive state object to manage component state
const state = reactive({
  isLoading: false,
  isSlugValidating: false,
  enabledCustomForm: true,
  forms: [],
  formData: {
    name: '',
    description: '',
    widgetSlug: '',
    formSubmitType: 'ThankYouMessage',
    formSubmitRedirectURL: '',
    formSubmitThanksMessage: '',
    enableConsentCheck: true,
    consentLabel: '' as string,
    enableAddGuests: true,
    enableAddMultipleServices: true,
    enableStaffSelection: true,
    formId: '',
    codeBlock: '' as string,
  },
})

// Reference to the form
const formRef = ref()
const isExpanded = ref(true)

onMounted(async () => {
  await methods.loadForms()
})

// Compute the editingServiceMenu property
const editingServiceMenu = computed(() => {
  return AppState.editingServiceMenu as ServiceMenu
})

const formOptions = computed(() => {
  const customFormOptions = state.forms.map(x => {
    return {
      value: x._id,
      label: x.name,
    }
  })

  return [
    {
      value: '',
      label: t('calendar_advanced.forms_payment.default_form'),
    },
    ...customFormOptions,
  ]
})

// Define methods for loading data and emitting save action
const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)

    // Populate form data with the editing service menu's values
    state.formData.name = editingServiceMenu.value?.name || ''
    state.formData.description = editingServiceMenu.value?.description || ''
    state.formData.widgetSlug = editingServiceMenu.value?.slug || ''
    state.formData.formSubmitType =
      editingServiceMenu.value?.formSubmitType || 'ThankYouMessage'
    state.formData.formSubmitRedirectURL =
      editingServiceMenu.value?.formSubmitRedirectUrl || ''
    state.formData.formSubmitThanksMessage =
      editingServiceMenu.value?.formSubmitThanksMessage ||
      'Thank you for your appointment request. We will contact you shortly to confirm your request. Please call our office at {{contactMethod}} if you have any questions.'
    state.formData.enableConsentCheck = editingServiceMenu.value
      ? Boolean(editingServiceMenu.value?.enableConsentCheck)
      : true
    state.formData.consentLabel =
      editingServiceMenu.value?.consentLabel ||
      'I confirm that I want to receive content from this company using any contact information I provide.'
    state.formData.enableAddGuests = editingServiceMenu.value
      ? Boolean(editingServiceMenu.value?.enableAddGuests)
      : true
    state.formData.enableAddMultipleServices = editingServiceMenu.value
      ? Boolean(editingServiceMenu.value?.enableAddMultipleServices)
      : true
    state.formData.enableStaffSelection = editingServiceMenu.value
      ? Boolean(editingServiceMenu.value?.enableStaffSelection)
      : true

    state.formData.formId = editingServiceMenu.value?.formId || ''
    state.formData.codeBlock = editingServiceMenu.value?.codeBlock || ''
    setIsAdvancedCalendarPageLoading(false)
  },
  async loadForms() {
    const batchSize = 50
    const {
      data: { forms, total },
    } = await formService.getAll(AppState.locationId, 0)

    const totalBatches = Math.ceil((total - batchSize) / batchSize)

    const fetchedForms = await Promise.all(
      Array.from({ length: totalBatches }, (_, i) =>
        formService.getAll(AppState.locationId, (i + 1) * batchSize)
      )
    )

    fetchedForms.forEach(({ data: { forms: nextForms } }) => {
      if (nextForms && nextForms.length > 0) {
        forms.push(...nextForms)
      }
    })

    state.forms = forms
  },
  async emitSave() {
    if (state.isSlugValidating) {
      return
    }
    try {
      // Validating the form
      await formRef.value.getForm().validate()
    } catch (error) {
      return
    }

    // Prepare the payload for saving
    const payload = {
      name: state.formData.name,
      description: state.formData.description,
      slug: state.formData.widgetSlug,
      formSubmitType: state.formData.formSubmitType,
      formSubmitRedirectUrl: state.formData.formSubmitRedirectURL,
      formSubmitThanksMessage: state.formData.formSubmitThanksMessage,
      enableConsentCheck: state.formData.enableConsentCheck,
      consentLabel: state.formData.consentLabel,
      enableAddGuests: state.formData.enableAddGuests,
      enableAddMultipleServices: state.formData.enableAddMultipleServices,
      enableStaffSelection: state.formData.enableStaffSelection,
      formId: state.formData.formId,
      codeBlock: state.formData.codeBlock,
    }
    return payload
  },
}

// Expose methods to the parent component
defineExpose({
  methods,
})

// Watch for changes in the editingServiceMenu value and load data accordingly
watch(
  () => editingServiceMenu.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)
// Define validation rules for form fields
const rules = computed(() => {
  return {
    name: {
      validator() {
        if (!state.formData.name?.trim()) {
          return new Error(t('service_menu_advanced.please_enter_name'))
        }

        return true
      },
      required: true,
      message: t('service_menu_advanced.service_name_required'),
      trigger: ['input', 'blur'],
    },
    description: {
      required: false,
      trigger: ['input', 'blur'],
    },
    widgetSlug: {
      required: true,
      trigger: ['input'],
      asyncValidator(rule, value) {
        if (
          editingServiceMenu.value?.slug &&
          value === editingServiceMenu.value?.slug
        ) {
          return Promise.resolve()
        }
        return new Promise(async (resolve, reject) => {
          state.isSlugValidating = true
          if (!state.formData.widgetSlug) {
            state.isSlugValidating = false
            return reject(t('service_menu_advanced.slug_required'))
          }

          // Validate the uniqueness of the slug
          try {
            const { data } = await CalendarServiceMenuService.validateSlug(
              AppState.locationId,
              state.formData.widgetSlug
            )
            if (!data?.available) {
              return reject(new Error('This slug is already taken'))
            }
          } catch (error) {
            return reject(error)
          } finally {
            state.isSlugValidating = false
          }

          state.isSlugValidating = false
          return resolve(true)
        })
      },
    },
  }
})

watch(
  () => state.formData.widgetSlug,
  () => {
    if (state.formData.widgetSlug) {
      state.formData.widgetSlug = state.formData.widgetSlug
        .replace(/[^a-zA-Z0-9-]/g, '')
        .toLowerCase()
    }
  }
)
</script>

<template>
  <div>
    <UIForm
      id="calendarGroupCreateForm"
      ref="formRef"
      :model="state.formData"
      :rules="rules"
      class="h-full w-full max-w-7xl sm:max-w-7xl"
    >
      <UIFormItem
        :label="$t('service_menu_advanced.service_menu_name')"
        path="name"
      >
        <UIInput
          id="service-name-input"
          v-model="state.formData.name"
          :placeholder="$t('service_menu_advanced.service_name_placeholder')"
        />
      </UIFormItem>

      <UIFormItem
        :label="$t('service_menu_advanced.service_menu_description')"
        class="mt-5"
      >
        <UIInput
          id="service-description-input"
          v-model="state.formData.description"
          type="textarea"
          :placeholder="$t('create_calendar.description_placeholder')"
          :rows="4"
        />
      </UIFormItem>

      <UIFormItem
        :label="$t('service_menu_advanced.service_menu_slug')"
        path="widgetSlug"
        class="pb-2"
      >
        <UIInputGroup>
          <UIInputGroupLabel>/widget/service-menus/</UIInputGroupLabel>
          <UIInput
            id="service-menu-slug"
            v-model="state.formData.widgetSlug"
            placeholder="main-menu"
            :loading="state.isSlugValidating"
            @update:model-value="
              state.formData.widgetSlug = $event.toLowerCase()
            "
          />
        </UIInputGroup>
      </UIFormItem>

      <UIDivider />

      <UIFormItem
        v-if="state.enabledCustomForm"
        label="Form"
        path="formData.formId"
        :tooltip="
          getTooltip($t('calendar_advanced.forms_payment.select_form_tooltip'))
        "
      >
        <div class="mt-1 flex flex-col">
          <UISelect
            id="calendar-selectForm"
            v-model:value="state.formData.formId"
            class="w-96"
            :filterable="true"
            :options="formOptions"
          />
        </div>
      </UIFormItem>

      <div v-if="state.formData.formId === ''" class="">
        <div class="flex space-x-3 pb-3">
          <div id="consent-form-toggle">
            <UISwitch
              v-model:value="state.formData.enableConsentCheck"
              aria-label="consent-form-toggle"
              size="small"
            />
          </div>
          <div class="font-normal">
            {{ $t('service_menu_advanced.consent_label') }}
          </div>
          <Tooltip
            :message="$t('service_menu_advanced.consent_tooltip')"
            placement="top"
          >
            <InformationCircleIcon class="-ml-1 w-4 pb-0.5 text-gray-400" />
          </Tooltip>
        </div>
        <UIInput
          v-if="state.formData.enableConsentCheck"
          id="consent-form-input"
          v-model:value="state.formData.consentLabel"
          type="textarea"
          :rows="4"
          class="mb-3 !w-96 items-center"
          :placeholder="$t('service_menu_advanced.consent_input_placeholder')"
        />
      </div>

      <UIDivider v-if="state.formData.formId === ''" />

      <UIFormItem
        :label="$t('calendar_advanced.forms_payment.confirmation_page')"
        path="isDefaultConfirmationPage"
        :tooltip="
          getTooltip(
            $t('calendar_advanced.forms_payment.confirmation_page_tooltip')
          )
        "
      >
        <div class="flex w-full flex-col space-y-4">
          <div class="flex space-x-4">
            <UIRadio
              id="calendar-default-thankyou-message"
              :checked="state.formData.formSubmitType === 'ThankYouMessage'"
              name="default"
              @change="state.formData.formSubmitType = 'ThankYouMessage'"
            >
              {{ $t('calendar_advanced.forms_payment.default') }}
            </UIRadio>

            <UIRadio
              id="calendar-redirect-url"
              :checked="state.formData.formSubmitType === 'RedirectURL'"
              name="custom"
              @change="state.formData.formSubmitType = 'RedirectURL'"
            >
              {{ $t('calendar_advanced.forms_payment.redirect_url') }}
            </UIRadio>
          </div>

          <div
            v-if="state.formData.formSubmitType === 'ThankYouMessage'"
            class="w-96"
          >
            <UIFormItem
              :label="$t('calendar_advanced.forms_payment.thank_you')"
              path="formData.formSubmitThanksMessage"
            >
              <UIInput
                id="redirect-url-input"
                v-model:value="state.formData.formSubmitThanksMessage"
                type="textarea"
                :rows="4"
              />
            </UIFormItem>
          </div>

          <div
            v-if="state.formData.formSubmitType === 'RedirectURL'"
            class="w-96"
          >
            <UIInput
              id="redirect-url-input"
              v-model:value="state.formData.formSubmitRedirectURL"
              :placeholder="$t('calendar_advanced.forms_payment.enter_url')"
            />
          </div>
        </div>
      </UIFormItem>
      <div class="mt-5 w-full rounded-lg border-[1px] border-gray-200">
        <div
          id="expand-customization-accordion"
          class="flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-lg bg-gray-50 px-5 pb-5 pt-6 transition-all"
          :class="{ 'border-b-[1px]': isExpanded }"
          role="button"
          @click="isExpanded = !isExpanded"
          @keypress="isExpanded = !isExpanded"
        >
          <div
            class="flex w-2/3 flex-col gap-2 text-xl font-medium text-gray-700"
          >
            {{ $t('service_menu_advanced.additional_options') }}
          </div>
          <ChevronDownIcon v-if="!isExpanded" class="h-5 w-5" />
          <ChevronUpIcon v-else class="h-5 w-5" />
        </div>

        <div v-if="isExpanded" class="flex flex-col gap-5 px-6 py-8">
          <div class="flex flex-row gap-5">
            <div id="enable-add-guest-toggle">
              <UISwitch
                v-model:value="state.formData.enableAddGuests"
                class="mt-1"
                aria-label="enable-add-guest-toggle"
              />
            </div>
            <div class="flex flex-col gap-[3px]">
              <div class="font-normal text-gray-700">
                {{ $t('service_menu_advanced.enable_add_guest') }}
              </div>
              <div class="text-xs font-light text-gray-600">
                {{ $t('service_menu_advanced.enable_add_guest_desc') }}
              </div>
            </div>
          </div>

          <div class="flex flex-row gap-5">
            <UISwitch
              id="enable-multiple-services-toggle"
              v-model:value="state.formData.enableAddMultipleServices"
              class="mt-1"
              aria-label="enable-multiple-services-toggle"
            />
            <div class="flex flex-col gap-[3px]">
              <div class="font-normal text-gray-700">
                {{ $t('service_menu_advanced.enable_multiple_service') }}
              </div>
              <div class="text-xs font-light text-gray-600">
                {{ $t('service_menu_advanced.enable_multiple_service_desc') }}
              </div>
            </div>
          </div>

          <div class="flex flex-row gap-5">
            <div id="enable-staff-selection-toggle">
              <UISwitch
                v-model:value="state.formData.enableStaffSelection"
                class="mt-1"
                aria-label="enable-staff-selection-toggle"
              />
            </div>
            <div class="flex flex-col gap-[3px]">
              <div class="font-normal text-gray-700">
                {{ $t('service_menu_advanced.enable_staff_selection') }}
              </div>
              <div class="text-xs font-light text-gray-600">
                {{ $t('service_menu_advanced.enable_staff_selection_desc') }}
              </div>
            </div>
          </div>

          <UIFormItem
            v-if="isExpanded"
            :label="$t('service_menu_advanced.insert_custom_code')"
            class="mt-3 w-96"
          >
            <div class="flex w-full flex-col space-y-4">
              <UIInput
                id="calendar-custom-codeBlock"
                v-model="state.formData.codeBlock"
                :rows="4"
                type="textarea"
                :placeholder="
                  $t('service_menu_advanced.custom_code_placeholder')
                "
              />

              <div v-if="state.formData.codeBlock" class="text-yellow-500">
                {{ $t('service_menu_advanced.code_block_tooltip') }}
              </div>
            </div>
          </UIFormItem>
        </div>
      </div>
    </UIForm>
  </div>
</template>
