<template>
  <!-- Overflow menu -->
  <MenuComp as="div" class="relative h-6">
    <MenuButton>
      <!-- <Tooltip
        disable-default-style
        class="flex items-center justify-center w-6 h-6 cursor-pointer overflow-menu-icon"
        tooltip="More"
      > -->
      <DotsVerticalIcon class="h-5 text-gray-600" />
      <!-- </Tooltip> -->
    </MenuButton>
    <Transition
      enter-active-class="transition duration-100 ease-out origin-bottom"
      enter-from-class="origin-bottom transform scale-95 opacity-0"
      enter-to-class="origin-bottom transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in origin-bottom"
      leave-from-class="origin-bottom transform scale-100 opacity-100"
      leave-to-class="origin-bottom transform scale-95 opacity-0"
    >
      <MenuItems
        class="overflow-menu absolute origin-bottom bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <RTEControlStrike :editor="editor" tooltip-label="Strikethrough" />
        <hr class="overflow-menu-partition" />
        <OverflowMenuItem>
          <RTEControlSuperscript :editor="editor" tooltip-label="Superscript" />
        </OverflowMenuItem>
        <OverflowMenuItem>
          <RTEControlSubscript :editor="editor" tooltip-label="Subscript" />
        </OverflowMenuItem>
        <hr class="overflow-menu-partition" />
        <OverflowMenuItem>
          <RTEControlCode :editor="editor" tooltip-label="Inline code block" />
        </OverflowMenuItem>
        <OverflowMenuItem>
          <RTEControlCode
            :editor="editor"
            type="block"
            tooltip-label="Code block"
          />
        </OverflowMenuItem>
        <hr class="overflow-menu-partition" />
        <OverflowMenuItem>
          <RTEControlBlockQuote :editor="editor" tooltip-label="Block quote" />
        </OverflowMenuItem>
        <hr class="overflow-menu-partition" />
        <OverflowMenuItem label="Reset style">
          <RTEControlResetStyles :editor="editor" />
        </OverflowMenuItem>
        <OverflowMenuItem label="Reset document style">
          <RTEControlResetStyles :editor="editor" reset="document" />
        </OverflowMenuItem>
      </MenuItems>
    </Transition>
  </MenuComp>
</template>

<script lang="ts">
import { DotsVerticalIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  RTEControlBlockQuote,
  RTEControlCode,
  RTEControlResetStyles,
  RTEControlStrike,
  RTEControlSubscript,
  RTEControlSuperscript,
} from '@gohighlevel/ghl-text-editor'
import { MenuButton, Menu as MenuComp, MenuItems } from '@headlessui/vue'
import OverflowMenuItem from './OverflowMenuItem.vue'

// import Tooltip from '@/components/common/Tooltip.vue'

import { Editor } from '@tiptap/vue-3'

export default {
  components: {
    DotsVerticalIcon,
    MenuComp,
    MenuButton,
    MenuItems,
    OverflowMenuItem,
    RTEControlBlockQuote,
    RTEControlCode,
    RTEControlResetStyles,
    RTEControlStrike,
    RTEControlSubscript,
    RTEControlSuperscript,
  },
  props: {
    editor: {
      type: Editor,
      required: true,
    },
  },
}
</script>

<style scoped>
.overflow-menu {
  @apply -left-2 bottom-8 z-20;
}

.overflow-menu-partition {
  @apply border-t border-gray-200;
}
</style>
