{
  "settings" : {
    "calendar" : {
      "calendar_settings" : "Impostazioni del calendario",
      "select_calendar_type" : "Seleziona tipo di calendario",
      "add_new_calendar_group" : "Aggiungi nuovo gruppo di calendari",
      "add_new_equipment" : "Aggiungi nuova attrezzatura",
      "new_calendar" : "Nuovo calendario",
      "calendar_group_description" : "Usa i gruppi di calendari per organizzare e raggruppare efficacemente più calendari.",
      "group" : "Gruppo",
      "manage_your_calendars_and_groups" : "Gestisci i tuoi calendari e gruppi",
      "groups" : "Gruppi",
      "create_group" : "Crea gruppo",
      "create_calendar" : "Crea calendario",
      "calendar_name" : "Nome del calendario",
      "group_duration" : "Durata del gruppo",
      "calendar_type" : "Tipo di calendario",
      "action" : "Azione",
      "round_robin" : "A rotazione",
      "event" : "Evento",
      "group_name" : "Nome del gruppo",
      "room_name" : "Nome della stanza",
      "group_description" : "Descrizione del gruppo",
      "calendar_url" : "URL del calendario",
      "calendars" : "Calendari",
      "date_updated" : "Data aggiornata",
      "status" : "Stato",
      "action_dropdown" : "Elenco a discesa delle azioni",
      "calendar_list" : "Servizi associati a",
      "edit" : "Modifica",
      "duplicate" : "Duplicato",
      "copy_embed_code" : "Copia codice di incorporamento",
      "copy_permanent_link" : "Copia link permanente",
      "copy_new_slug_link" : "Copia link di programmazione",
      "copy_scheduling_link" : "Copia link di programmazione (versione precedente obsoleta)",
      "deactivate_calendar" : "Disattiva calendario",
      "move_to_group" : "Sposta nel gruppo",
      "delete_calendar" : "Elimina calendario",
      "delete_group" : "Elimina",
      "delete_this_group" : "Elimina questo gruppo",
      "activate_calendar" : "Attiva calendario",
      "duration" : "Durata (minuti)",
      "all" : "Tutto",
      "draft" : "Bozza",
      "active" : "Attivo",
      "rearrange_calendars" : "Riorganizza calendari",
      "deactivate_all_calendars_in_group" : "Disattiva tutti i calendari del gruppo",
      "activate_all_calendars_in_group" : "Attiva tutti i calendari del gruppo",
      "no_groups_found" : "La ricerca non ha trovato corrispondenza con alcun gruppo di calendari.",
      "no_groups_in_location" : "Inizia a usare i gruppi creando un nuovo gruppo di calendari.",
      "no_calendars_found" : "Inizia a usare il calendario creando un nuovo calendario.",
      "no_calendars_found_search" : "La tua ricerca non ha trovato corrispondenza con alcun calendario.",
      "delete_calendar_message" : "Stai per eliminare il calendario. Quando premi Elimina, vengono eliminati anche tutti i suoi appuntamenti.",
      "delete_calendar_message_title" : "Eliminare questo calendario?",
      "duplicate_calendar_message" : "Stai per duplicare il calendario. In questo modo verrà creato un nuovo calendario con le stesse impostazioni del calendario selezionato.",
      "duplicate_calendar_message_title" : "Duplicare questo calendario?",
      "no_calendars_found_title" : "Nessun calendario trovato",
      "no_groups_in_location_title" : "Nessun gruppo trovato",
      "delete_group_confirmation_title" : "Eliminare questo gruppo?",
      "delete_group_confirmation" : "Vuoi davvero eliminare il gruppo selezionato?",
      "delete_group_confirmation_checkbox_title" : "Elimina i calendari e gli appuntamenti associati",
      "delete_group_confirmation_checkbox_description" : "Selezionando questa opzione verranno eliminati anche i calendari di questo gruppo, insieme a tutti gli appuntamenti in quei calendari.",
      "group_status_message_title" : "Eseguire l'azione ({status}) per questo gruppo?",
      "calendar_status_message_title" : "Eseguire l'azione ({status}) per questo calendario?",
      "calendar_status_message" : "Stai per eseguire l'azione ({status}) per questo calendario",
      "group_status_message" : "Stai per eseguire l'azione ({status}) per questo gruppo",
      "coming_soon" : "In arrivo",
      "select" : "Seleziona",
      "15mins" : "15 minuti",
      "cancel" : "Annulla",
      "save" : "Salva",
      "create" : "Crea",
      "select_calendar_group" : "Seleziona gruppo di calendari",
      "move_calendar_confirmation" : "Tieni presente che il link precedente smetterebbe di funzionare una volta effettuata la modifica. Conferma selezionando la casella di controllo.",
      "previously_called_unassigned" : " (Precedentemente chiamato come non assegnato)",
      "scheduling_type" : "Tipo di programmazione",
      "scheduling_type_description" : "Scegli un tipo di programmazione per il tuo nuovo calendario",
      "service_menu_name" : "Nome del menu dei servizi",
      "template" : "Modello",
      "group_url" : "URL del gruppo",
      "template_description" : "Ora hai la possibilità di scegliere tra i modelli Classico o Neo per la Vista gruppo",
      "rooms" : "Stanze",
      "rooms_name" : "Nome",
      "rooms_description" : "Descrizione",
      "rooms_modal_description" : "Aggiungi o modifica stanza",
      "rooms_capacity" : "Capacità totale",
      "no_rooms_in_location_title" : "Nessuna stanza trovata",
      "no_rooms_in_location" : "Nessuna stanza trovata in questo luogo",
      "no_equipments_in_location" : "Nessuna attrezzatura trovata in questo luogo",
      "equipments" : "Attrezzatura",
      "equipment_name" : "Nome",
      "equipment_name_search" : "Nome dell'attrezzatura",
      "equipment_description" : "Descrizione",
      "equipment_modal_description" : "Aggiungi o modifica dell'attrezzatura",
      "add_equipment" : "Aggiungi/Modifica attrezzatura",
      "add_room" : "Aggiungi/Modifica stanza",
      "equipment_quantity" : "Quantità totale",
      "equipment_outOfService" : "Quantità fuori servizio",
      "no_equipments_in_location_title" : "Nessun dispositivo trovato",
      "delete_this_equipment" : "Elimina questa attrezzatura",
      "delete_this_rooms" : "Elimina questa stanza",
      "delete_equipment_confirmation" : "Vuoi davvero eliminare questa attrezzatura? Verrà rimossa anche da tutti i calendari collegati.",
      "delete_rooms_confirmation" : "Vuoi davvero eliminare questa stanza? Verrà rimossa anche da tutti i calendari collegati.",
      "add_new_calendar_equipments" : "Aggiungi nuova attrezzatura",
      "no_equipments_found" : "La tua ricerca non ha trovato alcuna attrezzatura.",
      "no_rooms_found" : "La tua ricerca non ha trovato alcuna stanza.",
      "add_new_calendar_rooms" : "Aggiungi nuova stanza",
      "rooms_disable" : "Disabilita stanza",
      "equipments_disable" : "Disabilita attrezzatura",
      "equipments_activate" : "Attiva attrezzatura",
      "rooms_activate" : "Attiva stanza",
      "create_equipments" : "Crea attrezzatura",
      "create_rooms" : "Crea stanza",
      "select_calendar_rooms_title" : "Seleziona calendario",
      "select_calendar_rooms_description" : "Puoi selezionare più calendari da associare alle stanze",
      "select_calendar_equipments_title" : "Seleziona calendario",
      "select_calendar_equipments_description" : "Puoi selezionare un calendario da associare all'attrezzatura",
      "disable_equipment_title" : "Esegui l'azione ({status}) per l'attrezzatura",
      "disable_room_title" : "Esegui l'azione ({status}) per la stanza",
      "equipments_status_message" : "Vuoi davvero eseguire l'azione ({status}) per questa attrezzatura?",
      "rooms_status_message" : "Vuoi davvero eseguire l'azione ({status}) per questa stanza?",
      "calendar_resource_disclaimer" : "Questo potrebbe influire sulla disponibilità nei calendari dei servizi associati.",
      "equipment_disclaimer" : "Nota: abilitando l'attrezzatura in un secondo momento, essa verrà automaticamente ricollegata ai calendari a cui è associata",
      "room_disclaimer" : "Nota: abilitando la stanza in un secondo momento, essa verrà automaticamente ricollegata ai calendari a cui è associata",
      "equipment_tab_name" : "Attrezzatura",
      "rooms_tab_name" : "Stanze",
      "manage_your_rooms" : "Gestisci qui le stanze per la tua attività",
      "manage_your_equipments" : "Gestisci qui l'attrezzatura per la tua attività",
      "select_service_calendar_description_equipments" : "Puoi selezionare più calendari dei servizi da associare all'attrezzatura",
      "select_service_calendar_description_rooms" : "Puoi selezionare più calendari dei servizi da associare alle stanze",
      "service_menus" : "Menu dei servizi",
      "manage_your_calendars_groups_and_service_menus" : "Gestisci i tuoi calendari, gruppi e menu dei servizi",
      "new_service_menu" : "Crea menu dei servizi",
      "deactivate_service_menu" : "Disattiva menu dei servizi",
      "delete_service_menu" : "Elimina questo menu dei servizi",
      "delete_service_menu_confirmation" : "Vuoi davvero eliminare questo menu dei servizi?",
      "service_menu_status_message_title" : "Eseguire l'azione ({status}) per questo menu dei servizi?",
      "service_menu_status_message" : "Stai per eseguire l'azione ({status}) per il menu dei servizi",
      "no_service_menus_in_location_title" : "Crea il tuo menu dei servizi personalizzato",
      "add_new_service_menu" : "Aggiungi nuovo menu dei servizi",
      "no_service_menus_in_location" : "Inizia creando un nuovo menu dei servizi e aggiungendo i gruppi",
      "no_service_menu_found" : "Nessun menu dei servizi trovato",
      "no_service_menu_found_desc" : "La tua ricerca non ha trovato alcun menu dei servizi.",
      "apply" : "Applica",
      "troubleshoot_calendar" : "Risolvi i problemi del calendario",
      "round_robin_booking" : "A rotazione",
      "round_robin_description" : "Distribuisce gli appuntamenti tra i membri del team a rotazione.",
      "round_robin_example" : "Ad esempio: chiamate di vendita, sessioni di onboarding.",
      "unassigned_booking" : "Calendario degli eventi",
      "unassigned_description" : "Per programmare eventi fisici senza associazione con un host.",
      "unassigned_example" : "Ad esempio: conferenze, seminari pubblici, esposizioni.",
      "class_booking" : "Prenotazione del corso",
      "class_booking_description" : "Un host incontra più partecipanti.",
      "class_booking_example" : "Ad esempio: webinar, formazione di gruppo, corsi online.",
      "collective_booking" : "Prenotazione collettiva",
      "collective_booking_description" : "Più host incontrano un partecipante.",
      "collective_booking_example" : "Ad esempio: interviste di gruppo, revisioni di comitato.",
      "service_booking" : "Prenotazione del servizio",
      "service_booking_description" : "Programmazione senza soluzione di continuità per le aziende basate sui servizi.",
      "service_booking_example" : "Ad esempio: appuntamenti in una spa, servizi di riparazione, consulenze.",
      "personal_booking" : "Prenotazione personale",
      "personal_booking_description" : "Programma incontri individuali con un membro specifico del team.",
      "personal_booking_example" : "Ad esempio: incontri con i clienti, consulenze private.",
      "personal" : "Personale",
      "explore_more_types" : "Esplora più tipi"
    },
    "preferences" : {
      "preferences" : "Preferenze",
      "my_preferences" : "La mia preferenza",
      "account_preferences" : "Preferenza dell'account",
      "user_preferences" : "Preferenze dell'utente",
      "set_preferences" : "Imposta le tue preferenze per il tuo account.",
      "scheduling_options" : "Opzioni di programmazione",
      "meeting_location" : "Luogo dell'incontro",
      "zoom" : "Zoom",
      "save_preferences" : "Salva preferenze",
      "in_app_preferences" : "Preferenze dell'app",
      "set_start_day" : "Imposta le preferenze come il giorno di inizio per l'app",
      "view_options" : "Visualizza opzioni",
      "week_starts_on" : "La settimana inizia:",
      "sunday" : "Domenica",
      "widget_preferences" : "Preferenze dei widget",
      "set_widget_language" : "Imposta le preferenze come la lingua per il widget",
      "language_and_region" : "Lingua e regione",
      "language" : "Lingua",
      "english" : "Inglese",
      "time_format" : "Formato ora",
      "time_format_value" : "13:30",
      "monday" : "Lunedì",
      "custom_meeting_location" : "Luogo dell'incontro personalizzato"
    },
    "availability" : {
      "availability" : "Disponibilità",
      "my_availability" : "La mia disponibilità",
      "account_availability" : "Disponibilità dell'account",
      "timezone" : "Fuso orario",
      "working_hours" : "Orario di lavoro",
      "set_recurring_hours" : "Imposta i tuoi orari di lavoro ricorrenti per gli incontri",
      "sunday" : "Domenica",
      "monday" : "Lunedì",
      "tuesday" : "Martedì",
      "wednesday" : "Mercoledì",
      "thursday" : "Giovedì",
      "friday" : "Venerdì",
      "saturday" : "Sabato",
      "widget_preferences" : "Preferenze dei widget",
      "set_widget_language" : "Imposta le preferenze come la lingua per il widget",
      "language_and_region" : "Lingua e regione",
      "language" : "Lingua",
      "english" : "Inglese",
      "copy_to_all" : "Copia a tutti",
      "save_availability" : "Salva disponibilità",
      "add_time" : "Aggiungi ora",
      "select_time" : "Seleziona ora"
    },
    "connections" : {
      "connections" : "Collegamenti",
      "connect" : "collega",
      "conferencing" : "Conferenza",
      "my_connections" : "I miei collegamenti",
      "account_connections" : "Collegamenti dell'account",
      "connection_preferences" : "Preferenze di collegamento",
      "manage_calendars_conferencing_channels" : "Gestisci calendari e canali di conferenza",
      "main_integration_calendar" : "Calendario principale di integrazione",
      "event_integration_calendar" : "Gli eventi creati su un calendario di sistema di cui fai parte verranno creati anche su questo calendario integrato",
      "2way_sync" : "Sincronizzazione bidirezionale: crea eventi di sistema dagli eventi prenotati nel calendario integrato principale",
      "calendars" : "Calendari",
      "google" : "Google",
      "outlook" : "Outlook",
      "check_for_conflicts" : "Verifica dei conflitti",
      "choose_accounts_to_connect" : "Scegli quali account vuoi collegare",
      "payments" : "Pagamenti",
      "stripe" : "Stripe",
      "ads" : "Annunci",
      "local_service_ads" : "Annunci di servizi locali",
      "fire_workflow" : "Attiva i trigger del flusso di lavoro per gli eventi prenotati tramite sincronizzazione bidirezionale",
      "connect_google_outlook" : "Collega il tuo account Google/Outlook/iCloud per poter scegliere il calendario primario.",
      "execute_automation" : "Esegui automazione",
      "save" : "Salva",
      "lsa" : "Annunci di servizi locali",
      "no_write_access" : "Non hai accesso in scrittura a questo calendario",
      "connection_remove_confirmation" : "Stai per rimuovere questa integrazione",
      "active" : "Attivo",
      "icloud" : {
        "title" : "Integrazione iCloud",
        "description" : "L'autenticazione a due fattori e una password specifica per l'app sono necessarie per collegare l'account.",
        "email" : "Indirizzo email iCloud",
        "password" : "Password iCloud",
        "connect" : "Collega",
        "cancel" : "Annulla"
      }
    }
  },
  "appointment_modal" : {
    "select_a_contact" : "Seleziona un contatto",
    "start_time" : "Ora di inizio",
    "unassigned" : "Non assegnato",
    "end_time" : "Ora di fine",
    "search_by_name_email_phone_or_company" : "Cerca per nome, email, telefono o azienda",
    "add_new" : "Aggiungi nuovo",
    "pick_from_available_contacts" : "Oppure scegli tra i contatti disponibili",
    "book_appointment" : "Prenota appuntamento",
    "add_blocked_off_time" : "Aggiungi tempo bloccato",
    "recommended_timezones" : "Fusi orari consigliati",
    "all_timezones" : "Tutti i fusi orari",
    "calendar" : "Calendario",
    "repeat" : "Ripetizione",
    "date_and_time" : "Data e ora",
    "close" : "Chiudi",
    "team_member" : "Membro del team",
    "recurring_booking" : "Prenotazione ricorrente",
    "one_slot_booking" : "Prenotazione di una fascia oraria",
    "event_type" : "Tipo di evento",
    "occurrences_booked" : "le ricorrenze saranno prenotate",
    "repeats" : "Ripetizioni",
    "booking_details" : "Dettagli della prenotazione",
    "showing_slots_in_this_timezone" : "Mostra le fasce orarie in questo fuso orario:",
    "update_contact_tz" : "Aggiorna anche il fuso orario del contatto a",
    "date" : "Data",
    "slot" : "Fascia oraria",
    "calendar_default" : "Calendario predefinito",
    "custom" : "Personalizzato",
    "meeting_notes" : "(es.) Note dell'incontro per il nostro prossimo appuntamento",
    "out_of_office" : "(es.) Fuori ufficio per questa settimana!",
    "standard" : "Predefinito",
    "appointment_title" : "Titolo dell'appuntamento",
    "event_title" : "Titolo dell'evento",
    "appt_with_bob" : "(es.) Appuntamento con Bob",
    "vacation" : "(es.) Vacanza",
    "show_notes" : "Mostra note",
    "hide_notes" : "Nascondi note",
    "additional_preferences" : "Preferenze aggiuntive",
    "meeting_location" : "Luogo dell'incontro",
    "appointment_status" : "Stato dell'appuntamento",
    "appt_description" : "Descrizione dell'appuntamento",
    "recurring_details" : "Dettagli ricorrenti",
    "event_description" : "Descrizione dell'evento",
    "in_contact_timezone" : "Fuso orario del contatto",
    "vacation_message" : "Vai in vacanza? Vuoi prenderti del tempo libero? Blocca del tempo sul tuo calendario per impedire ai clienti di prenotare appuntamenti. Gli appuntamenti esistenti rimarranno comunque nel tuo calendario.",
    "contact" : "Contatto",
    "delete" : "Elimina",
    "cancel" : "Annulla",
    "back" : "Indietro",
    "pick_available_contacts" : "Oppure scegli tra i contatti disponibili",
    "no_contacts_found" : "Nessun contatto trovato",
    "found" : "trovato",
    "select_one" : "Seleziona uno",
    "tz_tooltip" : "Questo è importante solo se utilizzi valori personalizzati, come appointment_start_time appointment_end_time appointment_timezone e così via, nella tua comunicazione in uscita con i tuoi clienti. E ciò che fa è semplicemente convertire i suddetti dati nell'ora locale.",
    "meeting_location_tooltip" : "Il luogo dell'incontro sarà impostato come configurato nel calendario.",
    "appt_meeting_location_tooltip" : "L'utente può impostare il luogo dell'incontro specifico per l'appuntamento.",
    "gmeet_tooltip" : "Assicurati che il rispettivo membro del team abbia impostato un calendario Google come calendario primario",
    "zoom_tooltip" : "Assicurati che il rispettivo membro del team abbia Zoom integrato",
    "enter_meeting_location" : "Inserisci luogo dell'incontro",
    "save_appointment" : "Salva appuntamento",
    "save_event" : "Salva evento",
    "search_contacts" : "Cerca per nome, email, telefono o azienda",
    "recurring_slots" : "{recurringSlotsLength}/{count}",
    "custom_timezone" : "Fuso orario personalizzato",
    "system_timezone" : "Fuso orario del sistema",
    "contact_timezone" : "Fuso orario del contatto",
    "account_timezone" : "Fuso orario dell'account",
    "an_error_occurred" : "Si è verificato un errore"
  },
  "popup" : {
    "calendar" : "Calendario",
    "appointment_owner" : "Titolare dell'appuntamento",
    "source" : "Fonte",
    "booked_by" : "Prenotato da",
    "view_activity_log" : "Visualizza registro delle attività",
    "status" : "Stato",
    "cancel_appointment" : "Annulla appuntamento",
    "cancel_all" : "Annulla tutto",
    "google_event" : "Evento Google",
    "outlook_event" : "Evento Outlook",
    "blocked_slot" : "Fascia oraria bloccata",
    "appointment" : "Appuntamento",
    "confirmed" : "Confermato",
    "unconfirmed" : "Non confermato",
    "cancelled" : "Annullato",
    "showed" : "Presentazione",
    "no_show" : "Mancata presentazione",
    "invalid" : "Non valido",
    "more" : "Più",
    "less" : "Meno",
    "edit" : "Modifica",
    "reschedule_all" : "Riprogramma tutto",
    "guests" : "Ospite/i",
    "guest_count" : "Numero di ospiti"
  },
  "create_calendar" : {
    "calendar_name" : "Nome del calendario",
    "calendar_logo" : "Logo del calendario",
    "select_team_member" : "Seleziona membri del team",
    "eg_outbound_reach" : "(es.) Portata in uscita",
    "description" : "Descrizione",
    "amount" : "Importo",
    "description_placeholder" : "Scrivi descrizione",
    "meeting_duration" : "Durata dell'incontro",
    "seats_per_class" : "Posti per corso",
    "group" : "Gruppo",
    "custom_url" : "URL personalizzato",
    "meeting_invite_title" : "Titolo dell'invito all'incontro",
    "event_color" : "Colore dell'evento",
    "availability_type" : "Tipo di disponibilità",
    "new_calendar" : "Nuovo calendario",
    "add_description" : "Aggiungi descrizione",
    "remove_description" : "Rimuovi descrizione",
    "booking_availability" : "Disponibilità della prenotazione",
    "slug_tooltip" : "Personalizza il link diretto a questo calendario",
    "meeting_location_tooltip" : "I link agli incontri vengono visualizzati in Eventi del calendario come \"luogo dell'incontro\" e consentono di aggiungere elementi come i link di Zoom per l'evento.",
    "advanced_settings" : "Impostazioni avanzate",
    "reschedule_preference" : "Preferenza di riprogrammazione",
    "reschedule_preference_subtext" : "Se un contatto riprogramma un appuntamento:",
    "reassign_through_round_robin" : "Riassegna tramite assegnazione a rotazione",
    "keep_same_assinged_user" : "Mantieni lo stesso titolare dell'appuntamento",
    "new_appointment_preference" : "Preferenza per nuovo appuntamento",
    "new_appointment_preference_text" : "Prenota sempre con l'utente assegnato al contatto",
    "new_appointment_preference_subtext" : "Prenota i nuovi appuntamenti con l'utente assegnato al contatto, invece di usare la programmazione a rotazione.",
    "cancel" : "Annulla",
    "confirm" : "Conferma",
    "service_calendar_name" : "Nome del servizio",
    "service_logo" : "Logo del servizio",
    "service_select_team_member" : "Seleziona membro del personale",
    "service_duration" : "Durata del servizio",
    "appointment_invite_title" : "Titolo dell'invito all'appuntamento",
    "payment_placeholder" : "Inserisci una descrizione che verrà mostrata all'utente per informarlo del pagamento",
    "deposit_amount" : "Importo del deposito",
    "collect_deposit_amount" : "Accetta pagamento parziale",
    "percentage" : "Percentuale",
    "total_amount" : "Importo totale"
  },
  "calendar_advanced" : {
    "general" : {
      "quick_tip" : "Suggerimento rapido",
      "timezone_change_tip" : "I fusi orari possono essere cambiati in qualsiasi momento nelle preferenze del fuso orario",
      "save" : "Salva",
      "close" : "Chiudi",
      "meeting_details" : "Dettagli dell'incontro",
      "availability" : "Disponibilità",
      "forms_and_payment" : "Moduli e pagamento",
      "notifications_and_additional_options" : "Notifiche e opzioni aggiuntive",
      "connections" : "Collegamenti",
      "customizations" : "Personalizzazioni",
      "create" : "Crea",
      "edit" : "Modifica",
      "equipments" : "Stanze e attrezzatura",
      "service_details" : "Dettagli del servizio"
    },
    "meeting_details" : {
      "meeting_location" : "Luogo dell'incontro",
      "enter_meeting_location" : "Inserisci luogo dell'incontro",
      "click_to_upload" : "Fai clic per caricare",
      "square" : "Quadrato",
      "circle" : "Cerchio",
      "remove_logo" : "Rimuovi logo",
      "meeting_invite_title_tooltip" : "Questo è il titolo dell'evento del calendario che viene visualizzato in Google Calendar, Outlook, Apple Calendar, ecc.",
      "event_color_tooltip" : "Scegli un colore per questo evento di prenotazione. I colori vengono sincronizzati con gli eventi di Google Calendar.",
      "enter_a_name" : "Inserisci un nome",
      "enter_an_event_title" : "Inserisci il titolo di un evento",
      "high_priority" : "Priorità alta",
      "medium_priority" : "Priorità media",
      "low_priority" : "Priorità bassa",
      "custom" : "Personalizzato",
      "zoom_not_added" : "L'utente non ha ancora collegato il proprio account Zoom",
      "opt_avail" : "Ottimizza per la disponibilità",
      "opt_equal" : "Ottimizza per una distribuzione equa",
      "team_members" : "Seleziona membri del team",
      "team_member" : "Seleziona membro del team",
      "meeting_location_required" : "Il luogo dell'incontro è obbligatorio",
      "meeting_details" : "Dettagli dell'incontro",
      "meeting_details_description" : "Questi sono i dettagli che verranno visualizzati nella pagina dell'elenco del calendario.",
      "or_drag_and_drop" : "oppure trascina e rilascia",
      "service_details" : "Dettagli del servizio",
      "team_member_disabled_recurring_tooltip" : "Gli incontri ricorrenti consentono un solo membro del team. Per aggiungere più membri del team, disabilita gli incontri ricorrenti.",
      "team_member_disabled_class_booking_tooltip" : "Solo un membro del team può essere aggiunto al calendario delle prenotazioni di un corso",
      "advanced_settings" : "Impostazioni avanzate",
      "enable_contact_assigned_to_setting" : "Per abilitare questa impostazione, il modulo deve essere il primo nell'ordine dei widget. Riorganizza l'ordine dei widget dalla scheda Moduli e pagamento.",
      "reschedule_preference_setting_tooltip" : "Scegli a chi assegnare l'appuntamento quando un contatto riprogramma dal widget di prenotazione.",
      "appointment_preference_tooltip" : "Scegli se i nuovi appuntamenti devono essere sempre prenotati con l'utente assegnato al contatto. Se a un contatto è assegnato un utente, a quell'utente verrà data la preferenza; in caso contrario, si procederà con l'assegnazione a rotazione. Suggerimento: disattiva \"Consenti selezione del personale\" per impedire ai clienti di modificare il membro del personale nel widget di prenotazione.",
      "add_location" : "Aggiungi luogo",
      "multiple_locations_neo" : "Più luoghi di incontro sono supportati solo dal widget Neo con il modulo predefinito.",
      "make_owner" : "Rendi titolare",
      "only_one_team_member" : "Quando si aggiungono più luoghi, è consentito un solo membro del team.",
      "ask_the_booker_error_message" : "Richiesta al prenotante: il luogo esiste già",
      "enter_location" : "Inserisci il valore del luogo",
      "enter_display_label" : "Inserisci etichetta di visualizzazione",
      "add_display_label" : "Aggiungi etichetta di visualizzazione",
      "multiple_location_support_message" : "Più luoghi di incontro sono supportati solo dal widget Neo con il modulo predefinito.",
      "only_one_team_member_personal_calendar" : "È consentito un solo membro del team per la prenotazione personale ",
      "ms_teams_not_added" : "L'utente non ha ancora collegato il proprio account Microsoft Teams"
    },
    "availability" : {
      "my_availability" : "La mia disponibilità",
      "set_availability" : "Imposta qui la tua disponibilità per il calendario.",
      "standard" : "Standard",
      "custom" : "Personalizzata",
      "to" : "A",
      "copy_to_all" : "Copia a tutti",
      "add_time" : "Aggiungi ora",
      "recurring_meeting" : "Incontro ricorrente",
      "recurring_info" : "Solo un utente per calendario per le prenotazioni ricorrenti",
      "repeat" : "Ripetizione",
      "times_to_repeat" : "Numero di ripetizioni",
      "slots_unavailable" : "Se le fasce orarie non sono disponibili?",
      "overlap_status" : "Stato dell'appuntamento per fasce orarie sovrapposte ",
      "meeting_interval" : "Intervallo di incontro",
      "meeting_duration" : "Durata dell'incontro",
      "minimum_scheduling" : "Preavviso minimo di programmazione",
      "date_range" : "Intervallo di date",
      "maximum_bookings_per_day" : "Numero massimo di prenotazioni al giorno",
      "maximum_bookings_per_slot" : "Numero massimo di prenotazioni per fascia oraria",
      "maximum_bookings_per_slot_per_user" : "Numero massimo di prenotazioni per fascia oraria (per utente)",
      "seats_per_class" : "Posti per corso",
      "pre_buffer_time" : "Tempo di pre-buffer",
      "post_buffer_time" : "Tempo di post-buffer",
      "pre_buffer_tooltip" : "Il pre-buffer è un periodo di tempo aggiuntivo che può essere aggiunto prima di un appuntamento, consentendo di avere più tempo per prepararsi",
      "post_buffer_tooltip" : "Il post-buffer è un periodo di tempo aggiuntivo che può essere aggiunto dopo un appuntamento, consentendo di avere più tempo per concludere",
      "buffer_time" : "Tempo di buffer",
      "daily" : "Quotidiana",
      "weekly" : "Settimanale",
      "monthly" : "Mensile",
      "skip_booking" : "Salta la prenotazione di fasce orarie non disponibili",
      "continue_booking" : "Continua a prenotare",
      "book_next_available" : "Prenota la prossima fascia oraria disponibile",
      "confirmed" : "Confermato",
      "unconfirmed" : "Non confermato",
      "enter_valid_hours" : "Inserisci orari di apertura validi",
      "enter_valid_avail" : "Inserisci una disponibilità personalizzata valida",
      "availability_type_tooltip" : "Scegli la disponibilità Standard per le prenotazioni regolari offerte settimanalmente. Scegli Personalizzata per le prenotazioni disponibili solo in date specifiche.",
      "meeting_interval_tooltip" : "L'intervallo di incontro riflette la quantità di tempo che intercorre tra le fasce orarie di prenotazione che verranno visualizzate nel calendario. Per un evento di 30 minuti che dovrebbe essere disponibile all'inizio di ogni ora, l'intervallo sarebbe di 1 ora. Per un evento di 30 minuti che dovrebbe essere disponibile ogni 30 minuti, l'intervallo sarebbe di 30 minuti.",
      "date_range_tooltip" : "Gli eventi possono essere programmati nei prossimi n giorni.",
      "maximum_bookings_per_day_tooltip" : " Numero massimo di appuntamenti consentiti al giorno su questo calendario.",
      "maximum_bookings_per_slot_tooltip" : "Numero massimo di appuntamenti consentiti per fascia oraria per utente (se il numero di appuntamenti supera la fascia oraria specificata per l'utente, passa all'utente successivo nella coda di assegnazione a rotazione o contrassegna la fascia oraria come non disponibile per la potenziale prenotazione).",
      "add_days" : "Aggiungi giorni",
      "month_on_day" : "Mensile il giorno",
      "month_on_last_day" : "Mensile l'ultimo giorno",
      "day" : "Giorno",
      "week" : "Settimana",
      "month" : "Mese",
      "mins" : "Minuti",
      "hours" : "Ore",
      "days" : "Giorni",
      "weeks" : "Settimane",
      "months" : "Mesi",
      "meeting_interval_error" : "L'intervallo di incontro deve essere compreso tra 5 minuti e 12 ore",
      "meeting_duration_error" : "La durata dell'incontro deve essere compresa tra 1 minuto e 12 ore",
      "buffer_time_error" : "Il tempo di buffer deve essere inferiore a 12 ore",
      "appointment_interval" : "Intervallo di appuntamento",
      "appointment_duration" : "Durata dell'appuntamento",
      "maximum_appointments_per_day" : "Numero massimo di appuntamenti al giorno",
      "subTitle" : "Scegli la data per impostare ore specifiche",
      "heading_1" : "Ore disponibili settimanali",
      "tooltip_1" : "Imposta il tuo programma di disponibilità settimanale. Queste sono le tue ore standard che verranno applicate in modo coerente su base settimanale",
      "heading_2" : "Quando sei disponibile?",
      "unavailable_label" : "Non disponibile",
      "tooltip_2" : "Adatta il tuo programma a date specifiche. Sostituisci il normale orario settimanale contrassegnando la disponibilità o l'indisponibilità per le date selezionate.",
      "date_specific_level" : "Aggiungi ore specifiche per data",
      "specific_hours_button" : "Ore specifiche per data",
      "overlap_error" : "Le fasce orarie non possono sovrapporsi",
      "apply" : "Applica",
      "copy_times_to" : "Copia orari in",
      "no_date_specific_heading" : "Nessuna data specifica aggiunta",
      "no_date_specific_description" : "Puoi aggiungere/rimuovere date e orari specifici alla tua disponibilità",
      "recurring_disabled_conditions_tooltip" : "Gli incontri ricorrenti sono disabilitati. Per attivarli, assicurati che il calendario abbia un solo membro del team e che non siano state aggiunte ore specifiche per una data.",
      "date_specific_hours_disabled_conditions_tooltip" : "Per includere un'ora specifica per una data, assicurati che gli incontri ricorrenti siano disattivati.",
      "recurring_disable_event_calendar_conditions" : "Gli incontri ricorrenti sono disabilitati. Per attivarli, assicurati che non siano state aggiunte ore specifiche per una data.",
      "service_duration" : "Durata del servizio",
      "service_interval" : "Intervallo di servizio",
      "service_interval_tooltip" : "L'intervallo di incontro stabilisce la frequenza con cui le fasce orarie di prenotazione vengono visualizzate nel calendario. Ad esempio, per un evento di 30 minuti, un intervallo di 1 ora significa che le fasce orarie vengono visualizzate ogni ora. Un intervallo di 30 minuti significa che le fasce orarie vengono visualizzate ogni 30 minuti.",
      "service_duration_tooltip" : "In questo modo si stabilisce la durata dell'appuntamento, determinando quando durerà.",
      "minimum_scheduling_tooltip" : "Imposta il tempo minimo necessario per prenotare un appuntamento tramite il widget del calendario. Ad esempio, se impostato su 4 ore, gli appuntamenti non possono essere prenotati entro 4 ore dall'ora di inizio; le prenotazioni devono essere effettuate con almeno 4 ore di anticipo.",
      "look_busy_title" : "Molto da fare",
      "look_busy_helper_text" : "Nascondi una percentuale delle tue fasce orarie disponibili per gli appuntamenti sul widget di prenotazione per far finta di avere molto da fare e non avere tanto tempo.",
      "look_busy_percentage_description" : "Nascondi un x% del numero di fasce orarie disponibili",
      "maximum_bookings_per_slot_tooltip_personal" : "Numero massimo di appuntamenti consentiti per fascia oraria per l'utente"
    },
    "forms_payment" : {
      "forms" : "Moduli",
      "forms_sub" : "Imposta le preferenze del modulo di post-programmazione.",
      "select_form" : "Seleziona modulo",
      "enable_same_user_assignment" : "Assegna i contatti ai rispettivi membri del team di calendario ogni volta che prenoti un appuntamento",
      "sticky_contacts" : "Precompila campi (contatti frequenti)",
      "confirmation_page" : "Pagina di conferma",
      "default" : "Predefinito",
      "redirect_url" : "URL di reindirizzamento",
      "thank_you" : "Messaggio di ringraziamento",
      "enter_url" : "Inserisci URL",
      "pixel_id" : "ID pixel di Facebook (opzionale)",
      "auto_confirm" : "Conferma automatica dei nuovi incontri del calendario",
      "configure_payment" : "Configura opzioni di pagamento",
      "accept_payments" : "Accetta pagamenti",
      "test" : "Test",
      "live" : "Live",
      "enable_guests" : "Aggiungi ospiti",
      "add_guest_tooltip" : "L'opzione di aggiunta ospiti consente al partecipante di aggiungere più partecipanti allo stesso appuntamento",
      "add_guest_custom_form_tooltip" : "Abilitando questa opzione, gli ospiti riceveranno un'email generata dal sistema con un link al modulo personalizzato una volta confermato l'appuntamento. Per evitare email duplicate, assicurati che non sia già stata configurata un'email di conferma separata per gli ospiti. (Verificalo nella scheda Notifiche > Notifica di conferma)",
      "add_guest_same_form_check" : "Invia lo stesso modulo per raccogliere informazioni via email a tutti gli ospiti",
      "payment_tooltip" : "Il pagamento sarà considerato solo per il partecipante principale e non per gli ospiti.",
      "default_form" : "Predefinito (nome, cognome, email, telefono, note)",
      "select_form_tooltip" : "Se vuoi raccogliere più informazioni rispetto a nome, cognome, email e telefono standard da chi prenota su questo calendario, crea un modulo personalizzato in Siti > Moduli > Builder, quindi seleziona il modulo dall'elenco a discesa sottostante.",
      "confirmation_page_tooltip" : "Una volta che qualcuno effettua una prenotazione, puoi scegliere di mostrargli un messaggio personalizzato sulla stessa pagina (predefinito) o reindirizzarlo a una pagina a tua scelta (reindirizzamento).",
      "add_guest_collect_email" : "Nome ed email",
      "add_guest_count_only" : "Solo numero",
      "custom_email_notification" : "Invia modulo personalizzato agli ospiti",
      "consent_label" : "Casella di controllo del consenso",
      "consent_input_placeholder" : "Aggiungi testo di consenso",
      "same_user_assignment_tooltip" : "Per abilitare questa impostazione, il modulo deve essere il primo sul widget. Per attivare questa impostazione, riorganizza il widget in modo da posizionare prima il modulo.",
      "consent_tooltip" : "Attiva questa opzione per consentire di ottenere il consenso dai contatti quando forniscono il loro numero di telefono durante il processo di prenotazione dell'appuntamento.",
      "stripe_error" : "Fino a due decimali (esempio: 25,99)",
      "auto_confirm_service" : "Conferma automatica dei nuovi appuntamenti",
      "service_form_message" : "Se un contatto prenota più servizi, vedrà solo il modulo predefinito nel menu dei servizi",
      "deposit_amount_error" : "L'importo del deposito deve essere inferiore all'importo totale.",
      "deposit_percentage_error" : "La percentuale del deposito deve essere inferiore al 100%.",
      "partial_payment_tooltip" : "Abilita i pagamenti parziali per riscuotere l'importo del deposito dai clienti durante il processo di prenotazione dell'appuntamento.",
      "partial_payment_enable_error" : "L'importo totale inserito deve essere maggiore di 0 per consentire i pagamenti parziali.",
      "payment_disable_text_integration" : "Integra un fornitore di pagamento in Pagamenti > Integrazioni per iniziare ad accettare pagamenti",
      "payment_disable_text_recurring" : "L'opzione Accetta pagamento non è disponibile per gli incontri ricorrenti. Disattiva gli incontri ricorrenti per abilitare i pagamenti.",
      "date_and_time_selector" : "Selettore di data e ora",
      "form_selector" : "Modulo",
      "order_step" : "Passaggio",
      "widget_order_label" : "Ordine dei widget",
      "widget_order_tool_tip" : "Riorganizza l'ordine del \"Selettore di data e ora\" e del \"Modulo\" nel widget del calendario. È sufficiente trascinare e riordinare secondo le proprie preferenze.",
      "widget_re_order_tooltip" : "Il riordino è disponibile solo con lo stile widget Neo. Passa a Neo per abilitare il riordino.",
      "widget_re_order_disabled_tooltip" : "Per riorganizzare l'ordine dei widget, disabilita \"Preferenza per nuovo appuntamento (Prenota sempre con l'utente assegnato al contatto)\" dalla sezione Dettagli dell'incontro > Membri del team > Impostazioni avanzate.",
      "payment_description_error" : "Limite massimo di 1000 caratteri raggiunto",
      "widget_multiple_location_disabled_tooltip" : "Il widget Classico non supporta più luoghi di incontro. Rimuovi eventuali luoghi aggiuntivi per utilizzare il widget Classico",
      "recurring_payment_label" : "Pagamento degli appuntamenti ricorrenti",
      "first_appointment_only" : "Solo primo appuntamento",
      "all_appointments" : "Tutti gli appuntamenti"
    },
    "notifications" : {
      "notifications" : "Notifiche e opzioni aggiuntive",
      "notifications_sub" : "Configura le notifiche e le opzioni aggiuntive",
      "select_notification_type" : "Seleziona il tipo di notifica",
      "notification_person" : "Chi dovrebbe ricevere questa notifica?",
      "contact" : "Contatto",
      "guest" : "Ospite",
      "assigned_user" : "Utente assegnato",
      "emails" : "Email",
      "alert_email_address" : "Indirizzo email di avviso",
      "ack_email" : "Email di conferma",
      "send_google_invites" : "Consenti a Google Calendar di inviare email di invito o di aggiornamento ai partecipanti.",
      "send_google_outlook_invites" : "Consenti a Google Calendar / Calendario di Outlook di inviare email di invito e aggiornamento ai partecipanti.",
      "assign_user_to_contact" : "Assegna i contatti ai rispettivi membri del team di calendario ogni volta che prenoti un appuntamento",
      "skip_assigning_user_to_contact" : "Salta l'assegnazione del contatto se il contatto ha già un utente assegnato",
      "assigned_staff" : "Personale assegnato",
      "additional_emails" : "Email aggiuntive",
      "assign_user_to_staff" : "Assegna i contatti ai rispettivi membri del personale di servizio ogni volta che prenoti un appuntamento",
      "allow_cancellation" : " Consenti annullamento dell'incontro",
      "allow_reschedule" : "Consenti riprogrammazione dell'incontro",
      "allow_cancellation_tooltip" : "Se l'opzione è abilitata, un link di annullamento verrà aggiunto alla sezione delle note aggiuntive e incluso nell'invito del calendario. Questo link scadrà come configurato prima dell'ora di inizio dell'incontro, impedendo al prenotante di annullare l'incontro.",
      "allow_reschedule_tooltip" : " Se l'opzione è abilitata, un link di riprogrammazione verrà aggiunto alla sezione delle note aggiuntive e incluso nell'invito del calendario. Questo link scadrà come configurato prima dell'ora di inizio dell'incontro, impedendo al prenotante di riprogrammare l'incontro.",
      "cancellation_link_expiry" : "Il link di annullamento scadrà",
      "reschedule_link_expiry" : "Il link di riprogrammazione scadrà",
      "before_meeting" : "prima dell'incontro",
      "cancellation_and_reschedule_policy" : "Politica di annullamento e riprogrammazione:"
    },
    "connections" : {
      "connections_heading" : "Sincronizzazione del calendario esterno",
      "connections_sub" : "Sincronizza gli eventi con il calendario esterno",
      "link_to_calendar" : "Link al calendario",
      "sync_option" : "Opzione di sincronizzazione",
      "execute_automation" : "Esegui automazione",
      "fire_workflow" : "Attiva i trigger del flusso di lavoro per gli eventi prenotati tramite sincronizzazione bidirezionale",
      "reserve_with_google" : "Prenota con Google",
      "connect_lsa" : "Collegati al tuo account di annunci di servizi locali",
      "reserve_error" : "Attualmente Prenota con Google è supportato solo per le nicchie aziendali \"Servizi per la casa\", \"Beni immobili\", \"Servizi legali\" (fornitori di servizi locali) negli Stati Uniti.",
      "one_way" : "Unidirezionale",
      "two_way" : "Bidirezionale",
      "smart" : "Intelligente",
      "confirm_remove" : "Vuoi davvero rimuovere il calendario Google?",
      "read_only_access" : "Non hai accesso in scrittura a questo calendario. Seleziona un calendario diverso.",
      "reserve_with_google_desc" : "Questo servizio di calendario sarà elencato in Prenota con Google solo quando l'integrazione sarà riuscita.",
      "check_integration" : "Controlla lo stato dell'integrazione",
      "setup_external" : "Configura il tuo calendario esterno",
      "reserve_price_decimal_error" : "Il prezzo decimale non è supportato",
      "reserve_price_max_error" : "Prezzo supportato inferiore a 1000"
    },
    "extras" : {
      "extras" : "Personalizzazioni",
      "extras_sub" : "Imposta lo stile del widget e altre preferenze.",
      "calendar_widget_style" : "Stile del widget del calendario",
      "neo" : "Neo",
      "classic" : "Classico",
      "insert_custom_code" : "Inserisci codice personalizzato",
      "show_seats_per_slot" : "Mostra posti per fascia oraria",
      "allow_reschedule_meeting" : "Consenti riprogrammazione dell'incontro",
      "allow_cancelling_meeting" : "Consenti annullamento dell'incontro",
      "additional_notes" : "Note aggiuntive",
      "cancellation_and_reschedule" : "Annullamento e riprogrammazione:",
      "allow_staff" : "Consenti selezione del personale durante prenotazione",
      "code_block_tooltip" : "Gli script di terze parti all'interno del blocco HTML possono fornire potenti funzionalità, ma comportano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. Pertanto, ti consigliamo di rivedere il contenuto di questo script prima di salvarlo nel calendario.",
      "calendar_image" : "Immagine di copertina del calendario",
      "remove_image" : "Rimuovi immagine",
      "calendar_image_info" : "L'immagine caricata sarà visibile all'interno della Vista gruppo per il modello Neo e non apparirà sul link del singolo calendario",
      "get_image_from_unsplash" : "Migliora i tuoi contenuti visivi con",
      "unsplash" : "Unsplash",
      "service_additional_preferences" : "Configura preferenze aggiuntive",
      "service_image" : "Immagine di copertina del servizio",
      "service_image_description" : "Seleziona un'immagine che verrà visualizzata nel menu dei servizi per questo servizio",
      "allow_reschedule_appointment" : "Consenti riprogrammazione dell'appuntamento",
      "allow_cancelling_appointment" : "Consenti annullamento dell'appuntamento",
      "custom_code_placeholder" : "Inserisci qui il codice personalizzato",
      "calendar_widget_style_desc" : "Scegli tra il nostro widget Classico o l'elegante Neo",
      "seats_per_slot" : "Posti per fascia oraria",
      "show_staff_selection" : "Selezione del personale",
      "seats_per_slot_desc" : " Permetti ai prenotanti di vedere il numero di posti disponibili per ogni fascia oraria direttamente sul widget del calendario.",
      "show_staff_selection_desc" : "Permetti ai prenotanti di scegliere un membro dello staff direttamente dal widget del calendario durante la prenotazione dell'appuntamento. Funziona solo con il widget Neo",
      "widget_re_order_tooltip" : "Lo stile widget Classico non consente di modificare l'ordine dei widget. Assicurati che il selettore di data e ora sia al primo posto e il modulo al secondo prima di selezionare lo stile Classico.",
      "show_staff_selection_desc_service" : "Permetti ai prenotanti di scegliere un membro dello staff direttamente dal widget del calendario durante la prenotazione dell'appuntamento."
    },
    "roomsAndEquipments" : {
      "title" : "Seleziona stanze e attrezzatura",
      "description" : "Seleziona da un elenco di stanze e attrezzatura cosa verrà prenotato insieme a questo calendario",
      "rooms" : "Seleziona stanze",
      "equipments" : "Seleziona attrezzatura"
    }
  },
  "activity_log" : {
    "activity_log" : "Registro delle attività",
    "date" : "Data",
    "filter_by" : "Filtra per",
    "calendar_update" : "Aggiornamento del calendario",
    "start_time_update" : "Aggiornamento dell'ora di inizio",
    "end_time_update" : "Aggiornamento dell'ora di fine",
    "title_update" : "Aggiornamento del titolo",
    "notes_update" : "Aggiornamento delle note",
    "appointment_status_update" : "Aggiornamento dello stato dell'appuntamento",
    "meeting_location_update" : "Aggiornamento del luogo dell'incontro",
    "google_sync_update" : "Aggiornamento di Google Sync",
    "outlook_sync_update" : "Aggiornamento della sincronizzazione di Outlook",
    "assigned_user_update" : "Aggiornamento dell'utente assegnato",
    "appointment_status" : "Stato dell'appuntamento",
    "assigned_user" : "Utente assegnato",
    "uploaded_to" : "Caricato su",
    "cancelled" : "Annullato",
    "edited" : "modificato",
    "other_edits" : "Altre modifiche",
    "view_details" : "Visualizza dettagli",
    "all" : "Tutto"
  },
  "appointment_list" : {
    "group_all" : "TUTTO",
    "group_primary" : "PRIMARIO",
    "group_user" : "UTENTI",
    "group_team" : "GRUPPI",
    "group_calendar" : "CALENDARI",
    "all_appointments" : "Tutti gli appuntamenti",
    "my_calendar" : "Il mio calendario",
    "status_all" : "Tutto",
    "status_confirmed" : "Confermato",
    "status_new" : "Nuovo (azione richiesta)",
    "status_show" : "Presentazione",
    "status_no_show" : "Mancata presentazione",
    "status_cancelled" : "Annullato",
    "status_invalid" : "Non valido",
    "filter_date_added_desc" : "Data aggiunta (desc.)",
    "filter_start_added_desc" : "Data di inizio (desc.)",
    "filter_start_added_asc" : "Data di inizio in ordine crescente da oggi",
    "button_new_appointment" : "Nuovo appuntamento",
    "name" : "Nome",
    "title" : "Titolo",
    "requested_time" : "Ora richiesta",
    "date_added" : "Data aggiunta",
    "calendar" : "Calendario",
    "appointment_owner" : "Titolare dell'appuntamento",
    "notes" : "Note",
    "status" : "Stato",
    "action_view_detail" : "Visualizza dettagli",
    "action_view_consent" : "Visualizza consenso",
    "action_view_notes" : "Visualizza note",
    "action_edit" : "Modifica",
    "action_delete" : "Elimina",
    "action_reschedule" : "Riprogramma",
    "page_previous" : "Precedente",
    "page_next" : "Successivo",
    "appointment_notes" : "Note dell'appuntamento",
    "close" : "Chiudi",
    "confirm_delete_header" : "Elimina appuntamento",
    "confirm_delete_message" : "Vuoi davvero eliminare questo appuntamento? Questa azione non può essere annullata!",
    "confirm_delete_button" : "Elimina",
    "confirm_update_header" : "Aggiorna stato dell'appuntamento",
    "confirm_update_confirmed_message" : "Vuoi davvero contrassegnare questo appuntamento come \"confermato\"?",
    "confirm_update_invalid_message" : "Vuoi davvero contrassegnare questo appuntamento come \"non valido\"?",
    "confirm_update_showed_message" : "Vuoi davvero contrassegnare questo appuntamento come \"presentazione\"?",
    "confirm_update_noshow_message" : "Vuoi davvero contrassegnare questo appuntamento come \"mancata presentazione\"?",
    "confirm_update_cancelled_message" : "Vuoi davvero contrassegnare questo appuntamento come \"annullato\"?",
    "confirm_update_button" : "Aggiorna",
    "empty_state_title" : "Nessun appuntamento trovato",
    "empty_state_description" : "Nessun appuntamento trovato",
    "disable_menu_warning" : "Al momento, non disponi dell'autorizzazione per visualizzare o modificare i dettagli dell'appuntamento, poiché il contatto associato non ti è assegnato. Per ulteriore assistenza, ti preghiamo di contattare il tuo amministratore.",
    "no_contact_warning" : "Al momento non riesci ad accedere alle informazioni del contatto. Ciò potrebbe essere dovuto al fatto che il contatto non è assegnato a te. Contatta il tuo amministratore per ricevere assistenza.",
    "consent_label" : "Consenso",
    "ip_address" : "IP",
    "youre_viewing_in" : "Attualmente in visualizzazione in",
    "appointment_delete_msg" : "<b>Nota bene:</b> l'appuntamento eliminato da qui non verrà eliminato da Calendly.",
    "rescheduled" : "Riprogrammato"
  },
  "add_guest" : {
    "guest_label" : "Ospite/i",
    "search_placeholder" : "Cerca nei contatti",
    "add_new" : "Aggiungi nuovo",
    "save" : "Salva",
    "close" : "Chiudi",
    "name" : "Nome",
    "phone" : "Telefono",
    "email" : "Email",
    "guest_label_count" : "Numero di ospiti",
    "guest_count_error" : "Il numero di ospiti deve essere compreso tra 1 e 100"
  },
  "languages" : {
    "english" : "Inglese",
    "dutch" : "Olandese",
    "french" : "Francese",
    "german" : "Tedesco",
    "hungarian" : "Ungherese",
    "italian" : "Italiano",
    "polish" : "Polacco",
    "portuguese_brazil" : "Portoghese (Brasile)",
    "portuguese_portugal" : "Portoghese (Portogallo)",
    "spanish" : "Spagnolo",
    "danish" : "Danese"
  },
  "service_menu_advanced" : {
    "new_service_menu_details_header" : "Dettagli del menu dei servizi",
    "select_groups_header" : "Seleziona servizi",
    "arrange_groups_header" : "Organizza i tuoi servizi",
    "new_service_menu" : "Nuovo menu dei servizi",
    "service_menu_sub_title" : "Crea un menu dei servizi personalizzato per i tuoi clienti in pochi clic",
    "quick_tip" : "Puoi aggiungere gruppi al menu dei servizi",
    "service_menu_name" : "Nome del menu dei servizi",
    "service_menu_description" : "Descrizione",
    "service_menu_slug" : "URL del servizio",
    "select_groups_calendars" : "Seleziona gruppi e calendari",
    "select_groups_calendars_desc" : "Aggiungi servizi al tuo menu dei servizi",
    "select_all" : "Seleziona tutto",
    "arrange_groups" : "Organizza ordine dei servizi",
    "arrange_groups_desc" : "Determina la sequenza dei servizi nel menu dei servizi",
    "arrange_services_quicktip" : "Per riorganizzare i calendari all'interno del gruppo, vai a Gruppi > Opzioni > Riorganizza calendari.",
    "enable_add_guest_desc" : "Consente ai clienti di includere ospiti aggiuntivi al momento della prenotazione.",
    "enable_multiple_service_desc" : "Consente ai clienti di prenotare più servizi in un unico appuntamento.",
    "enable_staff_selection_desc" : "Consente ai clienti di scegliere i membri del personale preferiti quando effettuano una prenotazione.",
    "enable_add_guest" : "Abilita aggiunta di ospiti",
    "enable_multiple_service" : "Abilita selezione di più servizi",
    "enable_staff_selection" : "Abilita selezione del personale",
    "additional_options" : "Opzioni aggiuntive",
    "code_block_tooltip" : "Gli script di terze parti all'interno del blocco HTML possono fornire potenti funzionalità, ma comportano anche rischi per la privacy, la sicurezza, le prestazioni e il comportamento della pagina. Pertanto, ti consigliamo di rivedere il contenuto di questo script prima di salvarlo nel menu dei servizi.",
    "custom_code_placeholder" : "Inserisci qui il codice personalizzato",
    "insert_custom_code" : "Inserisci codice personalizzato",
    "no_service" : "Nessun servizio trovato",
    "no_service_desc" : " Nessun servizio trovato. Crea servizi e aggiungili ai gruppi per creare un menu dei servizi.",
    "learn_more" : "Scopri di più",
    "no_service_selected" : "Nessun servizio selezionato",
    "create_service_calendars" : "Crea calendari dei servizi",
    "alert_description" : " Per creare un menu dei servizi, devi creare calendari dei servizi e aggiungerli ai gruppi",
    "dont_show_again" : "Non mostrarlo più",
    "service_name_required" : "Il nome del menu dei servizi è obbligatorio",
    "slug_already_taken" : "Questo slug è già stato preso",
    "slug_required" : "Lo slug è obbligatorio",
    "please_enter_name" : "Inserisci un nome",
    "service_name_placeholder" : "Menu principale",
    "consent_label" : "Casella di controllo del consenso",
    "consent_input_placeholder" : "Aggiungi testo di consenso",
    "consent_tooltip" : "Attiva questa opzione per consentire di ottenere il consenso dai contatti quando forniscono il loro numero di telefono durante il processo di prenotazione dell'appuntamento."
  },
  "widget_configuration" : {
    "customise_calendar_widget" : "Personalizza widget del calendario",
    "works_with_neo_widget" : "Funziona solo con il widget Neo",
    "customise_widget_desc" : "Personalizza l'aspetto del widget: colore primario, colore di sfondo e testo del pulsante.",
    "learn_more" : "Scopri di più",
    "primary_settings" : "Impostazioni primarie",
    "primary_settings_desc" : "Configura il colore primario, il colore di sfondo e altre preferenze del widget per personalizzare il tuo widget del calendario",
    "primary_color" : "Colore primario",
    "primary_color_desc" : "Definisci il colore per pulsanti, date, fasce orarie e azioni",
    "background_color" : "Colore di sfondo",
    "background_color_desc" : "Imposta il colore di sfondo che funge da sfondo sia per il selettore di data e ora che per il modulo.",
    "button_text" : "Testo del pulsante",
    "button_text_desc" : "Personalizza il testo visualizzato sul pulsante quando prenoti un appuntamento",
    "calendar_title" : "Titolo del calendario",
    "calendar_title_desc" : "Il nome del tuo calendario",
    "calendar_description" : "Descrizione del calendario",
    "calendar_description_desc" : "La descrizione inserita",
    "calendar_details" : "Dettagli del calendario",
    "calendar_details_desc" : "Include informazioni come durata, data e ora, dettagli ricorrenti e fuso orario",
    "reset_to_default" : "Ripristina le impostazioni predefinite",
    "preview_widget" : "Anteprima del widget"
  },
  "eventNotification" : {
    "customFields" : "Custom Fields",
    "customValues" : "Custom Values",
    "list" : {
      "booked" : {
        "title" : "Appointment Booked (Status: Unconfirmed)",
        "note" : "This notification is sent when a new appointment is created with Unconfirmed status.",
        "subTitle" : "Notifica quando un appuntamento è prenotato con uno stato non confermato"
      },
      "confirmation" : {
        "title" : "Appointment Booked (Status: Confirmed)",
        "note" : "This notification is sent when an appointment is created with or updated to the Confirmed status.",
        "subTitle" : "Notifica quando un appuntamento viene confermato con successo"
      },
      "cancellation" : {
        "title" : "Cancellation",
        "note" : "This notification is sent when an appointment is cancelled, marked as no-show, or marked as invalid.",
        "subTitle" : "Avvisa quando un appuntamento viene annullato"
      },
      "reschedule" : {
        "title" : "Reschedule",
        "note" : "This notification is sent when an appointment is rescheduled. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Notifica quando un appuntamento viene riprogrammato"
      },
      "reminder" : {
        "title" : "Reminder",
        "note" : "This notification is sent as a reminder before the appointment. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Invia un promemoria prima dell'appuntamento"
      },
      "followup" : {
        "title" : "Follow-Up",
        "note" : "This notification is sent after the appointment as a follow-up. It will only be triggered if the appointment is in Unconfirmed, Confirmed, or Showed status.",
        "subTitle" : "Invia un messaggio di follow-up dopo il completamento dell'appuntamento"
      }
    },
    "saveToastMsg" : "Impostazioni salvate!",
    "saveToastMsgDesc" : "Le modifiche sono state salvate con successo",
    "enableSuccess" : "Notifiche attivate!",
    "disableSuccess" : "Notifiche disattivate!",
    "tooltip" : "This notification has been disabled. Please enable it to make any changes.",
    "enabled" : "Enabled",
    "disabled" : "Disabled",
    "statusLabel" : "Status Labels",
    "email" : {
      "channelName" : "Email",
      "noneTemplate" : "Nessuno",
      "notificationLabel" : "Chi dovrebbe ricevere questa notifica?",
      "sendButtonLabel" : "Invia email di test",
      "testEmailLabel" : "Email di test",
      "testEmailPlaceholder" : "Inserisci l'indirizzo email del destinatario per il test",
      "body" : "Corpo dell'email",
      "bodyPlaceholder" : "Digita qui il tuo messaggio",
      "subject" : "Oggetto",
      "subjectPlaceholder" : "Inserisci l'oggetto dell'email",
      "templateLabel" : "Modello di email",
      "templatePlaceholder" : "Seleziona un modello di email o inizia da zero",
      "alertEmailAddress" : "Indirizzo email di avviso",
      "addMore" : "Aggiungi altro",
      "afterAppointmentEnds" : "dopo la fine dell'appuntamento",
      "beforeAppointmentStarts" : "prima dell'inizio dell'appuntamento",
      "durationLabel" : "Quando desideri attivare la notifica?",
      "disableEmailNotification" : "Disabilita notifiche email",
      "enableEmailNotification" : "Abilita notifiche email",
      "enableSuccess" : "Notifica email abilitata!",
      "enableSuccessDesc" : "Ora inizierai a ricevere questa notifica",
      "disableSuccess" : "Notifica email disabilitata!",
      "disableSuccessDesc" : "Non riceverai più questa notifica"
    },
    "inApp" : {
      "channelName" : "In-app",
      "title" : "Impostazioni di notifica",
      "enableInAppNotification" : "Enable In-App Notification",
      "disableInAppNotification" : "Disable In-App Notification",
      "selectedUser" : {
        "label" : "Invia a",
        "helperText" : "Questa notifica interna viene inviata tramite il web e le app mobili",
        "placeholder" : "Seleziona utente/i"
      },
      "enableSuccess" : "Notifiche in-app abilitate!",
      "enableSuccessDesc" : "Ora inizierai a ricevere questa notifica",
      "disableSuccess" : "Notifiche in-app disabilitate!",
      "disableSuccessDesc" : "Non riceverai più questa notifica"
    }
  }
}