<template>
  <div>
    <div
      class="ghl-workflow-text-style-editor flex flex-wrap items-center gap-2 border-b px-2 py-1.5 pb-4"
    >
      <template v-if="isToolbarOptionEnabled('customVariablePicker')">
        <RTEControlCustomVariablePicker :editor="editor" />
        <RTEControlTriggerLinksPicker
          v-if="isToolbarOptionEnabled('triggers')"
          :editor="editor"
        />
      </template>
      <RTEControlHistory type="undo" :editor="editor" />
      <RTEControlHistory type="redo" :editor="editor" />
      <template v-if="isToolbarOptionEnabled('styleBasic')">
        <RTEControlBold :editor="editor" />
        <RTEControlItalic :editor="editor" />
        <RTEControlUnderline :editor="editor" />
      </template>

      <!-- Font styling -->
      <template v-if="isToolbarOptionEnabled('styleAdvanced')">
        <RTEControlFontFamily :editor="editor" fallback-font="verdana" />
        <RTEControlFontSize :editor="editor" :fallback-font-size="16" />
        <RTEControlTextBlocks :editor="editor" />

        <RTEControlLineHeight :editor="editor" :fallback-line-height="'1'" />
        <!-- Additional items -->
        <!-- Color picker -->
        <div class="overflow-menu-icon">
          <RTEControlColorPicker :editor="editor" tooltip-label="Text color" />
        </div>
        <div class="overflow-menu-icon">
          <RTEControlColorPicker
            :editor="editor"
            color-for="highlight"
            tooltip-label="Background color"
          />
        </div>
        <!-- List -->
        <RTEControlList :editor="editor" list-type="bulletList" />
        <RTEControlList :editor="editor" list-type="orderedList" />

        <!-- Text align -->
        <UIPopover trigger="click">
          <template #trigger>
            <div>
              <UITooltip>
                <template #trigger>
                  <AlignLeftIcon class="h-5 w-5 py-0.5 text-gray-600" />
                </template>
                Text align
              </UITooltip>
            </div>
          </template>
          <div class="flex gap-2">
            <RTEControlTextAlignment :editor="editor" align="left" />
            <RTEControlTextAlignment :editor="editor" align="center" />
            <RTEControlTextAlignment :editor="editor" align="right" />
            <RTEControlTextAlignment :editor="editor" align="justify" />
          </div>
        </UIPopover>

        <OverflowMenu :editor="editor" />
      </template>

      <template v-if="isToolbarOptionEnabled('link')">
        <RTEControlEmbedLink
          :editor="editor"
          :custom-values="triggerLinkOptions"
        />
      </template>
      <template v-if="isToolbarOptionEnabled('image')">
        <RTEControlEmbedImage
          :editor="editor"
          external-embed-text="Upload"
          :external-embedded-image="externalEmbeddedImage"
          @trigger-external-embed="triggerExternalEmbed"
        />
        <input
          ref="fileInputRef"
          class="hidden"
          type="file"
          accept="image/png, image/jpeg, image/gif"
          @change="onFileChange"
        />
      </template>
      <template v-if="isToolbarOptionEnabled('sourceCode')">
        <RTEControlSourceCode :editor="editor" />
      </template>
      <template v-if="isToolbarOptionEnabled('textWrap')">
        <RTEControlTextWrap :editor="editor" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
// import { getLinks } from '@/models/Triggers/FilterState'
import { FileService } from '@/class/services/FileService'
import { AlignLeftIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  RTEControlBold,
  RTEControlColorPicker,
  RTEControlEmbedImage,
  RTEControlEmbedLink,
  RTEControlFontFamily,
  RTEControlFontSize,
  RTEControlHistory,
  RTEControlItalic,
  RTEControlLineHeight,
  RTEControlList,
  RTEControlTextAlignment,
  RTEControlTextBlocks,
  RTEControlUnderline,
} from '@gohighlevel/ghl-text-editor'
import { UIPopover, UITooltip } from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import { defineComponent, onMounted, ref } from 'vue'
import OverflowMenu from './OverflowMenu.vue'
import RTEControlCustomVariablePicker from './RTEControlCustomVariablePicker.vue'
import RTEControlSourceCode from './RTEControlSourceCode.vue'
import RTEControlTextWrap from './RTEControlTextWrap.vue'
import RTEControlTriggerLinksPicker from './RTEControlTriggerLinksPicker.vue'
import { eventNotificationState } from '@/states/EventNotificationState'
import AppState from '@/states/app'

export default defineComponent({
  name: 'StyleMenu',
  components: {
    AlignLeftIcon,
    RTEControlBold,
    RTEControlColorPicker,
    RTEControlEmbedImage,
    RTEControlEmbedLink,
    RTEControlFontFamily,
    RTEControlFontSize,
    RTEControlItalic,
    RTEControlLineHeight,
    RTEControlList,
    RTEControlTextAlignment,
    RTEControlTextBlocks,
    RTEControlUnderline,
    UIPopover,
    OverflowMenu,
    UITooltip,
    RTEControlHistory,
    RTEControlSourceCode,
    RTEControlCustomVariablePicker,
    RTEControlTriggerLinksPicker,
    RTEControlTextWrap,
  },
  props: {
    editor: {
      type: Editor,
      required: true,
    },
    toolbarOptions: {
      type: Array,
      default: () => [
        'customVariablePicker',
        'styleBasic',
        'styleAdvanced',
        'image',
        'link',
        'emoji',
        'sourceCode',
        'textWrap',
      ],
    },
  },
  setup(props) {
    const externalEmbeddedImage = ref<string>('')
    const fileInputRef = ref()
    const triggerExternalEmbed = (e: MouseEvent) => {
      fileInputRef.value && fileInputRef.value.click()
    }
    const triggerLinkOptions = ref([])

    async function handleImageUploadInEditor(
      blobInfo: any,
      success: any,
      failure: any
    ) {
      const attachment = blobInfo.blob()
      const formData = new FormData()
      formData.append('file', attachment)
      try {
        const resp = await FileService.uploadFiles(
          formData,
          eventNotificationState.calendarId,
          AppState?.locationId
        )
        const url = resp[0].url
        success(url)
      } catch (err) {
        console.error('Error in uploading', err)
        failure()
      }
    }

    const onFileChange = (e: any) => {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      const file = files[0]
      handleImageUploadInEditor(
        { blob: () => file },
        (url: string) => {
          externalEmbeddedImage.value = url
        },
        () => {
          console.error('Error in uploading')
        }
      )
    }
    const isToolbarOptionEnabled = (option: string) =>
      props.toolbarOptions.includes(option)

    async function getTriggerLinks() {
      // const items = (await getLinks()).map(link => {
      //   return {
      //     label: link.name || link.id || '',
      //     value: `{{trigger_link.${link.id}}}`,
      //   }
      // })
      // triggerLinkOptions.value = items
    }

    onMounted(() => {
      getTriggerLinks()
    })

    return {
      isToolbarOptionEnabled,
      externalEmbeddedImage,
      fileInputRef,
      triggerExternalEmbed,
      onFileChange,
      triggerLinkOptions,
    }
  },
})
</script>

<style scoped>
.ghl-workflow-text-style-editor .ghl-color-picker__container,
.ghl-workflow-text-style-editor .n-color-picker.compact-picker {
  height: 24px;
  width: 24px;
  --n-height: 24px;
}

.ghl-workflow-text-style-editor #toolbar_select--font_family {
  width: 125px;
}

.ghl-workflow-text-style-editor #toolbar_select--font_size {
  width: 70px;
}

.ghl-workflow-text-style-editor #toolbar_select--text_block {
  width: 99px;
}

.ghl-workflow-text-style-editor #toolbar_select--line_height {
  min-width: 73px;
}

.ghl-workflow-text-style-editor .n-base-selection {
  --n-height: 24px !important;
  --n-border-radius: 5px !important;
}

.ghl-workflow-text-style-editor .n-base-selection .n-base-selection__border,
.ghl-workflow-text-style-editor
  .n-base-selection
  .n-base-selection__state-border {
  border-radius: var(--n-border-radius);
}

.ghl-workflow-text-style-editor
  .n-base-selection
  .n-base-selection-label
  .n-base-selection-input
  .n-base-selection-input__content {
  font-size: 12px;
}

.n-base-select-option__content {
  font-size: 12px;
}

.overflow-menu-icon {
  border-radius: 0.25rem;
  outline: none;
  transition: 0.3s ease-in-out;
}

.overflow-menu-icon:hover {
  background-color: #e8f3fe;
}

.ghl-workflow-text-style-editor .ghl-color-picker__container {
  @apply relative;
}

.ghl-workflow-text-style-editor .ghl-color-picker__container .hu-color-picker {
  top: unset;
  @apply bottom-8;
}

.ghl-workflow-text-style-editor .toolbar_button img {
  height: 16px;
  width: 16px;
}
</style>
