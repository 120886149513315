import { GetAppointmentsParams, GetCalendarEventsParams } from '@/types/index'

import AppState from '../../states/app'
import config from '@/config'
import { requests } from './BaseService'

export const CalendarService = {
  getAll: (locationId: string, showThirdPartyCal: boolean) =>
    requests().get(`${config.calendarApiUrl}/calendars/`, {
      params: {
        locationId,
        showThirdParty: showThirdPartyCal,
      },
    }),
  get: (calendarId: string) =>
    requests().get(`${config.calendarApiUrl}/calendars/${calendarId}`),
  updateStatus: (calendarId: string, isActive: boolean) =>
    requests().put(`${config.calendarApiUrl}/calendars/${calendarId}/status`, {
      isActive,
    }),
  create: (calendar: any) =>
    requests().post(`${config.calendarApiUrl}/calendars/`, calendar),
  update: (calendarId: string, calendar: any) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/${calendarId}`,
      calendar
    ),
  delete: (id: string) =>
    requests().delete(`${config.calendarApiUrl}/calendars/${id}`),
  getAvailableMeetingLocationChoices: (locationId: string) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/meeting-location-choices`,
      {
        params: {
          locationId,
        },
      }
    ),
  applyDefaultMeetingLocation: (locationId: string) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/apply-default-meeting-location`,
      {
        locationId,
      }
    ),
  reorder: (locationId: string, providerId: string, calendarOrderMap: any) =>
    requests().put(`${config.calendarApiUrl}/calendars/reorder`, {
      locationId,
      providerId,
      calendarOrderMap,
    }),
  moveToGroup: (locationId: string, providerId: string, calendarId: string) =>
    requests().put(`${config.calendarApiUrl}/calendars/move-group`, {
      locationId,
      providerId,
      calendarId,
    }),
  blockSlot: data =>
    requests().post(
      `${config.calendarApiUrl}/calendars/events/block-slots`,
      data
    ),
  createAppointment: data =>
    requests().post(
      `${config.calendarApiUrl}/calendars/events/appointments`,
      data
    ),
  createRecurringEvents: data =>
    requests().post(
      `${config.calendarApiUrl}/calendars/events/recurring-appointments`,
      data
    ),
  generateRRuleText: data =>
    requests().post(
      `${config.calendarApiUrl}/calendars/generate-rrule-text`,
      data
    ),
  updateAppointment: (appointmentId: string, appointment: any) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/events/appointments/${appointmentId}`,
      appointment
    ),
  updateBlockSlot: (appointmentId: string, appointment: any) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/events/block-slots/${appointmentId}`,
      appointment
    ),
  getEventById: (appointmentId: string) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/events/appointments/${appointmentId}`
    ),
  getRecurringSlot: (selectedCalendarId, params) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/${selectedCalendarId}/recurring-slots`,
      {
        params,
      }
    ),
  getFreeSlots: (calendarId, freeSlotQuery) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/${calendarId}/free-slots`,
      {
        params: freeSlotQuery,
      }
    ),
  deleteAppointment: (appointmentToBeEdited, data) =>
    requests().delete(
      `${config.calendarApiUrl}/calendars/events/appointments/${appointmentToBeEdited}`,
      {
        data,
      }
    ),
  getExternalGoogleCalendars: (locationId: string) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/external-calendars/get-calendars`,
      {
        params: {
          locationId,
          source: 'GOOGLE',
        },
      }
    ),
  linkGoogleCalendar: (calendarId: string) =>
    requests().post(
      `${config.baseUrl}/google/calendar/link`,
      {
        calendar_id: calendarId,
      },
      {
        headers: {
          Authorization: `Bearer ${AppState.authApiKey}`,
        },
      }
    ),
  unlinkGoogleCalendar: (calendarId: string) =>
    requests().get(`${config.baseUrl}/google/calendar/unlink`, {
      params: {
        calendar_id: calendarId,
      },
      headers: {
        Authorization: `Bearer ${AppState.authApiKey}`,
      },
    }),
  isValidCalendarSlug: (slug: string, calendarId = '', groupId = '') =>
    requests().post(
      `${config.calendarApiUrl}/calendars/validate-calendar-slug`,
      {
        calendarId,
        slug,
        groupId,
      }
    ),
  uploadCalendarLogo: (calendarId: string, data: any, location: string) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/${calendarId}/logo`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          locationId: location,
        },
      }
    ),
  removeCalendarLogo: (calendarId: string, location: string) =>
    requests().delete(`${config.calendarApiUrl}/calendars/${calendarId}/logo`, {
      params: {
        locationId: location,
      },
    }),
  fetchMerchant: (locationId: string) =>
    requests().get(`${config.reserveServiceBaseUrl}/merchant`, {
      params: {
        location_id: locationId,
      },
    }),
  getCalendarEvents: (params: GetCalendarEventsParams) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/events/get-calendar-events`,
      params
    ),
  getAppointments: (params: GetAppointmentsParams) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/events/get-appointments`,
      params
    ),

  createService: (data: any) =>
    requests().post(`${config.reserveServiceBaseUrl}/service/create`, data),
  deleteReserveService: (id: string) =>
    requests().delete(`${config.reserveServiceBaseUrl}/service/${id}`),
  fetchEntity: (locationId: string) =>
    requests().get(
      `${config.calendarApiUrl}/calendars/google-organic/entity?locationId=${locationId}`
    ),
  createEntity: (body: any) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/google-organic/entity`,
      body
    ),
  updateEntity: (entityId: string, body: any) =>
    requests().put(
      `${config.calendarApiUrl}/calendars/google-organic/entity/${entityId}`,
      body
    ),
  generateOneTimeSlug: (calendarId: string) =>
    requests().post(
      `${config.calendarApiUrl}/calendars/one-time-slug/${calendarId}`
    ),
}
