{
  "settings" : {
    "calendar" : {
      "calendar_settings" : "Kalendereinstellungen",
      "select_calendar_type" : "Kalendertyp auswählen",
      "add_new_calendar_group" : "Neue Kalendergruppe hinzufügen",
      "add_new_equipment" : "Neue Geräte hinzufügen",
      "new_calendar" : "Neuer Kalender",
      "calendar_group_description" : "Verwende Kalendergruppen, um mehrere Kalender effektiv zu organisieren und zu gruppieren.",
      "group" : "Gruppe",
      "manage_your_calendars_and_groups" : "Verwalte deine Kalender und Gruppen",
      "groups" : "Gruppen",
      "create_group" : "Gruppe erstellen",
      "create_calendar" : "Kalender erstellen",
      "calendar_name" : "Kalendername",
      "group_duration" : "Gruppendauer",
      "calendar_type" : "Kalendertyp",
      "action" : "Aktion",
      "round_robin" : "Round Robin",
      "event" : "Veranstaltung",
      "group_name" : "Gruppenname",
      "room_name" : "Raumname",
      "group_description" : "Gruppenbeschreibung",
      "calendar_url" : "Kalender-URL",
      "calendars" : "Kalender",
      "date_updated" : "Aktualisierungsdatum",
      "status" : "Status",
      "action_dropdown" : "Dropdown „Aktion“",
      "calendar_list" : "Services im Zusammenhang mit",
      "edit" : "Bearbeiten",
      "duplicate" : "Duplikat",
      "copy_embed_code" : "Einbettungscode kopieren",
      "copy_permanent_link" : "Permanentlink kopieren",
      "copy_new_slug_link" : "Planungslink kopieren",
      "copy_scheduling_link" : "Planungslink kopieren (veraltete Version)",
      "deactivate_calendar" : "Kalender deaktivieren",
      "move_to_group" : "In Gruppe verschieben",
      "delete_calendar" : "Kalender löschen",
      "delete_group" : "Löschen",
      "delete_this_group" : "Diese Gruppe löschen",
      "activate_calendar" : "Kalender aktivieren",
      "duration" : "Dauer (Min.)",
      "all" : "Alle",
      "draft" : "Entwurf",
      "active" : "Aktiv",
      "rearrange_calendars" : "Kalender neu anordnen",
      "deactivate_all_calendars_in_group" : "Alle Kalender in der Gruppe deaktivieren",
      "activate_all_calendars_in_group" : "Alle Kalender in der Gruppe aktivieren",
      "no_groups_found" : "Deine Suche stimmt mit keiner Kalendergruppe überein.",
      "no_groups_in_location" : "Beginne mit der Verwendung von Gruppen, indem du eine neue Kalendergruppe erstellst.",
      "no_calendars_found" : "Beginne mit der Verwendung des Kalenders, indem du einen neuen Kalender erstellst.",
      "no_calendars_found_search" : "Deine Suche hat mit keinem Kalender übereingestimmt.",
      "delete_calendar_message" : "Du bist dabei, den Kalender zu löschen. Wenn du auf Löschen klickst, werden auch alle Termine gelöscht.",
      "delete_calendar_message_title" : "Diesen Kalender löschen?",
      "duplicate_calendar_message" : "Du bist dabei, den Kalender zu duplizieren. Dadurch wird ein neuer Kalender mit den gleichen Einstellungen wie der ausgewählte Kalender erstellt.",
      "duplicate_calendar_message_title" : "Diesen Kalender duplizieren?",
      "no_calendars_found_title" : "Keine Kalender gefunden",
      "no_groups_in_location_title" : "Keine Gruppen gefunden",
      "delete_group_confirmation_title" : "Diese Gruppe löschen?",
      "delete_group_confirmation" : "Möchtest du die ausgewählte Gruppe wirklich löschen?",
      "delete_group_confirmation_checkbox_title" : "Verknüpfte Kalender und Termine löschen",
      "delete_group_confirmation_checkbox_description" : "Wenn du diese Option wählst, werden auch die Kalender in dieser Gruppe sowie alle Termine in diesen Kalendern gelöscht.",
      "group_status_message_title" : "Diese Gruppe {status}?",
      "calendar_status_message_title" : "Diesen Kalender {status}?",
      "calendar_status_message" : "Du bist dabei, den Kalender zu {status}",
      "group_status_message" : "Du bist dabei, die Gruppe zu {status}",
      "coming_soon" : "Demnächst verfügbar",
      "select" : "Auswählen",
      "15mins" : "15 Min.",
      "cancel" : "Absagen",
      "save" : "Speichern",
      "create" : "Erstellen",
      "select_calendar_group" : "Kalendergruppe auswählen",
      "move_calendar_confirmation" : "Bitte beachte, dass der alte Link nicht mehr funktioniert, sobald du mit der Änderung fortfährst. Bitte bestätige durch Ankreuzen des Kästchens.",
      "previously_called_unassigned" : " (Zuvor als nicht zugewiesen bezeichnet)",
      "scheduling_type" : "Planungstyp",
      "scheduling_type_description" : "Wähle einen Planungstyp für deinen neuen Kalender",
      "service_menu_name" : "Name des Servicemenüs",
      "template" : "Vorlage",
      "group_url" : "Gruppen-URL",
      "template_description" : "Du hast jetzt die Wahl zwischen den Vorlagen Klassisch oder Neo für die Gruppenansicht",
      "rooms" : "Räume",
      "rooms_name" : "Name",
      "rooms_description" : "Beschreibung",
      "rooms_modal_description" : "Raum hinzufügen oder bearbeiten",
      "rooms_capacity" : "Gesamtkapazität",
      "no_rooms_in_location_title" : "Keine Räume gefunden",
      "no_rooms_in_location" : "An diesem Ort wurden keine Räume gefunden",
      "no_equipments_in_location" : "An diesem Standort wurden keine Geräte gefunden",
      "equipments" : "Geräte",
      "equipment_name" : "Name",
      "equipment_name_search" : "Gerätename",
      "equipment_description" : "Beschreibung",
      "equipment_modal_description" : "Ein Gerät hinzufügen oder bearbeiten",
      "add_equipment" : "Gerät hinzufügen/bearbeiten",
      "add_room" : "Raum hinzufügen/bearbeiten",
      "equipment_quantity" : "Gesamtmenge",
      "equipment_outOfService" : "Menge außer Betrieb",
      "no_equipments_in_location_title" : "Keine Geräte gefunden",
      "delete_this_equipment" : "Dieses Gerät löschen",
      "delete_this_rooms" : "Diesen Raum löschen",
      "delete_equipment_confirmation" : "Bist du sicher, dass du dieses Gerät löschen willst? Dies wird auch aus allen verknüpften Kalendern entfernt.",
      "delete_rooms_confirmation" : "Bist du sicher, dass du diesen Raum löschen willst? Dieser wird auch aus allen verknüpften Kalendern entfernt.",
      "add_new_calendar_equipments" : "Neues Gerät hinzufügen",
      "no_equipments_found" : "Deine Suche hat mit keinem Gerät übereingestimmt.",
      "no_rooms_found" : "Deine Suche hat mit keinem Raum übereingestimmt.",
      "add_new_calendar_rooms" : "Neuen Raum hinzufügen",
      "rooms_disable" : "Raum deaktivieren",
      "equipments_disable" : "Gerät deaktivieren",
      "equipments_activate" : "Gerät aktivieren",
      "rooms_activate" : "Raum aktivieren",
      "create_equipments" : "Gerät erstellen",
      "create_rooms" : "Raum erstellen",
      "select_calendar_rooms_title" : "Kalender auswählen",
      "select_calendar_rooms_description" : "Du kannst mehrere Kalender auswählen, um sie mit Räumen zu verknüpfen",
      "select_calendar_equipments_title" : "Kalender auswählen",
      "select_calendar_equipments_description" : "Du kannst einen Kalender auswählen, der dem Gerät zugeordnet werden soll",
      "disable_equipment_title" : "Gerät {status}",
      "disable_room_title" : "Raum {status}",
      "equipments_status_message" : "Möchtest du dieses Gerät wirklich {status}?",
      "rooms_status_message" : "Möchtest du diesen Raum wirklich {status}?",
      "calendar_resource_disclaimer" : "Dies könnte die Verfügbarkeit in den zugehörigen Servicekalendern beeinflussen.",
      "equipment_disclaimer" : "Hinweis: Wenn du das Gerät später aktivierst, wird es automatisch wieder mit den Kalendern verbunden, mit denen es verknüpft ist",
      "room_disclaimer" : "Hinweis: Wenn du den Raum später aktivierst, wird er automatisch wieder mit den Kalendern verbunden, mit denen er verknüpft ist",
      "equipment_tab_name" : "Geräte",
      "rooms_tab_name" : "Räume",
      "manage_your_rooms" : "Verwalte hier Räume für dein Unternehmen",
      "manage_your_equipments" : "Verwalte hier Geräte für dein Unternehmen",
      "select_service_calendar_description_equipments" : "Du kannst mehrere Servicekalender auswählen, die mit Geräten verknüpft werden sollen",
      "select_service_calendar_description_rooms" : "Du kannst mehrere Servicekalender auswählen, die mit Räumen verknüpft werden sollen",
      "service_menus" : "Servicemenü",
      "manage_your_calendars_groups_and_service_menus" : "Verwalte deine Kalender, Gruppen und dein Servicemenü",
      "new_service_menu" : "Servicemenü erstellen",
      "deactivate_service_menu" : "Servicemenü deaktivieren",
      "delete_service_menu" : "Dieses Servicemenü löschen",
      "delete_service_menu_confirmation" : "Möchtest du dieses Servicemenü wirklich löschen?",
      "service_menu_status_message_title" : "Dieses Servicemenü {status}?",
      "service_menu_status_message" : "Du bist dabei, das Servicemenü zu {status}",
      "no_service_menus_in_location_title" : "Erstelle dein individuelles Servicemenü",
      "add_new_service_menu" : "Neues Servicemenü hinzufügen",
      "no_service_menus_in_location" : "Erstelle zunächst ein neues Servicemenü und füge Gruppen hinzu",
      "no_service_menu_found" : "Kein Servicemenü gefunden",
      "no_service_menu_found_desc" : "Deine Suche hat mit keinem Servicemenü übereingestimmt.",
      "apply" : "Anwenden",
      "troubleshoot_calendar" : "Problembehandlung bei Kalendern",
      "round_robin_booking" : "Round Robin",
      "round_robin_description" : "Verteilt Termine in rotierender Reihenfolge auf die Teammitglieder.",
      "round_robin_example" : "Z. B.: Verkaufsgespräche, Onboarding-Sitzungen.",
      "unassigned_booking" : "Veranstaltungskalender",
      "unassigned_description" : "Für die Planung von physischen Veranstaltungen ohne Zuordnung zu einem Gastgeber.",
      "unassigned_example" : "Z. B.: Konferenzen, öffentliche Seminare, Messen.",
      "class_booking" : "Kursbuchung",
      "class_booking_description" : "Ein Gastgeber trifft sich mit mehreren Teilnehmern.",
      "class_booking_example" : "Z. B.: Webinare, Gruppenschulungen, Online-Kurse.",
      "collective_booking" : "Sammelbuchung",
      "collective_booking_description" : "Mehrere Gastgeber treffen sich mit einem Teilnehmer.",
      "collective_booking_example" : "Z. B. Panelinterviews, Ausschussbewertungen.",
      "service_booking" : "Servicebuchung",
      "service_booking_description" : "Nahtlose Terminplanung für dienstleistungsorientierte Unternehmen.",
      "service_booking_example" : "Z. B.: Spa-Termine, Reparaturdienste, Beratungen.",
      "personal_booking" : "Persönliche Buchung",
      "personal_booking_description" : "Plant Einzelgespräche mit einem bestimmten Teammitglied.",
      "personal_booking_example" : "Z. B.: Kundentreffen, private Beratungen.",
      "personal" : "Persönlich",
      "explore_more_types" : "Mehr Arten erkunden"
    },
    "preferences" : {
      "preferences" : "Einstellungen",
      "my_preferences" : "Meine Einstellung",
      "account_preferences" : "Kontoeinstellungen",
      "user_preferences" : "Benutzereinstellungen",
      "set_preferences" : "Lege deine Einstellungen für dein Konto fest.",
      "scheduling_options" : "Optionen für die Terminplanung",
      "meeting_location" : "Besprechungsort",
      "zoom" : "Zoom",
      "save_preferences" : "Einstellungen speichern",
      "in_app_preferences" : "In App-Einstellungen",
      "set_start_day" : "Lege Einstellungen wie den Starttag für die App fest",
      "view_options" : "Optionen anzeigen",
      "week_starts_on" : "Wochenbeginn",
      "sunday" : "Sonntag",
      "widget_preferences" : "Widget-Einstellungen",
      "set_widget_language" : "Einstellungen wie Sprache für Widget festlegen",
      "language_and_region" : "Sprache und Region",
      "language" : "Sprache",
      "english" : "Englisch",
      "time_format" : "Zeitformat",
      "time_format_value" : "13:30 UHR",
      "monday" : "Montag",
      "custom_meeting_location" : "Benutzerdefinierter Besprechungsort"
    },
    "availability" : {
      "availability" : "Verfügbarkeit",
      "my_availability" : "Meine Verfügbarkeit",
      "account_availability" : "Kontoverfügbarkeit",
      "timezone" : "Zeitzone",
      "working_hours" : "Arbeitszeiten",
      "set_recurring_hours" : "Lege deine wiederkehrenden Arbeitszeiten für Besprechungen fest",
      "sunday" : "Sonntag",
      "monday" : "Montag",
      "tuesday" : "Dienstag",
      "wednesday" : "Mittwoch",
      "thursday" : "Donnerstag",
      "friday" : "Freitag",
      "saturday" : "Samstag",
      "widget_preferences" : "Widget-Einstellungen",
      "set_widget_language" : "Einstellungen wie Sprache für Widget festlegen",
      "language_and_region" : "Sprache und Region",
      "language" : "Sprache",
      "english" : "Englisch",
      "copy_to_all" : "Kopie an alle",
      "save_availability" : "Verfügbarkeit speichern",
      "add_time" : "Zeit hinzufügen",
      "select_time" : "Zeit auswählen"
    },
    "connections" : {
      "connections" : "Verbindungen",
      "connect" : "verbinden",
      "conferencing" : "Konferenzen",
      "my_connections" : "Meine Verbindungen",
      "account_connections" : "Kontoverbindungen",
      "connection_preferences" : "Verbindungseinstellungen",
      "manage_calendars_conferencing_channels" : "Kalender und Konferenzkanäle verwalten",
      "main_integration_calendar" : "Hauptintegrationkalender",
      "event_integration_calendar" : "Ereignisse, die in einem Systemkalender erstellt werden, an dem du beteiligt bist, werden auch in diesem integrierten Kalender erstellt",
      "2way_sync" : "2-Wege-Synchronisierung: Systemereignisse aus Ereignissen erstellen, die im integrierten Hauptkalender gebucht wurden",
      "calendars" : "Kalender",
      "google" : "Google",
      "outlook" : "Outlook",
      "check_for_conflicts" : "Auf Konflikte prüfen",
      "choose_accounts_to_connect" : "Auswählen, welche Konten du verbinden möchtest",
      "payments" : "Zahlungen",
      "stripe" : "Stripe",
      "ads" : "Anzeigen",
      "local_service_ads" : "Lokale Serviceanzeigen",
      "fire_workflow" : "Workflow-Trigger für über 2-Wege-Synchronisierung gebuchte Ereignisse auslösen",
      "connect_google_outlook" : "Google/Outlook/iCloud-Konto verbinden, um den Hauptkalender auswählen zu können.",
      "execute_automation" : "Automatisierung ausführen",
      "save" : "Speichern",
      "lsa" : "Lokale Serviceanzeigen",
      "no_write_access" : "Du hast keinen Schreibzugriff auf diesen Kalender",
      "connection_remove_confirmation" : "Du bist dabei, diese Integration zu entfernen",
      "active" : "Aktiv",
      "icloud" : {
        "title" : "iCloud-Integration",
        "description" : "Zum Verbinden deines Kontos sind eine Zwei-Faktor-Authentifizierung und ein app-spezifisches Passwort erforderlich.",
        "email" : "iCloud E-Mail-Adresse",
        "password" : "iCloud Kennwort",
        "connect" : "Verbinden",
        "cancel" : "Absagen"
      }
    }
  },
  "appointment_modal" : {
    "select_a_contact" : "Kontakt auswählen",
    "start_time" : "Startzeit",
    "unassigned" : "Nicht zugewiesen",
    "end_time" : "Endzeit",
    "search_by_name_email_phone_or_company" : "Suche nach Namen, E-Mail, Telefon oder Firma",
    "add_new" : "Neu hinzufügen",
    "pick_from_available_contacts" : "Oder aus den verfügbaren Kontakten wählen",
    "book_appointment" : "Termin buchen",
    "add_blocked_off_time" : "Blockierte Zeit hinzufügen",
    "recommended_timezones" : "Empfohlene Zeitzonen",
    "all_timezones" : "Alle Zeitzonen",
    "calendar" : "Kalender",
    "repeat" : "Wiederholen",
    "date_and_time" : "Datum und Uhrzeit",
    "close" : "Schließen",
    "team_member" : "Teammitglied",
    "recurring_booking" : "Wiederkehrende Buchung",
    "one_slot_booking" : "Buchung für ein Zeitfenster",
    "event_type" : "Veranstaltungstyp",
    "occurrences_booked" : "Veranstaltungen werden gebucht",
    "repeats" : "Wiederholungen",
    "booking_details" : "Buchungsdetails",
    "showing_slots_in_this_timezone" : "Zeitfenster in dieser Zeitzone werden angezeigt:",
    "update_contact_tz" : "Auch die Zeitzone des Kontakts aktualisieren auf",
    "date" : "Datum",
    "slot" : "Zeitfenster",
    "calendar_default" : "Kalenderstandard",
    "custom" : "Benutzerdefiniert",
    "meeting_notes" : "(z. B.) Sitzungsnotizen für unseren bevorstehenden Termin",
    "out_of_office" : "(z. B.) Für eine Woche nicht im Büro!",
    "standard" : "Standard",
    "appointment_title" : "Titel des Termins",
    "event_title" : "Titel der Veranstaltung",
    "appt_with_bob" : "(z. B.) Termin mit Bernd",
    "vacation" : "(z. B.) Urlaub",
    "show_notes" : "Notizen anzeigen",
    "hide_notes" : "Notizen ausblenden",
    "additional_preferences" : "Zusätzliche Präferenzen",
    "meeting_location" : "Treffpunkt",
    "appointment_status" : "Terminstatus",
    "appt_description" : "Beschreibung des Termins",
    "recurring_details" : "Wiederkehrende Details",
    "event_description" : "Beschreibung der Veranstaltung",
    "in_contact_timezone" : "In der Zeitzone des Kontakts",
    "vacation_message" : "Urlaub geplant? Auszeit geplant? Blockiere die Zeit in deinem Kalender, um Kunden daran zu hindern, Termine zu buchen (bestehende Termine bleiben weiterhin in deinem Kalender gespeichert).",
    "contact" : "Kontakt",
    "delete" : "Löschen",
    "cancel" : "Absagen",
    "back" : "Zurück",
    "pick_available_contacts" : "Oder wähle aus den verfügbaren Kontakten",
    "no_contacts_found" : "Keine Kontakte gefunden",
    "found" : "gefunden",
    "select_one" : "Einen auswählen",
    "tz_tooltip" : "Dies ist nur von Bedeutung, wenn du benutzerdefinierte Werte, wie z. B. termin_start_zeit termin_end_zeit termin_zeitzone usw., in deiner ausgehenden Kommunikation mit deinen Kunden verwendest. Dabei werden die vorgenannten Angaben einfach in die jeweilige Ortszeit umgerechnet.",
    "meeting_location_tooltip" : "Der Besprechungsort wird wie im Kalender konfiguriert festgelegt.",
    "appt_meeting_location_tooltip" : "Der Benutzer kann den Besprechungsort terminspezifisch festlegen.",
    "gmeet_tooltip" : "Bitte stelle sicher, dass das jeweilige Teammitglied einen Google-Kalender als primären Kalender festgelegt hat",
    "zoom_tooltip" : "Bitte stelle sicher, dass das jeweilige Teammitglied Zoom integriert hat",
    "enter_meeting_location" : "Besprechungs-Ort eingeben",
    "save_appointment" : "Termin speichern",
    "save_event" : "Veranstaltung speichern",
    "search_contacts" : "Suche nach Namen, E-Mail, Telefon oder Firma",
    "recurring_slots" : "{recurringSlotsLength}/{count}",
    "custom_timezone" : "Benutzerdefinierte Zeitzone",
    "system_timezone" : "Zeitzone des Systems",
    "contact_timezone" : "Zeitzone des Kontakts",
    "account_timezone" : "Zeitzone des Kontos",
    "an_error_occurred" : "Es ist ein Fehler aufgetreten"
  },
  "popup" : {
    "calendar" : "Kalender",
    "appointment_owner" : "Termin-Owner",
    "source" : "Quelle",
    "booked_by" : "Gebucht von",
    "view_activity_log" : "Aktivitätsprotokoll anzeigen",
    "status" : "Status",
    "cancel_appointment" : "Termin absagen",
    "cancel_all" : "Alle absagen",
    "google_event" : "Google-Ereignis",
    "outlook_event" : "Outlook-Ereignis",
    "blocked_slot" : "Blockiertes Zeitfenster",
    "appointment" : "Termin",
    "confirmed" : "Bestätigt",
    "unconfirmed" : "Unbestätigt",
    "cancelled" : "Abgesagt",
    "showed" : "Erschienen",
    "no_show" : "Nicht erschienen",
    "invalid" : "Ungültig",
    "more" : "Mehr",
    "less" : "Weniger",
    "edit" : "Bearbeiten",
    "reschedule_all" : "Alle verschieben",
    "guests" : "Gast/Gäste",
    "guest_count" : "Anzahl Gast/Gäste"
  },
  "create_calendar" : {
    "calendar_name" : "Kalendername",
    "calendar_logo" : "Kalenderlogo",
    "select_team_member" : "Teammitglieder auswählen",
    "eg_outbound_reach" : "(z. B.) Ausgehende Reichweite",
    "description" : "Beschreibung",
    "amount" : "Betrag",
    "description_placeholder" : "Beschreibung eingeben",
    "meeting_duration" : "Dauer der Besprechung",
    "seats_per_class" : "Plätze pro Kurs",
    "group" : "Gruppe",
    "custom_url" : "Benutzerdefinierte URL",
    "meeting_invite_title" : "Titel der Besprechungseinladung",
    "event_color" : "Ereignisfarbe",
    "availability_type" : "Verfügbarkeitstyp",
    "new_calendar" : "Neuer Kalender",
    "add_description" : "Beschreibung hinzufügen",
    "remove_description" : "Beschreibung entfernen",
    "booking_availability" : "Buchungsverfügbarkeit",
    "slug_tooltip" : "Passe den direkten Link zu diesem Kalender an",
    "meeting_location_tooltip" : "Besprechungs-Links werden in Kalenderereignissen als „Besprechungs-Ort“ angezeigt und ermöglichen es dir, Dinge wie Zoom-Links für das Ereignis hinzuzufügen.",
    "advanced_settings" : "Erweiterte Einstellungen",
    "reschedule_preference" : "Bevorzugte Terminverschiebung",
    "reschedule_preference_subtext" : "Wenn ein Kontakt einen Termin verschiebt:",
    "reassign_through_round_robin" : "Neuzuweisung durch Round Robin",
    "keep_same_assinged_user" : "Den gleichen Termin-Owner beibehalten",
    "new_appointment_preference" : "Neue Terminpräferenz",
    "new_appointment_preference_text" : "Immer mit dem zugewiesenen Benutzer des Kontakts buchen",
    "new_appointment_preference_subtext" : "Neue Termine mit dem zugewiesenen Benutzer des Kontakts buchen, anstatt die Round-Robin-Planung zu verwenden.",
    "cancel" : "Absagen",
    "confirm" : "Bestätigen",
    "service_calendar_name" : "Servicename",
    "service_logo" : "Servicelogo",
    "service_select_team_member" : "Mitarbeiter auswählen",
    "service_duration" : "Servicedauer",
    "appointment_invite_title" : "Titel der Termineinladung",
    "payment_placeholder" : "Gib eine Beschreibung ein, die dem Benutzer angezeigt wird, um ihn über die Zahlung zu informieren",
    "deposit_amount" : "Einzahlungsbetrag",
    "collect_deposit_amount" : "Teilzahlung akzeptieren",
    "percentage" : "Prozentsatz",
    "total_amount" : "Gesamtbetrag"
  },
  "calendar_advanced" : {
    "general" : {
      "quick_tip" : "Schneller Tipp",
      "timezone_change_tip" : "Zeitzonen können jederzeit unter Zeitzoneneinstellungen geändert werden",
      "save" : "Speichern",
      "close" : "Schließen",
      "meeting_details" : "Besprechungsdetails",
      "availability" : "Verfügbarkeit",
      "forms_and_payment" : "Formulare und Zahlung",
      "notifications_and_additional_options" : "Benachrichtigungen und zusätzliche Optionen",
      "connections" : "Verbindungen",
      "customizations" : "Anpassungen",
      "create" : "Erstellen",
      "edit" : "Bearbeiten",
      "equipments" : "Räume und Geräte",
      "service_details" : "Servicedetails"
    },
    "meeting_details" : {
      "meeting_location" : "Besprechungsort",
      "enter_meeting_location" : "Besprechungsort eingeben",
      "click_to_upload" : "Zum Hochladen klicken",
      "square" : "Quadrat",
      "circle" : "Kreis",
      "remove_logo" : "Logo entfernen",
      "meeting_invite_title_tooltip" : "Dies ist der Titel für das Kalenderereignis, das im Google Kalender, Outlook, Apple Kalender usw. angezeigt wird.",
      "event_color_tooltip" : "Wähle eine Farbe für dieses Buchungsereignis. Farben werden mit Terminen in Google Kalender synchronisiert.",
      "enter_a_name" : "Bitte gib einen Namen ein",
      "enter_an_event_title" : "Bitte gib einen Veranstaltungstitel ein",
      "high_priority" : "Hohe Priorität",
      "medium_priority" : "Mittlere Priorität",
      "low_priority" : "Niedrige Priorität",
      "custom" : "Benutzerdefiniert",
      "zoom_not_added" : "Der Benutzer hat sein Zoom-Konto noch nicht verbunden",
      "opt_avail" : "Für Verfügbarkeit optimieren",
      "opt_equal" : "Für eine gleichmäßige Verteilung optimieren",
      "team_members" : "Teammitglieder auswählen",
      "team_member" : "Teammitglied auswählen",
      "meeting_location_required" : "Besprechungsort ist erforderlich",
      "meeting_details" : "Besprechungsdetails",
      "meeting_details_description" : "Dies sind die Details, die auf der Kalenderlistenseite angezeigt werden.",
      "or_drag_and_drop" : "oder ziehen und ablegen",
      "service_details" : "Servicedetails",
      "team_member_disabled_recurring_tooltip" : "Bei wiederkehrenden Besprechungen ist nur ein Teammitglied zugelassen. Um mehrere Teammitglieder hinzuzufügen, deaktiviere wiederkehrende Meetings.",
      "team_member_disabled_class_booking_tooltip" : "Es kann nur ein Teammitglied zu einem Kursbuchungskalender hinzugefügt werden",
      "advanced_settings" : "Erweiterte Einstellungen",
      "enable_contact_assigned_to_setting" : "Um diese Einstellung zu aktivieren, muss das Formular an erster Stelle in der Widget-Reihenfolge stehen. Bitte ordne die Widget-Reihenfolge auf der Registerkarte „Formulare und Zahlung“ neu an.",
      "reschedule_preference_setting_tooltip" : "Wähle, wem der Termin zugewiesen werden soll, wenn ein Kontakt über das Buchungs-Widget einen neuen Termin bucht.",
      "appointment_preference_tooltip" : "Wähle aus, ob neue Termine immer mit dem dem Kontakt zugewiesenen Benutzer gebucht werden sollen. Wenn einem Kontakt ein Benutzer zugewiesen ist, wird diesem Benutzer der Vorzug gegeben. Andernfalls erfolgt eine Round-Robin-Zuweisung. Tipp: Deaktiviere „Mitarbeiterauswahl zulassen“, um zu verhindern, dass Buchende den Mitarbeiter im Buchungs-Widget ändern.",
      "add_location" : "Ort hinzufügen",
      "multiple_locations_neo" : "Mehrere Besprechungsorte werden nur vom Neo-Widget mit dem Standardformular unterstützt.",
      "make_owner" : "Zum Owner machen",
      "only_one_team_member" : "Beim Hinzufügen mehrerer Orte ist nur ein Teammitglied erlaubt.",
      "ask_the_booker_error_message" : "Ort „Den Buchenden fragen“ existiert bereits",
      "enter_location" : "Ortwert eingeben",
      "enter_display_label" : "Anzeigebeschriftung eingeben",
      "add_display_label" : "Anzeigebeschriftung hinzufügen",
      "multiple_location_support_message" : "Mehrere Besprechungsorte werden nur vom Neo-Widget mit dem Standardformular unterstützt.",
      "only_one_team_member_personal_calendar" : "Bei der persönlichen Buchung ist nur ein Teammitglied erlaubt. ",
      "ms_teams_not_added" : "Der Benutzer hat sein Microsoft Teams-Konto noch nicht verbunden"
    },
    "availability" : {
      "my_availability" : "Meine Verfügbarkeit",
      "set_availability" : "Stelle hier deine Verfügbarkeit für den Kalender ein.",
      "standard" : "Standard",
      "custom" : "Benutzerdefiniert",
      "to" : "An",
      "copy_to_all" : "Kopie an alle",
      "add_time" : "Zeit hinzufügen",
      "recurring_meeting" : "Wiederkehrendes Treffen",
      "recurring_info" : "Nur ein Benutzer pro Kalender für wiederkehrende Buchungen",
      "repeat" : "Wiederholen",
      "times_to_repeat" : "Zeiten zum Wiederholen",
      "slots_unavailable" : "Wenn Zeitfenster nicht verfügbar sind?",
      "overlap_status" : "Terminstatus bei Terminüberschneidungen ",
      "meeting_interval" : "Besprechungsintervall",
      "meeting_duration" : "Dauer der Besprechung",
      "minimum_scheduling" : "Mindestterminvorgabe",
      "date_range" : "Datumsbereich",
      "maximum_bookings_per_day" : "Maximale Buchungen pro Tag",
      "maximum_bookings_per_slot" : "Maximale Buchungen pro Zeitfenster",
      "maximum_bookings_per_slot_per_user" : "Maximale Buchungen pro Zeitfenster (pro Benutzer)",
      "seats_per_class" : "Plätze pro Kurs",
      "pre_buffer_time" : "Vorlaufzeit",
      "post_buffer_time" : "Nachlaufzeit",
      "pre_buffer_tooltip" : "Vorlaufzeit ist zusätzliche Zeit, die vor einem Termin hinzugefügt werden kann, um zusätzliche Zeit zum Vorbereiten zu haben",
      "post_buffer_tooltip" : "Nachlaufzeit ist zusätzliche Zeit, die nach einem Termin hinzugefügt werden kann, um zusätzliche Zeit zum Nachbereiten zu haben",
      "buffer_time" : "Pufferzeit",
      "daily" : "Täglich",
      "weekly" : "Wöchentlich",
      "monthly" : "Monatlich",
      "skip_booking" : "Buchung von nicht verfügbaren Zeitfenstern überspringen",
      "continue_booking" : "Buchung fortsetzen",
      "book_next_available" : "Nächstes verfügbares Zeitfenster buchen",
      "confirmed" : "Bestätigt",
      "unconfirmed" : "Unbestätigt",
      "enter_valid_hours" : "Bitte gib gültige Öffnungszeiten ein",
      "enter_valid_avail" : "Bitte gib eine gültige kundenspezifische Verfügbarkeit ein",
      "availability_type_tooltip" : "Wähle „Standardverfügbarkeit“ für wöchentlich angebotene reguläre Buchungen. Wähle „Benutzerdefiniert“ für Buchungen, die nur an bestimmten Terminen verfügbar sind.",
      "meeting_interval_tooltip" : "Das Besprechungsintervall gibt die Zeitspanne zwischen den Buchungszeiträumen an, die im Kalender angezeigt werden. Bei einer 30-minütigen Veranstaltung, die zu jeder vollen Stunde verfügbar sein soll, beträgt das Intervall 1 Stunde. Bei einer 30-minütigen Veranstaltung, die alle 30 Minuten verfügbar sein soll, beträgt das Intervall 30 Minuten.",
      "date_range_tooltip" : "Veranstaltungen können in den nächsten n Tagen geplant werden.",
      "maximum_bookings_per_day_tooltip" : " Maximale Anzahl der zulässigen Termine pro Tag in diesem Kalender.",
      "maximum_bookings_per_slot_tooltip" : "Maximale Anzahl an Terminen, die pro Zeitfenster für den Benutzer zulässig sind. (Wenn die Anzahl der Termine für das angegebene Zeitfenster des Benutzers überschritten wird, wird entweder zum nächsten Benutzer in der Round-Robin-Warteschlange gewechselt oder das Zeitfenster wird als für die Buchung durch den Interessenten nicht verfügbar markiert.)",
      "add_days" : "Tage hinzufügen",
      "month_on_day" : "Monatlich am Tag",
      "month_on_last_day" : "Monatlich am letzten Tag",
      "day" : "Tag",
      "week" : "Woche",
      "month" : "Monat",
      "mins" : "Minuten",
      "hours" : "Stunden",
      "days" : "Tage",
      "weeks" : "Wochen",
      "months" : "Monate",
      "meeting_interval_error" : "Das Besprechungs-Intervall muss zwischen 5 Minuten und 12 Stunden liegen",
      "meeting_duration_error" : "Die Dauer des Meetings muss zwischen 1 Minute und 12 Stunden liegen",
      "buffer_time_error" : "Die Pufferzeit muss weniger als 12 Stunden betragen",
      "appointment_interval" : "Terminintervall",
      "appointment_duration" : "Dauer des Termins",
      "maximum_appointments_per_day" : "Maximale Anzahl an Terminen pro Tag",
      "subTitle" : "Wähle das Datum, um bestimmte Stunden festzulegen",
      "heading_1" : "Wöchentlich verfügbare Stunden",
      "tooltip_1" : "Lege deinen wöchentlichen Verfügbarkeitsplan fest. Dies sind deine Standardstunden, die wöchentlich einheitlich angewendet werden",
      "heading_2" : "Wann bist du verfügbar?",
      "unavailable_label" : "Nicht verfügbar",
      "tooltip_2" : "Passe deinen Zeitplan für bestimmte Termine an. Überschreibe deine regulären Wochenstunden, indem du die Verfügbarkeit oder Nichtverfügbarkeit für ausgewählte Daten markierst.",
      "date_specific_level" : "Datumsspezifische Stunden hinzufügen",
      "specific_hours_button" : "Datumsspezifische Uhrzeiten",
      "overlap_error" : "Zeitfenster dürfen sich nicht überschneiden",
      "apply" : "Anwenden",
      "copy_times_to" : "Zeiten kopieren nach",
      "no_date_specific_heading" : "Keine datumsspezifische Uhrzeit hinzugefügt",
      "no_date_specific_description" : "Du kannst bestimmte Daten und Uhrzeiten zu deiner Verfügbarkeit hinzufügen/entfernen",
      "recurring_disabled_conditions_tooltip" : "Wiederkehrende Besprechungen sind deaktiviert. Um sie zu aktivieren, stelle sicher, dass der Kalender nur ein Teammitglied enthält und keine datumsspezifischen Stunden hinzugefügt wurden.",
      "date_specific_hours_disabled_conditions_tooltip" : "Wenn du eine datumsspezifische Uhrzeit angeben möchtest, stelle sicher, dass wiederkehrende Besprechungen deaktiviert sind.",
      "recurring_disable_event_calendar_conditions" : "Wiederkehrende Besprechungen sind deaktiviert. Um diese zu aktivieren, achte darauf, dass keine datumsspezifischen Stunden eingetragen sind.",
      "service_duration" : "Servicedauer",
      "service_interval" : "Serviceintervall",
      "service_interval_tooltip" : "Das Besprechungsintervall legt fest, wie oft Buchungszeiträume im Kalender erscheinen. Bei einer 30-minütigen Veranstaltung bedeutet ein 1-Stunden-Intervall beispielsweise, dass stündlich Zeitfenster angezeigt werden. Ein 30-Minuten-Intervall bedeutet, dass alle 30 Minuten Zeitfenster erscheinen.",
      "service_duration_tooltip" : "Dies legt die Dauer deines Termins fest und bestimmt, wie lange er dauern wird.",
      "minimum_scheduling_tooltip" : "Hiermit legst du die Mindestzeit fest, die zum Buchen eines Termins über das Kalender-Widget erforderlich ist. Wenn beispielsweise ein Termin auf 4 Stunden festgelegt ist, können Termine nicht innerhalb von 4 Stunden vor ihrer Startzeit gebucht werden; Buchungen müssen mindestens 4 Stunden im Voraus erfolgen.",
      "look_busy_title" : "Als nicht verfügbar anzeigen",
      "look_busy_helper_text" : "Blende einen Prozentsatz deiner verfügbaren Termin-Zeitfenster im Buchungs-Widget aus, um geschäftiger und gefragter zu erscheinen.",
      "look_busy_percentage_description" : "Die Anzahl der verfügbaren Zeitfenster um x % ausblenden",
      "maximum_bookings_per_slot_tooltip_personal" : "Maximale Anzahl der zulässigen Termine pro Slot für den Nutzer"
    },
    "forms_payment" : {
      "forms" : "Formulare",
      "forms_sub" : "Lege die Formulareinstellungen für die Nachbereitung fest.",
      "select_form" : "Formular auswählen",
      "enable_same_user_assignment" : "Weise bei jeder Terminbuchung Kontakte ihren jeweiligen Kalenderteammitgliedern zu",
      "sticky_contacts" : "Felder vorab ausfüllen (Sticky Contacts)",
      "confirmation_page" : "Bestätigungsseite",
      "default" : "Standard",
      "redirect_url" : "Weiterleitungs-URL",
      "thank_you" : "Dankesnachricht",
      "enter_url" : "URL eingeben",
      "pixel_id" : "Facebook-Pixel-ID (optional)",
      "auto_confirm" : "Neue Kalenderbesprechungen automatisch bestätigen",
      "configure_payment" : "Zahlungsoptionen konfigurieren",
      "accept_payments" : "Zahlungen akzeptieren",
      "test" : "Test",
      "live" : "Live",
      "enable_guests" : "Gäste hinzufügen",
      "add_guest_tooltip" : "„Gast hinzufügen“ ermöglicht es deinem Teilnehmer, mehrere Teilnehmer zum selben Termin hinzuzufügen",
      "add_guest_custom_form_tooltip" : "Bei Aktivierung dieser Option wird eine vom System generierte E-Mail mit einem Link zum benutzerdefinierten Formular an die Gäste gesendet, sobald ihr Termin bestätigt wurde. Um doppelte E-Mail-Versand zu vermeiden, stell bitte sicher, dass nicht bereits eine separate Bestätigungs-E-Mail für Gäste eingerichtet ist. (Überprüfen kannst du dies auf der Registerkarte „Benachrichtigungen“ > „Bestätigungsbenachrichtigung“.)",
      "add_guest_same_form_check" : "Sende dasselbe Formular zur Informationserfassung per E-Mail an alle Gäste",
      "payment_tooltip" : "Die Zahlung wird nur für den Hauptteilnehmer und nicht für die Gäste berücksichtigt.",
      "default_form" : "Standard (Vorname, Nachname, E-Mail, Telefon, Notizen)",
      "select_form_tooltip" : "Wenn du von den Personen, die diesen Kalender buchen, mehr Informationen als nur Vorname, Nachname, E-Mail-Adresse und Telefonnummer erfassen möchtest, erstelle ein benutzerdefiniertes Formular unter „Sites > Formulare > Builder“ und wähle das Formular dann über die Dropdown-Liste unten aus.",
      "confirmation_page_tooltip" : "Sobald jemand eine Buchung getätigt hat, kannst du wählen, ob du ihm eine benutzerdefinierte Nachricht auf derselben Seite anzeigen möchtest (Standard) oder ihn auf eine Seite deiner Wahl umleiten möchtest (Weiterleitung).",
      "add_guest_collect_email" : "Name und E-Mail",
      "add_guest_count_only" : "Nur Anzahl",
      "custom_email_notification" : "Benutzerdefiniertes Formular an Gäste senden",
      "consent_label" : "Kontrollkästchen „Einwilligung“",
      "consent_input_placeholder" : "Einwilligungstext hinzufügen",
      "same_user_assignment_tooltip" : "Um diese Einstellung zu aktivieren, muss sich das Formular an erster Stelle im Widget befinden. Ordne das Widget neu an, um das Formular zuerst zu positionieren, um diese Einstellung zu aktivieren.",
      "consent_tooltip" : "Schalte diese Option ein, um die Einwilligung von Kontakten einzuholen, wenn sie ihre Telefonnummer während des Terminbuchungsprozesses angeben.",
      "stripe_error" : "Bis zu zwei Dezimalstellen (Beispiel: 25,99)",
      "auto_confirm_service" : "Neue Termine automatisch bestätigen",
      "service_form_message" : "Wenn ein Kontakt mehrere Services bucht, wird er nur das Standardformular im Servicemenü sehen",
      "deposit_amount_error" : "Der Einzahlungsbetrag muss geringer sein als der Gesamtbetrag.",
      "deposit_percentage_error" : "Der Einzahlungsprozentsatz muss unter 100 % liegen.",
      "partial_payment_tooltip" : "Ermögliche Teilzahlungen, um während der Terminbuchung eine Anzahlung vom Kunden zu erhalten.",
      "partial_payment_enable_error" : "Der eingegebene Gesamtbetrag muss größer als 0 sein, um Teilzahlungen zu ermöglichen.",
      "payment_disable_text_integration" : "Integriere einen Zahlungsanbieter unter Zahlungen > Integrationen, um Zahlungen zu akzeptieren",
      "payment_disable_text_recurring" : "Die Option „Zahlung akzeptieren“ ist für wiederkehrende Besprechungen nicht verfügbar. Schalte wiederkehrend aus, um Zahlungen zu aktivieren.",
      "date_and_time_selector" : "Datums- und Uhrzeitauswahl",
      "form_selector" : "Formular",
      "order_step" : "Schritt",
      "widget_order_label" : "Widget-Reihenfolge",
      "widget_order_tool_tip" : "Ordne die Reihenfolge „Datums- und Uhrzeitauswahl“ und „Formular“ im Kalender-Widget neu an. Ordne sie per Drag & Drop nach deiner Präferenz neu an.",
      "widget_re_order_tooltip" : "Die Neuanordnung ist nur mit dem Neo-Widget-Stil verfügbar. Wechsle zu Neo, um die Neuanordnung zu aktivieren.",
      "widget_re_order_disabled_tooltip" : "Um die Reihenfolge der Widgets zu ändern, deaktiviere die Option „Bevorzugung neuer Termine (immer mit dem zugewiesenen Benutzer des Kontakts buchen)“ im Abschnitt Besprechungsdetails > Teammitglieder > Erweiterte Einstellungen.",
      "payment_description_error" : "Maximale Zeichenanzahl von 1000 erreicht",
      "widget_multiple_location_disabled_tooltip" : "Das Widget Klassisch unterstützt nicht mehrere Besprechungsorte. Bitte entferne alle zusätzlichen Standorte, um das Klassisch-Widget zu verwenden",
      "recurring_payment_label" : "Zahlung für wiederkehrende Termine",
      "first_appointment_only" : "Nur erster Termin",
      "all_appointments" : "Alle Termine"
    },
    "notifications" : {
      "notifications" : "Benachrichtigungen und zusätzliche Optionen",
      "notifications_sub" : "Benachrichtigungen und zusätzliche Optionen konfigurieren",
      "select_notification_type" : "Wähle die Art der Benachrichtigung",
      "notification_person" : "Wer soll diese Benachrichtigung erhalten?",
      "contact" : "Kontakt",
      "guest" : "Gast",
      "assigned_user" : "Zugewiesener Benutzer",
      "emails" : "E-Mails",
      "alert_email_address" : "E-Mail-Adresse für Benachrichtigungen",
      "ack_email" : "Bestätigungs-E-Mail",
      "send_google_invites" : "Erlaube Google Kalender, Einladungs- oder Aktualisierungs-E-Mails an Teilnehmer zu senden.",
      "send_google_outlook_invites" : "Erlaube Google / Outlook-Kalender, Einladungs- und Aktualisierungs-E-Mails an Teilnehmer zu senden.",
      "assign_user_to_contact" : "Weise bei jeder Terminbuchung Kontakte ihren jeweiligen Kalenderteammitgliedern zu",
      "skip_assigning_user_to_contact" : "Überspringe die Kontaktzuweisung, wenn dem Kontakt bereits ein Benutzer zugewiesen ist",
      "assigned_staff" : "Zugewiesene Mitarbeiter",
      "additional_emails" : "Zusätzliche E-Mails",
      "assign_user_to_staff" : "Weise bei jeder Terminbuchung Kontakte den jeweiligen Servicemitarbeitern zu",
      "allow_cancellation" : " Absage der Besprechung zulassen",
      "allow_reschedule" : "Verschiebung der Besprechung zulassen",
      "allow_cancellation_tooltip" : "Wenn diese Option aktiviert ist, wird ein Link zur Absage in den Abschnitt für zusätzliche Notizen und in die Kalendereinladung aufgenommen. Dieser Link läuft wie konfiguriert vor der Startzeit des Meetings ab, sodass der Buchende die Besprechung nicht absagen kann.",
      "allow_reschedule_tooltip" : " Wenn diese Option aktiviert ist, wird ein Link zur Terminverschiebung dem Abschnitt für zusätzliche Notizen hinzugefügt und in die Kalendereinladung aufgenommen. Dieser Link läuft wie konfiguriert vor der Startzeit des Meetings ab und verhindert, dass der Buchende die Besprechung verschiebt.",
      "cancellation_link_expiry" : "Der Link zur Absage läuft ab",
      "reschedule_link_expiry" : "Der Link zur Terminverschiebung läuft ab",
      "before_meeting" : "vor der Besprechung",
      "cancellation_and_reschedule_policy" : "Absage- und Umbuchungsbedingungen:"
    },
    "connections" : {
      "connections_heading" : "Externe Kalendersynchronisierung",
      "connections_sub" : "Veranstaltungen mit externem Kalender synchronisieren",
      "link_to_calendar" : "Link zum Kalender",
      "sync_option" : "Option „Synchronisieren“",
      "execute_automation" : "Automatisierung ausführen",
      "fire_workflow" : "Workflow-Trigger für über 2-Wege-Synchronisierung gebuchte Veranstaltungen auslösen",
      "reserve_with_google" : "Mit Google reservieren",
      "connect_lsa" : "Mit deinem Local Services Ads-Konto verbinden",
      "reserve_error" : "Derzeit wird Reserve bei Google nur für „Home Services“, „Real Estate“, „Legal Services“ (lokale Dienstleister) in den Vereinigten Staaten unterstützt.",
      "one_way" : "Einwege",
      "two_way" : "Zweiwege",
      "smart" : "Intelligent",
      "confirm_remove" : "Möchtest du den Google-Kalender wirklich entfernen?",
      "read_only_access" : "Du hast keinen Schreibzugriff auf diesen Kalender. Bitte wähle einen anderen Kalender aus.",
      "reserve_with_google_desc" : "Dieser Kalenderservice wird erst nach erfolgreicher Integration bei Google als Reserve aufgeführt.",
      "check_integration" : "Überprüfe deinen Integrationsstatus",
      "setup_external" : "Richte deinen externen Kalender ein",
      "reserve_price_decimal_error" : "Dezimalpreis wird nicht unterstützt",
      "reserve_price_max_error" : "Unterstützter Preis unter 1000"
    },
    "extras" : {
      "extras" : "Anpassungen",
      "extras_sub" : "Lege den Stil des Widgets und andere Einstellungen fest.",
      "calendar_widget_style" : "Kalender-Widget-Stil",
      "neo" : "Neo",
      "classic" : "Klassisch",
      "insert_custom_code" : "Benutzerdefinierten Code einfügen",
      "show_seats_per_slot" : "Plätze pro Zeitfenster anzeigen",
      "allow_reschedule_meeting" : "Verschieben von Besprechungen zulassen",
      "allow_cancelling_meeting" : "Absagen von Besprechungen erlauben",
      "additional_notes" : "Zusätzliche Hinweise",
      "cancellation_and_reschedule" : "Absage und Verschiebung:",
      "allow_staff" : "Mitarbeiterauswahl bei der Buchung zulassen",
      "code_block_tooltip" : "Drittanbieter-Skripte innerhalb des HTML-Blocks können zwar leistungsstarke Funktionen bieten, bringen aber auch Risiken für Datenschutz, Sicherheit, Leistung und Seitenverhalten mit sich. Daher empfehlen wir, den Inhalt dieses Skripts zu überprüfen, bevor du es im Kalender speicherst.",
      "calendar_image" : "Kalender-Titelbild",
      "remove_image" : "Bild entfernen",
      "calendar_image_info" : "Das hochgeladene Bild ist in der Gruppenansicht für die Neo-Vorlage sichtbar und erscheint nicht im individuellen Kalenderlink",
      "get_image_from_unsplash" : "Verbessere dein Bildmaterial mit",
      "unsplash" : "Unsplash",
      "service_additional_preferences" : "Zusätzliche Einstellungen konfigurieren",
      "service_image" : "Titelbild des Service",
      "service_image_description" : "Wähle ein Bild aus, das im Servicemenü für diesen Service angezeigt werden soll",
      "allow_reschedule_appointment" : "Terminverschiebung zulassen",
      "allow_cancelling_appointment" : "Terminabsage zulassen",
      "custom_code_placeholder" : "Bitte gib hier den benutzerdefinierten Code ein",
      "calendar_widget_style_desc" : "Wähle zwischen unserem klassischen oder dem eleganten Neo-Widget",
      "seats_per_slot" : "Plätze pro Zeitfenster",
      "show_staff_selection" : "Mitarbeiterauswahl",
      "seats_per_slot_desc" : " Ermögliche Buchenden, die Anzahl der verfügbaren Plätze für jedes Zeitfenster direkt im Kalender-Widget zu sehen.",
      "show_staff_selection_desc" : "Ermögliche Buchenden, bei der Terminbuchung einen Mitarbeiter direkt aus dem Kalender-Widget auszuwählen. Funktioniert nur mit Neo Widget",
      "widget_re_order_tooltip" : "Der klassische Widget-Stil erlaubt keine Änderungen der Widget-Reihenfolge. Stelle sicher, dass die Datums- und Uhrzeitauswahl an erster Stelle und das Formular an zweiter Stelle steht, bevor du den klassischen Stil auswählst.",
      "show_staff_selection_desc_service" : "Ermögliche Buchenden, bei der Terminbuchung einen Mitarbeiter direkt aus dem Kalender-Widget auszuwählen."
    },
    "roomsAndEquipments" : {
      "title" : "Wähle Räume und Geräte",
      "description" : "Wähle aus einer Liste von Räumen und Geräten aus, was zusammen mit diesem Kalender gebucht werden soll",
      "rooms" : "Räume auswählen",
      "equipments" : "Geräte auswählen"
    }
  },
  "activity_log" : {
    "activity_log" : "Aktivitätsprotokoll",
    "date" : "Datum",
    "filter_by" : "Filtern nach",
    "calendar_update" : "Kalenderaktualisierung",
    "start_time_update" : "Aktualisierung der Startzeit",
    "end_time_update" : "Aktualisierung der Endzeit",
    "title_update" : "Aktualisierung des Titels",
    "notes_update" : "Aktualisierung der Notizen",
    "appointment_status_update" : "Aktualisierung des Terminstatus",
    "meeting_location_update" : "Aktualisierung des Besprechungsortes",
    "google_sync_update" : "Aktualisierung der Google Synchronisierung",
    "outlook_sync_update" : "Aktualisierung der Outlook-Synchronisierung",
    "assigned_user_update" : "Aktualisierung des zugewiesenen Benutzers",
    "appointment_status" : "Terminstatus",
    "assigned_user" : "Zugewiesener Benutzer",
    "uploaded_to" : "Hochgeladen nach",
    "cancelled" : "Abgesagt",
    "edited" : "bearbeitet",
    "other_edits" : "Andere Bearbeitungen",
    "view_details" : "Details anzeigen",
    "all" : "Alle"
  },
  "appointment_list" : {
    "group_all" : "ALLE",
    "group_primary" : "PRIMÄRE",
    "group_user" : "BENUTZER",
    "group_team" : "GRUPPEN",
    "group_calendar" : "KALENDER",
    "all_appointments" : "Alle Termine",
    "my_calendar" : "Mein Kalender",
    "status_all" : "Alle",
    "status_confirmed" : "Bestätigt",
    "status_new" : "Neu (Aktion erforderlich)",
    "status_show" : "Erschienen",
    "status_no_show" : "Nichterscheinen",
    "status_cancelled" : "Abgesagt",
    "status_invalid" : "Ungültig",
    "filter_date_added_desc" : "Datum hinzugefügt (absteigend)",
    "filter_start_added_desc" : "Startdatum (absteigend)",
    "filter_start_added_asc" : "Startdatum ab heute aufsteigend",
    "button_new_appointment" : "Neuer Termin",
    "name" : "Name",
    "title" : "Titel",
    "requested_time" : "Angeforderte Zeit",
    "date_added" : "Hinzugefügtes Datum",
    "calendar" : "Kalender",
    "appointment_owner" : "Termin-Owner",
    "notes" : "Notizen",
    "status" : "Status",
    "action_view_detail" : "Details anzeigen",
    "action_view_consent" : "Einwilligung anzeigen",
    "action_view_notes" : "Notizen anzeigen",
    "action_edit" : "Bearbeiten",
    "action_delete" : "Löschen",
    "action_reschedule" : "Verschieben",
    "page_previous" : "Vorherige",
    "page_next" : "Nächste",
    "appointment_notes" : "Terminnotizen",
    "close" : "Schließen",
    "confirm_delete_header" : "Termin löschen",
    "confirm_delete_message" : "Bist du sicher, dass du diesen Termin löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden!",
    "confirm_delete_button" : "Löschen",
    "confirm_update_header" : "Terminstatus aktualisieren",
    "confirm_update_confirmed_message" : "Bist du sicher, dass du diesen Termin als bestätigt markieren möchtest?",
    "confirm_update_invalid_message" : "Bist du sicher, dass du diesen Termin als ungültig markieren möchtest?",
    "confirm_update_showed_message" : "Bist du sicher, dass du diesen Termin als erschienen markieren möchtest?",
    "confirm_update_noshow_message" : "Bist du sicher, dass du diesen Termin als nicht erschienen markieren möchtest?",
    "confirm_update_cancelled_message" : "Bist du sicher, dass du diesen Termin als abgesagt markieren möchtest?",
    "confirm_update_button" : "Aktualisierung",
    "empty_state_title" : "Keine Termine gefunden",
    "empty_state_description" : "Keine Termine gefunden",
    "disable_menu_warning" : "Derzeit hast du nicht die Berechtigung, den Termin zu bearbeiten oder zu löschen, da der zugehörige Kontakt nicht dir zugeordnet ist. Bitte wende dich an deinen Administrator, um weitere Unterstützung zu erhalten.",
    "no_contact_warning" : "Du kannst derzeit nicht auf die Kontaktinformationen zugreifen. Dies kann daran liegen, dass der Kontakt dir nicht zugewiesen ist. Bitte wende dich an deinen Administrator, um Hilfe zu erhalten.",
    "consent_label" : "Einwilligung",
    "ip_address" : "IP",
    "youre_viewing_in" : "Wird derzeit angezeigt in",
    "appointment_delete_msg" : "<b>Bitte beachte:</b> Der hier gelöschte Termin wird nicht aus Calendly gelöscht.",
    "rescheduled" : "Verschoben"
  },
  "add_guest" : {
    "guest_label" : "Gast/Gäste",
    "search_placeholder" : "In Kontakten suchen",
    "add_new" : "Neu hinzufügen",
    "save" : "Speichern",
    "close" : "Schließen",
    "name" : "Name",
    "phone" : "Telefon",
    "email" : "E-Mail",
    "guest_label_count" : "Anzahl Gast/Gäste",
    "guest_count_error" : "Die Anzahl der Gäste sollte zwischen 1 und 100 liegen"
  },
  "languages" : {
    "english" : "Englisch",
    "dutch" : "Niederländisch",
    "french" : "Französisch",
    "german" : "Deutsch",
    "hungarian" : "Ungarisch",
    "italian" : "Italienisch",
    "polish" : "Polnisch",
    "portuguese_brazil" : "Portugiesisch (Brasilien)",
    "portuguese_portugal" : "Portugiesisch (Portugal)",
    "spanish" : "Spanisch",
    "danish" : "Dänisch"
  },
  "service_menu_advanced" : {
    "new_service_menu_details_header" : "Details zum Servicemenü",
    "select_groups_header" : "Services auswählen",
    "arrange_groups_header" : "Services anordnen",
    "new_service_menu" : "Neues Servicemenü",
    "service_menu_sub_title" : "Erstelle mit nur wenigen Klicks ein personalisiertes Servicemenü für deine Kunden",
    "quick_tip" : "Du kannst Gruppen zum Servicemenü hinzufügen",
    "service_menu_name" : "Name des Servicemenüs",
    "service_menu_description" : "Beschreibung",
    "service_menu_slug" : "Service-URL",
    "select_groups_calendars" : "Gruppen und Kalender auswählen",
    "select_groups_calendars_desc" : "Services zu deinem Servicemenü hinzufügen",
    "select_all" : "Alle auswählen",
    "arrange_groups" : "Reihenfolge der Services festlegen",
    "arrange_groups_desc" : "Reihenfolge der Services im Servicemenü festlegen",
    "arrange_services_quicktip" : "Um Kalender innerhalb einer Gruppe neu anzuordnen, gehe zu Gruppen > Optionen > Kalender neu anordnen.",
    "enable_add_guest_desc" : "Ermöglicht es Kunden, bei der Buchung zusätzliche Gäste hinzuzufügen.",
    "enable_multiple_service_desc" : "Ermöglicht es Kunden, mehrere Services in einem einzigen Termin zu buchen.",
    "enable_staff_selection_desc" : "Ermöglicht es Kunden, bei der Buchung bevorzugte Mitarbeiter auszuwählen.",
    "enable_add_guest" : "„Gäste hinzufügen“ aktivieren",
    "enable_multiple_service" : "Mehrfachauswahl von Services aktivieren",
    "enable_staff_selection" : "Mitarbeiterauswahl aktivieren",
    "additional_options" : "Zusätzliche Optionen",
    "code_block_tooltip" : "Drittanbieter-Skripte innerhalb des HTML-Blocks können zwar leistungsstarke Funktionen bieten, bringen aber auch Risiken für Datenschutz, Sicherheit, Leistung und Seitenverhalten mit sich. Daher empfehlen wir, den Inhalt dieses Skripts zu überprüfen, bevor du es im Kalender speicherst.",
    "custom_code_placeholder" : "Bitte gib hier den benutzerdefinierten Code ein",
    "insert_custom_code" : "Benutzerdefinierten Code einfügen",
    "no_service" : "Keinen Service gefunden",
    "no_service_desc" : " Keine Services gefunden. Erstelle Services und füge sie zu Gruppen hinzu, um ein Servicemenü zu erstellen.",
    "learn_more" : "Mehr erfahren",
    "no_service_selected" : "Kein Service ausgewählt",
    "create_service_calendars" : "Servicekalender erstellen",
    "alert_description" : " Um ein Servicemenü zu erstellen, musst du Service-Kalender erstellen und diese zu Gruppen hinzufügen",
    "dont_show_again" : "Diese Nachricht nicht mehr anzeigen",
    "service_name_required" : "Der Name des Servicemenüs ist erforderlich",
    "slug_already_taken" : "Dieser URL-Teil ist bereits vergeben",
    "slug_required" : "URL-Teil ist erforderlich",
    "please_enter_name" : "Bitte gib einen Namen ein",
    "service_name_placeholder" : "Hauptmenü",
    "consent_label" : "Kontrollkästchen „Einwilligung“",
    "consent_input_placeholder" : "Einwilligungstext hinzufügen",
    "consent_tooltip" : "Schalte diese Option ein, um die Einwilligung von Kontakten einzuholen, wenn sie ihre Telefonnummer während des Terminbuchungsprozesses angeben."
  },
  "widget_configuration" : {
    "customise_calendar_widget" : "Kalender-Widget anpassen",
    "works_with_neo_widget" : "Funktioniert nur mit Neo Widget",
    "customise_widget_desc" : "Passe das Aussehen des Widgets an: Grundfarbe, Hintergrundfarbe und Schaltflächentext.",
    "learn_more" : "Mehr erfahren",
    "primary_settings" : "Primäre Einstellungen",
    "primary_settings_desc" : "Konfiguriere die Primärfarbe, Hintergrundfarbe und andere Widget-Einstellungen, um dein Kalender-Widget anzupassen",
    "primary_color" : "Primärfarbe",
    "primary_color_desc" : "Definiere die Farbe für Schaltflächen, Daten, Zeitfenster und Aktionen",
    "background_color" : "Hintergrundfarbe",
    "background_color_desc" : "Lege die Hintergrundfarbe fest, die als Hintergrund für die Datums-/Uhrzeitauswahl und das Formular dient.",
    "button_text" : "Schaltflächentext",
    "button_text_desc" : "Passe den Text an, der bei der Terminbuchung auf der Schaltfläche angezeigt wird",
    "calendar_title" : "Kalendertitel",
    "calendar_title_desc" : "Der Name deines Kalenders",
    "calendar_description" : "Kalenderbeschreibung",
    "calendar_description_desc" : "Die eingegebene Beschreibung",
    "calendar_details" : "Kalenderdetails",
    "calendar_details_desc" : "Enthält Informationen wie Dauer, Datum und Uhrzeit, wiederkehrende Details und Zeitzone",
    "reset_to_default" : "Auf Standard zurücksetzen",
    "preview_widget" : "Vorschau-Widget"
  },
  "eventNotification" : {
    "customFields" : "Custom Fields",
    "customValues" : "Custom Values",
    "list" : {
      "booked" : {
        "title" : "Appointment Booked (Status: Unconfirmed)",
        "note" : "This notification is sent when a new appointment is created with Unconfirmed status.",
        "subTitle" : "Benachrichtigt, wenn ein Termin mit unbestätigtem Status gebucht wird"
      },
      "confirmation" : {
        "title" : "Appointment Booked (Status: Confirmed)",
        "note" : "This notification is sent when an appointment is created with or updated to the Confirmed status.",
        "subTitle" : "Benachrichtigt, wenn ein Termin erfolgreich bestätigt wird"
      },
      "cancellation" : {
        "title" : "Cancellation",
        "note" : "This notification is sent when an appointment is cancelled, marked as no-show, or marked as invalid.",
        "subTitle" : "Benachrichtigungen, wenn ein Termin abgesagt wird"
      },
      "reschedule" : {
        "title" : "Reschedule",
        "note" : "This notification is sent when an appointment is rescheduled. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Benachrichtigt, wenn ein Termin neu geplant wird"
      },
      "reminder" : {
        "title" : "Reminder",
        "note" : "This notification is sent as a reminder before the appointment. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Sendet dir eine Erinnerung vor dem Termin"
      },
      "followup" : {
        "title" : "Follow-Up",
        "note" : "This notification is sent after the appointment as a follow-up. It will only be triggered if the appointment is in Unconfirmed, Confirmed, or Showed status.",
        "subTitle" : "Sendet nach Abschluss des Termins eine Nachverfolgungsnachricht"
      }
    },
    "saveToastMsg" : "Einstellungen gespeichert!",
    "saveToastMsgDesc" : "Deine Änderungen wurden erfolgreich gespeichert",
    "enableSuccess" : "Benachrichtigungen aktiviert!",
    "disableSuccess" : "Benachrichtigungen deaktiviert!",
    "tooltip" : "This notification has been disabled. Please enable it to make any changes.",
    "enabled" : "Enabled",
    "disabled" : "Disabled",
    "statusLabel" : "Status Labels",
    "email" : {
      "channelName" : "Email",
      "noneTemplate" : "Keine",
      "notificationLabel" : "Wer soll diese Benachrichtigung erhalten?",
      "sendButtonLabel" : "Test-E-Mail senden",
      "testEmailLabel" : "Test-E-Mail",
      "testEmailPlaceholder" : "Gib die E-Mail-Adresse des Empfängers für den Test ein",
      "body" : "E-Mail-Text",
      "bodyPlaceholder" : "Gib hier deine Nachricht ein",
      "subject" : "Betreff",
      "subjectPlaceholder" : "Gib den Betreff der E-Mail ein",
      "templateLabel" : "E-Mail-Vorlage",
      "templatePlaceholder" : "Wähle eine E-Mail-Vorlage oder beginne neu",
      "alertEmailAddress" : "E-Mail-Adresse für Benachrichtigungen",
      "addMore" : "Mehr hinzufügen",
      "afterAppointmentEnds" : "nach Beendigung des Termins",
      "beforeAppointmentStarts" : "bevor der Termin beginnt",
      "durationLabel" : "Wann möchtest du die Benachrichtigung auslösen?",
      "disableEmailNotification" : "E-Mail-Benachrichtigungen deaktivieren",
      "enableEmailNotification" : "E-Mail-Benachrichtigungen aktivieren",
      "enableSuccess" : "E-Mail-Benachrichtigung aktiviert!",
      "enableSuccessDesc" : "Du wirst nun diese Benachrichtigung erhalten",
      "disableSuccess" : "E-Mail-Benachrichtigung deaktiviert!",
      "disableSuccessDesc" : "Du wirst diese Benachrichtigung nicht mehr erhalten"
    },
    "inApp" : {
      "channelName" : "In-app",
      "title" : "Benachrichtigungseinstellungen",
      "enableInAppNotification" : "Enable In-App Notification",
      "disableInAppNotification" : "Disable In-App Notification",
      "selectedUser" : {
        "label" : "Senden an",
        "helperText" : "Diese interne Benachrichtigung wird über das Web und die mobilen Apps gesendet",
        "placeholder" : "Benutzer auswählen"
      },
      "enableSuccess" : "In-App-Benachrichtigungen aktiviert!",
      "enableSuccessDesc" : "Du wirst nun diese Benachrichtigung erhalten",
      "disableSuccess" : "In-App-Benachrichtigungen deaktiviert!",
      "disableSuccessDesc" : "Du wirst diese Benachrichtigung nicht mehr erhalten"
    }
  }
}