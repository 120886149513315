{
  "settings" : {
    "calendar" : {
      "calendar_settings" : "Paramètres du calendrier",
      "select_calendar_type" : "Sélectionner le type de calendrier",
      "add_new_calendar_group" : "Ajouter un groupe de calendriers",
      "add_new_equipment" : "Ajouter un équipement",
      "new_calendar" : "Nouveau calendrier",
      "calendar_group_description" : "Utilisez les groupes pour organiser et regrouper efficacement plusieurs calendriers.",
      "group" : "Groupe",
      "manage_your_calendars_and_groups" : "Gérer vos calendriers et vos groupes",
      "groups" : "Groupes",
      "create_group" : "Créer un groupe",
      "create_calendar" : "Créer un calendrier",
      "calendar_name" : "Nom du calendrier",
      "group_duration" : "Durée du groupe",
      "calendar_type" : "Type de calendrier",
      "action" : "Action",
      "round_robin" : "Round-robin",
      "event" : "Événement",
      "group_name" : "Nom du groupe",
      "room_name" : "Nom de la salle",
      "group_description" : "Description du groupe",
      "calendar_url" : "URL du calendrier",
      "calendars" : "Calendriers",
      "date_updated" : "Date de mise à jour",
      "status" : "Statut",
      "action_dropdown" : "Liste déroulante des actions",
      "calendar_list" : "Services associés à",
      "edit" : "Modifier",
      "duplicate" : "Dupliquer",
      "copy_embed_code" : "Copier le code d'intégration",
      "copy_permanent_link" : "Copier le lien permanent",
      "copy_new_slug_link" : "Copier le lien de planification",
      "copy_scheduling_link" : "Copier le lien de planification (ancienne version obsolète)",
      "deactivate_calendar" : "Désactiver le calendrier",
      "move_to_group" : "Déplacer vers le groupe",
      "delete_calendar" : "Supprimer le calendrier",
      "delete_group" : "Supprimer",
      "delete_this_group" : "Supprimer ce groupe",
      "activate_calendar" : "Activer le calendrier",
      "duration" : "Durée (min)",
      "all" : "Tout",
      "draft" : "Brouillon",
      "active" : "Actif",
      "rearrange_calendars" : "Réorganiser les calendriers",
      "deactivate_all_calendars_in_group" : "Désactiver tous les calendriers du groupe",
      "activate_all_calendars_in_group" : "Activer tous les calendriers du groupe",
      "no_groups_found" : "Aucun groupe de calendriers ne correspond à votre recherche.",
      "no_groups_in_location" : "Commencez à utiliser les groupes en créant un groupe de calendriers.",
      "no_calendars_found" : "Commencez à utiliser les calendriers en créant un calendrier.",
      "no_calendars_found_search" : "Aucun calendrier ne correspond à votre recherche.",
      "delete_calendar_message" : "Vous êtes sur le point de supprimer le calendrier. Si vous cliquez sur Supprimer, tous les rendez-vous seront également supprimés.",
      "delete_calendar_message_title" : "Supprimer ce calendrier ?",
      "duplicate_calendar_message" : "Vous êtes sur le point de dupliquer le calendrier. Cela créera un nouveau calendrier avec les mêmes paramètres que le calendrier sélectionné.",
      "duplicate_calendar_message_title" : "Dupliquer ce calendrier ?",
      "no_calendars_found_title" : "Aucun calendrier trouvé",
      "no_groups_in_location_title" : "Aucun groupe trouvé",
      "delete_group_confirmation_title" : "Supprimer ce groupe ?",
      "delete_group_confirmation" : "Voulez-vous vraiment supprimer le groupe sélectionné ?",
      "delete_group_confirmation_checkbox_title" : "Supprimer les calendriers et les rendez-vous associés",
      "delete_group_confirmation_checkbox_description" : "Sélectionner cette option supprimera également les calendriers de ce groupe, ainsi que tous les rendez-vous de ces calendriers.",
      "group_status_message_title" : "{status} ce groupe ?",
      "calendar_status_message_title" : "{status} ce calendrier ?",
      "calendar_status_message" : "Vous êtes sur le point de {status} le calendrier",
      "group_status_message" : "Vous êtes sur le point de {status} le groupe",
      "coming_soon" : "À venir",
      "select" : "Sélectionner",
      "15mins" : "15 min",
      "cancel" : "Annuler",
      "save" : "Enregistrer",
      "create" : "Créer",
      "select_calendar_group" : "Sélectionner un groupe de calendriers",
      "move_calendar_confirmation" : "Veuillez noter que l'ancien lien cessera de fonctionner une fois que vous aurez procédé au changement. Veuillez confirmer en cochant la case.",
      "previously_called_unassigned" : " (Précédemment désigné comme non assigné)",
      "scheduling_type" : "Type de planification",
      "scheduling_type_description" : "Choisissez un type de planification pour votre nouveau calendrier",
      "service_menu_name" : "Nom du menu de services",
      "template" : "Modèle",
      "group_url" : "URL du groupe",
      "template_description" : "Vous avez maintenant le choix de sélectionner les modèles Classic ou Neo pour la vue de groupe",
      "rooms" : "Salles",
      "rooms_name" : "Nom",
      "rooms_description" : "Description",
      "rooms_modal_description" : "Ajouter ou modifier une salle",
      "rooms_capacity" : "Capacité totale",
      "no_rooms_in_location_title" : "Aucune salle trouvée",
      "no_rooms_in_location" : "Aucune salle n'a été trouvée à cet endroit",
      "no_equipments_in_location" : "Aucun équipement n'a été trouvé à cet endroit",
      "equipments" : "Équipements",
      "equipment_name" : "Nom",
      "equipment_name_search" : "Nom de l'équipement",
      "equipment_description" : "Description",
      "equipment_modal_description" : "Ajouter ou modifier un équipement",
      "add_equipment" : "Ajouter/Modifier un équipement",
      "add_room" : "Ajouter/Modifier une salle",
      "equipment_quantity" : "Quantité totale",
      "equipment_outOfService" : "Quantité hors service",
      "no_equipments_in_location_title" : "Aucun équipement trouvé",
      "delete_this_equipment" : "Supprimer cet équipement",
      "delete_this_rooms" : "Supprimer cette salle",
      "delete_equipment_confirmation" : "Voulez-vous vraiment supprimer cet équipement ? Il sera également supprimé de tous les calendriers liés.",
      "delete_rooms_confirmation" : "Voulez-vous vraiment supprimer cette salle ? Elle sera également supprimée de tous les calendriers liés.",
      "add_new_calendar_equipments" : "Ajouter un équipement",
      "no_equipments_found" : "Aucun équipement ne correspond à votre recherche.",
      "no_rooms_found" : "Aucune salle ne correspond à votre recherche.",
      "add_new_calendar_rooms" : "Ajouter une salle",
      "rooms_disable" : "Désactiver la salle",
      "equipments_disable" : "Désactiver l'équipement",
      "equipments_activate" : "Activer l'équipement",
      "rooms_activate" : "Activer la salle",
      "create_equipments" : "Créer un équipement",
      "create_rooms" : "Créer une salle",
      "select_calendar_rooms_title" : "Sélectionner un calendrier",
      "select_calendar_rooms_description" : "Vous pouvez sélectionner plusieurs calendriers à associer aux salles",
      "select_calendar_equipments_title" : "Sélectionner un calendrier",
      "select_calendar_equipments_description" : "Vous pouvez sélectionner un calendrier à associer à l'équipement",
      "disable_equipment_title" : "{status} l'équipement",
      "disable_room_title" : "{status} la salle",
      "equipments_status_message" : "Voulez-vous vraiment {status} cet équipement ?",
      "rooms_status_message" : "Voulez-vous vraiment {status} cette salle ?",
      "calendar_resource_disclaimer" : "Cela pourrait affecter la disponibilité sur les calendriers de service associés.",
      "equipment_disclaimer" : "Remarque : Le fait d'activer l'équipement ultérieurement le reconnectera automatiquement aux calendriers auxquels il est associé",
      "room_disclaimer" : "Remarque : Le fait d'activer la salle ultérieurement la reconnectera automatiquement aux calendriers auxquels elle est associée",
      "equipment_tab_name" : "Équipements",
      "rooms_tab_name" : "Salles",
      "manage_your_rooms" : "Gérez les salles de votre entreprise ici",
      "manage_your_equipments" : "Gérez les équipements de votre entreprise ici",
      "select_service_calendar_description_equipments" : "Vous pouvez sélectionner plusieurs calendriers de service à associer à l'équipement",
      "select_service_calendar_description_rooms" : "Vous pouvez sélectionner plusieurs calendriers de service à associer aux salles",
      "service_menus" : "Menu de services",
      "manage_your_calendars_groups_and_service_menus" : "Gérer vos calendriers, vos groupes et votre menu de services",
      "new_service_menu" : "Créer un menu de services",
      "deactivate_service_menu" : "Désactiver le menu de services",
      "delete_service_menu" : "Supprimer ce menu de services",
      "delete_service_menu_confirmation" : "Voulez-vous vraiment supprimer ce menu de services ?",
      "service_menu_status_message_title" : "{status} ce menu de services ?",
      "service_menu_status_message" : "Vous êtes sur le point de {status} le menu de services",
      "no_service_menus_in_location_title" : "Créez votre menu de services personnalisé",
      "add_new_service_menu" : "Ajouter un menu de services",
      "no_service_menus_in_location" : "Commencez par créer un menu de services et ajoutez-y des groupes",
      "no_service_menu_found" : "Aucun menu de services n'a été trouvé",
      "no_service_menu_found_desc" : "Aucun menu de services ne correspond à votre recherche.",
      "apply" : "Appliquer",
      "troubleshoot_calendar" : "Résoudre les problèmes liés au calendrier",
      "round_robin_booking" : "Round-robin",
      "round_robin_description" : "Répartit les rendez-vous entre les membres d'équipe selon un roulement.",
      "round_robin_example" : "Ex. : appels commerciaux, sessions d'intégration.",
      "unassigned_booking" : "Calendrier des événements",
      "unassigned_description" : "Pour la planification d'événements physiques sans association d'animateurs.",
      "unassigned_example" : "Ex. : conférences, séminaires publics, expositions.",
      "class_booking" : "Réservation de cours",
      "class_booking_description" : "Un animateur rencontre plusieurs participants.",
      "class_booking_example" : "Ex. : webinaires, formations de groupe, cours en ligne.",
      "collective_booking" : "Réservation groupée",
      "collective_booking_description" : "Plusieurs animateurs rencontrent un participant.",
      "collective_booking_example" : "Ex. : entretiens avec panel, évaluations de comité.",
      "service_booking" : "Réservation de service",
      "service_booking_description" : "Planification sans faille pour les entreprises de services.",
      "service_booking_example" : "Ex. : rendez-vous au spa, services de réparation, consultations.",
      "personal_booking" : "Réservation personnelle",
      "personal_booking_description" : "Planifie des réunions individuelles avec un membre d'équipe particulier.",
      "personal_booking_example" : "Ex. : réunions avec les clients, consultations privées.",
      "personal" : "Personnel",
      "explore_more_types" : "Découvrez d'autres types"
    },
    "preferences" : {
      "preferences" : "Préférences",
      "my_preferences" : "Mes préférences",
      "account_preferences" : "Préférences du compte",
      "user_preferences" : "Préférences de l'utilisateur",
      "set_preferences" : "Définissez vos préférences pour votre compte.",
      "scheduling_options" : "Options de planification",
      "meeting_location" : "Lieu de la réunion",
      "zoom" : "Zoom",
      "save_preferences" : "Enregistrer les préférences",
      "in_app_preferences" : "Dans les préférences de l'application",
      "set_start_day" : "Définissez des préférences telles que le jour de démarrage de l'application",
      "view_options" : "Afficher les options",
      "week_starts_on" : "La semaine commence le",
      "sunday" : "dimanche",
      "widget_preferences" : "Préférences du widget",
      "set_widget_language" : "Définissez des préférences telles que la langue du widget",
      "language_and_region" : "Langue et région",
      "language" : "Langue",
      "english" : "Anglais",
      "time_format" : "Format d'heure",
      "time_format_value" : "1:30 PM",
      "monday" : "lundi",
      "custom_meeting_location" : "Lieu de réunion personnalisé"
    },
    "availability" : {
      "availability" : "Disponibilité",
      "my_availability" : "Ma disponibilité",
      "account_availability" : "Disponibilité du compte",
      "timezone" : "Fuseau horaire",
      "working_hours" : "Heures de travail",
      "set_recurring_hours" : "Définissez vos heures de travail récurrentes pour les réunions",
      "sunday" : "Dimanche",
      "monday" : "Lundi",
      "tuesday" : "Mardi",
      "wednesday" : "Mercredi",
      "thursday" : "Jeudi",
      "friday" : "Vendredi",
      "saturday" : "Samedi",
      "widget_preferences" : "Préférences du widget",
      "set_widget_language" : "Définissez des préférences telles que la langue du widget",
      "language_and_region" : "Langue et région",
      "language" : "Langue",
      "english" : "Anglais",
      "copy_to_all" : "Copier à tous",
      "save_availability" : "Enregistrer la disponibilité",
      "add_time" : "Ajouter une heure",
      "select_time" : "Sélectionner l'heure"
    },
    "connections" : {
      "connections" : "Connexions",
      "connect" : "connecter",
      "conferencing" : "Conférences",
      "my_connections" : "Mes connexions",
      "account_connections" : "Comptes connectés",
      "connection_preferences" : "Préférences de connexion",
      "manage_calendars_conferencing_channels" : "Gérer les calendriers et les canaux de conférence",
      "main_integration_calendar" : "Calendrier intégré principal",
      "event_integration_calendar" : "Les événements créés sur un calendrier système dont vous faites partie seront également créés sur ce calendrier intégré",
      "2way_sync" : "Synchronisation bidirectionnelle : Créez des événements système à partir d'événements inscrits sur le calendrier intégré principal",
      "calendars" : "Calendriers",
      "google" : "Google",
      "outlook" : "Outlook",
      "check_for_conflicts" : "Vérifier les conflits",
      "choose_accounts_to_connect" : "Choisissez les comptes que vous souhaitez connecter",
      "payments" : "Paiements",
      "stripe" : "Stripe",
      "ads" : "Annonces",
      "local_service_ads" : "Annonces de services locaux",
      "fire_workflow" : "Déclencher des flux de travail pour les événements réservés via la synchronisation bidirectionnelle",
      "connect_google_outlook" : "Veuillez connecter votre compte Google/Outlook/iCloud pour pouvoir choisir le calendrier principal.",
      "execute_automation" : "Exécuter l'automatisation",
      "save" : "Enregistrer",
      "lsa" : "Annonces de services locaux",
      "no_write_access" : "Vous n'avez pas accès en écriture à ce calendrier",
      "connection_remove_confirmation" : "Vous êtes sur le point de supprimer cette intégration",
      "active" : "Actif",
      "icloud" : {
        "title" : "Intégration iCloud",
        "description" : "Une authentification à deux facteurs et un mot de passe spécifique à l'application sont nécessaires pour connecter votre compte.",
        "email" : "Adresse e-mail iCloud",
        "password" : "Mot de passe iCloud",
        "connect" : "Se connecter",
        "cancel" : "Annuler"
      }
    }
  },
  "appointment_modal" : {
    "select_a_contact" : "Sélectionner un contact",
    "start_time" : "Heure de début",
    "unassigned" : "Non assigné",
    "end_time" : "Heure de fin",
    "search_by_name_email_phone_or_company" : "Rechercher par nom, e-mail, téléphone ou entreprise",
    "add_new" : "Ajouter",
    "pick_from_available_contacts" : "Ou choisir parmi les contacts disponibles",
    "book_appointment" : "Prendre rendez-vous",
    "add_blocked_off_time" : "Ajouter un créneau d'indisponibilité",
    "recommended_timezones" : "Fuseaux horaires recommandés",
    "all_timezones" : "Tous les fuseaux horaires",
    "calendar" : "Calendrier",
    "repeat" : "Répétition",
    "date_and_time" : "Date et heure",
    "close" : "Fermer",
    "team_member" : "Membre d'équipe",
    "recurring_booking" : "Réservation récurrente",
    "one_slot_booking" : "Réservation d'un créneau",
    "event_type" : "Type d'événement",
    "occurrences_booked" : "événements seront réservés",
    "repeats" : "Répétitions",
    "booking_details" : "Informations sur la réservation",
    "showing_slots_in_this_timezone" : "Affichage des créneaux dans ce fuseau horaire :",
    "update_contact_tz" : "Mettre également à jour le fuseau horaire du contact pour",
    "date" : "Date",
    "slot" : "Créneau",
    "calendar_default" : "Calendrier par défaut",
    "custom" : "Personnalisé",
    "meeting_notes" : "(p. ex.) Notes de réunion pour notre prochain rendez-vous",
    "out_of_office" : "(p. ex.) Absent du bureau pour la semaine !",
    "standard" : "Par défaut",
    "appointment_title" : "Titre du rendez-vous",
    "event_title" : "Titre de l'événement",
    "appt_with_bob" : "(p. ex.) Rendez-vous avec Bob",
    "vacation" : "(p. ex.) Vacances",
    "show_notes" : "Afficher les notes",
    "hide_notes" : "Masquer les notes",
    "additional_preferences" : "Préférences supplémentaires",
    "meeting_location" : "Lieu de la réunion",
    "appointment_status" : "Statut du rendez-vous",
    "appt_description" : "Description du rendez-vous",
    "recurring_details" : "Informations récurrentes",
    "event_description" : "Description de l'événement",
    "in_contact_timezone" : "Dans le fuseau horaire du contact",
    "vacation_message" : "Vous partez en vacances ? Vous prenez des congés ? Bloquez du temps dans votre agenda pour empêcher les clients de prendre rendez-vous ; les rendez-vous existants resteront dans votre calendrier.",
    "contact" : "Contact",
    "delete" : "Supprimer",
    "cancel" : "Annuler",
    "back" : "Retour",
    "pick_available_contacts" : "Ou choisir parmi les contacts disponibles",
    "no_contacts_found" : "Aucun contact trouvé",
    "found" : "trouvé",
    "select_one" : "Choisissez-en un",
    "tz_tooltip" : "Cela n'est important que si vous utilisez des valeurs personnalisées, telles que appointment_start_time appointment_end_time appointment_timezone, dans vos communications sortantes avec vos clients. Et il convertit simplement les informations susmentionnées en heure locale.",
    "meeting_location_tooltip" : "Le lieu de la réunion sera défini tel que configuré dans le calendrier.",
    "appt_meeting_location_tooltip" : "L'utilisateur peut définir le lieu de la réunion en fonction du rendez-vous.",
    "gmeet_tooltip" : "Assurez-vous que le membre d'équipe concerné dispose d'un agenda Google défini comme calendrier principal",
    "zoom_tooltip" : "Assurez-vous que le membre d'équipe concerné dispose bien de Zoom",
    "enter_meeting_location" : "Saisissez le lieu de la réunion",
    "save_appointment" : "Enregistrer le rendez-vous",
    "save_event" : "Enregistrer l'événement",
    "search_contacts" : "Rechercher par nom, e-mail, téléphone ou entreprise",
    "recurring_slots" : "{recurringSlotsLength}/{count}",
    "custom_timezone" : "Fuseau horaire personnalisé",
    "system_timezone" : "Fuseau horaire du système",
    "contact_timezone" : "Fuseau horaire du contact",
    "account_timezone" : "Fuseau horaire du compte",
    "an_error_occurred" : "Une erreur s'est produite"
  },
  "popup" : {
    "calendar" : "Calendrier",
    "appointment_owner" : "Propriétaire du rendez-vous",
    "source" : "Source",
    "booked_by" : "Réservé par",
    "view_activity_log" : "Afficher le journal d'activité",
    "status" : "Statut",
    "cancel_appointment" : "Annuler le rendez-vous",
    "cancel_all" : "Annuler tout",
    "google_event" : "Événement Google",
    "outlook_event" : "Événement Outlook",
    "blocked_slot" : "Créneau bloqué",
    "appointment" : "Rendez-vous",
    "confirmed" : "Confirmé",
    "unconfirmed" : "Non confirmé",
    "cancelled" : "Annulé",
    "showed" : "Honoré",
    "no_show" : "Non honoré",
    "invalid" : "Non valide",
    "more" : "Plus",
    "less" : "Moins",
    "edit" : "Modifier",
    "reschedule_all" : "Tout reporter",
    "guests" : "Invité(s)",
    "guest_count" : "Nombre d'invité(s)"
  },
  "create_calendar" : {
    "calendar_name" : "Nom du calendrier",
    "calendar_logo" : "Logo du calendrier",
    "select_team_member" : "Sélectionner les membres d'équipe",
    "eg_outbound_reach" : "(p. ex.) Couverture sortante",
    "description" : "Description",
    "amount" : "Montant",
    "description_placeholder" : "Rédiger une description",
    "meeting_duration" : "Durée de la réunion",
    "seats_per_class" : "Places par cours",
    "group" : "Groupe",
    "custom_url" : "URL personnalisée",
    "meeting_invite_title" : "Titre de l'invitation à la réunion",
    "event_color" : "Couleur de l'événement",
    "availability_type" : "Type de disponibilité",
    "new_calendar" : "Nouveau calendrier",
    "add_description" : "Ajouter une description",
    "remove_description" : "Supprimer la description",
    "booking_availability" : "Disponibilité pour les réservations",
    "slug_tooltip" : "Personnaliser le lien direct vers ce calendrier",
    "meeting_location_tooltip" : "Les liens de réunion sont affichés dans les événements du calendrier en tant que « lieu de réunion » et vous permettent d'ajouter des éléments tels que des liens Zoom.",
    "advanced_settings" : "Paramètres avancés",
    "reschedule_preference" : "Préférence de report",
    "reschedule_preference_subtext" : "Si un contact reporte un rendez-vous :",
    "reassign_through_round_robin" : "Réassigner via Round-robin",
    "keep_same_assinged_user" : "Conserver le même propriétaire du rendez-vous",
    "new_appointment_preference" : "Préférence des nouveaux rendez-vous",
    "new_appointment_preference_text" : "Toujours réserver avec l'utilisateur assigné au contact",
    "new_appointment_preference_subtext" : "Prenez de nouveaux rendez-vous avec l'utilisateur assigné au contact au lieu d'utiliser la planification Round-robin.",
    "cancel" : "Annuler",
    "confirm" : "Confirmer",
    "service_calendar_name" : "Nom du service",
    "service_logo" : "Logo du service",
    "service_select_team_member" : "Sélectionner un membre du personnel",
    "service_duration" : "Durée du service",
    "appointment_invite_title" : "Titre de l'invitation au rendez-vous",
    "payment_placeholder" : "Saisissez une description qui sera affichée à l'utilisateur pour l'informer du paiement.",
    "deposit_amount" : "Montant de l'acompte",
    "collect_deposit_amount" : "Accepter les paiements partiels",
    "percentage" : "Pourcentage",
    "total_amount" : "Montant total"
  },
  "calendar_advanced" : {
    "general" : {
      "quick_tip" : "Conseil pratique",
      "timezone_change_tip" : "Les fuseaux horaires peuvent être modifiés à tout moment dans les préférences de fuseau horaire",
      "save" : "Enregistrer",
      "close" : "Fermer",
      "meeting_details" : "Informations sur la réunion",
      "availability" : "Disponibilité",
      "forms_and_payment" : "Formulaires et paiement",
      "notifications_and_additional_options" : "Notifications et options supplémentaires",
      "connections" : "Connexions",
      "customizations" : "Personnalisations",
      "create" : "Créer",
      "edit" : "Modifier",
      "equipments" : "Salles et équipements",
      "service_details" : "Informations sur le service"
    },
    "meeting_details" : {
      "meeting_location" : "Lieu de la réunion",
      "enter_meeting_location" : "Saisissez le lieu de la réunion",
      "click_to_upload" : "Cliquez pour importer",
      "square" : "Carré",
      "circle" : "Cercle",
      "remove_logo" : "Supprimer le logo",
      "meeting_invite_title_tooltip" : "Il s'agit du titre de l'événement de calendrier qui s'affiche dans Google Agenda, le calendrier Outlook, Calendrier d'Apple, etc.",
      "event_color_tooltip" : "Choisissez une couleur pour cet événement de réservation. Les couleurs sont synchronisées avec les événements dans Google Agenda.",
      "enter_a_name" : "Veuillez saisir un nom",
      "enter_an_event_title" : "Veuillez saisir le titre de l'événement",
      "high_priority" : "Priorité élevée",
      "medium_priority" : "Priorité moyenne",
      "low_priority" : "Priorité basse",
      "custom" : "Personnalisée",
      "zoom_not_added" : "L'utilisateur n'a pas encore connecté son compte Zoom",
      "opt_avail" : "Optimiser la disponibilité",
      "opt_equal" : "Optimiser la répartition",
      "team_members" : "Sélectionner les membres d'équipe",
      "team_member" : "Sélectionner un membre d'équipe",
      "meeting_location_required" : "Le lieu de la réunion est obligatoire",
      "meeting_details" : "Informations sur la réunion",
      "meeting_details_description" : "Ces informations seront affichées sur la page Liste des calendriers.",
      "or_drag_and_drop" : "ou par glisser-déposer",
      "service_details" : "Informations sur le service",
      "team_member_disabled_recurring_tooltip" : "Un seul membre d'équipe est autorisé à participer aux réunions récurrentes. Pour ajouter plusieurs membres d'équipe, veuillez désactiver les réunions récurrentes.",
      "team_member_disabled_class_booking_tooltip" : "Un seul membre d'équipe peut être ajouté à un calendrier de réservation de cours",
      "advanced_settings" : "Paramètres avancés",
      "enable_contact_assigned_to_setting" : "Pour activer ce paramètre, le formulaire doit être le premier dans l'ordre des widgets. Veuillez réorganiser l'ordre des widgets à partir de l'onglet Formulaires et paiement.",
      "reschedule_preference_setting_tooltip" : "Choisissez à qui le rendez-vous doit être attribué lorsqu'un contact le reporte à partir du widget de réservation.",
      "appointment_preference_tooltip" : "Choisissez si les nouveaux rendez-vous doivent toujours être pris avec l'utilisateur assigné au contact. Si un utilisateur est assigné à un contact, c'est lui qui sera privilégié ; sinon, l'assignation se fera selon la méthode Round-robin. Conseil : Désactivez l'option « Autoriser la sélection du personnel » pour empêcher les personnes effectuant la réservation de modifier le membre du personnel sur le widget de réservation.",
      "add_location" : "Ajouter un lieu",
      "multiple_locations_neo" : "Les lieux de réunion multiples sont uniquement pris en charge par le widget Neo avec le formulaire par défaut.",
      "make_owner" : "Rendre propriétaire",
      "only_one_team_member" : "Un seul membre d'équipe est autorisé en cas d'ajout de plusieurs lieux.",
      "ask_the_booker_error_message" : "Le lieu Demander à l'auteur de la réservation existe déjà",
      "enter_location" : "Saisissez la valeur du lieu",
      "enter_display_label" : "Saisissez l'étiquette d'affichage",
      "add_display_label" : "Ajouter une étiquette d'affichage",
      "multiple_location_support_message" : "Les lieux de réunion multiples sont uniquement pris en charge par le widget Neo avec le formulaire par défaut.",
      "only_one_team_member_personal_calendar" : "Un seul membre d'équipe est autorisé à effectuer une réservation personnelle ",
      "ms_teams_not_added" : "L'utilisateur n'a pas encore connecté son compte Microsoft Teams"
    },
    "availability" : {
      "my_availability" : "Ma disponibilité",
      "set_availability" : "Définissez votre disponibilité pour le calendrier ici.",
      "standard" : "Standard",
      "custom" : "Personnalisée",
      "to" : "À",
      "copy_to_all" : "Copier à tous",
      "add_time" : "Ajouter une heure",
      "recurring_meeting" : "Réunion récurrente",
      "recurring_info" : "Un seul utilisateur par calendrier pour les réservations récurrentes",
      "repeat" : "Répétition",
      "times_to_repeat" : "Nombre de répétitions",
      "slots_unavailable" : "Si les créneaux ne sont pas disponibles ?",
      "overlap_status" : "Statut des rendez-vous pour les créneaux qui se chevauchent ",
      "meeting_interval" : "Intervalle entre les réunions",
      "meeting_duration" : "Durée de la réunion",
      "minimum_scheduling" : "Préavis de planification minimum",
      "date_range" : "Période",
      "maximum_bookings_per_day" : "Nombre maximum de réservations par jour",
      "maximum_bookings_per_slot" : "Nombre maximum de réservations par créneau",
      "maximum_bookings_per_slot_per_user" : "Nombre maximum de réservations par créneau (par utilisateur)",
      "seats_per_class" : "Places par cours",
      "pre_buffer_time" : "Tampon préalable",
      "post_buffer_time" : "Tampon final",
      "pre_buffer_tooltip" : "Le tampon préalable est un temps supplémentaire qui peut être ajouté avant un rendez-vous, ce qui permet de disposer de plus de temps pour se préparer",
      "post_buffer_tooltip" : "Le tampon final est un temps supplémentaire qui peut être ajouté après un rendez-vous, ce qui permet de disposer de plus de temps pour conclure",
      "buffer_time" : "Tampon",
      "daily" : "Quotidiennement",
      "weekly" : "Hebdomadaire",
      "monthly" : "Mensuellement",
      "skip_booking" : "Ignorer la réservation des créneaux non disponibles",
      "continue_booking" : "Continuer la réservation",
      "book_next_available" : "Réserver le prochain créneau disponible",
      "confirmed" : "Confirmé",
      "unconfirmed" : "Non confirmé",
      "enter_valid_hours" : "Veuillez saisir des heures d'ouverture valides",
      "enter_valid_avail" : "Veuillez saisir une disponibilité personnalisée valide",
      "availability_type_tooltip" : "Choisissez la disponibilité standard pour les réservations normales proposées chaque semaine. Choisissez Personnalisée pour les réservations uniquement disponibles à des dates spécifiques.",
      "meeting_interval_tooltip" : "L'intervalle entre les réunions correspond à la durée entre les créneaux de réservation qui seront affichés dans le calendrier. Pour un événement de 30 minutes qui doit être disponible au début de chaque heure, l'intervalle sera de 1 heure. Pour un événement de 30 minutes qui doit être disponible toutes les 30 minutes, l'intervalle sera de 30 minutes.",
      "date_range_tooltip" : "Les événements peuvent être programmés au cours des n jours à venir.",
      "maximum_bookings_per_day_tooltip" : " Nombre maximum de rendez-vous autorisés par jour sur ce calendrier.",
      "maximum_bookings_per_slot_tooltip" : "Nombre maximum de rendez-vous autorisés par créneau pour l'utilisateur (si le nombre de rendez-vous dépasse le créneau attribué à l'utilisateur, le système passe à l'utilisateur suivant dans la file d'attente ou marque le créneau comme ne pouvant pas être réservé par le prospect).",
      "add_days" : "Ajouter des jours",
      "month_on_day" : "Mensuellement le jour",
      "month_on_last_day" : "Mensuellement le dernier jour",
      "day" : "Jour",
      "week" : "Semaine",
      "month" : "Mois",
      "mins" : "Minutes",
      "hours" : "Heures",
      "days" : "Jours",
      "weeks" : "Semaines",
      "months" : "Mois",
      "meeting_interval_error" : "L'intervalle entre les réunions doit être compris entre 5 minutes et 12 heures",
      "meeting_duration_error" : "La durée de la réunion doit être comprise entre 1 min et 12 heures",
      "buffer_time_error" : "Le tampon doit être inférieur à 12 heures",
      "appointment_interval" : "Intervalle entre les rendez-vous",
      "appointment_duration" : "Durée du rendez-vous",
      "maximum_appointments_per_day" : "Nombre maximum de rendez-vous par jour",
      "subTitle" : "Choisissez la date à laquelle vous souhaitez définir des heures précises",
      "heading_1" : "Heures hebdomadaires disponibles",
      "tooltip_1" : "Établissez votre disponibilité hebdomadaire. Il s'agit de vos heures standard qui seront appliquées chaque semaine",
      "heading_2" : "Quand êtes-vous disponible ?",
      "unavailable_label" : "Non disponible",
      "tooltip_2" : "Adaptez votre emploi du temps à des dates précises. Remplacez vos heures hebdomadaires normales en indiquant votre disponibilité ou votre indisponibilité pour les dates sélectionnées.",
      "date_specific_level" : "Ajouter des heures précises",
      "specific_hours_button" : "Heures précises",
      "overlap_error" : "Les créneaux ne peuvent pas se chevaucher",
      "apply" : "Appliquer",
      "copy_times_to" : "Copier les heures vers",
      "no_date_specific_heading" : "Aucune heure précise ajoutée",
      "no_date_specific_description" : "Vous pouvez ajouter une date et une heure précises à votre disponibilité ou les supprimer",
      "recurring_disabled_conditions_tooltip" : "Les réunions récurrentes sont désactivées. Pour les activer, assurez-vous que le calendrier compte un seul membre d'équipe et qu'aucune heure précise n'a été ajoutée.",
      "date_specific_hours_disabled_conditions_tooltip" : "Pour ajouter une heure précise, assurez-vous que les réunions récurrentes sont désactivées.",
      "recurring_disable_event_calendar_conditions" : "Les réunions récurrentes sont désactivées. Pour les activer, assurez-vous qu'aucune heure précise n'a été ajoutée.",
      "service_duration" : "Durée du service",
      "service_interval" : "Intervalle entre les services",
      "service_interval_tooltip" : "L'intervalle entre les réunions définit la fréquence à laquelle les créneaux de réservation apparaissent dans le calendrier. Par exemple, pour un événement de 30 minutes, un intervalle de 1 heure signifie que les créneaux apparaissent toutes les heures, tandis qu'un intervalle de 30 minutes signifie que les créneaux apparaissent toutes les 30 minutes.",
      "service_duration_tooltip" : "Cela définit la durée de votre rendez-vous.",
      "minimum_scheduling_tooltip" : "Cela définit le temps minimum requis pour réserver un rendez-vous via le widget de calendrier. Par exemple, si le paramètre est fixé à 4 heures, les réservations doivent être effectuées au moins 4 heures avant le début du rendez-vous.",
      "look_busy_title" : "Avoir l'air occupé(e)",
      "look_busy_helper_text" : "Masquez un pourcentage de vos créneaux disponibles sur le widget de réservation afin d'apparaître plus occupé(e) et plus demandé(e).",
      "look_busy_percentage_description" : "Masquer le nombre de créneaux disponibles de x %",
      "maximum_bookings_per_slot_tooltip_personal" : "Nombre maximum de rendez-vous autorisés par créneau pour l'utilisateur"
    },
    "forms_payment" : {
      "forms" : "Formulaires",
      "forms_sub" : "Définissez les préférences du formulaire de post-planification.",
      "select_form" : "Sélectionner un formulaire",
      "enable_same_user_assignment" : "Assigner les contacts aux membres d'équipe chargés du calendrier concerné à chaque fois qu'un rendez-vous est pris",
      "sticky_contacts" : "Pré-remplir les champs (contacts permanents)",
      "confirmation_page" : "Page de confirmation",
      "default" : "Par défaut",
      "redirect_url" : "URL de redirection",
      "thank_you" : "Message de remerciement",
      "enter_url" : "Saisissez une URL",
      "pixel_id" : "ID de pixel Facebook (facultatif)",
      "auto_confirm" : "Confirmer automatiquement les nouvelles réunions du calendrier",
      "configure_payment" : "Configurer les options de paiement",
      "accept_payments" : "Accepter les paiements",
      "test" : "Test",
      "live" : "En direct",
      "enable_guests" : "Ajouter des invités",
      "add_guest_tooltip" : "Ajouter un invité permettra au participant d'ajouter plusieurs participants au même rendez-vous",
      "add_guest_custom_form_tooltip" : "Si vous activez cette option, les invités recevront un e-mail généré par le système avec un lien vers le formulaire personnalisé une fois leur rendez-vous confirmé. Pour éviter les e-mails en double, assurez-vous qu'aucun e-mail de confirmation distinct n'est déjà configuré pour les invités. (Vérifiez cela dans l'onglet Notifications > Notification de confirmation.)",
      "add_guest_same_form_check" : "Envoyer le même formulaire de collecte d'informations par courrier à tous les invités",
      "payment_tooltip" : "Le paiement sera pris en compte seulement pour le participant principal et non pour les invités.",
      "default_form" : "Par défaut (prénom, nom, e-mail, téléphone, notes)",
      "select_form_tooltip" : "Si vous souhaitez collecter plus d'informations que le prénom, le nom, l'e-mail et le numéro de téléphone auprès des personnes qui effectuent une réservation sur ce calendrier, créez un formulaire personnalisé dans Sites > Formulaires > Générateur, puis sélectionnez le formulaire dans la liste déroulante.",
      "confirmation_page_tooltip" : "Lorsque quelqu'un effectue une réservation, vous pouvez choisir de lui afficher un message personnalisé sur la même page (par défaut) ou de le rediriger vers une page de votre choix (redirection).",
      "add_guest_collect_email" : "Nom et e-mail",
      "add_guest_count_only" : "Nombre seulement",
      "custom_email_notification" : "Envoyer un formulaire personnalisé aux invités",
      "consent_label" : "Case de consentement",
      "consent_input_placeholder" : "Ajouter un texte de consentement",
      "same_user_assignment_tooltip" : "Pour activer ce paramètre, le formulaire doit apparaître en premier sur le widget. Réorganisez le widget pour positionner le formulaire en premier afin d'activer ce paramètre.",
      "consent_tooltip" : "Activez cette option pour autoriser l'obtention du consentement des contacts lorsqu'ils fournissent leur numéro de téléphone lors de la prise de rendez-vous.",
      "stripe_error" : "Jusqu'à deux décimales (exemple : 25,99)",
      "auto_confirm_service" : "Confirmer automatiquement les nouveaux rendez-vous",
      "service_form_message" : "Si un contact réserve plusieurs services, il verra uniquement le formulaire par défaut dans le menu de services",
      "deposit_amount_error" : "Le montant de l'acompte doit être inférieur au montant total.",
      "deposit_percentage_error" : "Le pourcentage d'acompte doit être inférieur à 100 %.",
      "partial_payment_tooltip" : "Activez les paiements partiels pour collecter le montant de l'acompte auprès des clients pendant le processus de prise de rendez-vous.",
      "partial_payment_enable_error" : "Le montant total saisi doit être supérieur à 0 pour permettre les paiements partiels.",
      "payment_disable_text_integration" : "Intégrez un fournisseur de services de paiement sous Paiements > Intégrations pour commencer à accepter les paiements",
      "payment_disable_text_recurring" : "L'option Accepter les paiements n'est pas disponible pour les réunions récurrentes. Désactivez le mode récurrent pour activer les paiements.",
      "date_and_time_selector" : "Sélecteur de date et d'heure",
      "form_selector" : "Formulaire",
      "order_step" : "Étape",
      "widget_order_label" : "Ordre des widgets",
      "widget_order_tool_tip" : "Réorganisez l'ordre du « Sélecteur de date et d'heure » et du « Formulaire » dans le widget de calendrier. Il vous suffit de faire glisser et de réorganiser selon vos préférences.",
      "widget_re_order_tooltip" : "La réorganisation est disponible uniquement avec le style de widget Neo. Passer à Neo pour activer la réorganisation.",
      "widget_re_order_disabled_tooltip" : "Pour réorganiser l'ordre des widgets, désactivez « Préférence des nouveaux rendez-vous (réservez toujours avec l'utilisateur assigné au contact) » dans la section Informations sur la réunion > Membres d'équipe > Configuration avancée.",
      "payment_description_error" : "Limite maximale de 1 000 caractères atteinte",
      "widget_multiple_location_disabled_tooltip" : "Le widget Classic ne prend pas en charge plusieurs lieux de réunion. Veuillez supprimer tous les lieux supplémentaires pour utiliser ce widget",
      "recurring_payment_label" : "Paiement des rendez-vous récurrents",
      "first_appointment_only" : "Premier rendez-vous seulement",
      "all_appointments" : "Tous les rendez-vous"
    },
    "notifications" : {
      "notifications" : "Notifications et options supplémentaires",
      "notifications_sub" : "Configurer les notifications et les options supplémentaires",
      "select_notification_type" : "Sélectionner le type de notification",
      "notification_person" : "Qui doit recevoir cette notification ?",
      "contact" : "Contact",
      "guest" : "Invité",
      "assigned_user" : "Utilisateur assigné",
      "emails" : "E-mails",
      "alert_email_address" : "Adresse e-mail d'alerte",
      "ack_email" : "E-mail d'accusé de réception",
      "send_google_invites" : "Autorisez Google Agenda à envoyer des invitations aux participants ou à mettre à jour des e-mails.",
      "send_google_outlook_invites" : "Autorisez Google Agenda ou Outlook à envoyer des invitations aux participants ou à mettre à jour des e-mails.",
      "assign_user_to_contact" : "Assigner les contacts aux membres d'équipe chargés du calendrier concerné à chaque fois qu'un rendez-vous est pris",
      "skip_assigning_user_to_contact" : "Ignorer l'assignation d'un contact si un utilisateur lui est déjà assigné",
      "assigned_staff" : "Personnel assigné",
      "additional_emails" : "E-mails supplémentaires",
      "assign_user_to_staff" : "Assigner les contacts aux membres d'équipe chargés du service concerné à chaque fois qu'un rendez-vous est pris",
      "allow_cancellation" : " Autoriser l'annulation de la réunion",
      "allow_reschedule" : "Autoriser le report de la réunion",
      "allow_cancellation_tooltip" : "Lorsque cette option est activée, un lien d'annulation est ajouté à la section des notes supplémentaires et inclus dans l'invitation au calendrier. Ce lien expirera avant l'heure de début de la réunion, ce qui empêchera l'auteur de la réservation d'annuler la réunion.",
      "allow_reschedule_tooltip" : " Lorsque cette option est activée, un lien de report est ajouté à la section des notes supplémentaires et inclus dans l'invitation au calendrier. Ce lien expirera avant l'heure de début de la réunion, ce qui empêchera l'auteur de la réservation de reporter la réunion.",
      "cancellation_link_expiry" : "Le lien d'annulation expirera",
      "reschedule_link_expiry" : "Le lien de report expirera",
      "before_meeting" : "avant la réunion",
      "cancellation_and_reschedule_policy" : "Politique d'annulation et de report :"
    },
    "connections" : {
      "connections_heading" : "Synchronisation du calendrier externe",
      "connections_sub" : "Synchroniser les événements avec un calendrier externe",
      "link_to_calendar" : "Lien vers le calendrier",
      "sync_option" : "Option de synchronisation",
      "execute_automation" : "Exécuter l'automatisation",
      "fire_workflow" : "Déclencher des flux de travail pour les événements réservés via la synchronisation bidirectionnelle",
      "reserve_with_google" : "Réserver avec Google",
      "connect_lsa" : "Se connecter à votre compte d'annonces de services locaux",
      "reserve_error" : "Actuellement, Réservez avec Google est uniquement pris en charge aux États-Unis pour les secteurs suivants : « services à domicile », « immobilier », « services juridiques » (fournisseurs de services locaux).",
      "one_way" : "Unidirectionnel",
      "two_way" : "Bidirectionnel",
      "smart" : "Intelligent",
      "confirm_remove" : "Voulez-vous vraiment supprimer Google Agenda ?",
      "read_only_access" : "Vous n'avez pas accès en écriture à ce calendrier. Veuillez sélectionner un autre calendrier.",
      "reserve_with_google_desc" : "Ce service de calendrier sera inscrit dans Réserver avec Google uniquement si l'intégration est réussie.",
      "check_integration" : "Vérifier le statut de votre intégration",
      "setup_external" : "Configurer votre calendrier externe",
      "reserve_price_decimal_error" : "Le prix décimal n'est pas pris en charge",
      "reserve_price_max_error" : "Prix pris en charge inférieur à 1 000"
    },
    "extras" : {
      "extras" : "Personnalisations",
      "extras_sub" : "Définissez le style du widget et d'autres préférences.",
      "calendar_widget_style" : "Style du widget de calendrier",
      "neo" : "Neo",
      "classic" : "Classic",
      "insert_custom_code" : "Insérer du code personnalisé",
      "show_seats_per_slot" : "Afficher les places par créneau",
      "allow_reschedule_meeting" : "Autoriser le report de la réunion",
      "allow_cancelling_meeting" : "Autoriser l'annulation de la réunion",
      "additional_notes" : "Notes supplémentaires",
      "cancellation_and_reschedule" : "Annulation et report :",
      "allow_staff" : "Autoriser la sélection du personnel lors de la réservation",
      "code_block_tooltip" : "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités intéressantes, mais ils présentent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Nous vous recommandons donc de vérifier le contenu de ce script avant de l'enregistrer dans le calendrier.",
      "calendar_image" : "Image de couverture du calendrier",
      "remove_image" : "Supprimer l'image",
      "calendar_image_info" : "L'image importée sera visible dans la Vue de groupe pour le modèle Neo et n'apparaîtra pas sur le lien du calendrier concerné",
      "get_image_from_unsplash" : "Améliorez vos visuels avec",
      "unsplash" : "Unsplash",
      "service_additional_preferences" : "Configurer des préférences supplémentaires",
      "service_image" : "Image de couverture du service",
      "service_image_description" : "Sélectionnez une image qui sera affichée dans le menu de services pour ce service",
      "allow_reschedule_appointment" : "Autoriser le report du rendez-vous",
      "allow_cancelling_appointment" : "Autoriser l'annulation du rendez-vous",
      "custom_code_placeholder" : "Saisissez le code personnalisé ici",
      "calendar_widget_style_desc" : "Choisissez entre notre widget Classic ou l'élégant widget Neo",
      "seats_per_slot" : "Places par créneau",
      "show_staff_selection" : "Sélection du personnel",
      "seats_per_slot_desc" : " Permettez aux auteurs de réservation de voir le nombre de places disponibles pour chaque créneau directement sur le widget de calendrier.",
      "show_staff_selection_desc" : "Permettez aux auteurs de réservation de choisir un membre du personnel directement à partir du widget de calendrier lors de la prise de rendez-vous. Fonctionne uniquement avec le widget Neo",
      "widget_re_order_tooltip" : "Le style de widget Classic ne permet pas de modifier l'ordre des widgets. Assurez-vous que le Sélecteur de date et d'heure est positionné en premier et que le Formulaire est en second avant de sélectionner le style Classic.",
      "show_staff_selection_desc_service" : "Permettez aux auteurs de réservation de choisir un membre du personnel directement à partir du widget de calendrier lors de la prise de rendez-vous."
    },
    "roomsAndEquipments" : {
      "title" : "Sélectionner des salles et des équipements",
      "description" : "Sélectionnez dans une liste de salles et d'équipements ce qui sera réservé dans ce calendrier",
      "rooms" : "Sélectionner des salles",
      "equipments" : "Sélectionner des équipements"
    }
  },
  "activity_log" : {
    "activity_log" : "Journal d'activité",
    "date" : "Date",
    "filter_by" : "Filtrer par",
    "calendar_update" : "Mise à jour du calendrier",
    "start_time_update" : "Mise à jour de l'heure de début",
    "end_time_update" : "Mise à jour de l'heure de fin",
    "title_update" : "Mise à jour du titre",
    "notes_update" : "Mise à jour des notes",
    "appointment_status_update" : "Mise à jour du statut du rendez-vous",
    "meeting_location_update" : "Mise à jour du lieu de la réunion",
    "google_sync_update" : "Mise à jour de la synchronisation Google",
    "outlook_sync_update" : "Mise à jour de la synchronisation Outlook",
    "assigned_user_update" : "Mise à jour de l'utilisateur assigné",
    "appointment_status" : "Statut du rendez-vous",
    "assigned_user" : "Utilisateur assigné",
    "uploaded_to" : "Importé vers",
    "cancelled" : "Annulé",
    "edited" : "modifié",
    "other_edits" : "Autres modifications",
    "view_details" : "Afficher les informations",
    "all" : "Tout"
  },
  "appointment_list" : {
    "group_all" : "TOUT",
    "group_primary" : "PRINCIPAL",
    "group_user" : "UTILISATEURS",
    "group_team" : "GROUPES",
    "group_calendar" : "CALENDRIERS",
    "all_appointments" : "Tous les rendez-vous",
    "my_calendar" : "Mon calendrier",
    "status_all" : "Tout",
    "status_confirmed" : "Confirmé",
    "status_new" : "Nouveau (action requise)",
    "status_show" : "Honoré",
    "status_no_show" : "Non honoré",
    "status_cancelled" : "Annulé",
    "status_invalid" : "Non valide",
    "filter_date_added_desc" : "Date d'ajout (desc)",
    "filter_start_added_desc" : "Date de début (desc)",
    "filter_start_added_asc" : "Date de début croissante à partir d'aujourd'hui",
    "button_new_appointment" : "Nouveau rendez-vous",
    "name" : "Nom",
    "title" : "Titre",
    "requested_time" : "Heure demandée",
    "date_added" : "Date d'ajout",
    "calendar" : "Calendrier",
    "appointment_owner" : "Propriétaire du rendez-vous",
    "notes" : "Notes",
    "status" : "Statut",
    "action_view_detail" : "Afficher les informations",
    "action_view_consent" : "Afficher le consentement",
    "action_view_notes" : "Afficher les notes",
    "action_edit" : "Modifier",
    "action_delete" : "Supprimer",
    "action_reschedule" : "Reporter",
    "page_previous" : "Précédent",
    "page_next" : "Suivant",
    "appointment_notes" : "Notes du rendez-vous",
    "close" : "Fermer",
    "confirm_delete_header" : "Supprimer le rendez-vous",
    "confirm_delete_message" : "Voulez-vous vraiment supprimer ce rendez-vous ? Cette action est irréversible !",
    "confirm_delete_button" : "Supprimer",
    "confirm_update_header" : "Mettre à jour le statut du rendez-vous",
    "confirm_update_confirmed_message" : "Voulez-vous vraiment marquer ce rendez-vous comme confirmé ?",
    "confirm_update_invalid_message" : "Voulez-vous vraiment marquer ce rendez-vous comme non valide ?",
    "confirm_update_showed_message" : "Voulez-vous vraiment marquer ce rendez-vous comme honoré ?",
    "confirm_update_noshow_message" : "Voulez-vous vraiment marquer ce rendez-vous comme non non honoré ?",
    "confirm_update_cancelled_message" : "Voulez-vous vraiment marquer ce rendez-vous comme annulé ?",
    "confirm_update_button" : "Mettre à jour",
    "empty_state_title" : "Aucun rendez-vous trouvé",
    "empty_state_description" : "Aucun rendez-vous trouvé",
    "disable_menu_warning" : "Actuellement, vous n'avez pas l'autorisation d'afficher ou de modifier les informations relatives au rendez-vous, car le contact associé ne vous a pas été assigné. Veuillez contacter votre administrateur pour obtenir de l'aide.",
    "no_contact_warning" : "Vous ne pouvez pas accéder aux coordonnées pour le moment. Cela peut être dû au fait que le contact ne vous est pas assigné. Veuillez contacter votre administrateur pour obtenir de l'aide.",
    "consent_label" : "Consentement",
    "ip_address" : "IP",
    "youre_viewing_in" : "Actuellement affiché dans",
    "appointment_delete_msg" : "<b>Remarque :</b> Le rendez-vous supprimé ici ne sera pas supprimé de calendly.",
    "rescheduled" : "Reporté"
  },
  "add_guest" : {
    "guest_label" : "Invité(s)",
    "search_placeholder" : "Rechercher parmi les contacts",
    "add_new" : "Ajouter",
    "save" : "Enregistrer",
    "close" : "Fermer",
    "name" : "Nom",
    "phone" : "Téléphone",
    "email" : "E-mail",
    "guest_label_count" : "Nombre d'invité(s)",
    "guest_count_error" : "Le nombre d'invités doit être compris entre 1 et 100"
  },
  "languages" : {
    "english" : "Anglais",
    "dutch" : "Néerlandais",
    "french" : "Français",
    "german" : "Allemand",
    "hungarian" : "Hongrois",
    "italian" : "Italien",
    "polish" : "Polonais",
    "portuguese_brazil" : "Portugais (Brésil)",
    "portuguese_portugal" : "Portugais (Portugal)",
    "spanish" : "Espagnol",
    "danish" : "Danois"
  },
  "service_menu_advanced" : {
    "new_service_menu_details_header" : "Informations sur le menu de services",
    "select_groups_header" : "Sélectionner des services",
    "arrange_groups_header" : "Organiser vos services",
    "new_service_menu" : "Nouveau menu de services",
    "service_menu_sub_title" : "Créez un menu de services personnalisé pour vos clients en quelques clics",
    "quick_tip" : "Vous pouvez ajouter des groupes au menu de services",
    "service_menu_name" : "Nom du menu de services",
    "service_menu_description" : "Description",
    "service_menu_slug" : "URL du service",
    "select_groups_calendars" : "Sélectionner des groupes et des calendriers",
    "select_groups_calendars_desc" : "Ajouter des services à votre menu de services",
    "select_all" : "Sélectionner tout",
    "arrange_groups" : "Organiser l'ordre des services",
    "arrange_groups_desc" : "Déterminer l'ordre des services dans le menu de services",
    "arrange_services_quicktip" : "Pour réorganiser les calendriers au sein d'un groupe, accédez à Groupes > Options > Réorganiser les calendriers.",
    "enable_add_guest_desc" : "Permet aux clients d'inclure des invités supplémentaires lors de la réservation.",
    "enable_multiple_service_desc" : "Permet aux clients de réserver plusieurs services en un seul rendez-vous.",
    "enable_staff_selection_desc" : "Permet aux clients de sélectionner les membres du personnel qu'ils préfèrent lorsqu'ils effectuent une réservation.",
    "enable_add_guest" : "Activer l'ajout d'invités",
    "enable_multiple_service" : "Activer la sélection de plusieurs services",
    "enable_staff_selection" : "Activer la sélection du personnel",
    "additional_options" : "Options supplémentaires",
    "code_block_tooltip" : "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités intéressantes, mais ils présentent également des risques pour la confidentialité, la sécurité, les performances et le comportement de la page. Nous vous recommandons donc de vérifier le contenu de ce script avant de l'enregistrer dans le menu de services.",
    "custom_code_placeholder" : "Saisissez le code personnalisé ici",
    "insert_custom_code" : "Insérer du code personnalisé",
    "no_service" : "Aucun service trouvé",
    "no_service_desc" : " Aucun service trouvé. Créez des services et ajoutez-les à des groupes pour créer un menu de services.",
    "learn_more" : "En savoir plus",
    "no_service_selected" : "Aucun service sélectionné",
    "create_service_calendars" : "Créer des calendriers de service",
    "alert_description" : " Pour créer un menu de services, vous devez créer des calendriers de service et les ajouter à des groupes",
    "dont_show_again" : "Ne plus afficher",
    "service_name_required" : "Le nom du menu de services est obligatoire",
    "slug_already_taken" : "Ce slug est déjà pris",
    "slug_required" : "Le slug est obligatoire",
    "please_enter_name" : "Veuillez saisir un nom",
    "service_name_placeholder" : "Menu principal",
    "consent_label" : "Case de consentement",
    "consent_input_placeholder" : "Ajouter un texte de consentement",
    "consent_tooltip" : "Activez cette option pour autoriser l'obtention du consentement des contacts lorsqu'ils fournissent leur numéro de téléphone lors de la prise de rendez-vous."
  },
  "widget_configuration" : {
    "customise_calendar_widget" : "Personnaliser le widget de calendrier",
    "works_with_neo_widget" : "Fonctionne uniquement avec le widget Neo",
    "customise_widget_desc" : "Personnalisez l'apparence du widget : couleur principale, couleur d'arrière-plan et texte des boutons.",
    "learn_more" : "En savoir plus",
    "primary_settings" : "Paramètres principaux",
    "primary_settings_desc" : "Configurez la couleur principale, la couleur d'arrière-plan et d'autres préférences du widget pour personnaliser votre widget de calendrier.",
    "primary_color" : "Couleur principale",
    "primary_color_desc" : "Définissez la couleur des boutons, des dates, des créneaux et des actions.",
    "background_color" : "Couleur d'arrière-plan",
    "background_color_desc" : "Définissez la couleur d'arrière-plan qui sert de toile de fond au sélecteur de date et d'heure et au formulaire.",
    "button_text" : "Texte du bouton",
    "button_text_desc" : "Personnalisez le texte affiché sur le bouton lors de la prise de rendez-vous.",
    "calendar_title" : "Titre du calendrier",
    "calendar_title_desc" : "Le nom de votre calendrier",
    "calendar_description" : "Description du calendrier",
    "calendar_description_desc" : "La description saisie",
    "calendar_details" : "Informations sur le calendrier",
    "calendar_details_desc" : "Comprend des informations telles que la durée, la date et l'heure, les informations récurrentes et le fuseau horaire",
    "reset_to_default" : "Réinitialiser les paramètres par défaut",
    "preview_widget" : "Aperçu du widget"
  },
  "eventNotification" : {
    "customFields" : "Custom Fields",
    "customValues" : "Custom Values",
    "list" : {
      "booked" : {
        "title" : "Appointment Booked (Status: Unconfirmed)",
        "note" : "This notification is sent when a new appointment is created with Unconfirmed status.",
        "subTitle" : "Informe qu'un rendez-vous a été pris mais qu'il n'est pas confirmé"
      },
      "confirmation" : {
        "title" : "Appointment Booked (Status: Confirmed)",
        "note" : "This notification is sent when an appointment is created with or updated to the Confirmed status.",
        "subTitle" : "Informe qu'un rendez-vous est confirmé"
      },
      "cancellation" : {
        "title" : "Cancellation",
        "note" : "This notification is sent when an appointment is cancelled, marked as no-show, or marked as invalid.",
        "subTitle" : "Informe qu'un rendez-vous est annulé"
      },
      "reschedule" : {
        "title" : "Reschedule",
        "note" : "This notification is sent when an appointment is rescheduled. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Informe qu'un rendez-vous est reporté"
      },
      "reminder" : {
        "title" : "Reminder",
        "note" : "This notification is sent as a reminder before the appointment. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Envoie un rappel avant le rendez-vous"
      },
      "followup" : {
        "title" : "Follow-Up",
        "note" : "This notification is sent after the appointment as a follow-up. It will only be triggered if the appointment is in Unconfirmed, Confirmed, or Showed status.",
        "subTitle" : "Envoie un message de suivi après la fin du rendez-vous"
      }
    },
    "saveToastMsg" : "Paramètres enregistrés !",
    "saveToastMsgDesc" : "Les modifications ont été enregistrées",
    "enableSuccess" : "Notifications activées !",
    "disableSuccess" : "Notifications désactivées !",
    "tooltip" : "This notification has been disabled. Please enable it to make any changes.",
    "enabled" : "Enabled",
    "disabled" : "Disabled",
    "statusLabel" : "Status Labels",
    "email" : {
      "channelName" : "Email",
      "noneTemplate" : "Aucune",
      "notificationLabel" : "Qui doit recevoir cette notification ?",
      "sendButtonLabel" : "Envoyer un e-mail de test",
      "testEmailLabel" : "E-mail de test",
      "testEmailPlaceholder" : "Saisissez l'adresse e-mail du destinataire pour le test",
      "body" : "Corps de l'e-mail",
      "bodyPlaceholder" : "Saisissez votre message ici",
      "subject" : "Objet",
      "subjectPlaceholder" : "Saisissez l'objet de l'e-mail",
      "templateLabel" : "Modèle d'e-mail",
      "templatePlaceholder" : "Sélectionnez un modèle d'e-mail ou partez de zéro",
      "alertEmailAddress" : "Adresse e-mail d'alerte",
      "addMore" : "Ajouter plus",
      "afterAppointmentEnds" : "après la fin du rendez-vous",
      "beforeAppointmentStarts" : "avant le début du rendez-vous",
      "durationLabel" : "Quand souhaitez-vous déclencher la notification ?",
      "disableEmailNotification" : "Désactiver les notifications par e-mail",
      "enableEmailNotification" : "Activer les notifications par e-mail",
      "enableSuccess" : "Notification par e-mail activée !",
      "enableSuccessDesc" : "Vous allez à présent recevoir cette notification",
      "disableSuccess" : "Notification par e-mail désactivée !",
      "disableSuccessDesc" : "Vous ne recevrez plus cette notification"
    },
    "inApp" : {
      "channelName" : "In-app",
      "title" : "Paramètres de notification",
      "enableInAppNotification" : "Enable In-App Notification",
      "disableInAppNotification" : "Disable In-App Notification",
      "selectedUser" : {
        "label" : "Envoyer à",
        "helperText" : "Cette notification interne est envoyée via le Web et les applications mobiles",
        "placeholder" : "Sélectionnez un ou plusieurs utilisateurs"
      },
      "enableSuccess" : "Notifications dans l'application activées !",
      "enableSuccessDesc" : "Vous allez à présent recevoir cette notification",
      "disableSuccess" : "Notifications dans l'application désactivées !",
      "disableSuccessDesc" : "Vous ne recevrez plus cette notification"
    }
  }
}