<script setup lang="ts">
import AppState from '@/states/app'
import { UICard, UIHeader, UITabPane, UITabs } from '@gohighlevel/ghl-ui'
import { computed, ref, watchEffect } from 'vue'
import MyAvailability from '../components/Availability/MyAvailability.vue'
import AccessDenied from '@/components/common/AccessDenied.vue'
const tabType = ref('my-availability')

const isUserPartOfLocation = computed(() => {
  return !!AppState?.isUserPartOfLocation
})

watchEffect(() => {
  if (!isUserPartOfLocation.value) {
    tabType.value = 'account-availability'
  }
})
</script>

<template>
  <div class="p-8">
    <UIHeader
      id="calendar-availability-settings-headers"
      :title="$t('settings.availability.availability')"
    />
    <UITabs v-if="isUserPartOfLocation" v-model:value="tabType" type="line">
      <UITabPane
        name="my-availability"
        :tab="$t('settings.availability.my_availability')"
        class="mt-6"
      >
        <MyAvailability />
      </UITabPane>
    </UITabs>
    <div v-else>
      <UICard class="h-auto">
        <AccessDenied
          :header="$t('settings.availability.accessRestricted.header')"
          :description="$t('settings.availability.accessRestricted.message')"
        />
      </UICard>
    </div>
  </div>
</template>
