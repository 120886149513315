{
  "settings" : {
    "calendar" : {
      "calendar_settings" : "Paramètres du calendrier",
      "select_calendar_type" : "Sélectionne le type de calendrier",
      "add_new_calendar_group" : "Ajouter un nouveau groupe de calendrier",
      "add_new_equipment" : "Ajoute un nouvel équipement",
      "new_calendar" : "Nouveau calendrier",
      "calendar_group_description" : "Utilise des groupes de calendriers pour organiser et regrouper efficacement plusieurs calendriers.",
      "group" : "Groupe",
      "manage_your_calendars_and_groups" : "Gérer vos calendriers et vos groupes",
      "groups" : "Groupes",
      "create_group" : "Créer un groupe",
      "create_calendar" : "Créer un calendrier",
      "calendar_name" : "Nom du calendrier",
      "group_duration" : "Durée du groupe",
      "calendar_type" : "Type de calendrier",
      "action" : "Action",
      "round_robin" : "Tour de table",
      "event" : "Événement",
      "group_name" : "Nom du groupe",
      "room_name" : "Nom de la salle",
      "group_description" : "Description du groupe",
      "calendar_url" : "URL du calendrier",
      "calendars" : "Calendriers",
      "date_updated" : "Date de mise à jour",
      "status" : "Statut",
      "action_dropdown" : "Liste déroulante d’action",
      "calendar_list" : "Services associés à",
      "edit" : "Modifier",
      "duplicate" : "Dupliquer",
      "copy_embed_code" : "Copier le code d'intégration",
      "copy_permanent_link" : "Copie le lien permanent",
      "copy_new_slug_link" : "Copier le lien de planification",
      "copy_scheduling_link" : "Copier le lien de planification (héritage obsolète)",
      "deactivate_calendar" : "Désactiver le calendrier",
      "move_to_group" : "Déplacer vers le groupe",
      "delete_calendar" : "Supprimer le calendrier",
      "delete_group" : "Supprimer",
      "delete_this_group" : "Supprime ce groupe",
      "activate_calendar" : "Active le calendrier",
      "duration" : "Durée (minutes)",
      "all" : "Tous",
      "draft" : "Ébauche",
      "active" : "Actif",
      "rearrange_calendars" : "Réorganiser les calendriers",
      "deactivate_all_calendars_in_group" : "Désactive tous les calendriers du groupe",
      "activate_all_calendars_in_group" : "Active tous les calendriers dans le groupe",
      "no_groups_found" : "Votre recherche ne correspondait à aucun groupe de calendrier.",
      "no_groups_in_location" : "Commence à utiliser des groupes en créant un nouveau groupe de calendrier.",
      "no_calendars_found" : "Commence à utiliser le calendrier en créant un nouveau calendrier.",
      "no_calendars_found_search" : "Votre recherche ne correspondait à aucun calendrier.",
      "delete_calendar_message" : "Vous êtes sur le point de supprimer le calendrier. Lorsque vous cliquez sur supprimer, tous les rendez-vous sont également supprimés.",
      "delete_calendar_message_title" : "Supprimer ce calendrier?",
      "duplicate_calendar_message" : "Vous êtes sur le point de dupliquer le calendrier. Cela créera un nouveau calendrier avec les mêmes paramètres que le calendrier sélectionné.",
      "duplicate_calendar_message_title" : "Dupliquer ce calendrier ?",
      "no_calendars_found_title" : "Aucun calendrier trouvé",
      "no_groups_in_location_title" : "Aucun groupe trouvé",
      "delete_group_confirmation_title" : "Supprimer ce groupe ?",
      "delete_group_confirmation" : "Es-tu sûr de vouloir supprimer le groupe sélectionné?",
      "delete_group_confirmation_checkbox_title" : "Supprime les calendriers et les rendez-vous associés",
      "delete_group_confirmation_checkbox_description" : "En sélectionnant cette option, tu supprimeras également les calendriers de ce groupe, ainsi que tous les rendez-vous de ces calendriers.",
      "group_status_message_title" : "{status} ce groupe ?",
      "calendar_status_message_title" : "{status} ce calendrier ?",
      "calendar_status_message" : "Vous êtes sur le point de {status} Calendrier",
      "group_status_message" : "Vous êtes sur le point de {status} Groupe",
      "coming_soon" : "Bientôt disponible",
      "select" : "Sélectionner",
      "15mins" : "15 min",
      "cancel" : "Annuler",
      "save" : "Enregistrer",
      "create" : "Créer",
      "select_calendar_group" : "Sélectionner Groupe de calendrier",
      "move_calendar_confirmation" : "Veuillez noter que le lien hérité cessera de fonctionner une fois que vous aurez procédé au changement. Veuillez confirmer en cochant la case.",
      "previously_called_unassigned" : " (Précédemment appelé non attribué)",
      "scheduling_type" : "Type de planification",
      "scheduling_type_description" : "Choisis un type de planification pour ton nouveau calendrier",
      "service_menu_name" : "Nom du menu de service",
      "template" : "Modèle",
      "group_url" : "URL du groupe",
      "template_description" : "Vous avez maintenant le choix de sélectionner les modèles Classic ou Neo pour la vue Groupe",
      "rooms" : "Pièces",
      "rooms_name" : "Nom",
      "rooms_description" : "Description",
      "rooms_modal_description" : "Ajouter ou modifier une salle",
      "rooms_capacity" : "Capacité totale",
      "no_rooms_in_location_title" : "Aucune pièce trouvée",
      "no_rooms_in_location" : "Aucune chambre n'a été trouvée à cet endroit",
      "no_equipments_in_location" : "Aucun équipement trouvé à cet endroit",
      "equipments" : "Équipement",
      "equipment_name" : "Nom",
      "equipment_name_search" : "Nom de l’équipement",
      "equipment_description" : "Description",
      "equipment_modal_description" : "Ajouter ou modifier un équipement",
      "add_equipment" : "Ajouter/modifier de l'équipement",
      "add_room" : "Ajouter/Modifier une salle",
      "equipment_quantity" : "Quantité totale",
      "equipment_outOfService" : "Quantité hors service",
      "no_equipments_in_location_title" : "Aucun équipement trouvé",
      "delete_this_equipment" : "Supprime cet équipement",
      "delete_this_rooms" : "Supprime cette salle",
      "delete_equipment_confirmation" : "Êtes-vous sûr de vouloir supprimer cet équipement ? Elle sera également supprimée de tous les calendriers liés.",
      "delete_rooms_confirmation" : "Êtes-vous sûr de vouloir supprimer cette pièce ? Elle sera également supprimée de tous les calendriers liés.",
      "add_new_calendar_equipments" : "Ajouter un nouvel équipement",
      "no_equipments_found" : "Votre recherche ne correspondait à aucun équipement.",
      "no_rooms_found" : "Votre recherche ne correspond à aucune pièce.",
      "add_new_calendar_rooms" : "Ajouter une nouvelle pièce",
      "rooms_disable" : "Désactiver la pièce",
      "equipments_disable" : "Désactiver l'équipement",
      "equipments_activate" : "Activer l’équipement",
      "rooms_activate" : "Activer la pièce",
      "create_equipments" : "Créer de l’équipement",
      "create_rooms" : "Créer une pièce",
      "select_calendar_rooms_title" : "Sélectionne le calendrier",
      "select_calendar_rooms_description" : "Tu peux sélectionner plusieurs calendriers à associer à des pièces",
      "select_calendar_equipments_title" : "Sélectionne le calendrier",
      "select_calendar_equipments_description" : "Vous pouvez sélectionner un calendrier à associer à l’équipement",
      "disable_equipment_title" : "{status} équipement",
      "disable_room_title" : "{status} pièce",
      "equipments_status_message" : "Êtes-vous sûr de vouloir {status} cet équipement?",
      "rooms_status_message" : "Êtes-vous sûr de vouloir {status} cette pièce?",
      "calendar_resource_disclaimer" : "Cela pourrait affecter la disponibilité sur les calendriers de service associés.",
      "equipment_disclaimer" : "Remarque : Activer l'équipement plus tard le reconnectera automatiquement aux calendriers auxquels il est associé",
      "room_disclaimer" : "Remarque : l'activation de la pièce plus tard reconnectera automatiquement la salle aux calendriers auxquels elle est associée",
      "equipment_tab_name" : "Équipement",
      "rooms_tab_name" : "Pièces",
      "manage_your_rooms" : "Gérez les pièces pour votre entreprise ici",
      "manage_your_equipments" : "Gérez l’équipement pour votre entreprise ici",
      "select_service_calendar_description_equipments" : "Vous pouvez sélectionner plusieurs calendriers de service à associer à l'équipement",
      "select_service_calendar_description_rooms" : "Vous pouvez sélectionner plusieurs calendriers de service à associer à des pièces",
      "service_menus" : "Menu de services",
      "manage_your_calendars_groups_and_service_menus" : "Gérer vos calendriers, groupes et menu de service",
      "new_service_menu" : "Créer un menu de service",
      "deactivate_service_menu" : "Désactiver le menu de service",
      "delete_service_menu" : "Supprimer ce menu de service",
      "delete_service_menu_confirmation" : "Etes-vous sûr de vouloir supprimer ce menu de service?",
      "service_menu_status_message_title" : "{status} ce menu de service?",
      "service_menu_status_message" : "Vous êtes sur le point de {status} le menu de service",
      "no_service_menus_in_location_title" : "Créez votre menu de service personnalisé",
      "add_new_service_menu" : "Ajouter un nouveau menu de service",
      "no_service_menus_in_location" : "Commencez par créer un nouveau menu de services et ajoutez-y des groupes.",
      "no_service_menu_found" : "Aucun menu de service trouvé",
      "no_service_menu_found_desc" : "Votre recherche ne correspondait à aucun menu de service.",
      "apply" : "Appliquer",
      "troubleshoot_calendar" : "Dépanner le calendrier",
      "round_robin_booking" : "Tour de table",
      "round_robin_description" : "Planifie les réunions entre les membres de l'équipe dans un ordre rotatif.",
      "round_robin_example" : "Par exemple : les appels de vente, les sessions de recrutement.",
      "unassigned_booking" : "Calendrier des événements",
      "unassigned_description" : "Pour planifier des événements physiques sans la présence d'un hôte.",
      "unassigned_example" : "Par exemple : les conférences, les séminaires publics, les expositions.",
      "class_booking" : "Réservation de cours",
      "class_booking_description" : "Un hôte rencontre plusieurs participants.",
      "class_booking_example" : "Par exemple : les webinaires, les formations de groupe, les cours en ligne.",
      "collective_booking" : "Réservation collective",
      "collective_booking_description" : "Plusieurs hôtes rencontrent le même participant.",
      "collective_booking_example" : "Par exemple : les entretiens avec un groupe, les examens par des comités.",
      "service_booking" : "Réservation professionnelle",
      "service_booking_description" : "Planification fluide pour les entreprises de prestation de services.",
      "service_booking_example" : "Par exemple : les rendez-vous au spa, les services de réparation, les consultations.",
      "personal_booking" : "Réservation personnelle",
      "personal_booking_description" : "Planifie des réunions individuelles avec un membre spécifique de l’équipe.",
      "personal_booking_example" : "Ex. : les réunions avec des clients, les consultations privées.",
      "personal" : "Personnel",
      "explore_more_types" : "Découvrez davantage de formats"
    },
    "preferences" : {
      "preferences" : "Préférences",
      "my_preferences" : "Ma préférence",
      "account_preferences" : "Préférence de compte",
      "user_preferences" : "Préférences de l’utilisateur",
      "set_preferences" : "Définissez vos préférences pour votre compte.",
      "scheduling_options" : "Options de planification",
      "meeting_location" : "Lieu de réunion",
      "zoom" : "Zoom",
      "save_preferences" : "Enregistrer tes préférences",
      "in_app_preferences" : "Dans les préférences de l'application",
      "set_start_day" : "Définir des préférences telles que le jour de début de l'application",
      "view_options" : "Afficher les options",
      "week_starts_on" : "La semaine commence le",
      "sunday" : "Dimanche",
      "widget_preferences" : "Préférences de widget",
      "set_widget_language" : "Définir les préférences comme la langue du widget",
      "language_and_region" : "Langue et région",
      "language" : "Langue",
      "english" : "Anglais",
      "time_format" : "Format de l'heure",
      "time_format_value" : "1:30 PM",
      "monday" : "Lundi",
      "custom_meeting_location" : "Lieu de réunion personnalisé"
    },
    "availability" : {
      "availability" : "Disponibilité",
      "my_availability" : "Ma disponibilité",
      "account_availability" : "Disponibilité du compte",
      "timezone" : "Fuseau horaire",
      "working_hours" : "Heures de travail",
      "set_recurring_hours" : "Définissez vos heures de travail récurrentes pour la réunion",
      "sunday" : "Dimanche",
      "monday" : "Lundi",
      "tuesday" : "Mardi",
      "wednesday" : "Mercredi",
      "thursday" : "Jeudi",
      "friday" : "Vendredi",
      "saturday" : "Samedi",
      "widget_preferences" : "Préférences de widget",
      "set_widget_language" : "Définir les préférences comme la langue du widget",
      "language_and_region" : "Langue et région",
      "language" : "Langue",
      "english" : "Anglais",
      "copy_to_all" : "Copier à tous",
      "save_availability" : "Sauvegarder la disponibilité",
      "add_time" : "Ajouter du temps",
      "select_time" : "Sélectionner l'heure"
    },
    "connections" : {
      "connections" : "Connexions",
      "connect" : "connecter",
      "conferencing" : "Conférence",
      "my_connections" : "Mes connexions",
      "account_connections" : "Connexions de compte",
      "connection_preferences" : "Préférences de connexion",
      "manage_calendars_conferencing_channels" : "Gérer les calendriers et les canaux de conférence",
      "main_integration_calendar" : "Calendrier d'intégration principal",
      "event_integration_calendar" : "Les événements créés sur un calendrier système dont tu fais partie seront également créés sur ce calendrier intégré",
      "2way_sync" : "Synchronisation bidirectionnelle : créez des événements système à partir d'événements réservés sur le calendrier intégré principal",
      "calendars" : "Calendriers",
      "google" : "Google",
      "outlook" : "Perspective",
      "check_for_conflicts" : "Vérifie les conflits",
      "choose_accounts_to_connect" : "Choisis les comptes que tu souhaites connecter",
      "payments" : "Paiements",
      "stripe" : "Bande",
      "ads" : "Publicités",
      "local_service_ads" : "Annonces de services locaux",
      "fire_workflow" : "Déclencheurs de flux de travail pour les événements réservés au moyen de la synchronisation bidirectionnelle",
      "connect_google_outlook" : "Veuillez connecter votre compte Google/Outlook/iCloud pour pouvoir choisir le calendrier principal.",
      "execute_automation" : "Exécuter l'automatisation",
      "save" : "Enregistrer",
      "lsa" : "Annonces de services locaux",
      "no_write_access" : "Vous n'avez pas accès en écriture à ce calendrier",
      "connection_remove_confirmation" : "Vous êtes sur le point de supprimer cette intégration",
      "active" : "Actif",
      "icloud" : {
        "title" : "Intégration iCloud",
        "description" : "Une authentification à deux facteurs et un mot de passe particulier à l'application sont requis pour connecter votre compte.",
        "email" : "Adresse courriel iCloud",
        "password" : "Mot de passe iCloud",
        "connect" : "Connecte-toi",
        "cancel" : "Annuler"
      }
    }
  },
  "appointment_modal" : {
    "select_a_contact" : "Sélectionne un contact",
    "start_time" : "Heure de début",
    "unassigned" : "Non assigné",
    "end_time" : "Heure de fin",
    "search_by_name_email_phone_or_company" : "Recherche par nom, courriel, téléphone ou entreprise",
    "add_new" : "Ajouter nouveau",
    "pick_from_available_contacts" : "Ou choisissez parmi les contacts disponibles",
    "book_appointment" : "Prendre rendez-vous",
    "add_blocked_off_time" : "Ajouter du temps bloqué",
    "recommended_timezones" : "Fuseaux horaires recommandés",
    "all_timezones" : "Tous les fuseaux horaires",
    "calendar" : "Calendrier",
    "repeat" : "Répète",
    "date_and_time" : "Date et heure",
    "close" : "Fermer",
    "team_member" : "Membre de l'équipe",
    "recurring_booking" : "Réservation récurrente",
    "one_slot_booking" : "Réservation à un seul créneau",
    "event_type" : "Type d’événement",
    "occurrences_booked" : "les occurrences seront réservées",
    "repeats" : "Répétitions",
    "booking_details" : "Détails de la réservation",
    "showing_slots_in_this_timezone" : "Affichage des créneaux horaires dans ce fuseau horaire :",
    "update_contact_tz" : "Mets également à jour le fuseau horaire du contact pour",
    "date" : "Date",
    "slot" : "Fente",
    "calendar_default" : "Calendrier par défaut",
    "custom" : "Personnalisé",
    "meeting_notes" : "(p. ex.) Notes de réunion pour notre prochain rendez-vous",
    "out_of_office" : "(par exemple) Absent du bureau pour la semaine!",
    "standard" : "Par défaut",
    "appointment_title" : "Titre du rendez-vous",
    "event_title" : "Titre de l’événement",
    "appt_with_bob" : "(par exemple) Rendez-vous avec Bob",
    "vacation" : "(par exemple) Vacances",
    "show_notes" : "Afficher les notes",
    "hide_notes" : "Masque les notes",
    "additional_preferences" : "Préférences supplémentaires",
    "meeting_location" : "Lieu de la réunion",
    "appointment_status" : "Statut du rendez-vous",
    "appt_description" : "Description du rendez-vous",
    "recurring_details" : "Détails récurrents",
    "event_description" : "Description de l’événement",
    "in_contact_timezone" : "Dans le fuseau horaire du contact",
    "vacation_message" : "En vacances ? Vous prenez un peu de temps libre? Bloquez du temps sur votre calendrier pour empêcher les clients de prendre des rendez-vous (les rendez-vous existants resteront sur votre calendrier).",
    "contact" : "Contact",
    "delete" : "Supprimer",
    "cancel" : "Annuler",
    "back" : "Retour",
    "pick_available_contacts" : "Ou choisissez parmi les contacts disponibles",
    "no_contacts_found" : "Aucun contact trouvé",
    "found" : "trouvé",
    "select_one" : "Sélectionnez une option",
    "tz_tooltip" : "Cela n'est important que si vous utilisez des valeurs personnalisées, telles que appointment_start_time, appointment_end_time, appointment_timezone, etc. dans vos communications sortantes avec vos clients ou clients. Et ce qu'il fait simplement, c'est convertir les détails susmentionnés en leur heure locale.",
    "meeting_location_tooltip" : "Le lieu de la réunion sera défini comme configuré dans le calendrier.",
    "appt_meeting_location_tooltip" : "L'utilisateur peut définir le lieu de la réunion spécifique au rendez-vous.",
    "gmeet_tooltip" : "Assurez-vous que le membre de l'équipe concerné dispose d'un calendrier Google défini comme calendrier principal.",
    "zoom_tooltip" : "Veuillez vous assurer que le membre de l'équipe concerné a intégré Zoom",
    "enter_meeting_location" : "Saisis le lieu de la réunion",
    "save_appointment" : "Enregistrer le rendez-vous",
    "save_event" : "Sauvegarder l'événement",
    "search_contacts" : "Recherche par nom, courriel, téléphone ou entreprise",
    "recurring_slots" : "{recurringSlotsLength}/{count}",
    "custom_timezone" : "Fuseau horaire personnalisé",
    "system_timezone" : "Fuseau horaire du système",
    "contact_timezone" : "Fuseau horaire de contact",
    "account_timezone" : "Fuseau horaire du compte",
    "an_error_occurred" : "Une erreur s’est produite"
  },
  "popup" : {
    "calendar" : "Calendrier",
    "appointment_owner" : "Propriétaire du rendez-vous",
    "source" : "Source",
    "booked_by" : "Réservé par",
    "view_activity_log" : "Afficher le journal d’activité",
    "status" : "Statut",
    "cancel_appointment" : "Annuler le rendez-vous",
    "cancel_all" : "Annuler tout",
    "google_event" : "Événement Google",
    "outlook_event" : "Événement Outlook",
    "blocked_slot" : "Créneau bloqué",
    "appointment" : "Rendez-vous",
    "confirmed" : "Confirmé",
    "unconfirmed" : "Non confirmé",
    "cancelled" : "Annulé",
    "showed" : "Montré",
    "no_show" : "Pas de spectacle",
    "invalid" : "Invalide",
    "more" : "Plus",
    "less" : "Moins",
    "edit" : "Modifier",
    "reschedule_all" : "Replanifier tout",
    "guests" : "Invité(s)",
    "guest_count" : "Nombre d'invités"
  },
  "create_calendar" : {
    "calendar_name" : "Nom du calendrier",
    "calendar_logo" : "Logo du calendrier",
    "select_team_member" : "Sélectionner les membres de l’équipe",
    "eg_outbound_reach" : "(par exemple) Portée sortante",
    "description" : "Description",
    "amount" : "Montant",
    "description_placeholder" : "Rédige une description",
    "meeting_duration" : "Durée de la réunion",
    "seats_per_class" : "Sièges par classe",
    "group" : "Groupe",
    "custom_url" : "URL personnalisée",
    "meeting_invite_title" : "Titre de l’invitation à la réunion",
    "event_color" : "Couleur de l'événement",
    "availability_type" : "Type de disponibilité",
    "new_calendar" : "Nouveau calendrier",
    "add_description" : "Ajoute une description",
    "remove_description" : "Supprime la description",
    "booking_availability" : "Disponibilité des réservations",
    "slug_tooltip" : "Personnalise le lien direct vers ce calendrier",
    "meeting_location_tooltip" : "Les liens de réunion sont affichés dans les événements du calendrier en tant que « lieu de réunion » et vous permettent d’ajouter des éléments tels que des liens de zoom pour l’événement.",
    "advanced_settings" : "Paramètres avancés",
    "reschedule_preference" : "Rééchelonnez la préférence",
    "reschedule_preference_subtext" : "Si un contact reprogramme un rendez-vous :",
    "reassign_through_round_robin" : "Réaffecter par le biais d'un système à la ronde",
    "keep_same_assinged_user" : "Conserve le même propriétaire de rendez-vous",
    "new_appointment_preference" : "Nouvelle préférence de rendez-vous",
    "new_appointment_preference_text" : "Réserve toujours avec l'utilisateur attribué au contact",
    "new_appointment_preference_subtext" : "Prenez de nouveaux rendez-vous avec l’utilisateur assigné du contact au lieu d’utiliser la planification round robin.",
    "cancel" : "Annuler",
    "confirm" : "Confirme",
    "service_calendar_name" : "Nom du service",
    "service_logo" : "Logo du service",
    "service_select_team_member" : "Sélectionne un membre du personnel",
    "service_duration" : "Durée du service",
    "appointment_invite_title" : "Titre de l'invitation à un rendez-vous",
    "payment_placeholder" : "Saisissez une description qui sera affichée à l'utilisateur pour l'informer du paiement",
    "deposit_amount" : "Montant du dépôt",
    "collect_deposit_amount" : "Accepte le paiement partiel",
    "percentage" : "Pourcentage",
    "total_amount" : "Montant total"
  },
  "calendar_advanced" : {
    "general" : {
      "quick_tip" : "Astuce rapide",
      "timezone_change_tip" : "Les fuseaux horaires peuvent être modifiés à tout moment dans les préférences de fuseau horaire",
      "save" : "Enregistrer",
      "close" : "Fermer",
      "meeting_details" : "Détails de la réunion",
      "availability" : "Disponibilité",
      "forms_and_payment" : "Formulaires et paiement",
      "notifications_and_additional_options" : "Notifications et options supplémentaires",
      "connections" : "Connexions",
      "customizations" : "Personnalisations",
      "create" : "Créer",
      "edit" : "Modifier",
      "equipments" : "Chambres et équipements",
      "service_details" : "Détails du service"
    },
    "meeting_details" : {
      "meeting_location" : "Lieu de réunion",
      "enter_meeting_location" : "Entre le lieu de la réunion",
      "click_to_upload" : "Clique pour télécharger",
      "square" : "Carré",
      "circle" : "Cercle",
      "remove_logo" : "Supprime le logo",
      "meeting_invite_title_tooltip" : "Il s'agit du titre de l'événement du calendrier qui s'affiche dans Google Calendar, Outlook, Apple Calendar, etc.",
      "event_color_tooltip" : "Choisissez une couleur pour cet événement de réservation. Les couleurs sont synchronisées avec les événements dans Google Agendas.",
      "enter_a_name" : "Veuillez saisir un nom",
      "enter_an_event_title" : "Veuillez saisir le titre d’un événement",
      "high_priority" : "Haute priorité",
      "medium_priority" : "Priorité moyenne",
      "low_priority" : "Faible priorité",
      "custom" : "Personnalisé",
      "zoom_not_added" : "L’utilisateur n’a pas encore connecté son compte Zoom",
      "opt_avail" : "Optimise la disponibilité",
      "opt_equal" : "Optimise pour une distribution égale",
      "team_members" : "Sélectionner les membres de l’équipe",
      "team_member" : "Sélectionner un membre de l'équipe",
      "meeting_location_required" : "Le lieu de la réunion est requis",
      "meeting_details" : "Détails de la réunion",
      "meeting_details_description" : "Voici les détails qui seront affichés sur la page de liste du calendrier.",
      "or_drag_and_drop" : "ou glisser-déposer",
      "service_details" : "Détails du service",
      "team_member_disabled_recurring_tooltip" : "Les réunions récurrentes n'autorisent qu'un seul membre de l'équipe. Pour ajouter plusieurs membres de l'équipe, veuillez désactiver les réunions récurrentes.",
      "team_member_disabled_class_booking_tooltip" : "Un seul membre de l'équipe peut être ajouté au calendrier de réservation d'une classe.",
      "advanced_settings" : "Paramètres avancés",
      "enable_contact_assigned_to_setting" : "Pour activer ce paramètre, le formulaire doit être le premier dans l’ordre des widgets. Veuillez réorganiser l'ordre des widgets depuis l'onglet Formulaires et paiement.",
      "reschedule_preference_setting_tooltip" : "Choisissez à qui le rendez-vous doit être attribué lorsqu’un contact reprogramme à partir du widget de réservation.",
      "appointment_preference_tooltip" : "Choisissez si les nouveaux rendez-vous doivent toujours être réservés avec l’utilisateur désigné du contact. Si un contact a un utilisateur assigné, cet utilisateur aura la préférence ; sinon, il s’agira d’une mission de tournoi à la ronde. Conseil : Désactivez « Autoriser la sélection du personnel » pour empêcher les bookers de changer le membre du personnel sur le widget de réservation.",
      "add_location" : "Ajouter un emplacement",
      "multiple_locations_neo" : "Les emplacements de réunion multiples sont uniquement pris en charge par le widget Neo avec le formulaire par défaut.",
      "make_owner" : "Devenir propriétaire",
      "only_one_team_member" : "Un seul membre de l'équipe est autorisé lorsque plusieurs emplacements sont ajoutés.",
      "ask_the_booker_error_message" : "L'emplacement d'Ask the Booker existe déjà",
      "enter_location" : "Entrer la valeur de l'emplacement",
      "enter_display_label" : "Saisir l'étiquette d'affichage",
      "add_display_label" : "Ajouter une étiquette d'affichage",
      "multiple_location_support_message" : "Les emplacements de réunion multiples sont uniquement pris en charge par le widget Neo avec le formulaire par défaut.",
      "only_one_team_member_personal_calendar" : "Un seul membre de l'équipe est autorisé pour les réservations individuelles ",
      "ms_teams_not_added" : "L’utilisateur n’a pas encore connecté son compte Microsoft Teams"
    },
    "availability" : {
      "my_availability" : "Ma disponibilité",
      "set_availability" : "Définis ici ta disponibilité pour le calendrier.",
      "standard" : "Standard",
      "custom" : "Personnalisé",
      "to" : "À",
      "copy_to_all" : "Copier à tous",
      "add_time" : "Ajouter du temps",
      "recurring_meeting" : "Réunion récurrente",
      "recurring_info" : "Un seul utilisateur par calendrier pour les réservations récurrentes",
      "repeat" : "Répète",
      "times_to_repeat" : "Temps à répéter",
      "slots_unavailable" : "Si les créneaux ne sont pas disponibles?",
      "overlap_status" : "Statut des rendez-vous pour les créneaux horaires qui se chevauchent ",
      "meeting_interval" : "Intervalle des réunions",
      "meeting_duration" : "Durée de la réunion",
      "minimum_scheduling" : "Préavis de planification minimum",
      "date_range" : "Plage de dates",
      "maximum_bookings_per_day" : "Nombre maximal de réservations par jour",
      "maximum_bookings_per_slot" : "Nombre maximum de réservations par créneau",
      "maximum_bookings_per_slot_per_user" : "Nombre maximal de réservations par créneau (par utilisateur)",
      "seats_per_class" : "Sièges par classe",
      "pre_buffer_time" : "Temps de pré-mise en mémoire tampon",
      "post_buffer_time" : "Temps de mise en mémoire tampon après publication",
      "pre_buffer_tooltip" : "Le pré-tampon est un temps supplémentaire qui peut être ajouté avant un rendez-vous, ce qui permet d'avoir plus de temps pour se préparer",
      "post_buffer_tooltip" : "Le post-buffer est du temps supplémentaire qui peut être ajouté après un rendez-vous, permettant ainsi plus de temps pour conclure",
      "buffer_time" : "Temps de latence",
      "daily" : "Quotidiennement",
      "weekly" : "Hebdomadaire",
      "monthly" : "Mensuel",
      "skip_booking" : "Ignorer la réservation des créneaux indisponibles",
      "continue_booking" : "Poursuivre la réservation",
      "book_next_available" : "Réservez le prochain créneau disponible",
      "confirmed" : "Confirmé",
      "unconfirmed" : "Non confirmé",
      "enter_valid_hours" : "Veuillez saisir des heures d'ouverture valides",
      "enter_valid_avail" : "Veuillez saisir une disponibilité personnalisée valide",
      "availability_type_tooltip" : "Choisissez la disponibilité standard pour les réservations régulières proposées chaque semaine. Choisissez Personnalisé pour les réservations uniquement disponibles à des dates précises.",
      "meeting_interval_tooltip" : "L'intervalle de réunion reflète la durée entre les créneaux de réservation qui seront affichés dans le calendrier. Pour un événement de 30 minutes qui devrait être disponible en haut de chaque heure, l’intervalle serait de 1 heure. Pour un événement de 30 minutes qui devrait être disponible toutes les 30 minutes, l’intervalle serait de 30 minutes.",
      "date_range_tooltip" : "Les événements peuvent être planifiés au cours des n prochains jours.",
      "maximum_bookings_per_day_tooltip" : " Nombre maximal de rendez-vous permis par jour sur ce calendrier.",
      "maximum_bookings_per_slot_tooltip" : "Nombre maximal de rendez-vous autorisés par créneau pour l'utilisateur (si le nombre de rendez-vous dépasse le créneau donné pour l'utilisateur, il passe alors à l'utilisateur suivant dans la file d'attente à tour de rôle ou marque le créneau indisponible pour la réservation du client potentiel).",
      "add_days" : "Ajouter des jours",
      "month_on_day" : "Mensuellement le jour",
      "month_on_last_day" : "Mensuellement le dernier jour",
      "day" : "Jour",
      "week" : "Semaine",
      "month" : "Mois",
      "mins" : "Minutes",
      "hours" : "Heures",
      "days" : "Jours",
      "weeks" : "Semaines",
      "months" : "Mois",
      "meeting_interval_error" : "L'intervalle de rencontre doit être compris entre 5 minutes et 12 heures",
      "meeting_duration_error" : "La durée de la réunion doit être comprise entre 1 min et 12 heures",
      "buffer_time_error" : "Le temps tampon doit être inférieur à 12 heures",
      "appointment_interval" : "Intervalle de rendez-vous",
      "appointment_duration" : "Durée du rendez-vous",
      "maximum_appointments_per_day" : "Nombre maximal de rendez-vous par jour",
      "subTitle" : "Choisis la date pour définir des heures spécifiques",
      "heading_1" : "Heures hebdomadaires disponibles",
      "tooltip_1" : "Définir votre horaire de disponibilité hebdomadaire. Ce sont vos heures normales qui seront appliquées uniformément sur une base hebdomadaire",
      "heading_2" : "Quand es-tu disponible ?",
      "unavailable_label" : "Indisponible",
      "tooltip_2" : "Ajustez votre horaire pour des dates spécifiques. Remplacez vos horaires hebdomadaires habituels en marquant la disponibilité ou l'indisponibilité pour les dates sélectionnées.",
      "date_specific_level" : "Ajouter des heures spécifiques à la date",
      "specific_hours_button" : "Heures spécifiques à la date",
      "overlap_error" : "Les plages horaires ne peuvent pas se chevaucher",
      "apply" : "Applique",
      "copy_times_to" : "Copier les heures vers",
      "no_date_specific_heading" : "Aucune date et aucune heure particulières ajoutées",
      "no_date_specific_description" : "Vous pouvez ajouter/supprimer une date et une heure particulières à vos disponibilités.",
      "recurring_disabled_conditions_tooltip" : "Les réunions récurrentes sont désactivées. Pour les activer, assurez-vous que le calendrier ne compte qu’un seul membre de l’équipe et qu’aucune heure spécifique à la date n’est ajoutée.",
      "date_specific_hours_disabled_conditions_tooltip" : "Pour inclure une heure spécifique à la date, assure-toi que les réunions récurrentes sont désactivées.",
      "recurring_disable_event_calendar_conditions" : "Les réunions récurrentes sont désactivées. Pour les activer, assurez-vous qu’aucune heure spécifique à la date n’est ajoutée.",
      "service_duration" : "Durée du service",
      "service_interval" : "Intervalle de service",
      "service_interval_tooltip" : "L'intervalle de réunion définit la fréquence à laquelle les créneaux de réservation apparaissent sur le calendrier. Par exemple, pour un événement de 30 minutes, un intervalle d'une heure signifie que les créneaux apparaissent toutes les heures. Un intervalle de 30 minutes signifie que les créneaux apparaissent toutes les 30 minutes.",
      "service_duration_tooltip" : "Cela fixe la durée de ton rendez-vous, déterminant combien de temps il durera.",
      "minimum_scheduling_tooltip" : "Cela définit le temps minimum requis pour prendre rendez-vous via le widget de calendrier. Par exemple, s'ils sont définis sur 4 heures, les rendez-vous ne peuvent pas être pris dans les 4 heures suivant leur heure de début ; les réservations doivent être faites au moins 4 heures à l'avance.",
      "look_busy_title" : "Regardez occupé",
      "look_busy_helper_text" : "Cache un pourcentage de tes créneaux de rendez-vous disponibles sur le widget de réservation pour paraître plus occupé et plus demandé.",
      "look_busy_percentage_description" : "Masquer le nombre d'emplacements disponibles par x%",
      "maximum_bookings_per_slot_tooltip_personal" : "Nombre maximal de réunions autorisées par créneau pour l'utilisateur"
    },
    "forms_payment" : {
      "forms" : "Formulaires",
      "forms_sub" : "Définis les préférences du formulaire de planification de publication.",
      "select_form" : "Sélectionne le formulaire",
      "enable_same_user_assignment" : "Affectez des contacts aux membres de leur équipe de calendrier respective à chaque fois qu'un rendez-vous est réservé",
      "sticky_contacts" : "Champs pré-remplis (contacts collants)",
      "confirmation_page" : "Page de confirmation",
      "default" : "Par défaut",
      "redirect_url" : "URL de redirection",
      "thank_you" : "Message de remerciement",
      "enter_url" : "Entrez l'URL",
      "pixel_id" : "ID de pixel Facebook (facultatif)",
      "auto_confirm" : "Confirmer automatiquement les nouvelles réunions du calendrier",
      "configure_payment" : "Configurer les options de paiement",
      "accept_payments" : "Accepter les paiements",
      "test" : "Essai",
      "live" : "En direct",
      "enable_guests" : "Ajouter des invités",
      "add_guest_tooltip" : "Ajouter un invité permettra à votre participant d'ajouter plusieurs participants au même rendez-vous",
      "add_guest_custom_form_tooltip" : "L'activation de cette option enverra un courriel généré par le système aux invités avec un lien vers le formulaire personnalisé une fois leur rendez-vous confirmé. Pour éviter les courriels en double, assurez-vous qu’un courriel de confirmation séparé n’est pas déjà configuré pour les invités. (Vérifiez ceci dans l'onglet Notifications > Notification de confirmation)",
      "add_guest_same_form_check" : "Envoyer le même formulaire de collecte d'information par courrier à tous les invités",
      "payment_tooltip" : "Le paiement ne sera pris en compte que pour le participant principal et non pour les invités.",
      "default_form" : "Par défaut (prénom, nom, courriel, téléphone, notes)",
      "select_form_tooltip" : "Si vous souhaitez collecter plus d'information que le prénom, le nom, le courriel et le téléphone standard auprès des personnes qui réservent sur ce calendrier, créez un formulaire personnalisé dans Sites Web > Formulaires > Générateur, puis sélectionnez le formulaire au moyen de la liste déroulante ci-dessous.",
      "confirmation_page_tooltip" : "Une fois que quelqu'un effectue une réservation, vous pouvez choisir de lui afficher un message personnalisé sur la même page (par défaut) ou du rediriger vers une page de votre choix (rediriger).",
      "add_guest_collect_email" : "Nom et courriel",
      "add_guest_count_only" : "Compter seulement",
      "custom_email_notification" : "Envoyer un formulaire personnalisé aux invités",
      "consent_label" : "Case à cocher de consentement",
      "consent_input_placeholder" : "Ajouter du texte de consentement",
      "same_user_assignment_tooltip" : "Pour activer ce paramètre, le formulaire doit être le premier sur le widget. Réorganisez le widget pour positionner le formulaire en premier pour activer ce paramètre.",
      "consent_tooltip" : "Activez cette option pour permettre l'obtention du consentement des contacts lorsqu'ils fournissent leur numéro de téléphone lors du processus de réservation de rendez-vous.",
      "stripe_error" : "Jusqu’à deux décimales (exemple : 25,99)",
      "auto_confirm_service" : "Confirmer automatiquement les nouveaux rendez-vous",
      "service_form_message" : "Si un contact réserve plusieurs services, il ne verra que le formulaire par défaut dans le menu des services",
      "deposit_amount_error" : "Le montant du dépôt doit être inférieur au montant total.",
      "deposit_percentage_error" : "Le pourcentage de dépôt doit être inférieur à 100 %.",
      "partial_payment_tooltip" : "Activez les paiements partiels pour collecter le montant de l'acompte auprès des clients pendant le processus de réservation de rendez-vous.",
      "partial_payment_enable_error" : "Le montant total saisi doit être supérieur à 0 pour permettre des paiements partiels.",
      "payment_disable_text_integration" : "Intègre un fournisseur de paiement sous Paiements > Intégrations pour commencer à accepter les paiements",
      "payment_disable_text_recurring" : "L'option Accepter le paiement n'est pas disponible pour les réunions récurrentes. Désactivez les paiements récurrents pour activer les paiements.",
      "date_and_time_selector" : "Sélecteur de date et d’heure",
      "form_selector" : "Formulaire",
      "order_step" : "Étape",
      "widget_order_label" : "Commande de widgets",
      "widget_order_tool_tip" : "Réorganiser l'ordre de « Sélecteur de date et d'heure » et de « Formulaire » dans le widget du calendrier. Faites simplement glisser et réordonner selon vos préférences.",
      "widget_re_order_tooltip" : "La réorganisation n'est disponible qu'avec le style de widget Neo. Passez à Neo pour activer la réorganisation.",
      "widget_re_order_disabled_tooltip" : "Pour réorganiser l'ordre des widgets, veuillez désactiver « Nouvelle préférence de rendez-vous (toujours réserver avec l'utilisateur attribué au contact) » dans la section Détails de la réunion > Membres de l'équipe > Réglage avancé.",
      "payment_description_error" : "Limite maximale de caractères de 1000 atteinte",
      "widget_multiple_location_disabled_tooltip" : "Le widget classique ne prend pas en charge plusieurs lieux de réunion. Veuillez supprimer tous les emplacements supplémentaires pour utiliser le widget classique",
      "recurring_payment_label" : "Paiement des rendez-vous récurrents",
      "first_appointment_only" : "Premier rendez-vous seulement",
      "all_appointments" : "Tous les rendez-vous"
    },
    "notifications" : {
      "notifications" : "Notifications et options supplémentaires",
      "notifications_sub" : "Configure les notifications et les options supplémentaires",
      "select_notification_type" : "Sélectionne le type de notification",
      "notification_person" : "Qui devrait recevoir cette notification ?",
      "contact" : "Contact",
      "guest" : "Invité",
      "assigned_user" : "Utilisateur assigné",
      "emails" : "Courriels",
      "alert_email_address" : "Adresse courriel d’alerte",
      "ack_email" : "Courriel d’accusé de réception",
      "send_google_invites" : "Autoriser Google Agenda à envoyer des invitations ou à mettre à jour des courriels aux participants.",
      "send_google_outlook_invites" : "Autoriser Google / Outlook Calendar à envoyer des courriels d'invitation et de mise à jour aux participants.",
      "assign_user_to_contact" : "Affectez des contacts aux membres de leur équipe de calendrier respective à chaque fois qu'un rendez-vous est réservé",
      "skip_assigning_user_to_contact" : "Ignorer l'attribution d'un contact si le contact a déjà un utilisateur attribué",
      "assigned_staff" : "Personnel assigné",
      "additional_emails" : "Courriels supplémentaires",
      "assign_user_to_staff" : "Affectez les contacts à leurs membres de service respectifs à chaque fois qu'un rendez-vous est pris",
      "allow_cancellation" : " Autoriser l'annulation de la réunion",
      "allow_reschedule" : "Permettre de reprogrammer la réunion",
      "allow_cancellation_tooltip" : "Lorsqu'il est activé, un lien d'annulation sera ajouté à la section des notes supplémentaires et inclus dans l'invitation du calendrier. Ce lien expirera comme configuré avant l’heure de début de la réunion, empêchant le booker d’annuler la réunion.",
      "allow_reschedule_tooltip" : " Lorsqu'il est activé, un lien de reprogrammation sera ajouté à la section des notes supplémentaires et inclus dans l'invitation du calendrier. Ce lien expirera comme configuré avant l’heure de début de la réunion, empêchant le booker de reprogrammer la réunion.",
      "cancellation_link_expiry" : "Le lien d’annulation expirera",
      "reschedule_link_expiry" : "Le lien de reprogrammation expirera",
      "before_meeting" : "avant la réunion",
      "cancellation_and_reschedule_policy" : "Politique d'annulation et de report :"
    },
    "connections" : {
      "connections_heading" : "Synchronisation du calendrier externe",
      "connections_sub" : "Synchronise les événements avec le calendrier externe",
      "link_to_calendar" : "Lien vers le calendrier",
      "sync_option" : "Option de synchronisation",
      "execute_automation" : "Exécuter l'automatisation",
      "fire_workflow" : "Déclencheurs de processus Fire pour les événements réservés au moyen de la synchronisation bidirectionnelle",
      "reserve_with_google" : "Réserver avec Google",
      "connect_lsa" : "Connectez-vous à ton compte de publicités de services locaux",
      "reserve_error" : "Actuellement, Reserve with Google est pris en charge uniquement pour « Home Services », « Real Estate », « Legal Services » (fournisseurs de services locaux) Company Niche aux États-Unis.",
      "one_way" : "Sens Unique",
      "two_way" : "Bidirectionnel",
      "smart" : "Intelligent",
      "confirm_remove" : "Êtes-vous sûr de vouloir supprimer Google Agenda ?",
      "read_only_access" : "Vous n'avez pas accès en écriture à ce calendrier. Veuillez sélectionner un autre calendrier.",
      "reserve_with_google_desc" : "Ce service de calendrier sera répertorié en réserve avec Google uniquement après une intégration réussie.",
      "check_integration" : "Vérifier l'état de votre intégration",
      "setup_external" : "Configurer votre calendrier externe",
      "reserve_price_decimal_error" : "Le prix décimal n’est pas pris en charge",
      "reserve_price_max_error" : "Prix pris en charge moins de 1000"
    },
    "extras" : {
      "extras" : "Personnalisations",
      "extras_sub" : "Définir le style du widget et d'autres préférences.",
      "calendar_widget_style" : "Style du widget de calendrier",
      "neo" : "Neo",
      "classic" : "Classique",
      "insert_custom_code" : "Insérer du code personnalisé",
      "show_seats_per_slot" : "Afficher les sièges par créneau",
      "allow_reschedule_meeting" : "Permettre de reprogrammer la réunion",
      "allow_cancelling_meeting" : "Autoriser l'annulation de la réunion",
      "additional_notes" : "Notes supplémentaires",
      "cancellation_and_reschedule" : "Annulation et report :",
      "allow_staff" : "Permets la sélection du personnel lors de la réservation",
      "code_block_tooltip" : "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités puissantes, mais ils présentent également des risques pour la vie privée, la sécurité, les performances et le comportement de la page. Nous vous recommandons donc de vérifier le contenu de ce script avant de l'enregistrer dans le calendrier.",
      "calendar_image" : "Image de couverture de calendrier",
      "remove_image" : "Supprimer l'image",
      "calendar_image_info" : "L’image téléchargée sera visible dans le modèle Vue de groupe pour Neo et n’apparaîtra pas sur le lien du calendrier individuel",
      "get_image_from_unsplash" : "Améliorez vos visuels avec",
      "unsplash" : "Unsplash",
      "service_additional_preferences" : "Configurer des préférences supplémentaires",
      "service_image" : "Image de couverture du service",
      "service_image_description" : "Sélectionnez une image qui sera affichée dans le menu des services pour ce service",
      "allow_reschedule_appointment" : "Permettre de reprogrammer le rendez-vous",
      "allow_cancelling_appointment" : "Autoriser l'annulation du rendez-vous",
      "custom_code_placeholder" : "Veuillez saisir votre code personnalisé ici",
      "calendar_widget_style_desc" : "Choisissez entre notre widget classique ou l'élégant widget Neo",
      "seats_per_slot" : "Sièges par créneau",
      "show_staff_selection" : "Sélection du personnel",
      "seats_per_slot_desc" : " Permettez aux réservateurs de visualiser le nombre de places disponibles pour chaque créneau horaire directement sur le widget du calendrier.",
      "show_staff_selection_desc" : "Permettez aux réservateurs de choisir un membre du personnel directement à partir du widget de calendrier lors de la réservation de rendez-vous. Fonctionne uniquement avec le widget Neo",
      "widget_re_order_tooltip" : "Le style de widget classique ne permet pas de modifier l'ordre des widgets. Assurez-vous que le sélecteur de date et d'heure est en premier et que le formulaire est en deuxième avant de sélectionner le style classique.",
      "show_staff_selection_desc_service" : "Permettez aux réservateurs de choisir un membre du personnel directement à partir du widget de calendrier lors de la réservation de rendez-vous."
    },
    "roomsAndEquipments" : {
      "title" : "Sélectionne les salles et les équipements",
      "description" : "Sélectionnez dans une liste de chambres et d’équipement ce qui sera réservé avec ce calendrier",
      "rooms" : "Sélectionnez les pièces",
      "equipments" : "Sélectionner l'équipement"
    }
  },
  "activity_log" : {
    "activity_log" : "Journal d'activité",
    "date" : "Date",
    "filter_by" : "Filtrer par",
    "calendar_update" : "Mise à jour du calendrier",
    "start_time_update" : "Mise à jour de l'heure de début",
    "end_time_update" : "Mise à jour de la fin des temps",
    "title_update" : "Mise à jour du titre",
    "notes_update" : "Mise à jour des notes",
    "appointment_status_update" : "Mise à jour du statut du rendez-vous",
    "meeting_location_update" : "Mise à jour sur le lieu de la réunion",
    "google_sync_update" : "Mise à jour de Google Sync",
    "outlook_sync_update" : "Mise à jour de la synchronisation Outlook",
    "assigned_user_update" : "Mise à jour de l'utilisateur assigné",
    "appointment_status" : "Statut du rendez-vous",
    "assigned_user" : "Utilisateur assigné",
    "uploaded_to" : "Téléversé sur",
    "cancelled" : "Annulé",
    "edited" : "édité",
    "other_edits" : "Autres modifications",
    "view_details" : "Voir les détails",
    "all" : "Tous"
  },
  "appointment_list" : {
    "group_all" : "TOUS",
    "group_primary" : "PRIMAIRE",
    "group_user" : "UTILISATEURS",
    "group_team" : "GROUPES",
    "group_calendar" : "CALENDRIERS",
    "all_appointments" : "Tous les rendez-vous",
    "my_calendar" : "Mon calendrier",
    "status_all" : "Tous",
    "status_confirmed" : "Confirmé",
    "status_new" : "Nouveau (action requise)",
    "status_show" : "Montré",
    "status_no_show" : "Absent",
    "status_cancelled" : "Annulé",
    "status_invalid" : "Invalide",
    "filter_date_added_desc" : "Date d’ajout (Desc)",
    "filter_start_added_desc" : "Date de début (Desc)",
    "filter_start_added_asc" : "Date de début décroissant à partir d'aujourd'hui",
    "button_new_appointment" : "Nouveau rendez-vous",
    "name" : "Nom",
    "title" : "Titre",
    "requested_time" : "Temps demandé",
    "date_added" : "Date d'ajout",
    "calendar" : "Calendrier",
    "appointment_owner" : "Propriétaire du rendez-vous",
    "notes" : "Notes",
    "status" : "Statut",
    "action_view_detail" : "Voir les détails",
    "action_view_consent" : "Voir le consentement",
    "action_view_notes" : "Voir les notes",
    "action_edit" : "Modifier",
    "action_delete" : "Supprimer",
    "action_reschedule" : "Rééchelonnement",
    "page_previous" : "Précédent",
    "page_next" : "Suivant",
    "appointment_notes" : "Notes de rendez-vous",
    "close" : "Fermer",
    "confirm_delete_header" : "Supprimer le rendez-vous",
    "confirm_delete_message" : "Êtes-vous sûr de vouloir supprimer ce rendez-vous ? Cette action ne peut pas être annulée !",
    "confirm_delete_button" : "Supprimer",
    "confirm_update_header" : "Mettre à jour le statut du rendez-vous",
    "confirm_update_confirmed_message" : "Êtes-vous sûr de vouloir marquer ce rendez-vous comme confirmé?",
    "confirm_update_invalid_message" : "Êtes-vous sûr de vouloir marquer ce rendez-vous comme non valide?",
    "confirm_update_showed_message" : "Êtes-vous sûr de vouloir marquer ce rendez-vous comme présent?",
    "confirm_update_noshow_message" : "Êtes-vous sûr de vouloir marquer ce rendez-vous comme étant absent?",
    "confirm_update_cancelled_message" : "Êtes-vous sûr de vouloir marquer ce rendez-vous comme annulé?",
    "confirm_update_button" : "Mise à jour",
    "empty_state_title" : "Aucun rendez-vous trouvé",
    "empty_state_description" : "Aucun rendez-vous trouvé",
    "disable_menu_warning" : "Actuellement, vous n'avez pas la permission de voir ou de modifier les détails du rendez-vous, car le contact associé ne vous est pas assigné. Veuillez contacter votre administrateur pour obtenir de l'aide.",
    "no_contact_warning" : "Vous ne pouvez pas accéder aux coordonnées pour le moment. Cela peut être dû au fait que le contact ne vous est pas attribué. Veuillez communiquer avec votre administrateur pour obtenir de l'aide.",
    "consent_label" : "Consentement",
    "ip_address" : "PI",
    "youre_viewing_in" : "Actuellement en train de regarder dans",
    "appointment_delete_msg" : "<b>Attention :</b> le rendez-vous supprimé ici ne sera pas supprimé de calendly.",
    "rescheduled" : "Replanifié"
  },
  "add_guest" : {
    "guest_label" : "Invité(s)",
    "search_placeholder" : "Rechercher parmi les contacts",
    "add_new" : "Ajouter nouveau",
    "save" : "Enregistrer",
    "close" : "Fermer",
    "name" : "Nom",
    "phone" : "Téléphone",
    "email" : "Courriel",
    "guest_label_count" : "Nombre d'invités",
    "guest_count_error" : "Le nombre d'invités doit être compris entre 1 et 100"
  },
  "languages" : {
    "english" : "Anglais",
    "dutch" : "Néerlandais",
    "french" : "Français",
    "german" : "Allemand",
    "hungarian" : "hongrois",
    "italian" : "Italien",
    "polish" : "Polonais",
    "portuguese_brazil" : "Portugais (Brésil)",
    "portuguese_portugal" : "Portugais (Portugal)",
    "spanish" : "Espagnol",
    "danish" : "Danois"
  },
  "service_menu_advanced" : {
    "new_service_menu_details_header" : "Détails du menu de service",
    "select_groups_header" : "Sélectionne des services",
    "arrange_groups_header" : "Arrange tes services",
    "new_service_menu" : "Nouveau menu de service",
    "service_menu_sub_title" : "Crée un menu de service personnalisé pour tes clients en quelques clics",
    "quick_tip" : "Vous pouvez ajouter des groupes au menu de service",
    "service_menu_name" : "Nom du menu de service",
    "service_menu_description" : "Description",
    "service_menu_slug" : "URL du service",
    "select_groups_calendars" : "Sélectionner des groupes et des calendriers",
    "select_groups_calendars_desc" : "Ajoute des services à ton menu de services",
    "select_all" : "Sélectionner tout",
    "arrange_groups" : "Organiser l’ordre des services",
    "arrange_groups_desc" : "Déterminer l'ordre des services dans le menu de service",
    "arrange_services_quicktip" : "Pour réorganiser les calendriers au sein d'un groupe, accédez à Groupes > Options > Réorganiser les calendriers.",
    "enable_add_guest_desc" : "Permet aux clients d'inclure des invités supplémentaires lors de la réservation.",
    "enable_multiple_service_desc" : "Permet aux clients de réserver plusieurs services en un seul rendez-vous.",
    "enable_staff_selection_desc" : "Permet aux clients de sélectionner les membres du personnel qu'ils préfèrent lorsqu'ils effectuent une réservation.",
    "enable_add_guest" : "Activer Ajouter des invités",
    "enable_multiple_service" : "Activer la sélection de plusieurs services",
    "enable_staff_selection" : "Activer la sélection du personnel",
    "additional_options" : "Options supplémentaires",
    "code_block_tooltip" : "Les scripts tiers placés dans le bloc HTML peuvent offrir des fonctionnalités puissantes, mais ils présentent également des risques pour la vie privée, la sécurité, les performances et le comportement de la page. Par conséquent, nous vous recommandons de revoir le contenu de ce script avant de l'enregistrer dans le menu de service.",
    "custom_code_placeholder" : "Veuillez saisir votre code personnalisé ici",
    "insert_custom_code" : "Insérer du code personnalisé",
    "no_service" : "Aucun service trouvé",
    "no_service_desc" : " Aucun service trouvé. Créez des services et ajoutez-les à des groupes pour créer un menu de services.",
    "learn_more" : "En savoir plus",
    "no_service_selected" : "Aucun service sélectionné",
    "create_service_calendars" : "Créer des calendriers de service",
    "alert_description" : " Pour créer un menu de services, tu dois créer des calendriers de services et les ajouter à des groupes",
    "dont_show_again" : "Ne la montrez plus",
    "service_name_required" : "Le nom du menu de service est requis",
    "slug_already_taken" : "Ce slug est déjà pris",
    "slug_required" : "Le slug est requis",
    "please_enter_name" : "Veuillez saisir un nom",
    "service_name_placeholder" : "Menu principal",
    "consent_label" : "Case à cocher de consentement",
    "consent_input_placeholder" : "Ajouter du texte de consentement",
    "consent_tooltip" : "Activez cette option pour permettre l'obtention du consentement des contacts lorsqu'ils fournissent leur numéro de téléphone lors du processus de réservation de rendez-vous."
  },
  "widget_configuration" : {
    "customise_calendar_widget" : "Personnalise le widget de calendrier",
    "works_with_neo_widget" : "Fonctionne uniquement avec le widget Neo",
    "customise_widget_desc" : "Personnaliser l'apparence du widget : couleur primaire, couleur de fond et texte du bouton.",
    "learn_more" : "En savoir plus",
    "primary_settings" : "Paramètres principaux",
    "primary_settings_desc" : "Configurez la couleur principale, la couleur d'arrière-plan et d'autres préférences de widget pour personnaliser votre widget de calendrier",
    "primary_color" : "Couleur primaire",
    "primary_color_desc" : "Définissez la couleur des boutons, des dates, des plages horaires et des actions",
    "background_color" : "Couleur d’arrière-plan",
    "background_color_desc" : "Définissez la couleur d'arrière-plan qui sert d'arrière-plan au sélecteur de date-heure et au formulaire.",
    "button_text" : "Texte du bouton",
    "button_text_desc" : "Personnalise le texte affiché sur le bouton lors de la prise de rendez-vous",
    "calendar_title" : "Titre du calendrier",
    "calendar_title_desc" : "Le nom de ton calendrier",
    "calendar_description" : "Description du calendrier",
    "calendar_description_desc" : "La description saisie",
    "calendar_details" : "Détails du calendrier",
    "calendar_details_desc" : "Inclut de l'information telle que la durée, la date et l'heure, les détails récurrents et le fuseau horaire",
    "reset_to_default" : "Réinitialiser aux paramètres par défaut",
    "preview_widget" : "Widget d’aperçu"
  },
  "eventNotification" : {
    "customFields" : "Custom Fields",
    "customValues" : "Custom Values",
    "list" : {
      "booked" : {
        "title" : "Appointment Booked (Status: Unconfirmed)",
        "note" : "This notification is sent when a new appointment is created with Unconfirmed status.",
        "subTitle" : "Avertit lorsqu'un rendez-vous est réservé avec un statut non confirmé"
      },
      "confirmation" : {
        "title" : "Appointment Booked (Status: Confirmed)",
        "note" : "This notification is sent when an appointment is created with or updated to the Confirmed status.",
        "subTitle" : "Avise lorsqu’un rendez-vous est confirmé avec succès"
      },
      "cancellation" : {
        "title" : "Cancellation",
        "note" : "This notification is sent when an appointment is cancelled, marked as no-show, or marked as invalid.",
        "subTitle" : "Alertes lorsqu’un rendez-vous est annulé"
      },
      "reschedule" : {
        "title" : "Reschedule",
        "note" : "This notification is sent when an appointment is rescheduled. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Notification en cas de report d'un rendez-vous"
      },
      "reminder" : {
        "title" : "Reminder",
        "note" : "This notification is sent as a reminder before the appointment. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Envoie un rappel avant le rendez-vous"
      },
      "followup" : {
        "title" : "Follow-Up",
        "note" : "This notification is sent after the appointment as a follow-up. It will only be triggered if the appointment is in Unconfirmed, Confirmed, or Showed status.",
        "subTitle" : "Envoi d'un message de suivi une fois le rendez-vous terminé"
      }
    },
    "saveToastMsg" : "Paramètres enregistrés!",
    "saveToastMsgDesc" : "Tes modifications ont été enregistrées avec succès",
    "enableSuccess" : "Notifications activées!",
    "disableSuccess" : "Notifications désactivées !",
    "tooltip" : "This notification has been disabled. Please enable it to make any changes.",
    "enabled" : "Enabled",
    "disabled" : "Disabled",
    "statusLabel" : "Status Labels",
    "email" : {
      "channelName" : "Email",
      "noneTemplate" : "Aucun",
      "notificationLabel" : "Qui devrait recevoir cette notification ?",
      "sendButtonLabel" : "Envoyer un courriel de test",
      "testEmailLabel" : "Courriel de test",
      "testEmailPlaceholder" : "Entrez l'adresse courriel du destinataire pour tester",
      "body" : "Corps du courriel",
      "bodyPlaceholder" : "Tapez votre message ici",
      "subject" : "Sujet",
      "subjectPlaceholder" : "Saisissez l'objet du courriel",
      "templateLabel" : "Modèle de courriel",
      "templatePlaceholder" : "Sélectionnez un modèle de courriel ou commencez à partir de zéro",
      "alertEmailAddress" : "Adresse courriel d’alerte",
      "addMore" : "Ajouter plus",
      "afterAppointmentEnds" : "après la fin du rendez-vous",
      "beforeAppointmentStarts" : "avant que le rendez-vous commence",
      "durationLabel" : "Quand souhaitez-vous déclencher la notification?",
      "disableEmailNotification" : "Désactiver les notifications par courriel",
      "enableEmailNotification" : "Activer les notifications par courriel",
      "enableSuccess" : "Notification par courriel activée!",
      "enableSuccessDesc" : "Vous allez maintenant commencer à recevoir cette notification",
      "disableSuccess" : "Notification par courriel désactivée!",
      "disableSuccessDesc" : "Vous ne recevrez plus cette notification"
    },
    "inApp" : {
      "channelName" : "In-app",
      "title" : "Paramètres de notification",
      "enableInAppNotification" : "Enable In-App Notification",
      "disableInAppNotification" : "Disable In-App Notification",
      "selectedUser" : {
        "label" : "Envoyer à",
        "helperText" : "Cette notification interne est envoyée au moyen du Web et les applications mobiles.",
        "placeholder" : "Sélectionner un ou des utilisateurs"
      },
      "enableSuccess" : "Notifications intégrées à l'application activées!",
      "enableSuccessDesc" : "Vous allez maintenant commencer à recevoir cette notification",
      "disableSuccess" : "Notifications intégrées à l'application désactivées!",
      "disableSuccessDesc" : "Vous ne recevrez plus cette notification"
    }
  }
}