import Availability from '@/pages/Availability.vue'
import CalendarPreferences from '@/pages/CalendarPreferences.vue'
import CalendarSettingsWizard from '@/pages/CalendarSettingsWizard.vue'
import Connections from '@/pages/Connections.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/v2/location/:locationId/settings/calendars',
    name: 'calendar-settings__default',
    component: CalendarSettingsWizard,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/preferences',
    name: 'calendar-settings__preferences',
    component: CalendarPreferences,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/availability',
    name: 'calendar-settings__availability',
    component: Availability,
  },
  {
    path: '/v2/location/:locationId/settings/calendars/connections',
    name: 'calendar-settings__connections',
    component: Connections,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
