<!-- eslint-disable prettier/prettier -->
<script setup lang="ts">
import NoGroupImg from '@/assets/no-group.svg'
import { UICheckbox, UIDivider, UIMenu } from '@gohighlevel/ghl-ui'
import { computed, h, onMounted, ref, watch } from 'vue'
import AppState, { setIsAdvancedCalendarPageLoading } from '../../states/app'

onMounted(() => {
  createMasterObjects()
})

// Create refs for selected groups and select all flag
const selectedGroups: any = ref([])
const selectAll = ref(false)

// Function to create master objects from globalTeams and globalCalendars
const createMasterObjects = () => {
  const globalTeams: any = AppState.globalTeams
  const groups = globalTeams?.teams
  const globalCalendars: any = AppState.globalCalendars

  const calendars = globalCalendars?.calendars
  const masterObjects: any = []

  // Create a mapping of group IDs to groups for efficient lookup
  const groupMap: any = {}
  groups?.forEach((group: any) => {
    if (group.isActive) {
      groupMap[group.id] = group
    }
  })

  // Map calendars to their respective groups
  groups?.forEach((group: any) => {
    if (group.isActive && !group.deleted) {
      // Filtering the groups based on id and also checking group is active
      const calendarsForGroup = calendars.filter(
        (calendar: any) =>
          calendar.isActive &&
          !calendar.deleted &&
          calendar.groupId === group.id &&
          calendar.calendarType === 'service_booking'
      )
      if (calendarsForGroup.length > 0) {
        const masterObject = {
          id: group.id,
          name: group.name,
          description: group.description,
          slug: group.slug,
          isActive: group.isActive,
          nosOfCalendars: group.nosOfCalendars,
          calendars: calendarsForGroup,
        }
        masterObjects.push(masterObject)
      }
    }
  })

  return masterObjects
}

// Function to handle click on a calendar checkbox
const onClickCalendar = (isChecked: boolean, group: any, calendar: any) => {
  if (isChecked) {
    // First, check if the group is already in selectedGroups
    const groupIndex = selectedGroups.value?.findIndex(
      item => item.groupId === group.id
    )
    if (groupIndex > -1) {
      // If the group is already in selectedGroups, add the calendar to its 'calendars' array
      selectedGroups.value[groupIndex]?.calendarIds?.push({ id: calendar.id })
    } else {
      selectedGroups.value?.push({
        type: 'group',
        groupId: group.id,
        name: group.name,
        sortOrder: selectedGroups.value.length,
        calendarIds: [{ id: calendar.id }],
      })
    }
  } else {
    // If isChecked is false, remove the calendar from the array
    const groupIndex = selectedGroups.value?.findIndex(
      item => item.groupId === group.id
    )
    if (groupIndex > -1) {
      const calendarIndex = selectedGroups.value[
        groupIndex
      ]?.calendarIds.findIndex(item => item.id === calendar.id)
      if (calendarIndex > -1) {
        selectedGroups.value[groupIndex]?.calendarIds.splice(calendarIndex, 1)
      }
      if (selectedGroups.value[groupIndex]?.calendarIds.length === 0) {
        selectedGroups.value.splice(groupIndex, 1)
      }
    }
  }
  selectAll.value = areAllGroupsCalendarsSelected.value
  generateMenuOptions()
}

// Function to handle click on a group checkbox
const onClickGroup = (isChecked: boolean, group: any) => {
  if (isChecked) {
    const calendarIds: any = []
    // Select all calendars for the selected group also
    group.calendars.forEach((calendar: any) => {
      calendarIds.push({ id: calendar.id })
    })
    selectedGroups.value?.push({
      type: 'group',
      groupId: group.id,
      name: group.name,
      sortOrder: selectedGroups.value.length,
      calendarIds: calendarIds,
    })
  } else {
    const index = selectedGroups.value?.findIndex(
      item => item.groupId === group.id
    )
    if (index !== -1) {
      selectedGroups.value.splice(index, 1)
    }
    // Update the sort order of the remaining groups
    for (let i = 0; i < selectedGroups.value.length; i++) {
      selectedGroups.value[i].sortOrder = i
    }
  }
  selectAll.value = areAllGroupsCalendarsSelected.value
  generateMenuOptions()
}

// Function to check if a calendar is selected// Function to check if a calendar is selected
const isCalendarSelected = calendarId => {
  return selectedGroups.value?.some(group =>
    group.calendarIds?.some(ele => ele.id === calendarId)
  )
}

// Function to handle click on "Select All" checkbox
const onClickSelectAll = (isChecked: boolean) => {
  selectAll.value = isChecked
  if (isChecked) {
    // Clear the selectedGroups array to start with an empty array
    selectedGroups.value = []

    // Iterate through the data and add all groups and calendars to selectedGroups
    const data = createMasterObjects()
    // Create a map to store calendars by groupId
    const calendarMap = new Map()

    data.forEach((group, index) => {
      const groupObject = {
        type: 'group',
        groupId: group.id,
        name: group.name,
        sortOrder: index,
        calendarIds: [],
      }
      selectedGroups.value.push(groupObject)
      group.calendars.forEach(calendar => {
        if (!calendarMap.has(calendar.groupId)) {
          calendarMap.set(calendar.groupId, [])
        }

        // Push the calendar into the appropriate groupId in calendarMap
        calendarMap.get(calendar.groupId).push({ id: calendar.id })
      })
    })
    // Iterate through calendarMap and push calendars to their respective groups
    calendarMap.forEach((calendars, groupId) => {
      const groupObject = selectedGroups.value.find(
        group => group.groupId === groupId
      )
      if (groupObject) {
        groupObject.calendarIds = calendars
      }
    })
  } else {
    selectedGroups.value = []
  }
  // Trigger a re-render by calling generateMenuOptions
  generateMenuOptions()
}

// Function to generate menu options for the UI
const generateMenuOptions = () => {
  const data = createMasterObjects()
  const menuOptions: any = []

  data.forEach((group, groupIndex) => {
    if (groupIndex > 0) {
      menuOptions.push({
        key: `divider-${group.slug}`,
        type: 'divider',
      })
    }
    menuOptions.push({
      label: () =>
        h(
          h(UICheckbox, {
            id: group.id,
            checked: selectedGroups.value?.some(el => el.groupId === group.id),
            onChange: e => onClickGroup(e, group),
            label: group.name,
          }),
          h('div', { class: `ml-2`, innerHTML: group.name })
        ),
      key: group.slug,
      children: group.calendars.map(calendar => ({
        label: () =>
          h(
            h(UICheckbox, {
              class: 'h-[25px]',
              id: calendar.id,
              checked: isCalendarSelected(calendar.id),
              onChange: e => onClickCalendar(e, group, calendar),
              label: calendar.name,
            }),
            h('div', { class: 'ml-2', innerHTML: calendar.name })
          ),
        key: calendar.id,
      })),
    })
  })

  return menuOptions
}

// Compute editingServiceMenu from AppState
const editingServiceMenu = computed(() => {
  return AppState.editingServiceMenu
})

// Define methods for loading data and emitting save action
const methods = {
  async loadData() {
    setIsAdvancedCalendarPageLoading(true)
    // Load selectedGroups from editingServiceMenu's menu property
    selectedGroups.value = editingServiceMenu.value?.menu || []
    selectAll.value = areAllGroupsCalendarsSelected.value
    setIsAdvancedCalendarPageLoading(false)
  },
  async emitSave() {
    // Emit the selectedGroups data
    const data = selectedGroups.value.filter(
      (item: any) => item.calendarIds?.length > 0
    )
    return data
  },
}

const areAllGroupsCalendarsSelected = computed(() => {
  const masterCalendarIds = new Set()
  const apiCalendarIds = new Set()
  const data = createMasterObjects()
  for (const group of data) {
    for (const calendar of group.calendars) {
      masterCalendarIds.add(calendar.id)
    }
  }

  for (const entry of selectedGroups.value) {
    for (const calendars of entry.calendarIds) {
      apiCalendarIds.add(calendars.id)
    }
  }

  return Array.from(masterCalendarIds).every(calendarId =>
    apiCalendarIds.has(calendarId)
  )
})

// Watch for changes in editingServiceMenu and load data when it changes
watch(
  () => editingServiceMenu.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

// Expose methods to the parent component
defineExpose({
  methods,
})

const isDataEmpty = computed(() => {
  const data = createMasterObjects()
  return data.length === 0
})

const menuOptions = generateMenuOptions()

const onClickLearnMore = () => {
  window.open(
    'https://help.leadconnectorhq.com/en/support/solutions/articles/155000001376-service-menu',
    '_blank'
  )
}
</script>

<template>
  <div id="service-menu-select-groups-section">
    <div class="flex flex-col">
      <div class="font-medium text-gray-700">
        {{ $t('service_menu_advanced.select_groups_calendars') }}
      </div>
      <div class="mt-1 text-gray-600">
        {{ $t('service_menu_advanced.select_groups_calendars_desc') }}
      </div>
    </div>
    <UIDivider />
    <div v-if="!isDataEmpty">
      <UICheckbox
        id="select-all"
        :checked="selectAll"
        @change="e => onClickSelectAll(e)"
      >
        {{ $t('service_menu_advanced.select_all') }}</UICheckbox
      >
      <UIDivider />

      <UIMenu :options="menuOptions" value="beverage" />
    </div>
    <div v-else class="mt-8 flex flex-col items-center justify-center gap-2">
      <div class="text-[16px] font-medium text-gray-900">
        {{ $t('service_menu_advanced.no_service') }}
      </div>
      <div class="text-[14px] font-light text-gray-500">
        {{ $t('service_menu_advanced.no_service_desc') }}
      </div>
      <div
        class="cursor-pointer font-medium text-primary-700"
        role="button"
        @click="onClickLearnMore"
        @keypress="onClickLearnMore"
      >
        {{ $t('service_menu_advanced.learn_more') }}
      </div>
      <img :src="NoGroupImg" alt="no-group-found" />
    </div>
  </div>
</template>
