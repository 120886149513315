<script setup lang="ts">
import DateSpecificHours from '@/assets/date-specific-hours.png'
import {
  InfoCircleIcon,
  PlusIcon,
  Trash02Icon,
  XCloseIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIContentWrap,
  UIDatepicker,
  UIModal,
  UIModalContent,
  UIModalFooter,
  UITimePicker,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import moment from 'moment'
import { computed, nextTick, ref, watch, watchEffect } from 'vue'

import AppState from '../../states/app'

import { MyAvailability } from '../../class/pages/MyAvailability'
import { getLocationTzTimezone } from '../../utils/timezones'

const GHLLanguageKeysMapper = {
  en_US: 'en-US',
  da: 'da',
  nl: 'nl',
  fi: 'en-US',
  fr_FR: 'fr-FR',
  fr_CA: 'fr-CA',
  de: 'de',
  it: 'it',
  no: 'en-US',
  pt_PT: 'pt-PT',
  pt_BR: 'pt-BR',
  es: 'es',
  sv: 'sv',
}

moment.locale(GHLLanguageKeysMapper[AppState.locale])

const myAvailability = ref(new MyAvailability(''))
const showOverRideModal = ref(false)
const activeId = ref('')
const overriddenDates = ref(
  moment().tz(AppState.locationTimezone).format('MMMM D, YYYY')
)

const props = defineProps({
  isRecurringEnabled: Boolean,
})
const disableView = computed(() => {
  return props.isRecurringEnabled
})

const editMode = ref(false)
const overriddenHours = ref([
  {
    startTime: '8:0',
    endTime: '17:0',
  },
])
const customSlotSelectorModal = ref()
const editingCalendar = computed(() => {
  return AppState.editingCalendar
})
const submitCallback = () => {
  if (overlappingTimes.value.length > 0) {
    return
  }

  let target = myAvailability.value.availableDays.find(item => {
    return (
      item.day ===
      moment(overriddenDates.value, 'MMMM D, YYYY').format('MMMM D, YYYY')
    )
  })

  if (target) {
    target.hours =
      overriddenHours.value?.length === 0
        ? []
        : [
            ...new Set([
              ...overriddenHours.value.map(x => ({
                startTime: x.startTime,
                endTime: x.endTime,
              })),
            ]),
          ]
  } else {
    myAvailability.value.addAvailabilityDay(
      moment(overriddenDates.value, 'MMMM D, YYYY').format('MMMM D, YYYY')
    )
    target = myAvailability.value.availableDays.find(
      item =>
        item.day ===
        moment(overriddenDates.value, 'MMMM D, YYYY').format('MMMM D, YYYY')
    )
    if (target) {
      target.hours =
        overriddenHours.value?.length === 0
          ? []
          : overriddenHours.value.map(x => ({
              startTime: x.startTime,
              endTime: x.endTime,
            }))
    }
  }

  showOverRideModal.value = false
  activeId.value = ''
  editMode.value = false
}

const cancelCallback = () => {
  showOverRideModal.value = false
  overriddenDates.value = moment()
    .tz(AppState.locationTimezone)
    .format('MMMM D, YYYY')
  overriddenHours.value = [
    {
      startTime: '8:0',
      endTime: '17:0',
    },
  ]
  activeId.value = ''
  editMode.value = false
}

const methods = {
  loadData() {
    myAvailability.value.setCustomAvailabilityDataFromDatabase(
      editingCalendar.value?.availabilities || []
    )

    // if (myAvailability.value.availableDays.length === 0) {
    //   methods.addCustomAvailabilityDay()
    // }
  },
  async addNextSlots(overriddenHours, item) {
    overriddenHours.push({
      startTime: moment(item.endTime, 'H:m')
        .set({
          hour: moment(item.endTime, 'H:m').hour() + 1,
        })
        .format('H:m'),
      endTime: moment(item.endTime, 'H:m')
        .set({
          hour: moment(item.endTime, 'H:m').hour() + 2,
        })
        .format('H:m'),
    })
    await nextTick()
    customSlotSelectorModal.value.scrollTop =
      customSlotSelectorModal.value.scrollHeight
  },
  async removeHours(index) {
    overriddenHours.value.length > 0
      ? overriddenHours.value.splice(index, 1)
      : (overriddenHours.value = [])
  },

  dateDisabled(ts: number) {
    const selectedDate = moment(ts).startOf('day')
    const currentDate = getLocationTzTimezone(
      AppState.locationTimezone
    ).startOf('day')

    return selectedDate.isBefore(currentDate)
  },
  getAvailability() {
    const openHours = myAvailability.value.availableDays.map(day =>
      day.serializeCustomAvailabilityAsPerAPIFormat()
    )
    const existingOpenHourIds = editingCalendar.value?.availabilities
      ?.map(x => x._id)
      ?.filter(x => x)

    const newOpenHourIds = openHours?.map(x => x!.id)?.filter(x => x)

    const idsToDelete = existingOpenHourIds?.filter(
      x => !newOpenHourIds.includes(x)
    )

    idsToDelete?.forEach(id => {
      const openHour = editingCalendar.value?.availabilities.find(
        x => x._id === id
      )

      openHours.push({
        date: openHour?.date,
        hours: openHour?.hours,
        id: openHour?._id,
        deleted: true,
      })
    })

    return openHours
  },
  addCustomAvailabilityDay() {
    let day = ''

    const days = myAvailability.value.availableDays.map(x =>
      moment(x.day, 'MMMM D, YYYY').tz(AppState.locationTimezone)
    )

    if (
      days.length > 0 &&
      days[days.length - 1].isSameOrAfter(
        moment().tz(AppState.locationTimezone),
        'day'
      )
    ) {
      day = days[days.length - 1].add(1, 'days').format('MMMM D, YYYY')
    } else {
      day = moment()
        .tz(AppState.locationTimezone)

        .format('MMMM D, YYYY')
    }

    myAvailability.value.addAvailabilityDay(day)
  },
  hasErrors() {
    // return myAvailability.value.hasAnyInvalidHours
    return false
  },
  getAvailabilityLength() {
    return myAvailability.value.availableDays.length
  },
  openOverrideModal() {
    showOverRideModal.value = true
    editMode.value = false
    overriddenHours.value = [
      {
        startTime: '8:0',
        endTime: '17:0',
      },
    ]
    overriddenDates.value = moment()
      .tz(AppState.locationTimezone)
      .format('MMMM D, YYYY')
  },
}

const invalidTimes = computed(() => {
  return (
    overriddenHours.value.filter(x => {
      if (
        (x.startTime === '0:0' && x.endTime === '0:0') ||
        x.endTime === '0:0'
      ) {
        return false
      }
      return (
        moment(x.startTime, 'H:m').isSameOrAfter(moment(x.endTime, 'H:m')) ||
        moment(x.endTime, 'H:m').isSameOrBefore(moment(x.startTime, 'H:m'))
      )
    }).length > 0
  )
})

const has24hourError = computed(() => {
  overriddenHours.value.forEach(h => {
    if (
      h.startTime === '0:0' &&
      h.endTime === '0:0' &&
      overriddenHours.value.length > 1
    ) {
      return true
    }
  })
  return false
})

const is24HourSelected = computed(() => {
  return overriddenHours.value.length === 1
    ? overriddenHours.value[0].startTime === '0:0' &&
        overriddenHours.value[0].endTime === '0:0'
    : false
})

const overlappingTimes = computed(() => {
  const overlaps = [] as number[]

  if (
    overriddenHours.value.length === 1 &&
    overriddenHours.value[0].startTime === '0:0' &&
    overriddenHours.value[0].endTime === '0:0'
  ) {
    return []
  }

  if (
    overriddenHours.value.length <= 1 &&
    myAvailability.value.availableDays.filter(
      x => x.day === overriddenDates.value
    ).length === 0
  ) {
    return []
  }

  for (let i = 0; i < overriddenHours.value.length - 1; i++) {
    for (let j = i + 1; j < overriddenHours.value.length; j++) {
      const startTime1 = moment(overriddenHours.value[i].startTime, 'H:mm')
      const endTime1 = moment(overriddenHours.value[i].endTime, 'H:mm')
      const startTime2 = moment(overriddenHours.value[j].startTime, 'H:mm')
      const endTime2 = moment(overriddenHours.value[j].endTime, 'H:mm')

      if (
        startTime1.isBetween(startTime2, endTime2) ||
        endTime1.isBetween(startTime2, endTime2) ||
        startTime2.isBetween(startTime1, endTime1) ||
        endTime2.isBetween(startTime1, endTime1)
      ) {
        overlaps.push(i)
        overlaps.push(j)
      }
    }
  }

  return [...new Set(overlaps)]
})

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

watchEffect(() => {
  const existsHour = myAvailability.value.availableDays.find(
    x => x.day === overriddenDates.value
  ) as any

  if (existsHour) {
    overriddenHours.value = existsHour.hours.map(x => ({
      startTime: x.startTime,
      endTime: x.endTime,
    }))
  } else {
    overriddenHours.value = [
      {
        startTime: '8:0',
        endTime: '17:0',
      },
    ]
  }
})

const handleEdit = message => {
  activeId.value = message.id
  editMode.value = true
  overriddenDates.value = moment(message.day, 'MMMM D, YYYY').format(
    'MMMM D, YYYY'
  )
  overriddenHours.value = message.hours.map(x => ({
    startTime: x.startTime,
    endTime: x.endTime,
  }))
  showOverRideModal.value = true
}

defineExpose({
  methods,
})
</script>
<template>
  <UIContentWrap :locale="GHLLanguageKeysMapper[AppState.locale]">
    <div>
      <div class="mb-4 flex items-center gap-1">
        <p class="text-sm font-semibold">
          {{ $t('calendar_advanced.availability.specific_hours_button') }}
        </p>

        <UITooltip :placement="'top'">
          <template #trigger>
            <InfoCircleIcon class="w-4 text-gray-500" />
          </template>
          <p class="max-w-sm">
            {{ $t('calendar_advanced.availability.tooltip_2') }}
          </p>
        </UITooltip>
      </div>
      <p
        class="mb-4 text-sm text-gray-500"
        :class="{
          hidden: !disableView,
        }"
      >
        To include a date-specific hour, please ensure that recurring meetings
        are turned off.
      </p>
      <UIButton
        id="add-override"
        v-model="showOverRideModal"
        type="default"
        size="medium"
        class="w-full overflow-hidden px-2 text-xs"
        :title="$t('calendar_advanced.availability.date_specific_level')"
        :disabled="disableView"
        @click="methods.openOverrideModal"
      >
        {{ $t('calendar_advanced.availability.date_specific_level') }}
      </UIButton>

      <div
        v-if="myAvailability.availableDays.length === 0"
        class="my-20 flex flex-col items-center justify-center"
      >
        <img
          :src="DateSpecificHours"
          class="mb-2 h-36"
          alt="date-specific-hours"
        />
        <p class="text-md text-center font-semibold text-gray-900">
          {{ $t('calendar_advanced.availability.no_date_specific_heading') }}
        </p>

        <p class="text-center text-xs text-gray-600">
          {{
            $t('calendar_advanced.availability.no_date_specific_description')
          }}
        </p>
      </div>

      <div v-else>
        <div
          v-for="(availableDay, index) in myAvailability.availableDays"
          :key="index"
          class="mt-4 flex cursor-pointer items-center justify-between rounded border-[1px] border-gray-200 px-2 py-4"
          :class="{
            'line-through': methods.dateDisabled(
              moment(availableDay.day, 'MMMM D, YYYY').valueOf()
            ),
          }"
          role="button"
          @click="handleEdit(availableDay)"
          @keydown="handleEdit(availableDay)"
        >
          <div class="flex-1">
            <p class="text-sm font-semibold text-gray-700">
              {{ availableDay.day }}
            </p>
            <p
              v-if="availableDay.hours.length === 0"
              class="text-sm text-gray-500"
            >
              {{ $t('calendar_advanced.availability.unavailable_label') }}
            </p>
            <p
              v-for="(hour, hourIndex) in availableDay.hours"
              v-else
              :key="hourIndex"
              class="mt-1 text-sm font-normal text-gray-500"
            >
              {{ moment(hour.startTime, 'HH:mm').format('hh:mm A') }} -
              {{ moment(hour.endTime, 'HH:mm').format('hh:mm A') }}
            </p>
          </div>
          <UIButton
            id="remove-availability-hour-custom"
            class="px-3 text-red-600"
            :text="true"
            aria-label="Remove Custom Slot"
            @click.stop="myAvailability.removeAvailabilityDay(availableDay.day)"
          >
            <Trash02Icon class="w-4" />
          </UIButton>
        </div>
      </div>
      <UIModal :show="showOverRideModal" :width="460">
        <template #header>
          <div class="flex w-full items-center justify-between">
            <p class="w-3/4 text-lg text-gray-900">
              {{ $t('calendar_advanced.availability.subTitle') }}
              <span class="hidden">{{ overriddenDates }}</span>
            </p>
            <XCloseIcon
              class="h-6 w-6 cursor-pointer text-gray-500"
              aria-label="Close Modal"
              role="button"
              @click="cancelCallback()"
              @keydown="cancelCallback()"
            />
          </div>
        </template>
        <UIModalContent>
          <div
            id="do-picker"
            class="mb-5 flex items-center justify-center rounded-lg border-[1px] border-gray-200 py-8"
          >
            <UIDatepicker
              id="date-override picker"
              v-model:formatted-value="overriddenDates"
              :actions="[]"
              :panel="true"
              class="scale-125 transform"
              :is-date-disabled="methods.dateDisabled"
              format="MMMM d, yyyy"
            />
          </div>
          <div class="border-[1px] border-gray-200 p-4">
            <div class="flex items-center justify-between">
              <p class="mb-3 text-sm font-semibold text-gray-800">
                {{ $t('calendar_advanced.availability.heading_2') }}
              </p>
              <UIButton
                v-if="overriddenHours.length === 0"
                id="add-override-custom"
                aria-label="Add Custom Slot"
                :text="true"
                class="text-gray-700"
                @click="
                  overriddenHours.push({
                    startTime: '8:0',
                    endTime: '17:0',
                  })
                "
              >
                <PlusIcon class="w-4" />
              </UIButton>
            </div>

            <div
              v-if="overriddenDates"
              ref="customSlotSelectorModal"
              class="max-h-40 overflow-y-auto"
            >
              <div v-if="overriddenHours.length == 0">
                <p class="text-md font-semibold text-gray-600">
                  {{ $t('calendar_advanced.availability.unavailable_label') }}
                </p>
              </div>
              <div
                v-for="(item, index) in overriddenHours"
                :key="index"
                class="mb-2 flex items-center gap-2"
              >
                <UITimePicker
                  v-model:formatted-value="item.startTime"
                  value-format="H:m"
                  :minutes="5"
                  format="hh:mm a"
                  use-12-hours
                  :actions="['confirm']"
                  class="w-full"
                  :status="
                    !item.startTime ||
                    overlappingTimes.includes(index) ||
                    (moment(item.endTime, 'H:m').isSameOrBefore(
                      moment(item.startTime, 'H:m')
                    ) &&
                      item.endTime !== '0:0' &&
                      item.startTime !== '0:0')
                      ? 'error'
                      : 'success'
                  "
                />

                <div class="w-8 min-w-max font-semibold capitalize">
                  {{ $t('calendar_advanced.availability.to') }}
                </div>
                <UITimePicker
                  v-model:formatted-value="item.endTime"
                  value-format="H:m"
                  :minutes="5"
                  format="hh:mm a"
                  use-12-hours
                  :actions="['confirm']"
                  class="w-full"
                  :status="
                    !item.endTime ||
                    overlappingTimes.includes(index) ||
                    (moment(item.endTime, 'H:m').isSameOrBefore(
                      moment(item.startTime, 'H:m')
                    ) &&
                      item.endTime !== '0:0' &&
                      item.startTime !== '0:0')
                      ? 'error'
                      : 'success'
                  "
                />
                <div class="mr-6 flex items-center justify-around">
                  <div class="ml-4 w-4">
                    <UIButton
                      v-if="index === overriddenHours.length - 1"
                      id=""
                      :text="true"
                      class="text-gray-700"
                      :disabled="is24HourSelected"
                      aria-label="Add Custom Slot"
                      @click="methods.addNextSlots(overriddenHours, item)"
                    >
                      <PlusIcon class="w-4" />
                    </UIButton>
                  </div>
                  <div class="ml-4 w-4">
                    <UIButton
                      id="add-override-hour-custom"
                      class="text-red-600"
                      :text="true"
                      aria-label="Remove Custom Slot"
                      @click="methods.removeHours(index)"
                    >
                      <Trash02Icon class="w-4" />
                    </UIButton>
                  </div>
                </div>
              </div>
            </div>
            <p
              v-if="overlappingTimes.length > 0"
              class="text-left text-sm text-red-600"
            >
              {{ $t('calendar_advanced.availability.overlap_error') }}
            </p>
          </div>
        </UIModalContent>
        <template #footer>
          <UIModalFooter
            id="modal-footer"
            positive-text="Submit"
            negative-text="Cancel"
            :disabled="
              overlappingTimes.length > 0 ||
              methods.hasErrors() ||
              invalidTimes ||
              has24hourError
            "
            @positive-click="submitCallback"
            @negative-click="cancelCallback"
          />
        </template>
      </UIModal>
    </div>
  </UIContentWrap>
</template>
<style lang="scss">
#do-picker {
  --primary-600: #344054 !important;

  .n-date-panel-weekdays {
    font-weight: 500 !important;
  }

  .n-date-panel-month__prev {
    margin-left: -50px;
    transform: scale(1.5);
  }

  .n-date-panel-month__next {
    margin-right: -50px;
    transform: scale(1.5);
  }

  .n-date-panel-month__fast-next {
    height: 0px !important;
    width: 0px !important;
  }

  .n-date-panel-month__fast-prev {
    height: 0px !important;
    width: 0px !important;
  }

  .n-tabs {
    height: 90%;
    overflow: hidden;
  }

  .n-tab-pane {
    height: 90% !important;
    overflow: scroll !important;
  }
}
</style>
