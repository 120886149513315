import config from '@/config'
import AppState from '@/states/app'
import { requests } from './BaseService'

export const CustomFieldService = {
  async getAllFields(getStandardFields = true) {
    const resp = await requests().get(
      `${config.restApiUrl}/locations/${AppState.locationId}/customFields/search?limit=10000&documentType=field&includeStandards=${getStandardFields}&model=contact`
    )

    return resp?.data.customFields
  },
  async getAllCustomFields() {
    return CustomFieldService.getAllFields(false)
  },
  async getCustomFields(locationId: string) {
    const query = {
      model: 'opportunity',
    }
    return requests().get(
      `${config.restApiUrl}/locations/${locationId}/customFields`,
      { params: query }
    )
  },
  async getCustomValues(): Promise<any[]> {
    try {
      const { data } = await requests().get(
        `${config.restApiUrl}/conversations`,
        {
          params: {
            locationId: AppState.locationId,
            types: 'custom-values',
          },
        }
      )
      if (data?.['custom-values']) {
        return mapCustomValues(data['custom-values'])
      }
      return []
    } catch (error) {
      console.error('Error while getting custom values: ' + error)
      return []
    }
  },
}
function mapCustomValues(customValues): any[] {
  return customValues.map(customValue => {
    if (customValue.children) {
      return {
        name: customValue?.label,
        children: mapCustomValues(customValue.children),
      }
    }
    return {
      name: customValue?.label,
      fieldKey: customValue?.value?.replaceAll(' ', ''),
      id: customValue?.meta?.id,
      value: customValue?.meta.value,
    }
  })
}
