<script setup lang="ts">
import AppState, {
  getExternalCalendars,
  setEditCalendarValidationData,
} from '@/states/app'
import { ArrowNarrowUpRightIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UICheckbox,
  UIDivider,
  UIForm,
  UIFormItem,
  UIHeader,
  UIInputGroup,
  UIInputNumber,
  UISelect,
} from '@gohighlevel/ghl-ui'
import { flatten } from 'lodash'
import { computed, reactive, ref, watch } from 'vue'
import { CalendarType } from '../../models/calendar'
import { CATEGORY, LSA } from '../../utils/LSA'
import { currency } from '../../utils/currencyHelper'

import {
  RefreshCcw01Icon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const state = reactive({
  isLoading: false,
  isFetchingExternalCalendars: false,
  googleCalendars: [] as any[],
  googleAccounts: [] as any[],
  haveMerchant: false,
  formData: {
    selectedCalendar: '',
    syncOption: 'oneway',
    shouldExecuteAutomation: false,
    reserveService: false,
    category: '',
    service: '',
    price: undefined,
    currencyCode: 'USD',
  },
  syncOptions: [
    {
      value: 'oneway',
      label: t('calendar_advanced.connections.one_way'),
    },
    { value: 'twoway', label: t('calendar_advanced.connections.two_way') },
    { value: 'smart', label: t('calendar_advanced.connections.smart') },
  ],
})
const rwgFormRef = ref()

const categoryOptions = computed(() => {
  return CATEGORY.map(category => {
    return {
      value: category.value,
      label: category.key,
    }
  })
})

const serviceTypeOptions = computed(() => {
  const category = CATEGORY.find(
    category => category.value === state.formData.category
  )

  if (category) {
    return LSA[category.value].map(jobType => {
      return {
        value: `${jobType.vertical}-${jobType.job_type_id}`,
        label: jobType.job_type,
      }
    })
  }

  return []
})

const paymentCurrencyOptions = computed(() => {
  const currencyOptions: string[] = []
  for (const key of Object.keys(currency)) {
    currencyOptions.push(currency[key].code)
  }
  return currencyOptions.map(x => {
    return {
      value: x,
      label: x,
      disabled: x !== 'USD',
    }
  })
})

watch(
  () => state.formData.category,
  (newValue, oldValue) => {
    if (oldValue) {
      state.formData.service = ''
    }
  }
)

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})
const emit = defineEmits(['onSave', 'onCancel'])

const methods = {
  async loadData() {
    const googleCal = editingCalendar.value?.linkedCalendars?.google
    await Promise.all([methods.loadExternalCalendars()])

    if (googleCal?.id) {
      state.formData.selectedCalendar = googleCal?.oauthId + '_' + googleCal?.id

      state.formData.syncOption = editingCalendar.value?.syncOption

      if (editingCalendar.value?.syncOption === 'disable_trigger') {
        state.formData.syncOption = 'twoway'
        state.formData.shouldExecuteAutomation = false
      } else if (editingCalendar.value?.syncOption === 'twoway') {
        state.formData.syncOption = 'twoway'
        state.formData.shouldExecuteAutomation = true
      }
    }

    state.formData.reserveService = Boolean(
      editingCalendar.value?.reserveService
    )
    if (
      editingCalendar.value?.homeServiceData?.categoryType &&
      editingCalendar.value?.homeServiceData?.jobType
    ) {
      state.formData.category = categoryOptions.value.find(
        category =>
          category.value ===
          editingCalendar.value?.homeServiceData?.categoryType
      )?.value as string

      state.formData.service = `${editingCalendar.value?.homeServiceData?.categoryType}-${editingCalendar.value?.homeServiceData?.jobType}`

      state.formData.price = editingCalendar.value?.price?.priceMicros
        ? String(editingCalendar.value?.price?.priceMicros / 1000000)
        : ''

      state.formData.currencyCode = editingCalendar.value?.price?.currencyCode
        ? editingCalendar.value?.price?.currencyCode
        : 'USD'
    }
  },
  async openIntegratioinsPage() {
    AppState.parentApp?.then(parent => {
      parent.emit('open-integrations-page', {
        providerId: undefined,
      })
    })
  },
  async removeGoogleCalendar() {
    if (!confirm(t('calendar_advanced.connections.confirm_remove'))) {
      return
    }

    state.formData.selectedCalendar = ''
    state.formData.syncOption = 'oneway'
    state.formData.shouldExecuteAutomation = false
  },
  async loadExternalCalendars() {
    state.isFetchingExternalCalendars = true
    await getExternalCalendars()
    const calendars = AppState.externalCalendars
    const calendarList = flatten(Object.values(calendars))

    calendarList.forEach((googleCal: any) => {
      googleCal.uid = googleCal.oauthId + '_' + googleCal.id
    })

    state.googleAccounts = calendars
    state.googleCalendars = calendarList
    state.isFetchingExternalCalendars = false
  },
  async emitSave() {
    const selectedGoogleCalendar = state.googleCalendars.find(
      (cal: any) => cal.uid === state.formData.selectedCalendar
    )

    let reserveData: any = {}

    if (
      rwgFormRef.value &&
      state.formData.reserveService &&
      state.formData.service
    ) {
      try {
        await rwgFormRef.value.getForm().validate()
      } catch (error) {
        return
      }
      reserveData = {
        reserveService: state.formData.reserveService,
        homeServiceData: {
          categoryType: state.formData.service.split('-')[0],
          jobType: state.formData.service.split('-')[1],
        },
        price: {
          priceMicros: Math.round(Number(state.formData.price) * 1000000),
          currencyCode: state.formData.currencyCode,
        },
      }
    }

    if (selectedGoogleCalendar) {
      const googleCal = editingCalendar?.value?.linkedCalendars?.google

      let linkedCalendars: any = {}

      if (
        state.formData.selectedCalendar.includes(googleCal?.id) &&
        selectedGoogleCalendar?.oauthId?.includes(googleCal?.oauthId)
      ) {
        linkedCalendars = {
          google: {
            userId: AppState?.user?.id,
            ...googleCal,
          },
        }
      } else {
        linkedCalendars = {
          google: {
            id: selectedGoogleCalendar.id,
            userId: AppState?.user?.id,
            name: selectedGoogleCalendar.summary,
            accessRole: selectedGoogleCalendar.accessRole,
            oauthId: selectedGoogleCalendar.oauthId,
          },
        }
      }

      let syncOption = state.formData.syncOption

      if (state.formData.syncOption === 'twoway') {
        syncOption = state.formData.shouldExecuteAutomation
          ? 'twoway'
          : 'disable_trigger'
      }

      return {
        linkedCalendars,
        syncOption,
        ...reserveData,
      }
    }
    return {
      linkedCalendars: {},
      ...reserveData,
    }
  },
}

const rules = computed(() => {
  return {
    price: {
      trigger: ['input', 'blur'],
      validator() {
        if (!state.formData.price) {
          return true
        }
        if (state.formData?.price < 0 || state.formData?.price > 1000) {
          return new Error(
            t('calendar_advanced.connections.reserve_price_max_error')
          )
        }
        return true
      },
    },
  }
})
const calendarOptions = computed(() => {
  const masterOptions: any = []
  const googleAccounts = Object.keys(state.googleAccounts)
  googleAccounts.forEach(accountName => {
    const calendars = state.googleAccounts[accountName]
    masterOptions.push({
      type: 'group',
      label: `${accountName}`,
      key: `${accountName}`,
      children: calendars.map((calendar: any) => {
        return {
          label: calendar.summary,
          value: `${calendar.oauthId}_${calendar.id}`,
        }
      }),
    })
  })
  return masterOptions
})

const readOnlyAccessMessage = computed(() => {
  const selectedGoogleCalendar = state.googleCalendars.find(
    (cal: any) => cal.uid === state.formData.selectedCalendar
  )

  if (selectedGoogleCalendar) {
    if (selectedGoogleCalendar.accessRole.includes('reader')) {
      return t('calendar_advanced.connections.read_only_access')
    }
  }

  return ''
})

defineExpose({
  methods,
})

watch(
  () => AppState.merchant,
  () => {
    if (Object.keys(AppState.merchant).length > 0) {
      state.haveMerchant = true
      setEditCalendarValidationData('haveMerchant', state.haveMerchant)
    }
  },
  {
    deep: true,
    immediate: true,
  }
)

watch(
  () => editingCalendar.value,
  () => {
    if (editingCalendar.value) {
      methods.loadData()
    }
  },
  { immediate: true }
)
</script>

<template>
  <UIHeader
    v-if="editingCalendar?.calendarType === CalendarType.EVENT"
    id="text-only-header"
    :secondary-title="$t('calendar_advanced.connections.connections_heading')"
    :description="$t('calendar_advanced.connections.connections_sub')"
  >
  </UIHeader>
  <UIDivider />
  <div v-if="editingCalendar?.calendarType === CalendarType.EVENT" class="">
    <div class="flex items-center space-x-7">
      <UIFormItem
        :label="$t('calendar_advanced.connections.link_to_calendar')"
        path="teamMembers"
      >
        <div class="flex w-96 flex-col space-y-2">
          <UISelect
            id="calendar-external-cal-select"
            v-model:value="state.formData.selectedCalendar"
            :filterable="true"
            :options="calendarOptions"
          />
          <div v-if="!!readOnlyAccessMessage" class="text-red-600">
            {{ readOnlyAccessMessage }}
          </div>
        </div>
      </UIFormItem>

      <UIButton
        v-if="state.formData.selectedCalendar"
        id="delete-connections-calendar"
        class="ml-7 text-red-600"
        :text="true"
        @click="methods.removeGoogleCalendar"
      >
        <Trash01Icon class="w-4"></Trash01Icon>
      </UIButton>

      <UIButton
        id=""
        class="ml-7 text-blue-600"
        :text="true"
        @click="methods.loadExternalCalendars"
      >
        <RefreshCcw01Icon
          :class="{ 'animate-spin': state.isFetchingExternalCalendars }"
          class="w-4"
        ></RefreshCcw01Icon>
      </UIButton>
    </div>

    <UIFormItem
      v-if="state.formData.selectedCalendar"
      :label="$t('calendar_advanced.connections.sync_option')"
      path="sync_option"
    >
      <div class="flex w-96 flex-col space-y-2">
        <UISelect
          id="calendar-sync-options"
          v-model:value="state.formData.syncOption"
          :options="state.syncOptions"
        />
      </div>
    </UIFormItem>

    <div
      v-if="state.formData.syncOption === 'twoway'"
      class="flex flex-col space-y-0.5"
    >
      <UICheckbox
        id="automation-checkbox"
        v-model:checked="state.formData.shouldExecuteAutomation"
      >
        {{ $t('calendar_advanced.connections.execute_automation') }}
      </UICheckbox>

      <div class="pl-7 text-gray-400">
        {{ $t('calendar_advanced.connections.fire_workflow') }}
      </div>
    </div>

    <div
      class="flex cursor-pointer items-center space-x-2 pt-2"
      @click="methods.openIntegratioinsPage"
    >
      <div class="text-base font-medium text-blue-600">
        {{ $t('calendar_advanced.connections.setup_external') }}
      </div>

      <ArrowNarrowUpRightIcon class="h-5 w-5 text-blue-600" />
    </div>
    <UIDivider />
  </div>
  <div v-if="state.haveMerchant" class="">
    <UIHeader
      id="text-only-header"
      :secondary-title="$t('calendar_advanced.connections.reserve_with_google')"
      :description="$t('calendar_advanced.connections.connect_lsa')"
    >
    </UIHeader>

    <UIDivider />
    <UICheckbox
      id="automation-checkbox"
      v-model:checked="state.formData.reserveService"
    >
      Reserve with Google
    </UICheckbox>

    <div v-if="state.formData.reserveService" class="">
      <UIForm
        id="reserve-service-form"
        ref="rwgFormRef"
        :model="state.formData"
        :rules="rules"
      >
        <div class="mt-10 flex space-x-5">
          <div class="w-64">
            <UIFormItem label="Category" path="categoryType">
              <UISelect
                id="calendar-reserve-service-select"
                v-model:value="state.formData.category"
                :options="categoryOptions"
              />
            </UIFormItem>
          </div>

          <div class="w-64">
            <UIFormItem label="Service type" path="jobType">
              <UISelect
                id="calendar-reserve-category-select"
                v-model:value="state.formData.service"
                :options="serviceTypeOptions"
              />
            </UIFormItem>
          </div>

          <div class="w-52">
            <UIFormItem :label="$t('create_calendar.amount')" path="price">
              <UIInputGroup>
                <UIInputNumber
                  id="connections-page-reserve-price"
                  v-model="state.formData.price"
                  class="w-full"
                  :show-button="false"
                  size="large"
                  placeholder=""
                >
                </UIInputNumber>
                <UISelect
                  id="connections-page-reserve-currency-options"
                  v-model:value="state.formData.currencyCode"
                  class="w-32"
                  :options="paymentCurrencyOptions"
                >
                </UISelect>
              </UIInputGroup>
            </UIFormItem>
          </div>
        </div>

        <div class="mt-6 text-sm font-light text-gray-400">
          {{ $t('calendar_advanced.connections.reserve_with_google_desc') }}
        </div>

        <div class="pt-3 text-blue-700">
          {{ $t('calendar_advanced.connections.check_integration') }}
        </div>
      </UIForm>
    </div>
  </div>
  <!-- <div
    v-else
    class="flex space-x-4 rounded-md border border-gray-300 bg-gray-100 p-3"
  >
    <InfoCircleIcon class="h-7 w-7" />
    <div class="flex flex-col space-y-3">
      <div class="text-gray-500">
        {{ $t('calendar_advanced.connections.reserve_error') }}
      </div>

      <div class="cursor-pointer text-gray-600">Learn more</div>
    </div>
  </div> -->
</template>
