import AppState from '@/states/app'
import { requests } from './BaseService'
import config from '@/config'
export const FileService = {
  async uploadFiles(
    formData: FormData,
    calendarId: string,
    location: string
  ): Promise<{ url: string; name: string }[]> {
    // AppState.uploading = true
    const res = await requests().post(
      `${config.calendarApiUrl}/calendars/${calendarId}/logo`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          locationId: location,
        },
      }
    )
    // AppState.uploading = false
    const data = [{ url: res.data.uploadedUrl, name: '' }]
    return data as {
      url: string
      name: string
    }[]
  },
}
