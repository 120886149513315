<template>
  <UIDropdownTree
    :options="menu"
    trigger-label=""
    :debug="true"
    @on-select="updateValue"
  >
    <template #trigger="triggerProps">
      <UIButton id="dd" quaternary class="px-1">
        <Tag01Icon class="h-5 w-5" />
      </UIButton>
    </template>
  </UIDropdownTree>
</template>

<script lang="ts">
import { Tag01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UIDropdownTree } from '@gohighlevel/ghl-ui'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { fetchCustomFields as fetchCustomFieldsSubMenu } from '@/states/app'
export enum CustomFieldDataType {
  TEXT = 'TEXT',
  LARGE_TEXT = 'LARGE_TEXT',
  NUMERICAL = 'NUMERICAL',
  PHONE = 'PHONE',
  MONETORY = 'MONETORY',
  CHECKBOX = 'CHECKBOX',
  SINGLE_OPTIONS = 'SINGLE_OPTIONS',
  MULTIPLE_OPTIONS = 'MULTIPLE_OPTIONS',
  RADIO = 'RADIO',
  DATE = 'DATE',
  TEXTBOX_LIST = 'TEXTBOX_LIST',
  FILE_UPLOAD = 'FILE_UPLOAD',
  SIGNATURE = 'SIGNATURE',
}
export type MenuV2 = {
  label: string
  value: string
  children?: MenuV2[]
  type?: string
}
import { getNestedCustomValues } from '../../states/app'
import { getRequiredFields } from '@/utils/customValueMapper'
export default defineComponent({
  name: 'CustomVariablePickerV2',
  components: {
    Tag01Icon,
    UIButton,
    UIDropdownTree,
  },
  props: {
    class: String,
    noMarginTop: {
      type: Boolean,
      default: false,
    },
    isNumericOnly: {
      type: Boolean,
      default: false,
    },
    isDateOnly: {
      type: Boolean,
      default: false,
    },
    outsideField: {
      type: Boolean,
      default: false,
    },
    openFromTop: {
      type: Boolean,
      default: false,
    },
    openLeft: {
      type: Boolean,
      default: false,
    },
    showTextSelector: {
      type: Boolean,
      default: false,
    },
    requiredFields: {
      type: Object,
      required: false,
      default: () => ({
        contact: ['all'],
        user: ['all'],
        appointment: ['all'],
        calendar: ['all'],
        account: ['all'],
        right_now: ['all'],
        attribution: ['all'],
      }),
    },
  },
  emits: ['update:selectedValue'],
  setup(props, { emit }) {
    const { t } = useI18n()
    let customFields = [] as any[]
    let customValues = [] as any[]
    let customFieldsSubMenu = [] as any[]
    const isLoading = ref(false)

    const currentMenuItem = ref({} as MenuV2)
    const currentSubMenuItem = ref({} as MenuV2)
    const showMenu = ref(false as boolean)
    const menu = ref([] as MenuV2[])

    const isStaticHeight = computed(
      () => !props.isNumericOnly && !props.isDateOnly
    )

    const contactIdx = menu.value.findIndex(
      option => option.label.toLowerCase() === 'contact'
    )

    if (contactIdx === -1) {
      menu.value.push({
        label: 'Contact',
        value: 'contact',
        children: [],
      })
    }

    function updateMenuItem(payload: MenuV2) {
      currentMenuItem.value = payload
      currentSubMenuItem.value = {} as MenuV2
    }

    function updateSubMenuItem(payload: MenuV2) {
      currentSubMenuItem.value = payload
      if (!currentSubMenuItem.value.children) {
        emit('update:selectedValue', currentSubMenuItem.value)
        showMenu.value = false
      }
    }

    function updateValue(selected: string) {
      emit('update:selectedValue', selected)
      showMenu.value = false
    }

    function toggleMenu() {
      showMenu.value = !showMenu.value
      currentSubMenuItem.value = {} as MenuV2
      currentMenuItem.value = {} as MenuV2
    }

    const nestedMenu = computed(() => {
      return currentMenuItem.value.children || []
    })

    const nestedSubMenu = computed(() => {
      return currentSubMenuItem.value.children || []
    })
    function createMenuForCustomValues(list: any[]): MenuV2[] {
      return list.map(({ name, fieldKey, children }: any) => {
        if (children) {
          return {
            label: name,
            value: `${name}`,
            children: createMenuForCustomValues(children),
          }
        }
        return {
          label: name,
          value: fieldKey,
        }
      })
    }
    const getCustomFieldsSubMenu = (type: 'contact' | 'form_data') => {
      return {
        label: t('eventNotification.customFields'),
        value: 'custom_fields',
        children: customFieldsSubMenu
          .filter(field => {
            if (
              props.isNumericOnly &&
              field.dataType !== CustomFieldDataType.MONETORY &&
              field.dataType !== CustomFieldDataType.NUMERICAL
            ) {
              return false
            }
            if (
              props.isDateOnly &&
              field.dataType !== CustomFieldDataType.DATE &&
              field.dataType !== CustomFieldDataType.TEXT
            ) {
              return false
            }
            return true
          })
          .map(field => {
            const indexOfFirstDot = field.fieldKey.indexOf('.')
            let onlyFieldKey: string = field.fieldKey
            if (
              indexOfFirstDot > -1 &&
              indexOfFirstDot < field.fieldKey.length
            ) {
              onlyFieldKey = field.fieldKey.substring(indexOfFirstDot)
            }
            return {
              label: field.name,
              value: `{{${type + onlyFieldKey}}}`,
              type: field.dataType,
            }
          }),
      }
    }
    function addCustomFields() {
      const contactIndex = menu.value.findIndex(
        option => option.label.toLowerCase() === 'contact'
      )

      const formDataIndex = menu.value.findIndex(
        option => option.label.toLowerCase() === 'form'
      )

      const contactCustomFieldSubMenu = getCustomFieldsSubMenu('contact')
      const formDataCustomFieldSubMenu = getCustomFieldsSubMenu('form_data')

      if (contactIndex !== -1) {
        menu.value[contactIndex].children?.push(contactCustomFieldSubMenu)
      }

      if (formDataIndex !== -1) {
        menu.value[formDataIndex].children?.push(formDataCustomFieldSubMenu)
      }
    }
    function addCustomValues() {
      if (props.isNumericOnly) {
        customValues = customValues.filter(field => !isNaN(field.value))
      }
      menu.value.push({
        label: t('eventNotification.customValues'),
        value: 'customValue',
        children: createMenuForCustomValues(customValues),
      })
    }

    function mapCustomValues(customValues: any[]): any[] {
      return customValues.map((customValue: any) => {
        if (customValue.children) {
          return {
            name: customValue?.label,
            children: mapCustomValues(customValue.children),
          }
        }
        return {
          name: customValue?.label,
          fieldKey: customValue?.value?.replaceAll(' ', ''),
          id: customValue?.meta?.id,
          value: customValue?.meta.value,
        }
      })
    }

    async function initializeCustomFields() {
      isLoading.value = true

      // Fetch the custom fields based on required fields
      customFieldsSubMenu = await fetchCustomFieldsSubMenu()
      const availableCustomFields = getRequiredFields(props.requiredFields)
      customFields = availableCustomFields

      // Fetch custom values asynchronously
      customValues = (await getNestedCustomValues()) as any[]
      customValues = mapCustomValues(customValues)

      // Set menu to the updated custom fields
      menu.value = [...customFields]
      addCustomFields()
      if (customValues?.length) {
        addCustomValues()
      }
      isLoading.value = false
    }

    onMounted(async () => {
      await initializeCustomFields()
    })

    return {
      nestedMenu,
      updateMenuItem,
      updateSubMenuItem,
      currentMenuItem,
      currentSubMenuItem,
      nestedSubMenu,
      showMenu,
      toggleMenu,
      menu,
      updateValue,
      isLoading,
      isStaticHeight,
    }
  },
  mounted() {
    if (this.class) {
      const classList = this.class.split(' ')
      classList.forEach(customClass => {
        this.$refs.picker.classList.add(customClass)
      })
    }
  },
})
</script>
