import {
  AltType,
  NotificationChannel,
  NotificationConfig,
  ReceiverType,
} from '@/types/notification'
import { Ref, reactive, ref } from 'vue'

import AppState from './app'
import Calendar from '@/models/calendar'
import { Editor } from '@tiptap/vue-3'
import Email from '@/models/email'
import { getDefaultEmailSubjectAndBody } from '../utils/default-notification-template'
import { formatHTMLForTipTap } from '@gohighlevel/ghl-text-editor'

const receiverTypes: string[] = Object.values(ReceiverType)

export const eventNotificationState = reactive({
  calendarId: '',
  isEventCalendar: false,
  isCustomFormSelected: false,
  calendar: {} as Calendar | null,
  isLoading: false,
  saving: false,
  disableSave: false,
  eventNotificationData: [],
  eventNotifications: [] as NotificationConfig[],
  accordions: [] as {
    id: string
    isOpen: boolean
    isChecked: boolean
  }[],
  lastOpenedAccordion: null as number | null,
  inAppNotification: {} as NotificationConfig,
  enableNotification: false,
  contact: {},
  guest: {},
  assignedUser: {},
  additionalEmails: {},
})
export const resetState = () => {
  eventNotificationState.eventNotifications = []
  eventNotificationState.contact = {}
  eventNotificationState.guest = {}
  eventNotificationState.assignedUser = {}
  eventNotificationState.additionalEmails = {}
}
export class EventNotification {
  actionTemplate: Ref<Email | undefined>
  state = reactive({} as NotificationConfig)
  editor: Editor
  toAddress: Ref<string | undefined>
  open = ref(false)
  checked = ref(false)
  constructor(props: NotificationConfig) {
    this.actionTemplate = ref<Email>(new Email())
    this.editor = [] as any
    this.toAddress = ref<string | undefined>(undefined)
    this.open = ref(false)
    this.checked = ref(false)
    this.state = reactive({
      _id: props._id,
      altType: props.altType,
      altId: props.altId,
      receiverType: props.receiverType,
      additionalEmailIds: props.additionalEmailIds,
      channel: props.channel,
      notificationType: props.notificationType,
      isActive: props.isActive,
      templateId: props.templateId,
      body: formatHTMLForTipTap(props.body || ''),
      subject: props.subject,
      beforeTime: props.beforeTime || [],
      afterTime: props.afterTime || [],
      selectedUsers: props.selectedUsers,
      fromAddress: props.fromAddress || '',
      fromName: props.fromName || '',
    })
  }
}

export const updateStateOfNotification = (
  id: string,
  state: Partial<NotificationConfig>
) => {
  const index = eventNotificationState.eventNotifications.findIndex(
    notification => notification._id === id
  )
  if (index !== -1) {
    eventNotificationState.eventNotifications[index] = {
      ...eventNotificationState.eventNotifications[index],
      ...state,
    }
  } else {
    console.warn(`Notification with id ${id} not found.`)
  }
}

export const getDefaultNotifications = (
  notification: Partial<NotificationConfig>
) => {
  const defaultNotifications = [] as NotificationConfig[]
  receiverTypes.forEach(receiverType => {
    if (
      !(
        eventNotificationState.isEventCalendar &&
        receiverType === ReceiverType.ASSIGNED_USER
      )
    ) {
      notification.receiverType = receiverType as any
      notification.channel = notification.channel || NotificationChannel.EMAIL
      const defaultNotification = getDefaultNotification(notification)
      if (defaultNotification) {
        defaultNotifications.push(defaultNotification as NotificationConfig)
      }
    }
  })
  return defaultNotifications
}

export const getDefaultNotification = (
  notification: Partial<NotificationConfig>
): NotificationConfig => {
  const defaultTemplate = getDefaultEmailSubjectAndBody(
    notification,
    eventNotificationState.isCustomFormSelected
  )
  if (
    notification.channel === NotificationChannel.EMAIL &&
    ((notification.receiverType === ReceiverType.GUEST &&
      !AppState.editCalendarValidationData.enableGuests) ||
      (eventNotificationState.isEventCalendar &&
        notification.receiverType === ReceiverType.ASSIGNED_USER))
  ) {
    return null
  }
  return {
    _id: notification._id || '',
    altType: AltType.CALENDAR,
    altId: notification.altId || '',
    receiverType: notification.receiverType,
    additionalEmailIds: [],
    channel: notification.channel || NotificationChannel.EMAIL,
    notificationType: notification.notificationType,
    isActive: true,
    templateId: '',
    body: defaultTemplate.html,
    subject: defaultTemplate.subject,
    beforeTime: [],
    afterTime: [],
  }
}

export function updateNotificationState(data: NotificationConfig[]) {
  data.forEach(notification => {
    notification.body = formatHTMLForTipTap(notification.body)
    const index = eventNotificationState.eventNotifications.findIndex(
      n => n.receiverType === notification.receiverType
    )
    if (index !== -1) {
      eventNotificationState.eventNotifications[index] = notification
      if (eventNotificationState[notification.receiverType]?.state) {
        eventNotificationState[notification.receiverType].state._id =
          notification._id
      }
    }
  })
}

export function getEventNotificationState(tab: string) {
  // check if all the email notification are enabled
  // all are disabled
  let isNotificationsEnabled = true
  if (tab === 'inApp') {
    const isAvailableInDb = !!eventNotificationState.inAppNotification._id
    if (!isAvailableInDb) {
      return !!isAvailableInDb
    }
    return !eventNotificationState.inAppNotification.deleted
  }
  if (tab === 'email') {
    const isAvailableInDb = eventNotificationState.eventNotifications.find(
      notification => notification._id
    )
    if (!isAvailableInDb) {
      return !!isAvailableInDb
    }
    if (
      eventNotificationState.eventNotifications.every(
        notification => notification.deleted
      )
    ) {
      isNotificationsEnabled = false
    }
    if (
      eventNotificationState.inAppNotification.notificationType === 'booked'
    ) {
      const isStoredData = eventNotificationState.eventNotifications.find(
        notification => notification._id
      )
      if (!isStoredData) {
        return !eventNotificationState.eventNotifications
      }
    }
  }
  return isNotificationsEnabled
}
