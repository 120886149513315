type EmailTemplate = {
  templateId: string
  email: {
    body: string
    subject: string
  }
}

export type EmailNotification = {
  type: NotificationType
  channels: Array<{
    name: string
    isActive: boolean
  }>
}

export type EmailNotifications = EmailNotification[]

export enum NotificationChannel {
  EMAIL = 'email',
  SMS = 'sms',
  IN_APP = 'inApp',
}

export enum ReceiverType {
  CONTACT = 'contact',
  GUEST = 'guest',
  ASSIGNED_USER = 'assignedUser',
  EMAILS = 'emails',
}

export enum NotificationType {
  BOOKED = 'booked',
  CONFIRMATION = 'confirmation',
  CANCELLATION = 'cancellation',
  REMINDER = 'reminder',
  FOLLOWUP = 'followup',
  RESCHEDULE = 'reschedule',
}

export enum AltType {
  CALENDAR = 'calendar',
  SERVICE = 'service',
}

export type Schedule = { timeOffset: number; unit: string }
export type NotificationConfig = {
  _id: string
  altType: string
  altId: string
  receiverType: ReceiverType
  additionalEmailIds?: Array<string>
  channel: NotificationChannel
  notificationType: NotificationType
  isActive: boolean
  templateId: string
  body: string
  subject: string
  beforeTime?: Schedule[]
  afterTime?: Schedule[]
  selectedUsers?: string[]
  deleted?: boolean
  fromAddress?: string
  fromName?: string
}
