{
  "settings" : {
    "calendar" : {
      "calendar_settings" : "Kalenderinställningar",
      "select_calendar_type" : "Välj kalendertyp",
      "add_new_calendar_group" : "Lägg till ny kalendergrupp",
      "add_new_equipment" : "Lägg till ny utrustning",
      "new_calendar" : "Ny kalender",
      "calendar_group_description" : "Använd kalendergrupper för att effektivt organisera och gruppera flera kalendrar tillsammans.",
      "group" : "Grupp",
      "manage_your_calendars_and_groups" : "Hantera dina kalendrar och grupper",
      "groups" : "Grupper",
      "create_group" : "Skapa grupp",
      "create_calendar" : "Skapa kalender",
      "calendar_name" : "Kalenderns namn",
      "group_duration" : "Gruppens varaktighet",
      "calendar_type" : "Kalendertyp",
      "action" : "Åtgärd",
      "round_robin" : "Round Robin",
      "event" : "Händelse",
      "group_name" : "Gruppnamn",
      "room_name" : "Rumsnamn",
      "group_description" : "Gruppbeskrivning",
      "calendar_url" : "Kalenderns URL",
      "calendars" : "Kalendrar",
      "date_updated" : "Datum för uppdatering",
      "status" : "Status",
      "action_dropdown" : "Rullgardinsmeny för årgärder",
      "calendar_list" : "Tjänster kopplade till",
      "edit" : "Redigera",
      "duplicate" : "Duplicera",
      "copy_embed_code" : "Kopiera inbäddningskod",
      "copy_permanent_link" : "Kopiera permanent länk",
      "copy_new_slug_link" : "Kopiera schemaläggningslänk",
      "copy_scheduling_link" : "Kopiera schemaläggningslänk (äldre, utfasad)",
      "deactivate_calendar" : "Inaktivera kalender",
      "move_to_group" : "Flytta till grupp",
      "delete_calendar" : "Ta bort kalender",
      "delete_group" : "Radera",
      "delete_this_group" : "Ta bort gruppen",
      "activate_calendar" : "Aktivera kalender",
      "duration" : "Varaktighet (minuter)",
      "all" : "Alla",
      "draft" : "Utkast",
      "active" : "Aktiv",
      "rearrange_calendars" : "Ordna om kalendrar",
      "deactivate_all_calendars_in_group" : "Inaktivera alla kalendrar i gruppen",
      "activate_all_calendars_in_group" : "Aktivera alla kalendrar i gruppen",
      "no_groups_found" : "Din sökning matchade inte några kalendergrupper.",
      "no_groups_in_location" : "Kom igång med att använda grupper genom att skapa en ny kalendergrupp.",
      "no_calendars_found" : "Kom igång med kalendern genom att skapa en ny kalender.",
      "no_calendars_found_search" : "Din sökning matchade inte några kalendrar.",
      "delete_calendar_message" : "Du är på väg att ta bort en kalender. När du trycker på radera kommer det också att radera alla dess möten.",
      "delete_calendar_message_title" : "Vill du ta bort den här kalendern?",
      "duplicate_calendar_message" : "Du är på väg att duplicera en kalender. Detta skapar en ny kalender med samma inställningar som den valda kalendern.",
      "duplicate_calendar_message_title" : "Duplicera den här kalendern?",
      "no_calendars_found_title" : "Inga kalendrar hittades",
      "no_groups_in_location_title" : "Inga grupper hittades",
      "delete_group_confirmation_title" : "Ta bort den här gruppen?",
      "delete_group_confirmation" : "Är du säker på att du vill ta bort den valda gruppen?",
      "delete_group_confirmation_checkbox_title" : "Ta bort tillhörande kalendrar och möten",
      "delete_group_confirmation_checkbox_description" : "Om du väljer det här alternativet raderas även kalendrarna i den här gruppen, tillsammans med alla möten i kalendrarna.",
      "group_status_message_title" : "{status} den här gruppen?",
      "calendar_status_message_title" : "{status} den här kalendern?",
      "calendar_status_message" : "Du är på väg att {status} kalendern",
      "group_status_message" : "Du är på väg att {status} gruppen",
      "coming_soon" : "Kommer snart",
      "select" : "Välj",
      "15mins" : "15 minuter",
      "cancel" : "Avbryt",
      "save" : "Spara",
      "create" : "Skapa",
      "select_calendar_group" : "Välj kalendergrupp",
      "move_calendar_confirmation" : "Observera att den äldre länken slutar fungera när du fortsätter med ändringen. Bekräfta genom att markera kryssrutan.",
      "previously_called_unassigned" : " (Kallades tidigare för ej tilldelad)",
      "scheduling_type" : "Schemaläggningstyp",
      "scheduling_type_description" : "Välj en schemaläggningstyp för din nya kalender",
      "service_menu_name" : "Namn på tjänstemeny",
      "template" : "Mall",
      "group_url" : "Gruppens URL",
      "template_description" : "Du kan nu välja mellan mallarna Klassisk eller Neo för gruppvyn",
      "rooms" : "Rum",
      "rooms_name" : "Namn",
      "rooms_description" : "Beskrivning",
      "rooms_modal_description" : "Lägg till eller redigera rum",
      "rooms_capacity" : "Total kapacitet",
      "no_rooms_in_location_title" : "Inga rum hittades",
      "no_rooms_in_location" : "Inga rum hittades på platsen",
      "no_equipments_in_location" : "Ingen utrustning hittades på platsen",
      "equipments" : "Utrustning",
      "equipment_name" : "Namn",
      "equipment_name_search" : "Utrustningens namn",
      "equipment_description" : "Beskrivning",
      "equipment_modal_description" : "Lägg till eller redigera utrustning",
      "add_equipment" : "Lägg till/redigera utrustning",
      "add_room" : "Lägg till/redigera rum",
      "equipment_quantity" : "Totalt antal",
      "equipment_outOfService" : "Antal som inte är i drift",
      "no_equipments_in_location_title" : "Ingen utrustning hittades",
      "delete_this_equipment" : "Ta bort utrustningen",
      "delete_this_rooms" : "Ta bort rummet",
      "delete_equipment_confirmation" : "Är du säker på att du vill ta bort den här utrustningen? Det kommer också att tas bort från alla länkade kalendrar.",
      "delete_rooms_confirmation" : "Är du säker på att du vill ta bort det här rummet? Det kommer också att tas bort från alla länkade kalendrar.",
      "add_new_calendar_equipments" : "Lägg till ny utrustning",
      "no_equipments_found" : "Din sökning gav ingen träff på någon utrustning.",
      "no_rooms_found" : "Din sökning gav ingen träff på några rum.",
      "add_new_calendar_rooms" : "Lägg till nytt rum",
      "rooms_disable" : "Inaktivera rum",
      "equipments_disable" : "Inaktivera utrustning",
      "equipments_activate" : "Aktivera utrustning",
      "rooms_activate" : "Aktivera rum",
      "create_equipments" : "Skapa utrustning",
      "create_rooms" : "Skapa rum",
      "select_calendar_rooms_title" : "Välj kalender",
      "select_calendar_rooms_description" : "Du kan koppla flera kalendrar till ett rum",
      "select_calendar_equipments_title" : "Välj kalender",
      "select_calendar_equipments_description" : "Du kan välja en kalender att koppla till utrustningen",
      "disable_equipment_title" : "{status} utrustning",
      "disable_room_title" : "{status} rum",
      "equipments_status_message" : "Är du säker på att du vill {status} den här utrustningen?",
      "rooms_status_message" : "Är du säker på att du vill {status} det här rummet?",
      "calendar_resource_disclaimer" : "Detta kan påverka tillgängligheten för kopplade tjänstekalendrar.",
      "equipment_disclaimer" : "Obs: Om du aktiverar utrustningen senare kommer den automatiskt att återanslutas till kalendrar som den är kopplad till",
      "room_disclaimer" : "Obs: Om du aktiverar rummet senare kommer det automatiskt att återanslutas till kalendrar som det är kopplat till",
      "equipment_tab_name" : "Utrustning",
      "rooms_tab_name" : "Rum",
      "manage_your_rooms" : "Hantera företagets rum här",
      "manage_your_equipments" : "Hantera företagets utrustning här",
      "select_service_calendar_description_equipments" : "Du kan välja flera tjänstekalendrar som ska kopplas till utrustning",
      "select_service_calendar_description_rooms" : "Du kan välja flera tjänstekalendrar som ska kopplas till rum",
      "service_menus" : "Tjänstemeny",
      "manage_your_calendars_groups_and_service_menus" : "Hantera dina kalendrar, grupper och tjänstemenyn",
      "new_service_menu" : "Skapa tjänstemeny",
      "deactivate_service_menu" : "Inaktivera tjänstemeny",
      "delete_service_menu" : "Ta bort den här tjänstemenyn",
      "delete_service_menu_confirmation" : "Är du säker på att du vill ta bort den här tjänstemenyn?",
      "service_menu_status_message_title" : "{status} den här tjänstemenyn?",
      "service_menu_status_message" : "Du är på väg att {status} den här tjänstemenyn",
      "no_service_menus_in_location_title" : "Skapa din skräddarsydda tjänstemeny",
      "add_new_service_menu" : "Lägg till ny tjänstemeny",
      "no_service_menus_in_location" : "Börja med att skapa en ny tjänstemeny och lägg till grupper i den",
      "no_service_menu_found" : "Ingen tjänstemeny hittades",
      "no_service_menu_found_desc" : "Din sökning matchade inga tjänstemenyer.",
      "apply" : "Tillämpa",
      "troubleshoot_calendar" : "Felsöka kalender",
      "round_robin_booking" : "Round Robin",
      "round_robin_description" : "Fördelar möten mellan teammedlemmar i en roterande ordning.",
      "round_robin_example" : "Exempel: Säljsamtal, onboarding-sessioner.",
      "unassigned_booking" : "Händelsekalender",
      "unassigned_description" : "För att schemalägga fysiska händelser utan koppling till värd.",
      "unassigned_example" : "Exempel: Konferenser, offentliga seminarier, utställningar.",
      "class_booking" : "Klassbokning",
      "class_booking_description" : "En värd träffar flera deltagare.",
      "class_booking_example" : "Exempel: webbseminarier, grupputbildning, onlinekurser.",
      "collective_booking" : "Kollektiv bokning",
      "collective_booking_description" : "Flera värdar träffar en deltagare.",
      "collective_booking_example" : "Exempel: Panelintervjuer, kommittégranskningar.",
      "service_booking" : "Tjänstebokning",
      "service_booking_description" : "Sömlös schemaläggning för tjänstebaserade företag.",
      "service_booking_example" : "Exempel: Spa-besök, reparationstjänster, konsultationer.",
      "personal_booking" : "Personlig bokning",
      "personal_booking_description" : "Schemalägger enskilda möten med en specifik teammedlem.",
      "personal_booking_example" : "Exempel: Kundmöten, privata konsultationer.",
      "personal" : "Personlig",
      "explore_more_types" : "Utforska fler typer"
    },
    "preferences" : {
      "preferences" : "Inställningar",
      "my_preferences" : "Mina inställningar",
      "account_preferences" : "Kontoinställningar",
      "user_preferences" : "Användarinställningar",
      "set_preferences" : "Ange inställningar för ditt konto.",
      "scheduling_options" : "Schemaläggningsalternativ",
      "meeting_location" : "Mötets plats",
      "zoom" : "Zoom",
      "save_preferences" : "Spara inställningar",
      "in_app_preferences" : "Inställningar i appen",
      "set_start_day" : "Ange inställningar som appens startdag",
      "view_options" : "Visa alternativ",
      "week_starts_on" : "Veckan börjar på",
      "sunday" : "Söndag",
      "widget_preferences" : "Widget-inställningar",
      "set_widget_language" : "Ange inställningar som widgetens språk",
      "language_and_region" : "Språk och region",
      "language" : "Språk",
      "english" : "Engelska",
      "time_format" : "Tidsformat",
      "time_format_value" : "1:30 PM",
      "monday" : "Måndag",
      "custom_meeting_location" : "Anpassad mötesplats"
    },
    "availability" : {
      "availability" : "Tillgänglighet",
      "my_availability" : "Min tillgänglighet",
      "account_availability" : "Kontots tillgänglighet",
      "timezone" : "Tidszon",
      "working_hours" : "Arbetstid",
      "set_recurring_hours" : "Ställ in dina återkommande arbetstider för möten",
      "sunday" : "Söndag",
      "monday" : "Måndag",
      "tuesday" : "Tisdag",
      "wednesday" : "Onsdag",
      "thursday" : "Torsdag",
      "friday" : "Fredag",
      "saturday" : "Lördag",
      "widget_preferences" : "Widget-inställningar",
      "set_widget_language" : "Ange inställningar som widgetens språk",
      "language_and_region" : "Språk och region",
      "language" : "Språk",
      "english" : "Engelska",
      "copy_to_all" : "Kopiera till alla",
      "save_availability" : "Spara tillgänglighet",
      "add_time" : "Lägg till tid",
      "select_time" : "Välj tid"
    },
    "connections" : {
      "connections" : "Anslutningar",
      "connect" : "ansluta",
      "conferencing" : "Konferenser",
      "my_connections" : "Mina anslutningar",
      "account_connections" : "Kontots anslutningar",
      "connection_preferences" : "Anslutningsinställningar",
      "manage_calendars_conferencing_channels" : "Hantera kalendrar och konferenskanaler",
      "main_integration_calendar" : "Integrerad huvudkalender",
      "event_integration_calendar" : "Händelser som skapas i en systemkalender som du är en del av kommer också att skapas i den här integrerade kalendern",
      "2way_sync" : "Tvåvägssynkronisering: skapa systemhändelser från händelser som bokats i den integrerade huvudkalendern",
      "calendars" : "Kalendrar",
      "google" : "Google",
      "outlook" : "Outlook",
      "check_for_conflicts" : "Kontrollera om det finns konflikter",
      "choose_accounts_to_connect" : "Välj vilka konton du vill ansluta",
      "payments" : "Betalningar",
      "stripe" : "Stripe",
      "ads" : "Annonser",
      "local_service_ads" : "Lokala tjänsteannonser",
      "fire_workflow" : "Aktivera arbetsflödesutlösare för händelser som bokas via tvåvägssynkronisering",
      "connect_google_outlook" : "Anslut ditt konto på Google/Outlook/iCloud för att kunna välja primär kalender.",
      "execute_automation" : "Kör automatisering",
      "save" : "Spara",
      "lsa" : "Lokala tjänsteannonser",
      "no_write_access" : "Du har inte skrivbehörighet till den här kalendern",
      "connection_remove_confirmation" : "Du håller på att ta bort den här integrationen",
      "active" : "Aktiv",
      "icloud" : {
        "title" : "iCloud-integration",
        "description" : "Tvåfaktorsautentisering och ett appspecifikt lösenord krävs för att ansluta ditt konto.",
        "email" : "E-postadress för iCloud",
        "password" : "Lösenord för iCloud",
        "connect" : "Anslut",
        "cancel" : "Avbryt"
      }
    }
  },
  "appointment_modal" : {
    "select_a_contact" : "Välj en kontakt",
    "start_time" : "Starttid",
    "unassigned" : "Ej tilldelad",
    "end_time" : "Sluttid",
    "search_by_name_email_phone_or_company" : "Sök på namn, e-post, telefon eller företag",
    "add_new" : "Lägg till ny",
    "pick_from_available_contacts" : "Eller välj bland tillgängliga kontakter",
    "book_appointment" : "Boka möte",
    "add_blocked_off_time" : "Lägg till blockerad tid",
    "recommended_timezones" : "Rekommenderade tidszoner",
    "all_timezones" : "Alla tidszoner",
    "calendar" : "Kalender",
    "repeat" : "Upprepa",
    "date_and_time" : "Datum och tid",
    "close" : "Stäng",
    "team_member" : "Teammedlem",
    "recurring_booking" : "Återkommande bokning",
    "one_slot_booking" : "Bokning av en tidslucka",
    "event_type" : "Händelsetyp",
    "occurrences_booked" : "händelser kommer att bokas",
    "repeats" : "Upprepningar",
    "booking_details" : "Bokningsinformation",
    "showing_slots_in_this_timezone" : "Visar tidsluckor i den här tidszonen:",
    "update_contact_tz" : "Uppdatera även kontaktens tidszon till",
    "date" : "Datum",
    "slot" : "Tidslucka",
    "calendar_default" : "Kalenderstandard",
    "custom" : "Anpassad",
    "meeting_notes" : "(t.ex.) Mötesanteckningar för vårt kommande möte",
    "out_of_office" : "(t.ex.) Borta från kontoret den här veckan!",
    "standard" : "Standard",
    "appointment_title" : "Mötestitel",
    "event_title" : "Händelsetitel",
    "appt_with_bob" : "(t.ex.) Möte med Bob",
    "vacation" : "(t.ex.) Semester",
    "show_notes" : "Visa anteckningar",
    "hide_notes" : "Dölj anteckningar",
    "additional_preferences" : "Ytterligare inställningar",
    "meeting_location" : "Mötets plats",
    "appointment_status" : "Mötesstatus",
    "appt_description" : "Mötesbeskrivning",
    "recurring_details" : "Återkommande information",
    "event_description" : "Händelsebeskrivning",
    "in_contact_timezone" : "I kontaktens tidszon",
    "vacation_message" : "Ska du åka på semester? Tar du lite ledigt? Blockera tid i din kalender för att förhindra kunder från att boka möten. Befintliga möten kommer fortfarande att finnas kvar i din kalender).",
    "contact" : "Kontakt",
    "delete" : "Radera",
    "cancel" : "Avbryt",
    "back" : "Tillbaka",
    "pick_available_contacts" : "Eller välj bland tillgängliga kontakter",
    "no_contacts_found" : "Inga kontakter hittades",
    "found" : "hittades",
    "select_one" : "Välj en",
    "tz_tooltip" : "Detta är bara viktigt om du använder anpassade värden, till exempel appointment_start_time appointment_end_time appointment_timezone etc.. i din utgående kommunikation med dina klienter eller kunder. Det konverterar ovannämnda detaljer till personens lokala tid.",
    "meeting_location_tooltip" : "Mötesplatsen kommer att ställas in som konfigurerad i kalendern.",
    "appt_meeting_location_tooltip" : "Användaren kan ställa in mötesplatsen specifikt för mötet.",
    "gmeet_tooltip" : "Se till att respektive teammedlem har en Google-kalender inställd som sin primära kalender",
    "zoom_tooltip" : "Se till att respektive teammedlem har Zoom integrerat",
    "enter_meeting_location" : "Ange mötesplats",
    "save_appointment" : "Spara möte",
    "save_event" : "Spara händelse",
    "search_contacts" : "Sök på namn, e-post, telefon eller företag",
    "recurring_slots" : "{recurringSlotsLength}/{count}",
    "custom_timezone" : "Anpassad tidszon",
    "system_timezone" : "Systemets tidszon",
    "contact_timezone" : "Kontaktens tidszon",
    "account_timezone" : "Kontots tidszon",
    "an_error_occurred" : "Ett fel inträffade"
  },
  "popup" : {
    "calendar" : "Kalender",
    "appointment_owner" : "Mötesägare",
    "source" : "Källa",
    "booked_by" : "Bokad av",
    "view_activity_log" : "Visa aktivitetslogg",
    "status" : "Status",
    "cancel_appointment" : "Avboka tid",
    "cancel_all" : "Avbryt alla",
    "google_event" : "Google-händelse",
    "outlook_event" : "Outlook-händelse",
    "blocked_slot" : "Blockerad tidslucka",
    "appointment" : "Möte",
    "confirmed" : "Bekräftad",
    "unconfirmed" : "Ej bekräftad",
    "cancelled" : "Avbokad",
    "showed" : "Deltog",
    "no_show" : "Uteblev",
    "invalid" : "Ogiltig",
    "more" : "Mer",
    "less" : "Mindre",
    "edit" : "Redigera",
    "reschedule_all" : "Omboka alla",
    "guests" : "Gäster",
    "guest_count" : "Antal gäster"
  },
  "create_calendar" : {
    "calendar_name" : "Kalenderns namn",
    "calendar_logo" : "Kalenderns logotyp",
    "select_team_member" : "Välj teammedlemmar",
    "eg_outbound_reach" : "(t.ex.) Utgående räckvidd",
    "description" : "Beskrivning",
    "amount" : "Antal",
    "description_placeholder" : "Skriv beskrivning",
    "meeting_duration" : "Mötets varaktighet",
    "seats_per_class" : "Platser per klass",
    "group" : "Grupp",
    "custom_url" : "Anpassad URL",
    "meeting_invite_title" : "Titel för mötesinbjudan",
    "event_color" : "Händelsens färg",
    "availability_type" : "Tillgänglighetstyp",
    "new_calendar" : "Ny kalender",
    "add_description" : "Lägg till beskrivning",
    "remove_description" : "Ta bort beskrivning",
    "booking_availability" : "Bokningstillgänglighet",
    "slug_tooltip" : "Anpassa direktlänken till kalendern",
    "meeting_location_tooltip" : "Möteslänkar visas i kalenderhändelser som ”mötesplats” och gör att du kan lägga till saker som Zoom-länkar till händelsen.",
    "advanced_settings" : "Avancerade inställningar",
    "reschedule_preference" : "Inställningar för ombokning",
    "reschedule_preference_subtext" : "Om en kontakt ombokar ett möte:",
    "reassign_through_round_robin" : "Tilldela om med round robin",
    "keep_same_assinged_user" : "Behåll samma mötesägare",
    "new_appointment_preference" : "Inställning för nya möten",
    "new_appointment_preference_text" : "Boka alltid med kontaktens tilldelade användare",
    "new_appointment_preference_subtext" : "Boka nya möten med kontaktens tilldelade användare istället för att använda round robin-schemaläggning.",
    "cancel" : "Avbryt",
    "confirm" : "Bekräfta",
    "service_calendar_name" : "Tjänstens namn",
    "service_logo" : "Tjänstens logotyp",
    "service_select_team_member" : "Välj medarbetare",
    "service_duration" : "Tjänstens varaktighet",
    "appointment_invite_title" : "Mötesinbjudans titel",
    "payment_placeholder" : "Ange en beskrivning som kommer att visas för användaren och informera dem om betalningen",
    "deposit_amount" : "Depositionsbelopp",
    "collect_deposit_amount" : "Acceptera delbetalning",
    "percentage" : "Procentsats",
    "total_amount" : "Totalt belopp"
  },
  "calendar_advanced" : {
    "general" : {
      "quick_tip" : "Snabbtips",
      "timezone_change_tip" : "Tidszoner kan ändras när som helst under i tidszonsinställningarna",
      "save" : "Spara",
      "close" : "Stäng",
      "meeting_details" : "Mötesinformation",
      "availability" : "Tillgänglighet",
      "forms_and_payment" : "Formulär och betalning",
      "notifications_and_additional_options" : "Aviseringar och ytterligare alternativ",
      "connections" : "Anslutningar",
      "customizations" : "Anpassningar",
      "create" : "Skapa",
      "edit" : "Redigera",
      "equipments" : "Rum och utrustning",
      "service_details" : "Tjänsteinformation"
    },
    "meeting_details" : {
      "meeting_location" : "Mötets plats",
      "enter_meeting_location" : "Ange mötesplats",
      "click_to_upload" : "Klicka för att ladda upp",
      "square" : "Fyrkant",
      "circle" : "Cirkel",
      "remove_logo" : "Ta bort logotypen",
      "meeting_invite_title_tooltip" : "Det här är titeln på kalenderhändelsen som visas i Google Kalender, Outlook, Apple Kalender, etc.",
      "event_color_tooltip" : "Välj en färg för bokningshändelsen. Färgerna synkroniseras med händelser i Google Kalendern.",
      "enter_a_name" : "Ange ett namn",
      "enter_an_event_title" : "Ange en titel på händelsen",
      "high_priority" : "Hög prioritet",
      "medium_priority" : "Medelhög prioritet",
      "low_priority" : "Låg prioritet",
      "custom" : "Anpassad",
      "zoom_not_added" : "Användaren har inte anslutit sitt Zoom-konto ännu",
      "opt_avail" : "Optimera för tillgänglighet",
      "opt_equal" : "Optimera för jämn fördelning",
      "team_members" : "Välj teammedlemmar",
      "team_member" : "Välj teammedlem",
      "meeting_location_required" : "Mötesplats krävs",
      "meeting_details" : "Mötesinformation",
      "meeting_details_description" : "Det här är informationen som kommer att visas på sidan med kalenderlistan.",
      "or_drag_and_drop" : "eller dra och släpp",
      "service_details" : "Tjänsteinformation",
      "team_member_disabled_recurring_tooltip" : "Återkommande möten fungerar endast för en teammedlem. Om du vill lägga till flera teammedlemmar behöver du inaktivera återkommande möten.",
      "team_member_disabled_class_booking_tooltip" : "Endast en teammedlem kan läggas till i en klassbokningskalender",
      "advanced_settings" : "Avancerade inställningar",
      "enable_contact_assigned_to_setting" : "Om du vill aktivera den här inställningen måste formuläret vara först i widgetordningen. Ändra widgetordningen från fliken Formulär och betalning.",
      "reschedule_preference_setting_tooltip" : "Välj vem mötet ska tilldelas till när en kontakt bokas om från bokningswidgeten.",
      "appointment_preference_tooltip" : "Välj om nya möten alltid ska bokas med kontaktens tilldelade användare. Om en kontakt har en tilldelad användare kommer den användaren att få företräde, annars kommer tilldelningen att ske enligt principen ”round robin”. Tips: Stäng av ”Tillåt val av medarbetare” för att förhindra att bokare byter medarbetare i bokningswidgeten.",
      "add_location" : "Lägg till plats",
      "multiple_locations_neo" : "Flera mötesplatser stöds endast av Neo-widgeten med standardformuläret.",
      "make_owner" : "Gör till ägare",
      "only_one_team_member" : "Endast en teammedlem tillåts när flera platser läggs till.",
      "ask_the_booker_error_message" : "Platsen för Fråga bokaren finns redan",
      "enter_location" : "Ange platsvärde",
      "enter_display_label" : "Ange visningsetikett",
      "add_display_label" : "Lägg till visningsetikett",
      "multiple_location_support_message" : "Flera mötesplatser stöds endast av Neo-widgeten med standardformuläret.",
      "only_one_team_member_personal_calendar" : "Endast en teammedlem tillåts för personlig bokning ",
      "ms_teams_not_added" : "Användaren har inte anslutit sitt Microsoft Teams-konto ännu"
    },
    "availability" : {
      "my_availability" : "Min tillgänglighet",
      "set_availability" : "Ställ in din tillgänglighet för kalendern här.",
      "standard" : "Standard",
      "custom" : "Anpassad",
      "to" : "Till",
      "copy_to_all" : "Kopiera till alla",
      "add_time" : "Lägg till tid",
      "recurring_meeting" : "Återkommande möte",
      "recurring_info" : "Endast en användare per kalender för återkommande bokning",
      "repeat" : "Upprepa",
      "times_to_repeat" : "Antal upprepningar",
      "slots_unavailable" : "Om tidsluckor inte är tillgängliga?",
      "overlap_status" : "Mötesstatus för överlappande tidsluckor ",
      "meeting_interval" : "Mötesintervall",
      "meeting_duration" : "Mötets varaktighet",
      "minimum_scheduling" : "Minsta varsel för schemaläggning",
      "date_range" : "Datumintervall",
      "maximum_bookings_per_day" : "Max antal bokningar per dag",
      "maximum_bookings_per_slot" : "Max antal bokningar per tidslucka",
      "maximum_bookings_per_slot_per_user" : "Max antal bokningar per tidslucka (per användare)",
      "seats_per_class" : "Platser per klass",
      "pre_buffer_time" : "Bufferttid före",
      "post_buffer_time" : "Bufferttid efter",
      "pre_buffer_tooltip" : "Bufferttid före är ytterligare tid som kan läggas till före ett möte, vilket ger extra tid för förberedelser",
      "post_buffer_tooltip" : "Bufferttid efter är ytterligare tid som kan läggas till efter ett möte, vilket ger extra tid för avrundning och sammanställning",
      "buffer_time" : "Bufferttid",
      "daily" : "Dagligen",
      "weekly" : "Varje vecka",
      "monthly" : "Varje månad",
      "skip_booking" : "Hoppa över bokning av otillgängliga tidsluckor",
      "continue_booking" : "Fortsätt boka",
      "book_next_available" : "Boka nästa lediga tidslucka",
      "confirmed" : "Bekräftad",
      "unconfirmed" : "Ej bekräftad",
      "enter_valid_hours" : "Ange giltiga öppettider",
      "enter_valid_avail" : "Ange giltig anpassad tillgänglighet",
      "availability_type_tooltip" : "Välj Standardtillgänglighet för vanliga bokningar som erbjuds varje vecka. Välj Anpassad för bokningar som endast är gäller specifika datum.",
      "meeting_interval_tooltip" : "Mötesintervallet anger hur lång tid det ska gå mellan de bokade tiderna som visas i kalendern. För en händelse på 30 minuter som ska vara tillgänglig varje timme blir intervallet 1 timme. För en händelse på 30 minuter som ska vara tillgänglig var 30:e minut blir intervallet 30 minuter.",
      "date_range_tooltip" : "Händelser kan schemaläggas under de kommande n dagarna.",
      "maximum_bookings_per_day_tooltip" : " Max antal tillåtna möten per dag i den här kalendern.",
      "maximum_bookings_per_slot_tooltip" : "Maximalt antal möten som tillåts per tidslucka för användaren (om antalet möten överskrider inställningen för användarens tidslucka växlar den antingen över till nästa användare i round robin-kön eller markerar tidsluckan som ej bokningsbar för prospektet).",
      "add_days" : "Lägg till dagar",
      "month_on_day" : "Månadsvis på dag",
      "month_on_last_day" : "Månadsvis på föregående dag",
      "day" : "Dag",
      "week" : "Vecka",
      "month" : "Månad",
      "mins" : "Minuter",
      "hours" : "Timmar",
      "days" : "Dagar",
      "weeks" : "Veckor",
      "months" : "Månader",
      "meeting_interval_error" : "Mötesintervallet måste vara mellan 5 minuter och 12 timmar",
      "meeting_duration_error" : "Mötets varaktighet måste vara mellan 1 min och 12 timmar",
      "buffer_time_error" : "Bufferttiden måste vara mindre än 12 timmar",
      "appointment_interval" : "Mötesintervall",
      "appointment_duration" : "Mötets varaktighet",
      "maximum_appointments_per_day" : "Max antal möten per dag",
      "subTitle" : "Välj datum för att ställa in specifika tider",
      "heading_1" : "Tillgängliga timmar per vecka",
      "tooltip_1" : "Ställ in ditt tillgänglighetsschema per vecka. Detta är dina standardtimmar som kommer att tillämpas konsekvent på veckobasis",
      "heading_2" : "När är du tillgänglig?",
      "unavailable_label" : "Ej tillgänglig",
      "tooltip_2" : "Justera ditt schema för specifika datum. Åsidosätt dina vanliga veckotimmar genom att markera tillgänglig eller ej tillgänglig för valda datum.",
      "date_specific_level" : "Lägg till datumspecifika timmar",
      "specific_hours_button" : "Datumspecifika timmar",
      "overlap_error" : "Tidsluckor kan inte överlappa varandra",
      "apply" : "Tillämpa",
      "copy_times_to" : "Kopiera tider till",
      "no_date_specific_heading" : "Ingen datumspecifik tid har lagts till",
      "no_date_specific_description" : "Du kan lägga till/ta bort specifikt datum och tid i din tillgänglighet",
      "recurring_disabled_conditions_tooltip" : "Återkommande möten är inaktiverade. För att aktivera dem ser du till att kalendern bara har en teammedlem och att inga datumspecifika timmar har lagts till.",
      "date_specific_hours_disabled_conditions_tooltip" : "För att inkludera en datumspecifik timme ser du till att återkommande möten är avstängda.",
      "recurring_disable_event_calendar_conditions" : "Återkommande möten är inaktiverade. För att aktivera dem behöver du se till att inga datumspecifika timmar har lagts till.",
      "service_duration" : "Tjänstens varaktighet",
      "service_interval" : "Tjänsteintervall",
      "service_interval_tooltip" : "Mötesintervallet anger hur ofta bokningsbara tidsluckor visas i kalendern. Exempel: för en händelse på 30 minuter betyder ett intervall på 1 timme att luckor visas varje timme. Ett intervall på 30 minuter innebär att tidsluckor visas var 30:e minut.",
      "service_duration_tooltip" : "Detta anger längden på ditt möte och avgör hur länge det kommer att pågå.",
      "minimum_scheduling_tooltip" : "Här anges den minsta tid som krävs för att boka ett möte via kalenderwidgeten. Exempel: om det är inställt på 4 timmar kan möten inte bokas inom 4 timmar från deras starttid. Bokningar måste göras minst 4 timmar i förväg.",
      "look_busy_title" : "Se upptagen ut",
      "look_busy_helper_text" : "Dölj en procentandel av dina tillgängliga tider i bokningswidgeten för att framstå som mer upptagen och efterfrågad.",
      "look_busy_percentage_description" : "Dölj antalet tillgängliga tidsluckor med x %.",
      "maximum_bookings_per_slot_tooltip_personal" : "Högsta antalet tillåtna möten per tidslucka för användaren"
    },
    "forms_payment" : {
      "forms" : "Formulär",
      "forms_sub" : "Ange inställningar för formulär efter schemaläggning.",
      "select_form" : "Välj formulär",
      "enable_same_user_assignment" : "Tilldela kontakter till sina respektive kalenderteam varje gång ett möte bokas",
      "sticky_contacts" : "Fyll i fält i förväg (återkommande kontakter)",
      "confirmation_page" : "Bekräftelsesida",
      "default" : "Standard",
      "redirect_url" : "URL för omdirigering",
      "thank_you" : "Tack för ditt meddelande",
      "enter_url" : "Ange URL",
      "pixel_id" : "Facebook pixel-ID (valfritt)",
      "auto_confirm" : "Automatisk bekräftelse av nya kalendermöten",
      "configure_payment" : "Konfigurera betalningsalternativ",
      "accept_payments" : "Acceptera betalningar",
      "test" : "Test",
      "live" : "Live",
      "enable_guests" : "Lägg till gäster",
      "add_guest_tooltip" : "Med tillägg av gäster kan deltagaren lägga till flera deltagare till samma möte",
      "add_guest_custom_form_tooltip" : "Om du aktiverar det här alternativet skickas ett systemgenererat e-postmeddelande till gästerna med en länk till det anpassade formuläret när deras möte har bekräftats. För att undvika dubbla e-postmeddelanden ser du till att ett separat bekräftelsemeddelande inte redan har konfigurerats för gästerna. Detta kollar du i fliken Aviseringar > Bekräftelseavisering.",
      "add_guest_same_form_check" : "Skicka samma formulär för att samla in information via e-post till alla gäster",
      "payment_tooltip" : "Betalning gäller endast för huvuddeltagaren och inte för gästerna.",
      "default_form" : "Standard (förnamn, efternamn, e-post, telefon, anteckningar)",
      "select_form_tooltip" : "Om du vill samla in mer information än standardvärdena (förnamn, efternamn, e-post och telefon) från personer som bokar i den här kalendern skapar du ett anpassat formulär i Webbplatser > Formulär > Byggverktyg och välj sedan formuläret via rullgardinsmenyn nedan.",
      "confirmation_page_tooltip" : "När någon gör en bokning kan du välja att visa ett anpassat meddelande på samma sida (Standard) eller omdirigera dem till en sida som du väljer (Omdirigera).",
      "add_guest_collect_email" : "Namn och e-post",
      "add_guest_count_only" : "Endast antal",
      "custom_email_notification" : "Skicka anpassat formulär till gäster",
      "consent_label" : "Kryssruta för samtycke",
      "consent_input_placeholder" : "Lägg till samtyckestext",
      "same_user_assignment_tooltip" : "För att aktivera den här inställningen måste formuläret vara först på widgeten. Ordna om widgeten så att formuläret placeras först för att aktivera den här inställningen.",
      "consent_tooltip" : "Aktivera det här alternativet för att tillåta inhämtande av samtycke från kontakter när de anger sitt telefonnummer under mötesbokningsprocessen.",
      "stripe_error" : "Upp till två decimaler (exempel: 25,99)",
      "auto_confirm_service" : "Automatisk bekräftelse av nya möten",
      "service_form_message" : "Om en kontakt bokar flera tjänster kommer de bara att se standardformuläret i tjänstemenyn",
      "deposit_amount_error" : "Depositionsbeloppet måste vara mindre än det totala beloppet.",
      "deposit_percentage_error" : "Procentsatsen för deposition måste vara mindre än 100 %.",
      "partial_payment_tooltip" : "Aktivera delbetalningar för att ta ut depositionsbelopp från kunder under bokningsprocessen.",
      "partial_payment_enable_error" : "Det totala beloppet som anges måste vara större än 0 för att möjliggöra delbetalningar.",
      "payment_disable_text_integration" : "Integrera en betalningsleverantör under Betalningar > Integrationer för att börja ta emot betalningar",
      "payment_disable_text_recurring" : "Alternativet Acceptera betalning är inte tillgängligt för återkommande möten. Stäng av återkommande möten för att aktivera betalningar.",
      "date_and_time_selector" : "Datum- och tidsväljare",
      "form_selector" : "Formulär",
      "order_step" : "Steg",
      "widget_order_label" : "Widgetordning",
      "widget_order_tool_tip" : "Ändra ordningen på ”Datum- och tidsväljare” och ”Formulär” i kalenderwidgeten. Du behöver bara dra och ordna om efter dina önskemål.",
      "widget_re_order_tooltip" : "Det går endast att ordna om med widgetstilen Neo. Byt till Neo för att ordna om.",
      "widget_re_order_disabled_tooltip" : "För att ordna om widgetordningen behöver du inaktivera ”Inställning för nya möten (boka alltid med kontaktens tilldelade användare)” från avsnittet i Mötesinformation > Teammedlemmar > Avancerade inställningar.",
      "payment_description_error" : "Högsta teckengräns på 1 000 har uppnåtts",
      "widget_multiple_location_disabled_tooltip" : "Klassisk widget har inte stöd för flera mötesplatser. Ta bort eventuella ytterligare platser för att använda den klassiska widgeten",
      "recurring_payment_label" : "Återkommande mötesbetalning",
      "first_appointment_only" : "Endast första mötet",
      "all_appointments" : "Alla möten"
    },
    "notifications" : {
      "notifications" : "Aviseringar och ytterligare alternativ",
      "notifications_sub" : "Konfigurera aviseringar och ytterligare alternativ",
      "select_notification_type" : "Välj typ av avisering",
      "notification_person" : "Vem ska få den här aviseringen?",
      "contact" : "Kontakt",
      "guest" : "Gäst",
      "assigned_user" : "Tilldelad användare",
      "emails" : "E-postmeddelanden",
      "alert_email_address" : "Informera e-postadress",
      "ack_email" : "Bekräftelsemejl",
      "send_google_invites" : "Tillåt att Google-kalendern skickar inbjudnings- eller uppdateringsmejl till deltagare.",
      "send_google_outlook_invites" : "Tillåt att Google/Outlook Kalender skickar inbjudnings- och uppdateringsmejl till deltagare.",
      "assign_user_to_contact" : "Tilldela kontakter till sina respektive kalenderteam varje gång ett möte bokas",
      "skip_assigning_user_to_contact" : "Hoppa över att tilldela kontakt om kontakten redan har en tilldelad användare",
      "assigned_staff" : "Tilldelad medarbetare",
      "additional_emails" : "Ytterligare e-postmeddelanden",
      "assign_user_to_staff" : "Tilldela kontakter till sina respektive tjänstemedarbetare varje gång en tid bokas",
      "allow_cancellation" : " Tillåt avbokning av möte",
      "allow_reschedule" : "Tillåt ombokning av möte",
      "allow_cancellation_tooltip" : "När det är aktiverat läggs det till en avbokningslänk i avsnittet med ytterligare anteckningar och den inkluderas i kalenderinbjudan. Länken kommer sluta att gälla enligt angivna inställningar före mötets starttid, vilket förhindrar bokaren från att avboka mötet.",
      "allow_reschedule_tooltip" : " När det är aktiverat läggs det till en länk för ombokning i avsnittet med ytterligare anteckningar och den inkluderas i kalenderinbjudan. Länken kommer sluta att gälla enligt angivna inställningar före mötets starttid, vilket förhindrar bokaren från att boka om mötet.",
      "cancellation_link_expiry" : "Avbokningslänken upphör att gälla",
      "reschedule_link_expiry" : "Ombokningslänken upphör att gälla",
      "before_meeting" : "före mötet",
      "cancellation_and_reschedule_policy" : "Policy för avbokning och ombokning:"
    },
    "connections" : {
      "connections_heading" : "Synkronisering med extern kalender",
      "connections_sub" : "Synkronisera händelser med extern kalender",
      "link_to_calendar" : "Länk till kalender",
      "sync_option" : "Synkroniseringsalternativ",
      "execute_automation" : "Kör automatisering",
      "fire_workflow" : "Aktivera arbetsflödesutlösare för händelser som bokas via tvåvägssynkronisering",
      "reserve_with_google" : "Boka med Google",
      "connect_lsa" : "Anslut till ditt annonskonto för lokala tjänster",
      "reserve_error" : "För närvarande stöds Boka med Google endast för företagsnischerna ”Home Services”, ”Real Estate”, ”Legal Services” (lokala tjänsteleverantörer) i USA.",
      "one_way" : "Envägs",
      "two_way" : "Tvåvägs",
      "smart" : "Smart",
      "confirm_remove" : "Är du säker på att du vill ta bort Google-kalendern?",
      "read_only_access" : "Du har inte skrivbehörighet till den här kalendern. Välj en annan kalender.",
      "reserve_with_google_desc" : "Den här kalendertjänsten kommer endast att listas som reserv hos Google efter att integrationen har genomförts.",
      "check_integration" : "Kontrollera din integrationsstatus",
      "setup_external" : "Konfigurera din externa kalender",
      "reserve_price_decimal_error" : "Decimalpris stöds inte",
      "reserve_price_max_error" : "Endast pris under 1 000 stöds"
    },
    "extras" : {
      "extras" : "Anpassningar",
      "extras_sub" : "Ställ in widgetstil och andra inställningar.",
      "calendar_widget_style" : "Kalenderwidgetens stil",
      "neo" : "Neo",
      "classic" : "Klassisk",
      "insert_custom_code" : "Infoga anpassad kod",
      "show_seats_per_slot" : "Visa platser per tidslucka",
      "allow_reschedule_meeting" : "Tillåt ombokning av möte",
      "allow_cancelling_meeting" : "Tillåt avbokning av möte",
      "additional_notes" : "Ytterligare anteckningar",
      "cancellation_and_reschedule" : "Avbokning och ombokning:",
      "allow_staff" : "Tillåt val av medarbetare vid bokning",
      "code_block_tooltip" : "Tredjepartsskript inuti HTML-blocket kan ge effektiva funktioner men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. Därför rekommenderar vi att du granskar innehållet i det här skriptet innan du sparar det i kalendern.",
      "calendar_image" : "Kalenderomslagsbild",
      "remove_image" : "Ta bort bild",
      "calendar_image_info" : "Den uppladdade bilden kommer att vara synlig i gruppvyn för Neo-mallen och den kommer inte att visas på den enskilda kalenderlänken",
      "get_image_from_unsplash" : "Förbättra dina bilder med",
      "unsplash" : "Unsplash",
      "service_additional_preferences" : "Konfigurera ytterligare inställningar",
      "service_image" : "Tjänstens omslagsbild",
      "service_image_description" : "Välj en bild som visas för den här tjänsten på tjänstemenyn",
      "allow_reschedule_appointment" : "Tillåt ombokning av möte",
      "allow_cancelling_appointment" : "Tillåt avbokning av möte",
      "custom_code_placeholder" : "Ange anpassad kod här",
      "calendar_widget_style_desc" : "Välj mellan vår klassiska widget eller den eleganta Neo-widgeten",
      "seats_per_slot" : "Platser på tidslucka",
      "show_staff_selection" : "Val av medarbetare",
      "seats_per_slot_desc" : " Gör det möjligt för bokare att se antalet tillgängliga platser för varje tidslucka direkt i kalenderwidgeten.",
      "show_staff_selection_desc" : "Gör det möjligt för bokare att välja en medarbetare direkt från kalenderwidgeten under mötesbokningen. Fungerar endast med Neo-widgeten",
      "widget_re_order_tooltip" : "Det går inte att ändra widgetordningen med den klassiska widgetstilen. Se till att datumtidsväljaren är först och att formuläret är tvåa i listan innan du väljer den klassiska stilen.",
      "show_staff_selection_desc_service" : "Gör det möjligt för bokare att välja en medarbetare direkt från kalenderwidgeten under mötesbokningen."
    },
    "roomsAndEquipments" : {
      "title" : "Välj rum och utrustning",
      "description" : "Välj vad som ska bokas tillsammans med denna kalender från en lista över rum och utrustning",
      "rooms" : "Välj rum",
      "equipments" : "Välj utrustning"
    }
  },
  "activity_log" : {
    "activity_log" : "Aktivitetslogg",
    "date" : "Datum",
    "filter_by" : "Filtrera efter",
    "calendar_update" : "Uppdatering av kalender",
    "start_time_update" : "Uppdatering av starttid",
    "end_time_update" : "Uppdatering av sluttid",
    "title_update" : "Uppdatering av titel",
    "notes_update" : "Uppdatering av anteckningar",
    "appointment_status_update" : "Uppdatering av mötesstatus",
    "meeting_location_update" : "Uppdatering av mötesplatsen",
    "google_sync_update" : "Uppdatering av Google-synkronisering",
    "outlook_sync_update" : "Uppdatering av Outlook-synkronisering",
    "assigned_user_update" : "Uppdatering av tilldelad användare",
    "appointment_status" : "Mötesstatus",
    "assigned_user" : "Tilldelad användare",
    "uploaded_to" : "Uppladdad till",
    "cancelled" : "Avbokade",
    "edited" : "redigerade",
    "other_edits" : "Övriga ändringar",
    "view_details" : "Visa detaljer",
    "all" : "Alla"
  },
  "appointment_list" : {
    "group_all" : "ALLA",
    "group_primary" : "PRIMÄR",
    "group_user" : "ANVÄNDARE",
    "group_team" : "GRUPPER",
    "group_calendar" : "KALENDRAR",
    "all_appointments" : "Alla möten",
    "my_calendar" : "Min kalender",
    "status_all" : "Alla",
    "status_confirmed" : "Bekräftad",
    "status_new" : "Ny (åtgärd krävs)",
    "status_show" : "Deltog",
    "status_no_show" : "Uteblev",
    "status_cancelled" : "Avbokade",
    "status_invalid" : "Ogiltig",
    "filter_date_added_desc" : "Datum tillagt (beskrivning)",
    "filter_start_added_desc" : "Startdatum (beskrivning)",
    "filter_start_added_asc" : "Startdatum, stigande från idag",
    "button_new_appointment" : "Nytt möte",
    "name" : "Namn",
    "title" : "Titel",
    "requested_time" : "Efterrågad tid",
    "date_added" : "Datum tillagt",
    "calendar" : "Kalender",
    "appointment_owner" : "Mötesägare",
    "notes" : "Anteckningar",
    "status" : "Status",
    "action_view_detail" : "Visa detaljer",
    "action_view_consent" : "Visa samtycke",
    "action_view_notes" : "Visa anteckningar",
    "action_edit" : "Redigera",
    "action_delete" : "Radera",
    "action_reschedule" : "Omboka",
    "page_previous" : "Föregående",
    "page_next" : "Nästa",
    "appointment_notes" : "Mötesanteckningar",
    "close" : "Stäng",
    "confirm_delete_header" : "Ta bort möte",
    "confirm_delete_message" : "Är du säker på att du vill ta bort det här mötet? Åtgärden kan inte ångras!",
    "confirm_delete_button" : "Radera",
    "confirm_update_header" : "Uppdatera mötesstatus",
    "confirm_update_confirmed_message" : "Är du säker på att du vill markera mötet som bekräftat?",
    "confirm_update_invalid_message" : "Är du säker på att du vill markera mötet som ogiltigt?",
    "confirm_update_showed_message" : "Är du säker på att du vill markera mötet som deltog?",
    "confirm_update_noshow_message" : "Är du säker på att du vill markera mötet som uteblev?",
    "confirm_update_cancelled_message" : "Är du säker på att du vill markera mötet som avbokat?",
    "confirm_update_button" : "Uppdatera",
    "empty_state_title" : "Inga möten hittades",
    "empty_state_description" : "Inga möten hittades",
    "disable_menu_warning" : "För närvarande har du inte behörighet att visa eller redigera mötesinformation eftersom den associerade kontakten inte är tilldelad till dig. Kontakta din administratör för ytterligare hjälp.",
    "no_contact_warning" : "Du kan inte komma åt kontaktinformation just nu. Det kan bero på att kontakten inte är tilldelad till dig. Kontakta administratören om du behöver hjälp.",
    "consent_label" : "Samtycke",
    "ip_address" : "IP",
    "youre_viewing_in" : "Tittar för närvarande i",
    "appointment_delete_msg" : "<b>Observera:</b> Mötet som raderas härifrån kommer inte att raderas från calendly.",
    "rescheduled" : "Ombokad"
  },
  "add_guest" : {
    "guest_label" : "Gäster",
    "search_placeholder" : "Sök från kontakter",
    "add_new" : "Lägg till ny",
    "save" : "Spara",
    "close" : "Stäng",
    "name" : "Namn",
    "phone" : "Telefon",
    "email" : "E-post",
    "guest_label_count" : "Antal gäster",
    "guest_count_error" : "Antal gäster ska vara mellan 1 och 100"
  },
  "languages" : {
    "english" : "Engelska",
    "dutch" : "Holländska",
    "french" : "Franska",
    "german" : "Tyska",
    "hungarian" : "Ungerska",
    "italian" : "Italienska",
    "polish" : "Polska",
    "portuguese_brazil" : "Portugisiska (Brasilien)",
    "portuguese_portugal" : "Portugisiska (Portugal)",
    "spanish" : "Spanska",
    "danish" : "Danska"
  },
  "service_menu_advanced" : {
    "new_service_menu_details_header" : "Information på tjänstemeny",
    "select_groups_header" : "Välj tjänster",
    "arrange_groups_header" : "Ordna dina tjänster",
    "new_service_menu" : "Ny tjänstemeny",
    "service_menu_sub_title" : "Skapa en personlig tjänstemeny för dina kunder med bara några klick",
    "quick_tip" : "Du kan lägga till grupper till tjänstemenyn",
    "service_menu_name" : "Namn på tjänstemeny",
    "service_menu_description" : "Beskrivning",
    "service_menu_slug" : "Tjänstens URL",
    "select_groups_calendars" : "Välj grupper och kalendrar",
    "select_groups_calendars_desc" : "Lägg till tjänster till din tjänstemeny",
    "select_all" : "Välj alla",
    "arrange_groups" : "Ordna tjänsternas följd",
    "arrange_groups_desc" : "Bestäm ordningsföljden för tjänsterna på tjänstemenyn",
    "arrange_services_quicktip" : "Om du vill ändra ordning på kalendrar inom en grupp går du till Grupper > Alternativ > Ordna om kalendrar.",
    "enable_add_guest_desc" : "Tillåter kunder att inkludera ytterligare gäster när de gör en bokning.",
    "enable_multiple_service_desc" : "Tillåter kunder att boka flera tjänster i ett enda möte.",
    "enable_staff_selection_desc" : "Tillåter kunder att välja föredragna medarbetare när de gör en bokning.",
    "enable_add_guest" : "Aktivera Lägg till gäster",
    "enable_multiple_service" : "Aktivera Val av flera tjänster",
    "enable_staff_selection" : "Aktivera Val av medarbetare",
    "additional_options" : "Ytterligare alternativ",
    "code_block_tooltip" : "Tredjepartsskript inuti HTML-blocket kan ge effektiva funktioner men de medför också risker för integritet, säkerhet, prestanda och sidbeteende. Därför rekommenderar vi att du granskar innehållet i det här skriptet innan du sparar det i tjänstemenyn.",
    "custom_code_placeholder" : "Ange anpassad kod här",
    "insert_custom_code" : "Infoga anpassad kod",
    "no_service" : "Ingen tjänst hittades",
    "no_service_desc" : " Inga tjänster hittades. Skapa tjänster och lägg till dem i grupper för att skapa en tjänstemeny.",
    "learn_more" : "Mer information",
    "no_service_selected" : "Ingen tjänst har valts",
    "create_service_calendars" : "Skapa tjänstekalendrar",
    "alert_description" : " För att kunna skapa tjänstemenyer måste du skapa tjänstekalendrar och lägga till dem i grupper",
    "dont_show_again" : "Visa inte detta igen",
    "service_name_required" : "Namn på tjänstemenyn krävs",
    "slug_already_taken" : "Denna slug är redan upptagen",
    "slug_required" : "Slug krävs",
    "please_enter_name" : "Ange ett namn",
    "service_name_placeholder" : "Huvudmeny",
    "consent_label" : "Kryssruta för samtycke",
    "consent_input_placeholder" : "Lägg till samtyckestext",
    "consent_tooltip" : "Aktivera det här alternativet för att tillåta inhämtande av samtycke från kontakter när de anger sitt telefonnummer under mötesbokningsprocessen."
  },
  "widget_configuration" : {
    "customise_calendar_widget" : "Anpassa kalenderwidgeten",
    "works_with_neo_widget" : "Fungerar endast med Neo-widgeten",
    "customise_widget_desc" : "Anpassa widgetens utseende: primär färg, bakgrundsfärg och knapptext.",
    "learn_more" : "Mer information",
    "primary_settings" : "Primära inställningar",
    "primary_settings_desc" : "Konfigurera primärfärg, bakgrundsfärg och andra widgetinställningar för att skräddarsy din kalenderwidget",
    "primary_color" : "Primärfärg",
    "primary_color_desc" : "Ställ in färgen på knappar, datum, tidsluckor och åtgärder",
    "background_color" : "Bakgrundsfärg",
    "background_color_desc" : "Ställ in bakgrundsfärgen som fungerar som bakgrund för både datum- och tidsväljaren och formuläret.",
    "button_text" : "Knapptext",
    "button_text_desc" : "Anpassa texten som visas på knappen när man bokar ett möte",
    "calendar_title" : "Kalendertitel",
    "calendar_title_desc" : "Namnet på din kalender",
    "calendar_description" : "Kalenderbeskrivning",
    "calendar_description_desc" : "Den angivna beskrivningen",
    "calendar_details" : "Kalenderinformation",
    "calendar_details_desc" : "Innehåller information som varaktighet, datum och tid, återkommande information och tidszon",
    "reset_to_default" : "Återställ till standard",
    "preview_widget" : "Förhandsgranska widget"
  },
  "eventNotification" : {
    "customFields" : "Custom Fields",
    "customValues" : "Custom Values",
    "list" : {
      "booked" : {
        "title" : "Appointment Booked (Status: Unconfirmed)",
        "note" : "This notification is sent when a new appointment is created with Unconfirmed status.",
        "subTitle" : "Meddelar när en tid bokas med statusen Ej bekräftad"
      },
      "confirmation" : {
        "title" : "Appointment Booked (Status: Confirmed)",
        "note" : "This notification is sent when an appointment is created with or updated to the Confirmed status.",
        "subTitle" : "Meddelar när ett möte har bekräftats"
      },
      "cancellation" : {
        "title" : "Cancellation",
        "note" : "This notification is sent when an appointment is cancelled, marked as no-show, or marked as invalid.",
        "subTitle" : "Meddelar när en tid avbokas"
      },
      "reschedule" : {
        "title" : "Reschedule",
        "note" : "This notification is sent when an appointment is rescheduled. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Meddelar när ett möte bokas om"
      },
      "reminder" : {
        "title" : "Reminder",
        "note" : "This notification is sent as a reminder before the appointment. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle" : "Skickar en påminnelse innan mötet"
      },
      "followup" : {
        "title" : "Follow-Up",
        "note" : "This notification is sent after the appointment as a follow-up. It will only be triggered if the appointment is in Unconfirmed, Confirmed, or Showed status.",
        "subTitle" : "Skickar ett uppföljningsmeddelande efter att mötet är klart"
      }
    },
    "saveToastMsg" : "Inställningarna har sparats!",
    "saveToastMsgDesc" : "Dina ändringar har sparats",
    "enableSuccess" : "Aviseringar har aktiverats!",
    "disableSuccess" : "Aviseringar har inaktiverats!",
    "tooltip" : "This notification has been disabled. Please enable it to make any changes.",
    "enabled" : "Enabled",
    "disabled" : "Disabled",
    "statusLabel" : "Status Labels",
    "email" : {
      "channelName" : "Email",
      "noneTemplate" : "Ingen",
      "notificationLabel" : "Vem ska få den här aviseringen?",
      "sendButtonLabel" : "Skicka testmejl",
      "testEmailLabel" : "Testmejl",
      "testEmailPlaceholder" : "Ange mottagarens e-postadress för testet",
      "body" : "E-postmeddelandets brödtext",
      "bodyPlaceholder" : "Skriv ditt meddelande här",
      "subject" : "Ämne",
      "subjectPlaceholder" : "Ange e-postmeddelandets ämne",
      "templateLabel" : "E-postmall",
      "templatePlaceholder" : "Välj en e-postmall eller börja från grunden",
      "alertEmailAddress" : "Informera e-postadress",
      "addMore" : "Lägg till mer",
      "afterAppointmentEnds" : "efter att mötet är slut",
      "beforeAppointmentStarts" : "innan mötet börjar",
      "durationLabel" : "När vill du att aviseringen ska skickas?",
      "disableEmailNotification" : "Inaktivera e-postaviseringar",
      "enableEmailNotification" : "Aktivera e-postaviseringar",
      "enableSuccess" : "E-postavisering har aktiverats!",
      "enableSuccessDesc" : "Du kommer nu att börja få den här aviseringen",
      "disableSuccess" : "E-postavisering har inaktiverats!",
      "disableSuccessDesc" : "Du kommer inte längre att få den här aviseringen"
    },
    "inApp" : {
      "channelName" : "In-app",
      "title" : "Aviseringsinställningar",
      "enableInAppNotification" : "Enable In-App Notification",
      "disableInAppNotification" : "Disable In-App Notification",
      "selectedUser" : {
        "label" : "Skicka till",
        "helperText" : "Den här interna aviseringen skickas via webben och mobilappar",
        "placeholder" : "Välj användare"
      },
      "enableSuccess" : "Aviseringar i appen har aktiverats!",
      "enableSuccessDesc" : "Du kommer nu att börja få den här aviseringen",
      "disableSuccess" : "Aviseringar i appen är inaktiverade!",
      "disableSuccessDesc" : "Du kommer inte längre att få den här aviseringen"
    }
  }
}