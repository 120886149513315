<script lang="ts" setup>
import AppState from '@/states/app'
import { UIButton } from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'
import accessDenied from '../../assets/accessDenied.svg'
const { t } = useI18n()

defineProps({
  header: String,
  description: String,
})
</script>

<template>
  <div class="my-8 flex w-full flex-col items-center justify-center">
    <img :src="accessDenied" class="mb-2" />
    <div class="mb-1 text-[16px] font-medium leading-6 text-gray-900">
      {{ header }}
    </div>
    <div
      class="mb-1 w-[34%] text-center text-[14px] font-normal leading-6 text-gray-500"
    >
      {{ description }}
    </div>

    <UIButton
      v-if="
        AppState.loggedInUserDetail?.type === 'agency' ||
        AppState.loggedInUserDetail?.role === 'admin'
      "
      id="myStaff"
      type="primary"
      class="mt-4 px-3"
      @click="()=>{
         if (AppState.parentApp) {
          AppState.parentApp.then((parent: any) => {
            parent.emit('go-to-settings-team')
          })
        }
      }"
    >
      {{ t('settings.availability.accessRestricted.button_text') }}
    </UIButton>
  </div>
</template>
