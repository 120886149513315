<template>
  <UIModal :show="showModal" :width="750">
    <template #header>
      <UIModalHeader
        id="calendarGroupCreateModalHeader"
        type="success"
        :title="calendar?.name"
        @close="handleCancel"
      />
      <div class="flex flex-row space-x-5 text-sm font-normal text-gray-600">
        <div>
          <ClockIcon class="mb-1 h-4 w-4" />
          <span class="p-2"
            >{{ calendar?.slotDuration }}
            {{ calendar?.slotDurationUnit || 'mins' }}</span
          >
        </div>
        <div>
          <CalendarIcon class="mb-1 h-4 w-4" />
          <span class="p-2">{{ calendarType }}</span>
        </div>
      </div>
      <UIDivider class="!-m-1" />
    </template>
    <div class="mx-4 !-mt-3 px-4">
      <UITabs
        v-model:value="tabType"
        type="line"
        :justify-content="'space-between'"
        class="p-2"
      >
        <!-- 1 Scheduling link tab -->
        <UITabPane name="scheduling-link" tab="Scheduling Link">
          <div class="mt-4">
            <SchedulingLink :calendar="calendar" />
          </div>
        </UITabPane>

        <!-- 2 Scheduling link tab -->
        <UITabPane name="one-time-scheduling-link" tab="One Time Link">
          <div class="mt-4">
            <OneTimeLink :calendar="calendar" />
          </div>
        </UITabPane>

        <!-- 3 Scheduling link tab -->
        <UITabPane name="embed-code" tab="Embed Code">
          <div class="mt-4">
            <EmbedCode :calendar="calendar" />
          </div>
        </UITabPane>
      </UITabs>
    </div>
  </UIModal>
</template>

<script setup lang="ts">
import { CalendarIcon, ClockIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIDivider,
  UIModal,
  UIModalHeader,
  UITabPane,
  UITabs,
} from '@gohighlevel/ghl-ui'
import { Prop, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Calendar from '../../models/calendar'
import EmbedCode from './CalendarShare/EmbedCode.vue'
import OneTimeLink from './CalendarShare/OneTimeLink.vue'
import SchedulingLink from './CalendarShare/SchedulingLink.vue'

const { t } = useI18n()
const emit = defineEmits(['closeModal'])
const props = defineProps({
  showModal: Boolean,
  calendar: {} as Prop<Calendar>,
})

const tabType = ref('scheduling-link')
const calendarType = computed(() => {
  return props.calendar?.calendarType === 'event'
    ? t('settings.calendar.event')
    : props.calendar?.calendarType === 'class_booking'
    ? t('settings.calendar.class_booking')
    : props.calendar?.calendarType === 'collective'
    ? 'Collective Booking'
    : props.calendar?.calendarType === 'service_booking'
    ? 'Service'
    : props.calendar?.calendarType === 'personal'
    ? t('settings.calendar.personal_booking')
    : t('settings.calendar.round_robin')
})
const handleCancel = () => {
  tabType.value = 'scheduling-link'
  emit('closeModal')
}
</script>
