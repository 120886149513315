<template>
  <MenuItem as="div" class="my-1 flex items-center text-xs">
    <div class="overflow-menu-icon">
      <slot />
    </div>
    <!-- <span v-if="label" class="ml-2">{{ label }}</span> -->
  </MenuItem>
</template>

<script lang="ts">
import { MenuItem } from '@headlessui/vue'

export default {
  components: {
    MenuItem,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.overflow-menu-icon {
  border-radius: 0.25rem;
  outline: none;
  transition: 0.3s ease-in-out;
}

.overflow-menu-icon:hover {
  background-color: #e8f3fe;
}
</style>
