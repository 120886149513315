<script setup lang="ts">
import { MyAvailability } from '@/class/pages/MyAvailability'
import { timezones } from '@/utils/timezones'
import { UICheckbox, UITimePicker } from '@gohighlevel/ghl-ui'
import moment from 'moment-timezone'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import AppState from '../../states/app'

const { t } = useI18n()
const myAvailability = ref(new MyAvailability(timezones[0].value))

const daysOfTheWeekOptions = computed(() => {
  return MyAvailability.OFFICE_DAYS.map(day => {
    return {
      label: day,
      value: day,
    }
  })
})

const locationTimezone = computed(() => {
  const locationTimezone = AppState.locationTimezone

  const utcOffsetInMinutes = moment.tz(locationTimezone).utcOffset()

  const offsetHours = Math.floor(Math.abs(utcOffsetInMinutes) / 60)
  const offsetMinutes = Math.abs(utcOffsetInMinutes) % 60

  return `${locationTimezone} (UTC${
    utcOffsetInMinutes >= 0 ? '+' : '-'
  }${offsetHours.toString().padStart(2, '0')}:${offsetMinutes
    .toString()
    .padStart(2, '0')})`
})

const methods = {
  getAvailability() {
    const openHours = myAvailability.value.availableDays.map(day =>
      day.serializeAsPerAPIFormat()
    )

    const basicOpenHours =
      myAvailability.value.basicDay.serializeAsPerAPIFormat()

    openHours.forEach(oh => {
      oh!.hours = basicOpenHours?.hours ? basicOpenHours.hours : []
    })

    return openHours
  },
}

onMounted(() => {
  MyAvailability.OFFICE_DAYS.slice(1, 6).forEach(day => {
    myAvailability.value.dayWiseOpenMap[day] = true
    myAvailability.value.addAvailabilityDay(day)
  })
})

defineExpose({
  methods,
})
</script>

<template>
  <div class="w-full">
    <div class="">
      <div class="text-md font-normal">
        {{ $t('create_calendar.booking_availability') }}
      </div>
      <div class="text-gray-400">
        {{ locationTimezone }}
      </div>
    </div>

    <div class="mt-3 flex border-t py-4">
      <UICheckbox
        v-for="day in MyAvailability.OFFICE_DAYS"
        :id="day"
        :key="day"
        v-model:checked="myAvailability.dayWiseOpenMap[day]"
        :name="day"
        class="w-38 mr-2 flex content-center items-center rounded-md border bg-white px-1 py-2"
        :class="{
          'border-primary-500 bg-primary-50': myAvailability.availableDays.find(
            x => x.day === day
          ),
        }"
        @change="
          x =>
            x
              ? myAvailability.addAvailabilityDay(day)
              : myAvailability.removeAvailabilityDay(day)
        "
      >
        <span class="ml-1">{{ day.charAt(0) }}</span>
      </UICheckbox>
    </div>

    <div
      v-if="myAvailability.availableDays.length > 0"
      class="my-4 flex flex-col"
    >
      <div class="flex flex-col space-y-1">
        <div
          v-for="(availableDayHour, hourIndex) in myAvailability.basicDay.hours"
          :key="hourIndex"
          class="flex items-center space-x-3"
        >
          <UITimePicker
            v-model:formatted-value="availableDayHour.startTime"
            class="w-56"
            value-format="H:m"
            :minutes="5"
            format="hh:mm a"
            use-12-hours
            :actions="['confirm']"
            :status="
              myAvailability.basicDay.hoursErrorMapping[hourIndex] ||
              !availableDayHour.startTime
                ? 'error'
                : 'success'
            "
          />

          <div class="">to</div>

          <UITimePicker
            v-model:formatted-value="availableDayHour.endTime"
            class="w-56"
            value-format="H:m"
            :minutes="5"
            format="hh:mm a"
            use-12-hours
            :actions="['confirm']"
            :status="
              myAvailability.basicDay.hoursErrorMapping[hourIndex] ||
              !availableDayHour.endTime
                ? 'error'
                : 'success'
            "
          />
        </div>
      </div>

      <div class="mt-3 font-light text-gray-400">
        To further customize your business hours, please navigate to the
        advanced settings.
      </div>
    </div>
  </div>
</template>
