<script setup lang="ts">
import { CalendarService } from '@/class/services/CalendarService'
import Calendar from '@/models/calendar'
import AppState, {
  getCalendarsInLocation,
  getTeamsInLocation,
} from '@/states/app'
import { Menu05Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UIModal, UIModalFooter, UIModalHeader } from '@gohighlevel/ghl-ui'
import { computed, ref, watch } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'

const emit = defineEmits([
  'closeModal',
  'createdRecord',
  'uploadCSV',
  'update:show',
])

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  teamId: {
    type: String,
    default: '',
  },
})

const isSlugValidating = ref(false)
const saving = ref(false)
const calendars = ref<Calendar[]>([])

const formValue = ref({
  name: '',
  description: '',
  slug: '',
})

const teamCalendars = computed(() => {
  const calendars = (AppState?.globalCalendars?.calendars ||
    []) as unknown as Calendar[]

  return calendars.filter(
    calendar => calendar.providerId && calendar.providerId === props.teamId
  )
})
watch(
  () => props.teamId,
  () => {
    const calendarsSortedByOrder = teamCalendars.value.sort(
      (a, b) => a.order - b.order
    )

    calendars.value = calendarsSortedByOrder
  }
)

const saveOrder = async () => {
  const calendarOrderMap = calendars.value.reduce(
    (acc, calendar, index) => ({
      ...acc,
      [calendar.id]: index + 1,
    }),
    {}
  )

  saving.value = true
  await CalendarService.reorder(
    AppState.locationId,
    props.teamId,
    calendarOrderMap
  )

  await Promise.all([getCalendarsInLocation(true), getTeamsInLocation(true)])
  saving.value = false

  emit('closeModal')
}
</script>

<template>
  <UIModal :show="show" @update:show="val => $emit('update:show', val)">
    <template #header>
      <UIModalHeader
        id="calendarRearrangeModalHeader"
        type="success"
        :title="$t('settings.calendar.rearrange_calendars')"
        @close="$emit('closeModal')"
      />
    </template>

    <VueDraggableNext :list="calendars" class="dragArea list-group w-full">
      <transition-group name="flip-list">
        <div
          v-for="teamCal in calendars"
          :key="teamCal.id"
          class="flex cursor-move items-center space-x-5 border bg-gray-100 px-6 py-6 font-semibold"
        >
          <div class="">
            <Menu05Icon class="h-4 w-4" />
          </div>
          <div class="">
            {{ teamCal.name }}
          </div>
        </div>
      </transition-group>
    </VueDraggableNext>

    <template #footer>
      <UIModalFooter
        id="affiliateCreateModalFooter"
        :positive-text="$t('settings.calendar.save')"
        :negative-text="$t('settings.calendar.cancel')"
        :loading="saving"
        :disabled="isSlugValidating || saving"
        @positive-click="saveOrder"
        @negative-click="$emit('closeModal')"
      />
    </template>
  </UIModal>
</template>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
</style>
