<script setup lang="ts">
const props = defineProps({
  modalFunction: {
    type: Function || undefined,
    default: undefined,
    required: false,
  },
  imageSource: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  example: {
    type: String,
    required: true,
  },
  calendarType: {
    type: String,
    required: false,
  },
})

const handleClick = () => {
  if (props.modalFunction) {
    if (props.calendarType) {
      props.modalFunction(props.calendarType)
    } else {
      props.modalFunction()
    }
  }
}
</script>

<template>
  <div
    class="min-h-20 mx-2 mt-4 flex w-full cursor-pointer items-center rounded-xl border border-gray-200 p-4 px-2 hover:border-blue-400 lg:w-[48%]"
    role="button"
    @click="handleClick"
    @keypress="handleClick"
  >
    <div>
      <img :src="imageSource" class="w-8" alt="" />
    </div>
    <div class="flex w-full flex-col px-3 text-start">
      <div class="text-sm font-medium">
        {{ title }}
      </div>
      <div class="text-gray-400">
        {{ description }}
      </div>
      <div class="text-gray-400">
        {{ example }}
      </div>
    </div>
  </div>
</template>
