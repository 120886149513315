<!-- eslint-disable no-empty-function -->
<script setup lang="ts">
import { InfoCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIDivider,
  UIForm,
  UIModal,
  UIModalFooter,
  UIModalHeader,
  UIRadio,
  UISwitch,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { computed, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import AppState from '../../states/app'

const { t } = useI18n()

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  closeModal: {
    type: Function,
    default: () => {},
  },
})

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const state = reactive({
  enableSameUserAssignment:
    editingCalendar?.value?.enableSameUserAssignment || false,
  enableSameUserAssignmentForReschedule:
    editingCalendar?.value?.enableSameUserAssignmentForReschedule || false,
  calendarId: editingCalendar?.value?.id || '',
  isFormFirst: AppState.isFormFirstEnabled,
})

watch(
  () => props.show,
  newVal => {
    if (newVal) {
      state.enableSameUserAssignment =
        editingCalendar?.value?.enableSameUserAssignment || false
      state.enableSameUserAssignmentForReschedule =
        editingCalendar?.value?.enableSameUserAssignmentForReschedule || false
      state.calendarId = editingCalendar?.value?.id || ''
      state.isFormFirst = AppState.isFormFirstEnabled
    }
  }
)

const emit = defineEmits(['onAdvancedSchedulingSettingsUpdate'])

const onSaveForm = () => {
  if (editingCalendar?.value) {
    editingCalendar.value.enableSameUserAssignment =
      state.enableSameUserAssignment || false
    editingCalendar.value.enableSameUserAssignmentForReschedule =
      state.enableSameUserAssignmentForReschedule || false
  }
  emit('onAdvancedSchedulingSettingsUpdate', {
    enableSameUserAssignment: state.enableSameUserAssignment,
    enableSameUserAssignmentForReschedule:
      state.enableSameUserAssignmentForReschedule,
  })
  props.closeModal()
}
</script>
<template>
  <UIModal :show="show">
    <template #header>
      <UIModalHeader
        id="calendarAdvancedSettingsModalHeader"
        type="success"
        class=""
        :title="t('create_calendar.advanced_settings')"
        @close="props.closeModal"
      />
    </template>
    <UIForm
      id="calendarAdvancedSettingsForm"
      ref="formRef"
      class="mb-3 mt-0 h-full w-full max-w-7xl pt-0 sm:max-w-7xl"
    >
      <div>
        <div class="flex flex-row">
          <p class="m-0 p-0 text-sm font-medium">
            {{ t('create_calendar.reschedule_preference') }}
          </p>
          <UITooltip :placement="'top'">
            <template #trigger>
              <InfoCircleIcon class="mx-2 w-4 text-gray-500" />
            </template>
            <p class="max-w-sm">
              {{
                $t(
                  'calendar_advanced.meeting_details.reschedule_preference_setting_tooltip'
                )
              }}
            </p>
          </UITooltip>
        </div>
        <div>
          <p class="m-0 mb-2 mt-2 p-0 text-sm font-light text-gray-500">
            {{ t('create_calendar.reschedule_preference_subtext') }}
          </p>
          <div class="flex w-full flex-col">
            <UIRadio
              id=""
              class="flex bg-white p-1 pl-0"
              :checked="state.enableSameUserAssignmentForReschedule !== true"
              value="true"
              name="default"
              @change="state.enableSameUserAssignmentForReschedule = false"
            >
              <span class="text-[14px] text-gray-700">{{
                t('create_calendar.reassign_through_round_robin')
              }}</span>
            </UIRadio>
            <UIRadio
              id=""
              class="flex bg-white p-1 pl-0"
              :checked="state.enableSameUserAssignmentForReschedule === true"
              value="false"
              name="default"
              @change="state.enableSameUserAssignmentForReschedule = true"
            >
              <span class="text-[14px] text-gray-700">{{
                t('create_calendar.keep_same_assinged_user')
              }}</span>
            </UIRadio>
          </div>
        </div>
        <UIDivider />
        <div>
          <div class="flex flex-row">
            <p class="m-0 pb-[10px] text-sm font-medium text-gray-700">
              {{ t('create_calendar.new_appointment_preference') }}
            </p>
            <UITooltip :placement="'top'">
              <template #trigger>
                <InfoCircleIcon class="mx-2 -mt-2 w-4 text-gray-500" />
              </template>
              <p class="max-w-sm">
                {{
                  $t(
                    'calendar_advanced.meeting_details.appointment_preference_tooltip'
                  )
                }}
              </p>
            </UITooltip>
          </div>
          <div
            id="same-user-assignment-Toggle"
            class="mt-2 flex w-full flex-row justify-center gap-[5px]"
          >
            <UISwitch
              v-model:value="state.enableSameUserAssignment"
              size="medium"
              class="mr-1.5 mt-1"
              :disabled="!state.isFormFirst"
              aria-label="same-user-assignment-toggle"
            />

            <UITooltip v-if="!state.isFormFirst" :placement="'top'">
              <template #trigger>
                <div
                  class="ml-2 mt-[-2px] flex w-full cursor-not-allowed flex-col"
                >
                  <div class="mb-[3px] text-sm">
                    {{ t('create_calendar.new_appointment_preference_text') }}
                  </div>
                  <div class="text-[13px] font-normal leading-5 text-gray-500">
                    {{
                      t('create_calendar.new_appointment_preference_subtext')
                    }}
                  </div>
                </div>
              </template>
              <p class="max-w-sm">
                {{
                  $t(
                    'calendar_advanced.meeting_details.enable_contact_assigned_to_setting'
                  )
                }}
              </p>
            </UITooltip>
            <div v-else class="ml-2 mt-[-2px] flex w-full flex-col">
              <div class="mb-[3px] text-sm">
                {{ t('create_calendar.new_appointment_preference_text') }}
              </div>
              <div class="text-[13px] font-normal leading-5 text-gray-500">
                {{ t('create_calendar.new_appointment_preference_subtext') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UIForm>

    <template #footer>
      <UIModalFooter
        id="affiliateCreateModalFooter"
        :positive-text="t('settings.calendar.apply')"
        :negative-text="t('settings.calendar.cancel')"
        @negative-click="props.closeModal"
        @positive-click="onSaveForm"
      />
    </template>
  </UIModal>
</template>
