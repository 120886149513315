<template>
  <div class="rounded-md border border-t-0 bg-gray-25 p-4">
    <div class="mb-4 flex space-x-3 py-2">
      <UISwitch
        v-model:value="disabled"
        class="w-1/3"
        size="medium"
        aria-label="in-app-notification-switch"
        @update:value="
          value => {
            toggleNotification(value)
          }
        "
      />
      <span class="text-gray-600">{{
        disabled
          ? t('eventNotification.enabled')
          : t('eventNotification.disabled')
      }}</span>
    </div>
    <div class="flex w-full flex-row">
      <UIForm
        ref="testEmailFormRef"
        :model="state.inAppNotification"
        class="w-full space-y-4"
        :class="{ 'min-h-[10rem]': !showDurationPicker }"
      >
        <UIFormItem
          :label="t('eventNotification.inApp.selectedUser.label')"
          :feedback="t('eventNotification.inApp.selectedUser.helperText')"
        >
          <UITooltip :trigger="!disabled ? 'hover' : 'manual'">
            <template #trigger>
              <UISelect
                :key="state.calendarId"
                v-model:value="state.inAppNotification.selectedUsers"
                :options="usersInLocationOptions"
                :disabled="!disabled"
                :placeholder="
                  t('eventNotification.inApp.selectedUser.placeholder')
                "
                :multiple="true"
                :filterable="true"
                @change="updateSelectedUsers"
              />
            </template>
            <div>{{ t('eventNotification.tooltip') }}</div>
          </UITooltip>
        </UIFormItem>
      </UIForm>
    </div>
    <!-- duration picker -->
    <div
      v-if="showDurationPicker"
      :id="'notification-duration-picker-in-app'"
      class="py-4"
      :class="{ 'disabled-div': !disabled }"
    >
      <span class="font-medium text-gray-700">
        {{ t('eventNotification.email.durationLabel') }}
      </span>
      <div
        v-for="(d, i) in isFollowupNotifications
          ? state.inAppNotification.afterTime
          : state.inAppNotification.beforeTime"
        :key="'duration-picker-' + i"
        class="flex flex-row items-center space-x-3"
        :class="{ '!-mb-10 !-mt-5': i === 0, '!-my-10': i > 0 }"
      >
        <DurationPicker
          :id="'before-time-picker-in-app' + i"
          class="w-1/2"
          :value="d.timeOffset"
          :unit="d.unit === 'minutes' ? 'mins' : d.unit"
          @on-value-change="
            value => {
              d.timeOffset = value.duration
              d.unit = value.timeUnit === 'mins' ? 'minutes' : value.timeUnit
            }
          "
        />

        <span class="mt-5 flex items-center">{{
          isFollowupNotifications
            ? t('eventNotification.email.afterAppointmentEnds')
            : t('eventNotification.email.beforeAppointmentStarts')
        }}</span>
        <UIButton
          v-if="i > 0"
          id="remove-availability-hour"
          class="m-x-4 mt-4 text-red-600"
          :text="true"
          @click="removeHour(i)"
        >
          <Trash02Icon class="w-4 items-center" />
        </UIButton>
      </div>
      <div
        v-if="shouldEnableAddMore"
        class="!mt-4 w-[15%] py-4"
        role="button"
        @click="addHour"
        @keypress="addHour"
      >
        <a
          use="outline"
          class="mt-5 cursor-pointer bg-white text-blue-500"
          size="large"
          aria-label="add-more"
        >
          <PlusIcon class="mr-1 w-5" />
          {{ t('eventNotification.email.addMore') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EventNotificationService } from '@/class/services/EventNotificationService'
import { useNotify } from '@/components/composition/notification'
import User from '@/models/user'
import AppState from '@/states/app'
import {
  getEventNotificationState,
  eventNotificationState as state,
} from '@/states/EventNotificationState'
import { NotificationType } from '@/types/notification'
import { PlusIcon, Trash02Icon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIForm,
  UIFormItem,
  UISelect,
  UISwitch,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DurationPicker from '../DurationPicker.vue'
const { sendNotification } = useNotify()

const { t } = useI18n()

const disabled = ref(false)
const emit = defineEmits(['update-notification'])

const shouldEnableAddMore = computed(() => {
  return isFollowupNotifications.value
    ? state.inAppNotification.afterTime &&
        state.inAppNotification.afterTime.length < 3
    : state.inAppNotification.beforeTime &&
        state.inAppNotification.beforeTime.length < 3
})

const usersInLocationOptions = computed(() => {
  if (state.isEventCalendar) {
    const accountAdmin = ((AppState.globalUsers as any)?.users?.map(
      (user: User) => {
        return { label: user.name, value: user.id }
      }
    ) || []) as any[]
    return [{ label: 'Account Admin', value: 'sub_account_admin' }].concat(
      accountAdmin
    )
  }
  const users = (AppState.globalUsers as any)?.users.map((user: User) => {
    return { label: user.name, value: user.id }
  })
  return [{ label: 'Assigned User', value: 'assigned_user' }].concat(users)
})

const showDurationPicker = computed(() => {
  return [NotificationType.REMINDER, NotificationType.FOLLOWUP].includes(
    state.inAppNotification.notificationType
  )
})
const isFollowupNotifications = computed(() => {
  return state.inAppNotification.notificationType === NotificationType.FOLLOWUP
})

onMounted(() => {
  if (state.isEventCalendar && !state.inAppNotification.selectedUsers) {
    state.inAppNotification.selectedUsers = ['sub_account_admin']
  } else if (!state.isEventCalendar && !state.inAppNotification.selectedUsers) {
    state.inAppNotification.selectedUsers = ['assigned_user']
  }
  if (state.inAppNotification.beforeTime?.length === 0) {
    state.inAppNotification.beforeTime = [{ timeOffset: 10, unit: 'minutes' }]
  }
  if (state.inAppNotification.afterTime?.length === 0) {
    state.inAppNotification.afterTime = [{ timeOffset: 1, unit: 'hours' }]
  }
  disabled.value = getEventNotificationState('inApp')
})

async function toggleNotification(value) {
  state.inAppNotification.deleted = !value
  disabled.value = value
  state.disableSave = true
  try {
    const payload = Object.assign(state.inAppNotification)
    if (payload._id) {
      await EventNotificationService.updateEventNotifications(payload)
    } else {
      delete payload._id
      const { data } = await EventNotificationService.createEventNotifications(
        payload
      )
      state.inAppNotification._id = data[0]._id
    }
  } catch (error) {
    sendNotification({
      type: 'error',
      title: 'failed to update in-app notification',
      icon: 'AlertCircleIcon',
    })
    return
  } finally {
    state.disableSave = false
  }
  handleOuterNotification(value, state.inAppNotification.notificationType)
  sendNotification({
    type: 'success',
    title: value
      ? t('eventNotification.inApp.enableSuccess')
      : t('eventNotification.inApp.disableSuccess'),
    icon: 'CheckCircleIcon',
  })
}

function handleOuterNotification(value, type) {
  emit('update-notification', {
    channel: 'inApp',
    type: state.inAppNotification.notificationType,
    status: value,
  })
}

const updateSelectedUsers = selectedUsers => {
  state.inAppNotification.selectedUsers = selectedUsers
}
function addHour() {
  if (isFollowupNotifications.value) {
    state.inAppNotification.afterTime?.push({
      timeOffset: 10,
      unit: 'minutes',
    })
  } else {
    state.inAppNotification.beforeTime?.push({
      timeOffset: 10,
      unit: 'minutes',
    })
  }
}
function removeHour(i: number) {
  if (isFollowupNotifications.value) {
    state.inAppNotification.afterTime?.splice(i, 1)
  } else {
    state.inAppNotification.beforeTime?.splice(i, 1)
  }
}
</script>

<style scoped>
.disabled-div {
  position: relative;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
</style>
