<script setup lang="ts">
import { CalendarCheck01Icon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UICheckbox,
  UIModal,
  UIModalFooter,
  UIModalHeader,
  UISelect,
} from '@gohighlevel/ghl-ui'

import Team from '@/models/team'
import AppState from '@/states/app'
import { computed, ref, watch } from 'vue'
const props = defineProps({
  show: Boolean,
  disabledConfirm: Boolean,
  currentTeam: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['onConfirm', 'onModalClose', 'update:show'])

const handleSave = () => {
  if (props.currentTeam === selectedTeam.value) {
    return alert('Please select a different group')
  }

  emit('onConfirm', selectedTeam.value)
}
const handleCancel = () => {
  emit('onModalClose')
}

const teams = computed(() => {
  return AppState.globalTeams?.teams || []
})

const teamOptions = computed(() => {
  const teamOptions = teams.value.map((team: Team) => ({
    label: team.name,
    value: team.id,
  }))

  return [
    {
      label: 'Unassigned (No Group)',
      value: '',
    },
    ...teamOptions,
  ]
})

const selectedTeam = ref('')
const noteChecked = ref(false)

watch(
  () => props.show,
  () => {
    selectedTeam.value = props.currentTeam as string
    noteChecked.value = false
  }
)
</script>

<template>
  <UIModal
    id="generalConfirmationModal"
    :width="500"
    :show="show"
    @update:show="val => $emit('update:show', val)"
  >
    <template #header>
      <UIModalHeader
        id="generalConfirmationModalHeader"
        type="primary"
        :title="$t('settings.calendar.select_calendar_group')"
        :icon="CalendarCheck01Icon"
        @close="handleCancel"
      />
    </template>

    <UISelect
      id=""
      v-model:value="selectedTeam"
      placeholder="Select one"
      :options="teamOptions"
    />
    <UICheckbox
      id="can-trigger-run"
      v-model:checked="noteChecked"
      class="mt-4 text-sm text-gray-700"
    >
      {{ $t('settings.calendar.move_calendar_confirmation') }}
    </UICheckbox>

    <template #footer>
      <UIModalFooter
        id="generalConfirmationModalFooter"
        :negative-text="$t('settings.calendar.cancel')"
        :positive-text="$t('settings.calendar.select')"
        :loading="disabledConfirm"
        :disabled="disabledConfirm || !noteChecked"
        @negative-click="handleCancel"
        @positive-click="handleSave"
      />
    </template>
  </UIModal>
</template>
